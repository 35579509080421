import { useEffect, useState } from "react";
import { BiCaretDown, BiCaretUp, BiDownArrow, BiPaint, BiPowerOff } from "react-icons/bi";
import { BsGrid, BsMenuApp, BsMenuButton } from "react-icons/bs";
import { FaArrowLeft, FaList, FaPowerOff, FaTimes } from "react-icons/fa";
import { IoListSharp, IoReceiptSharp } from "react-icons/io5";
import { RiHomeSmileLine } from "react-icons/ri";
import { Text, TouchableOpacity, View, TextInput, Platform, ActivityIndicator, Keyboard, Dimensions, ScrollView } from "react-native";
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from "react-router-dom";
import { primarybg, primaryColour, secondarybg } from "../utils/string";
import '../utils/style.css'
import { MdCategory, MdCollections } from "react-icons/md";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { shadow } from "../utils/main";
import { AiFillSetting } from "react-icons/ai";

const Icon = () => (<></>);

export default function Sidebar(props) {

    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const { children, active, notScrollView, title, notHeaderBottom } = props

    const [orderMenu, setorderMenu] = useState(false);
    const [appearanceMenu, setappearanceMenu] = useState(false);

    const [showSidebar, setshowSidebar] = useState(false);

    useEffect(() => {
        (active == 'ordersPlaced' || active == 'ordersReceived' || active == 'orders') && setorderMenu(true);
        (active == 'customization' || active == 'themes') && setappearanceMenu(true)
    }, [props])

    const Header = () => (
        <View style={[{
            backgroundColor: primarybg, width: '100%', paddingHorizontal: 10, paddingVertical: 10,
            marginBottom: typeof notHeaderBottom == 'undefined' && 20, flexDirection: 'row', alignItems: 'center'
        }, shadow]}>
            <TouchableOpacity onPress={() => navigate(-1)}>
                <FaArrowLeft size={20} color={secondarybg} />
            </TouchableOpacity>
            <Text style={{ fontSize: 20, fontWeight: 'bold', color: primaryColour, marginLeft: isDesktopOrLaptop ? 20 : 20, width: '100%', fontSize: isDesktopOrLaptop ? 20 : 17 }} selectable={true}>
                {typeof title != 'undefined' && title}
            </Text>
            {!isDesktopOrLaptop &&
                <TouchableOpacity onPress={() => setshowSidebar(!showSidebar)}>
                    <FaList size={20} color={secondarybg} />
                </TouchableOpacity>
            }
        </View>
    )

    const SidebarList = () => (
        <View style={[{ backgroundColor: primaryColour, minWidth: 230, paddingTop: '3%' },
        isDesktopOrLaptop ? { flex: 0.1, } :
            { height: '100%', position: 'fixed', zIndex: 2000000, }
        ]}>
            {!isDesktopOrLaptop &&
                <TouchableOpacity style={{ alignSelf: 'flex-end', marginRight: '5%' }} onPress={() => setshowSidebar(false)} ><FaTimes color={primarybg} /></TouchableOpacity>
            }
            <Text style={{ fontSize: 40, fontFamily: 'Lobster', textAlign: 'center', color: secondarybg, marginBottom: 50 }}>Bazars.pk</Text>
            <TouchableOpacity style={{
                flexDirection: 'row', marginHorizontal: '5%', paddingHorizontal: '5%', alignItems: 'center', backgroundColor: active == 'home' && '#ffffff21', paddingVertical: 10,
                borderRadius: 10, marginBottom: 5
            }}
                onPress={() => navigate('/dashboard')}
            >
                <RiHomeSmileLine size={26} color={primarybg} />
                <Text style={{ color: primarybg, marginLeft: 10, fontWeight: 'bold', fontSize: 16 }}>Home</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{
                flexDirection: 'row', marginHorizontal: '5%', paddingHorizontal: '5%', alignItems: 'center', backgroundColor: active == 'myItems' && '#ffffff21', paddingVertical: 10,
                borderRadius: 10, marginBottom: 5
            }}
                onPress={() => navigate('/my-items')}
            >
                <BsGrid size={26} color={primarybg} />
                <Text style={{ color: primarybg, marginLeft: 10, fontWeight: 'bold', fontSize: 16 }}>My Items</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{
                flexDirection: 'row', marginHorizontal: '5%', paddingHorizontal: '5%', alignItems: 'center', backgroundColor: active == 'myCatalogs' && '#ffffff21', paddingVertical: 10,
                borderRadius: 10, marginBottom: 5
            }}
                onPress={() => navigate('/my-catalogs')}
            >
                <MdCollections size={26} color={primarybg} />
                <Text style={{ color: primarybg, marginLeft: 10, fontWeight: 'bold', fontSize: 16 }}>My Categories</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{
                flexDirection: 'row', marginHorizontal: '5%', paddingHorizontal: '5%', alignItems: 'center',
                backgroundColor: (active == 'ordersReceived' || active == 'ordersPlaced' || active == 'orders') && '#ffffff21', paddingVertical: 10,
                borderRadius: 10, marginBottom: 0
            }}
                onPress={() => setorderMenu(!orderMenu)}
            >
                <IoReceiptSharp size={26} color={primarybg} />
                <Text style={{ color: primarybg, marginLeft: 10, fontWeight: 'bold', fontSize: 16 }}>Orders</Text>
                {orderMenu ? <BiCaretDown size={20} color={primarybg} style={{ marginLeft: '30%' }} />
                    : <BiCaretDown size={20} color={primarybg} style={{ marginLeft: '30%' }} />}
            </TouchableOpacity>
            {orderMenu &&
                <>
                    <TouchableOpacity style={{
                        flexDirection: 'row', marginHorizontal: '5%', paddingHorizontal: '5%', alignItems: 'center',
                        backgroundColor: 1 != 1 && '#ffffff21',
                        borderRadius: 10, marginBottom: 5,
                        marginTop: (active == 'ordersPlaced' || active == 'ordersReceived') ? 10 : 0
                    }}
                        onPress={() => navigate('/orders-received')}
                    >
                        <Text style={{
                            color: primarybg, marginLeft: '20%', fontSize: 14, fontWeight: active == 'ordersReceived' && 'bold',
                            opacity: active == 'ordersReceived' ? 1 : 0.8
                        }}>Orders Received</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={{
                        flexDirection: 'row', marginHorizontal: '5%', paddingHorizontal: '5%', alignItems: 'center',
                        backgroundColor: 1 != 1 && '#ffffff21',
                        borderRadius: 10, marginBottom: 5
                    }}
                        onPress={() => navigate('/orders-placed')}
                    >
                        <Text style={{
                            color: primarybg, marginLeft: '20%', fontSize: 14, fontWeight: active == 'ordersPlaced' && 'bold',
                            opacity: active == 'ordersPlaced' ? 1 : 0.8
                        }}>Orders Placed</Text>
                    </TouchableOpacity>

                </>}



            <TouchableOpacity style={{
                flexDirection: 'row', marginHorizontal: '5%', paddingHorizontal: '5%', alignItems: 'center',
                backgroundColor: (active == 'customization' || active == 'themes') && '#ffffff21', paddingVertical: 10,
                borderRadius: 10, marginBottom: 0
            }}
                onPress={() => setappearanceMenu(!appearanceMenu)}
            >
                <BiPaint size={33} color={primarybg} />
                <Text style={{ color: primarybg, marginLeft: 10, fontWeight: 'bold', fontSize: 15 }}>Appearance</Text>
                {appearanceMenu ? <BiCaretDown size={20} color={primarybg} style={{ marginLeft: '30%' }} />
                    : <BiCaretDown size={20} color={primarybg} style={{ marginLeft: '30%' }} />}
            </TouchableOpacity>
            {appearanceMenu &&
                <>
                    <TouchableOpacity style={{
                        flexDirection: 'row', marginHorizontal: '5%', paddingHorizontal: '5%', alignItems: 'center',
                        backgroundColor: 1 != 1 && '#ffffff21',
                        borderRadius: 10, marginBottom: 5,
                        marginTop: (active == 'customization' || active == 'themes') ? 10 : 0
                    }}
                        onPress={() => navigate('/colors-setting')}
                    >
                        <Text style={{
                            color: primarybg, marginLeft: '20%', fontSize: 14, fontWeight: active == 'customization' && 'bold',
                            opacity: active == 'customization' ? 1 : 0.8
                        }}>Customization</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={{
                        flexDirection: 'row', marginHorizontal: '5%', paddingHorizontal: '5%', alignItems: 'center',
                        backgroundColor: 1 != 1 && '#ffffff21',
                        borderRadius: 10, marginBottom: 5
                    }}
                        onPress={() => navigate('/themes-setting')}
                    >
                        <Text style={{
                            color: primarybg, marginLeft: '20%', fontSize: 14, fontWeight: active == 'themes' && 'bold',
                            opacity: active == 'themes' ? 1 : 0.8
                        }}>Themes</Text>
                    </TouchableOpacity>

                </>}

            <TouchableOpacity style={{
                flexDirection: 'row', marginHorizontal: '5%', paddingHorizontal: '5%', alignItems: 'center', backgroundColor: active == 'settings' && '#ffffff21', paddingVertical: 10,
                borderRadius: 10, marginBottom: 5
            }}
                onPress={async () => { navigate('/settings') }}
            >
                <AiFillSetting size={26} color={primarybg} />
                <Text style={{ color: primarybg, marginLeft: 10, fontWeight: 'bold', fontSize: 16 }}>Settings</Text>
            </TouchableOpacity>

            <TouchableOpacity style={{
                flexDirection: 'row', marginHorizontal: '5%', paddingHorizontal: '5%', alignItems: 'center', backgroundColor: 1 != 1 && '#ffffff21', paddingVertical: 10,
                borderRadius: 10, marginBottom: 5
            }}
                onPress={async () => { await AsyncStorage.removeItem('secure_token'); navigate('/dashboard-login') }}
            >
                <BiPowerOff size={26} color={primarybg} />
                <Text style={{ color: primarybg, marginLeft: 10, fontWeight: 'bold', fontSize: 16 }}>Logout</Text>
            </TouchableOpacity>

        </View>
    )


    return (
        <View style={{ flexDirection: 'row', height: Dimensions.get('window').height }}>
            <link href='https://fonts.googleapis.com/css?family=Lobster' rel='stylesheet'></link>
            {isDesktopOrLaptop ?
                <>
                    <SidebarList />
                    {typeof notScrollView == 'undefined' ?
                        <ScrollView style={{ flex: 0.9, }}>
                            <Header />
                            {children}
                        </ScrollView>
                        :
                        <View style={{ flex: 0.9, }}>
                            <Header />
                            {children}
                        </View>
                    }
                </>
                :
                <>
                    {typeof showSidebar != 'undefined' && showSidebar &&
                        <SidebarList />
                    }
                    {typeof notScrollView == 'undefined' ?
                        <ScrollView >
                            <Header />
                            {children}
                        </ScrollView>
                        :
                        <View >
                            <Header />
                            {children}
                        </View>
                    }
                </>
            }
        </View>
    );
}

