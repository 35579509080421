import { StyleSheet, Text, TouchableOpacity, View, ScrollView, Image, FlatList, Dimensions } from "react-native";
import { primarybg, primaryColour, serverURL, shadowColor } from "../../../utils/string";
import { useMediaQuery } from 'react-responsive'
import { LoadingIndicator, RoundButton } from "../../../utils/utils";
// import FontAwesomeIcon from 'react-native-vector-icons/dist/FontAwesome5';
import ImageLoad from "../../../utils/imageLoad";
import Tab from "../tab";
import { useEffect, useState } from "react";
import { getPrimaryColor, orderDetails } from "../../../utils/api";
import { useNavigate, useParams } from "react-router-dom";
import '../templateUtils/loader.css'
// import HomeTabs from './pages/homeTabs';

// let Icon = FontAwesomeIcon;

export default function OrderDetails4(props) {

    const navigate = useNavigate();
    const { orderId, dukanId } = useParams();
    const { primaryColour, secondaryColor, dukanName } = props

    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })


    const [data, setdata] = useState({});
    const [isLoading, setisLoading] = useState(true);
    const [pageLoaded, setpageLoaded] = useState(false);

    useEffect(() => {
        if (primaryColour != '#5893D4aa') {
            setpageLoaded(true)
        }
    }, [primaryColour])

    useEffect(() => {
        orderDetails(setdata, setisLoading, navigate, orderId)
    }, [orderId])

    return (
        <>
            {!pageLoaded ?
                <View style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: Dimensions.get('window').height }}>
                    <div className="loadingio-spinner-chunk-nx0mjvmz269"><div className="ldio-q1sjvrz6fl">
                        <div><div><div></div><div></div><div></div><div></div></div></div>
                    </div></div>
                </View>
                :
                <Tab primaryColour={primaryColour} secondaryColor={secondaryColor} storeName={dukanName}>
                    {!isLoading ?
                        <View style={[{
                            flexDirection: 'column', paddingHorizontal: '15%', marginVertical: 30,
                            marginHorizontal: '10%',
                            height: Dimensions.get('window').height - 70,
                            backgroundColor: primarybg,
                            borderColor: '#f0ede9',
                            borderWidth: 0.2,
                            paddingTop: 20,
                            shadowColor: "#000",
                            shadowOffset: {
                                width: 0,
                                height: 2,
                            },
                            shadowOpacity: 0.23,
                            shadowRadius: 2.62,

                            elevation: 4,
                        },
                        !isDesktopOrLaptop ?
                            { marginHorizontal: '1%', paddingHorizontal: '1%', marginVertical: 3, shadowColor: primarybg, borderWidth: 0 } : {}
                        ]}
                        >
                            <Text style={{ fontSize: 23, fontWeight: '600', textAlign: 'left', color: primaryColour }}>Order Details</Text>
                            <Text
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: 15,
                                    color: primaryColour,
                                    marginTop: 20,
                                    textAlign: 'left',
                                }}>
                                Status:
                            </Text>
                            <View style={{ flexDirection: 'row', marginTop: 3, alignItems: 'center' }}>
                                <Text
                                    style={[{ fontWeight: 'bold', color: 'gray', flex: 0.2, marginTop: 1 },
                                    data.status == 'shipped' || data.status == 'delivered' || data.status == 'accepted' ? { color: primaryColour } : {}
                                    ]}>Accepted</Text>
                                <View style={[{ flex: 0.2, height: 1, backgroundColor: 'gray', alignSelf: 'center' }]} />
                                <Text style={[{ fontWeight: 'bold', color: 'gray', flex: 0.2, },
                                data.status == 'shipped' || data.status == 'delivered' ? { color: primaryColour } : {}
                                ]}>Shipped</Text>
                                <View style={[{ borderTopWidth: 0.1, flex: 0.2, marginTop: 4 }]} />
                                <Text style={[{ fontWeight: 'bold', color: 'gray', flex: 0.2, marginTop: 1 },
                                data.status == 'delivered' ? { color: primaryColour } : {}
                                ]}>Delivered</Text>
                            </View>
                            <Text
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: 15,
                                    color: primaryColour,
                                    marginTop: 10,
                                    textAlign: 'left',

                                }}>
                                Product:
                            </Text>
                            <TouchableOpacity style={[{
                                marginHorizontal: 10,
                                marginVertical: 10,
                                shadowColor: "#000",
                                shadowOffset: {
                                    width: 0,
                                    height: 1,
                                },
                                shadowOpacity: 0.22,
                                shadowRadius: 2.22,
                                elevation: 3,
                                borderWidth: 0.2,
                                borderColor: '#f0ede9',
                                width: '100%',
                                paddingHorizontal: 10,
                                paddingVertical: 5,
                                borderRadius: 6,
                                flexDirection: 'row'

                            },
                            !isDesktopOrLaptop ? {
                                marginHorizontal: 0,
                            } : {}
                            ]}
                                onPress={() => {
                                    let t = data.title.replace(/[^a-z0-9\s-]/ig, '')
                                        .trim()
                                        .replace(/\s+/g, '-')
                                        .toLowerCase()
                                    navigate('/' + dukanId + '/product/' + data.product_id + '/' + t)
                                }}
                            >

                                <ImageLoad
                                    style={{
                                        // marginTop: 20,
                                        height: 30,
                                        borderRadius: 7,
                                        flex: 0.15,
                                        marginTop: 2,
                                        // alignSelf: 'center',
                                        marginRight: 7
                                        // borderBottomLeftRadius: 0,
                                        // borderBottomRightRadius: 0,
                                        // backgroundColor: '#f0f0f0'
                                    }}
                                    imageStyle={{
                                        // flex: 1,
                                        borderRadius: 5,
                                        height: 30,
                                    }}
                                    resizeMode="cover"
                                    source={typeof data.thumbnail != 'undefined' ? serverURL + data.thumbnail : null}
                                />
                                <View style={{ flex: 0.85, flexDirection: 'column' }}>
                                    <Text style={{ textAlign: 'left', fontWeight: 'bold', color: primaryColour }}>{data.title}</Text>
                                    <Text style={{ textAlign: 'left', flex: 0.6, color: primaryColour }}>Quantity : {data.quantity}</Text>
                                </View>

                            </TouchableOpacity>
                            <Text
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                    color: primaryColour,
                                    marginTop: 10,
                                    textAlign: 'left',

                                }}>
                                Order Details:
                            </Text>
                            <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>Order # </Text>
                                <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>{data.order_number}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>Status</Text>
                                <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>{data.status}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>Date</Text>
                                <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>{data.date}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>SubTotal</Text>
                                <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>Rs. {data.subtotal}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>Shipping</Text>
                                <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>Rs. {data.shipping}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>Total</Text>
                                <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>Rs. {data.total}</Text>
                            </View>
                            <Text
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                    color: primaryColour,
                                    marginTop: 10,
                                    textAlign: 'left',

                                }}>
                                Customer Details:
                            </Text>
                            <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>Name</Text>
                                <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>{data.full_name}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>Mobile</Text>
                                <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>{data.mobile}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>Address</Text>
                                <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>{data.address}, {data.area}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>City</Text>
                                <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>{data.city}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>Province</Text>
                                <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>{data.province}</Text>
                            </View>
                        </View>
                        :
                        <LoadingIndicator />}
                </Tab >
            }
        </>
    );
}

