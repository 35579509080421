import React, { useEffect, useState, useContext, useRef } from 'react';
import {
    StyleSheet,
    View,
    Text,
    TouchableOpacity,
    ScrollView,
    RefreshControl,
    ImageBackground,
    TextInput,
    ActivityIndicator,
    StatusBar,
    Linking,
    Dimensions,
    Image
} from 'react-native';
// import Icon from 'react-native-vector-icons/FontAwesome';
// import FontAwesomeIcon from 'react-native-vector-icons/dist/FontAwesome5';
import { borderColor, demo, inputBackgound, primarybg, serverURL, shadowColor, textColor } from '../../../utils/string';
import { useMediaQuery } from 'react-responsive'
import Login from '../account/login';
import { AboutUsContext, HomeContext } from '../../../utils/contexts';
import { PerfectInput, RoundButton } from '../../../utils/utils';
import { IoCall, IoLogoWhatsapp, IoChatbox, IoChatboxSharp, IoSearchOutline, IoArrowRedoSharp, } from "react-icons/io5";
import SearchModal from '../templateUtils/searchModal';
import Swiper from "react-native-web-swiper";
import { BiRightArrow, BiLeftArrow } from "react-icons/bi";
// import { FontAwesome } from '@expo/vector-icons';
import { IoIosArrowDroprightCircle, IoIosArrowDropleftCircle } from 'react-icons/io'

const Icon = () => (<></>);
// let Icon = FontAwesomeIcon;


export default function ProfileHeader1(props) {

    const refRBSheet = useRef();
    const optionsRef = useRef();
    const [RITypeNumber, setRITypeNumber] = useState(0);
    const [RIType, setRIType] = useState('');
    const [RIComment, setRIComment] = useState('');
    const [reportSending, setreportSending] = useState(false);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const source =
        'https://images.unsplash.com/photo-1481595357459-84468f6eeaac?dpr=1&auto=format&fit=crop&w=376&h=251&q=60&cs=tinysrgb';

    const [bagVisible, setbagVisible] = useState(false);
    const [loginVisible, setloginVisible] = useState(false);
    const [searchModal, setsearchModal] = useState(false);


    const { data, searchText, setsearchText, primaryColour, secondaryColor } = useContext(typeof props.AboutUs == 'undefined' ? HomeContext : AboutUsContext);


    // address: "Bbbb"
    // ads_count: 4
    // city: "Bhalwal"
    // description: "We sell this and that...inbox me for details\r\nAgr ap apnay products online baichna chahtay bilkul freevvbb"
    // dp: "/media/profile_images/profile_1_djFsrp2.jpg"
    // dukan_title1: ""
    // dukan_title3: ""
    // followers_count: 0
    // following_count: 2
    // is_buyer: "true"
    // is_reseller: "true"
    // is_seller: "true"
    // same_dukan: 0
    // show_number_on_chats: "true"
    // user_following: 0
    // user_name: "Dukaa"

    return (
        < >
            {/* <Bag visible={bagVisible} setvisible={setbagVisible} /> */}
            <Login visible={loginVisible} setvisible={setloginVisible} />

            {Object.keys(data).length !== 0 &&
                <>{

                    data.banners.banner1 != '' || data.banners.banner2 != '' || data.banners.banner3 != '' ?
                        <View style={{
                            height: isDesktopOrLaptop ? Dimensions.get('window').width * 0.5 : Dimensions.get('window').width * 0.6,
                            backgroundColor: '#f1f1f1', marginTop: isDesktopOrLaptop ? 0 : 80, marginBottom: isDesktopOrLaptop ? 100 : 70, zIndex: -1
                        }}>
                            <Swiper
                                // from={1}
                                minDistanceForAction={0.1}
                                loop={true}
                                timeout={5}
                                springConfig={{ speed: 7, bounciness: 0, }}
                                controlsProps={{
                                    dotsTouchable: false,
                                    prevPos: 'left',
                                    nextPos: 'right',
                                    nextTitle: '',
                                    dotActiveStyle: { color: 'black', backgroundColor: 'black', right: 0, width: 50, },
                                    nextTitleStyle: { color: primaryColour, fontSize: 24, fontWeight: '500' },
                                    PrevComponent: ({ onPress }) => (
                                        <TouchableOpacity onPress={onPress} style={{ position: 'relative', left: -10, top: 20, }}>
                                            <IoIosArrowDropleftCircle color={primaryColour} solid={false} style={{ fontSize: isDesktopOrLaptop ? 35 : 20, }} />
                                        </TouchableOpacity>
                                    ),

                                    NextComponent: ({ onPress }) => (
                                        <TouchableOpacity onPress={onPress} style={{ position: 'relative', right: -10, top: 15, }}>
                                            <IoIosArrowDroprightCircle color={primaryColour} solid={false} style={{ fontSize: isDesktopOrLaptop ? 35 : 20, }} />
                                        </TouchableOpacity>
                                    ),
                                }}
                            >
                                {data.banners.banner1 !== '' &&
                                    <View style={{ height: '100%', backgroundColor: '#f1f1f1', justifyContent: 'center' }}>



                                        <Image
                                            style={{ height: '100%', width: '100%', backgroundColor: 'black' }}
                                            imageStyle={{ opacity: 0.9 }}
                                            // resizeMode="contain"
                                            source={serverURL + data.banners.banner1}
                                        />

                                        <Text style={{ position: 'absolute', bottom: 15, right: 15, zIndex: 20, fontWeight: 700, fontSize: isDesktopOrLaptop ? 30 : 17, color: 'black' }}>1/3</Text>

                                    </View>
                                }
                                {data.banners.banner2 !== '' &&
                                    <View style={{ height: '100%', backgroundColor: '#f1f1f1', justifyContent: 'center' }}>
                                        <Image
                                            style={{ height: '100%', width: '100%' }}
                                            // resizeMode="contain"
                                            source={serverURL + data.banners.banner2}
                                        />
                                        <Text style={{ position: 'absolute', bottom: 15, right: 15, zIndex: 20, fontWeight: 700, fontSize: isDesktopOrLaptop ? 30 : 17, color: 'black' }}>2/3</Text>
                                    </View>
                                }
                                {data.banners.banner3 !== '' &&
                                    <View style={{ height: '100%', backgroundColor: '#f1f1f1', justifyContent: 'center' }}>
                                        <Image
                                            style={{ height: '100%', width: '100%' }}
                                            // resizeMode="contain"
                                            source={serverURL + data.banners.banner3}
                                        />
                                        <Text style={{ position: 'absolute', bottom: 15, right: 15, zIndex: 20, fontWeight: 700, fontSize: isDesktopOrLaptop ? 30 : 17, color: 'black' }}>3/3</Text>
                                    </View>
                                }


                            </Swiper>

                        </View>
                        :
                        <ImageBackground
                            style={{
                                // backgroundColor: primarybg,
                                backgroundColor: 'black',
                                height: isDesktopOrLaptop ? Dimensions.get('window').height / 1.7 : 300,
                                width: '100%',
                                // flex: 1,
                                flexDirection: 'column',
                                paddingTop: StatusBar.currentHeight,
                                paddingHorizontal: isDesktopOrLaptop ? 50 : 0,
                                marginBottom: isDesktopOrLaptop ? 0 : 50

                            }}
                            imageStyle={{
                                // backgroundColor: 'black',
                                // opacity: 0.7,
                                height: isDesktopOrLaptop ? '100%' : 300

                            }}
                            resizeMode="cover"
                            source={data.dp !== 'null' ? serverURL + data.dp : '/static/dukanDefault.jpg'}
                        />
                }
                </>
            }

        </>
    );
}

