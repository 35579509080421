import { Text, TouchableOpacity, View, FlatList, Dimensions } from "react-native";
import { primarybg, serverURL, shadowColor } from "../../../utils/string";
import { useMediaQuery } from 'react-responsive'
import { getData, LoadingIndicator, notify, removeFromBag, RoundButton, storeData } from "../../../utils/utils";
import ImageLoad from "../../../utils/imageLoad";
import { useEffect, useState } from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { bagLoad, } from "../../../utils/api";
import Tab from "../tab";
import Login from "../account/login";

import { useNavigate, useParams } from "react-router-dom";
import { IoTrash } from "react-icons/io5";
import '../templateUtils/loader.css'
const Icon = () => (<></>);

export default function Bag1(props) {
    const navigate = useNavigate();
    const { dukanId } = useParams();
    const { primaryColour, secondaryColor } = props
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const isFocused = '';
    const [bagProductsId, setbagProductsId] = useState([]);
    const [bagProducts, setbagProducts] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const [bag, setbag] = useState([]);
    const [quantityKey, setquantityKey] = useState(Math.random());
    const [loginVisible, setloginVisible] = useState(false);

    const [secureToken, setsecureToken] = useState('');

    const [pageLoaded, setpageLoaded] = useState(false);

    useEffect(() => {
        if (primaryColour != '#5893D4aa') {
            setpageLoaded(true)
        }
    }, [primaryColour])

    const getProductIds = async () => {
        const ba = await AsyncStorage.getItem('bag')
        if (ba !== null) {
            let bagArray = JSON.parse(ba)
            setbag(bagArray);
            setbagProductsId(bagArray.map(a => a.id))
            // console.log(bagArray)
        }
    }

    // can_resell: 1
    // city: "Abbottabad"
    // cover: "/media/product_thumbnail/thumbnail_604bb79b190a45b2b587fa11843fca1f.jpg"
    // id: 57
    // is_owner_of_product: 0
    // liked: 0
    // price: "2500"
    // quantity: 3
    // shipping: 0
    // state: "Azad Kashmir"
    // title: "PakNeed key application ap Playstore say download kr sktay hain. Is"
    // user_id: 2

    useEffect(() => {
        getProductIds();
        getData('secure_token', setsecureToken);
        setisLoading(false)
    }, []);

    useEffect(() => {
        if (bagProductsId.length > 0) {
            console.log('bag loading')
            bagLoad(
                setbagProducts, setisLoading, navigate, '', dukanId
            )
            setquantityKey(Math.random())
        }

    }, [bagProductsId])


    useEffect(() => {

        setisLoading(true)
        if (bagProductsId.length > 0) {
            console.log('bag loading2')
            bagLoad(
                setbagProducts, setisLoading, navigate, '', dukanId
            )
            setquantityKey(Math.random())
        }
        setisLoading(false);
        setquantityKey(Math.random())
    }, [dukanId]);


    return (
        <>
            {!pageLoaded ?
                <View style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: Dimensions.get('window').height }}>
                    <div className="loadingio-spinner-chunk-nx0mjvmz269"><div className="ldio-q1sjvrz6fl">
                        <div><div><div></div><div></div><div></div><div></div></div></div>
                    </div></div>
                </View>
                :
                // <PerfectModal modalVisible={visible}
                //     style={[isDesktopOrLaptop ? { width: '50%' } : {}]}
                // >
                <View key={quantityKey}
                    style={{
                        height: Dimensions.get('window').height,
                    }}
                >
                    <Tab primaryColour={primaryColour} secondaryColor={secondaryColor}>
                        {!isLoading ?
                            <>
                                <Login visible={loginVisible} setvisible={setloginVisible} setsecureToken={setsecureToken} primaryColour={primaryColour} />
                                {bagProducts.length > 0 ?
                                    <View key={quantityKey}
                                        style={[{
                                            flexDirection: 'column', paddingHorizontal: '15%', marginVertical: 30,
                                            marginHorizontal: '10%',
                                            height: Dimensions.get('window').height - 70,
                                            backgroundColor: primarybg,
                                            borderColor: '#f0ede9',
                                            borderWidth: 0.2,
                                            paddingTop: 20,
                                            shadowColor: "#000",
                                            shadowOffset: {
                                                width: 0,
                                                height: 2,
                                            },
                                            shadowOpacity: 0.23,
                                            shadowRadius: 2.62,

                                            elevation: 4,
                                        },
                                        !isDesktopOrLaptop ?
                                            { marginHorizontal: '1%', paddingHorizontal: '1%', marginVertical: 3, shadowColor: primarybg, borderWidth: 0 } : {}
                                        ]}>
                                        <Text style={{ fontSize: 24, fontWeight: 'bold', textAlign: 'left' }}>Bag</Text>
                                        <FlatList
                                            contentContainerStyle={{ height: '100%' }}
                                            // style={{ height: }}


                                            horizontal={false}
                                            // onEndReached={({ distanceFromEnd }) => {
                                            //   if (typeof props.refreshing !== 'undefined') {
                                            //     if (props.refreshing) return;
                                            //   }
                                            //   if (distanceFromEnd < 0) return;
                                            //   props.loadMore ? props.onEndReached() : null;
                                            // }}
                                            // ListFooterComponent={props.listFooter}
                                            ListEmptyComponent={() => {
                                                return (
                                                    <Text style={{ textAlign: 'center', marginVertical: 10, fontSize: 39 }}>No products in bag</Text>
                                                )
                                            }}
                                            onEndThreshold={0.1}
                                            initialNumToRender={10}
                                            onEndReachedThreshold={0.01}
                                            data={bagProducts}
                                            numColumns={1}
                                            renderItem={({ item, index }) => (
                                                <TouchableOpacity
                                                    style={{ backgroundColor: '#e6e9ed', elevation: 2, shadowColor: shadowColor, marginVertical: 10, flexDirection: 'row', paddingBottom: 10 }}
                                                    onPress={() => {
                                                        let t = item.title.replace(/[^a-z0-9\s-]/ig, '')
                                                            .trim()
                                                            .replace(/\s+/g, '-')
                                                            .toLowerCase()
                                                        navigate('/' + window.location.pathname.split('/')[1] + '/ProductDetails/' + item.id)
                                                    }}
                                                >
                                                    <ImageLoad
                                                        // onPress={typeof props.onPress !== 'undefined' ? props.onPress : (
                                                        //     typeof props.profileOnPress !== 'undefined' ? () => props.profileOnPress(item.id) :
                                                        //         () => navigation.navigate('ProductDetail', { id: item.id })
                                                        // )
                                                        // }
                                                        style={{
                                                            // marginTop: 20,
                                                            flex: 0.2,
                                                            width: '100%',
                                                            height: 70,
                                                            borderRadius: 7,
                                                            marginVertical: 10,
                                                            marginLeft: 10,
                                                            // borderBottomLeftRadius: 0,
                                                            // borderBottomRightRadius: 0,
                                                            // backgroundColor: '#f0f0f0'
                                                        }}
                                                        imageStyle={{
                                                            // flex: 1,
                                                            width: '100%',
                                                            height: 70,
                                                            borderRadius: 12,
                                                            // borderBottomLeftRadius: 0,
                                                            // borderBottomRightRadius: 0,
                                                        }}
                                                        resizeMode="cover"
                                                        source={{ uri: serverURL + item.cover }}
                                                    />
                                                    <View style={{ flex: 0.8, marginTop: 5, marginRight: 5 }}>
                                                        <Text style={{ marginLeft: 10, textAlign: 'left' }}>
                                                            {item.title.length > 42 ? String(item.title.substring(0, 42) + '...').replace(/\n/g, " ") : String(item.title.replace(/\n/g, " "))}
                                                        </Text>

                                                        <View style={{ flexDirection: 'row', marginTop: 1 }}
                                                            key={bag}
                                                        >
                                                            <View style={{ flex: 0.5, marginLeft: 3, marginTop: 2 }}>
                                                                {item.variation_name != '' &&
                                                                    <Text style={{ marginLeft: 10, fontWeight: 'bold', color: 'gray', fontSize: 13, textAlign: 'left' }}>
                                                                        Variation: {item.variation_name}
                                                                    </Text>
                                                                }
                                                                {item.color && item.color != '' &&
                                                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                                        <Text style={{ marginLeft: 10, fontWeight: 'bold', color: 'gray', fontSize: 13, textAlign: 'left' }}>
                                                                            Color:{'  '}
                                                                        </Text>
                                                                        <View style={{ width: 15, height: 15, backgroundColor: item.color, }} />
                                                                    </View>
                                                                }
                                                                {item.size && item.size != '' &&
                                                                    <Text style={{ marginLeft: 10, fontWeight: 'bold', color: 'gray', fontSize: 13, textAlign: 'left' }}>
                                                                        Size: {item.size}
                                                                    </Text>
                                                                }
                                                                <Text style={{ marginLeft: 10, fontWeight: 'bold', color: 'gray', fontSize: 13, textAlign: 'left' }}>
                                                                    Shiping: Rs.
                                                                    {
                                                                        typeof bag.find(x => x.id == item.id) !== 'undefined' ?
                                                                            <>
                                                                                {/* {console.log(bag.find(x => x.id == item.id).quantity, 'mm', item.quantity)} */}
                                                                                {
                                                                                    item.quantity < bag.find(x => x.id == item.id).quantity ?
                                                                                        item.quantity * parseInt(item.shipping)
                                                                                        :
                                                                                        bag.find(x => x.id == item.id).quantity * parseInt(item.shipping)
                                                                                }
                                                                            </>
                                                                            : null
                                                                    }

                                                                </Text>
                                                                <Text style={{ marginLeft: 10, fontWeight: 'bold', color: 'gray', fontSize: 13, textAlign: 'left' }}>
                                                                    Price: Rs.

                                                                    {item.quantity < bag.find(x => x.id == item.id).quantity ?
                                                                        item.quantity * parseInt(item.price)
                                                                        :
                                                                        bag.find(x => x.id == item.id).quantity * parseInt(item.price)
                                                                    }
                                                                </Text>
                                                            </View>
                                                            <View style={{ flex: 0.3, flexDirection: 'row', marginRight: 7, marginTop: item.variation_name != '' ? 10 : 0 }}>
                                                                <RoundButton style={{ marginRight: 10, height: 30, width: 20, justifyContent: 'center', backgroundColor: primaryColour }} text='-'
                                                                    onPress={async () => {
                                                                        if (bag.find(x => x.id == item.id).quantity > 1) {
                                                                            let cur = bag.find(x => x.id == item.id)
                                                                            cur.quantity = cur.quantity - 1
                                                                            let curIndex = bag.findIndex(x => x.id == item.id)
                                                                            bag[curIndex] = cur
                                                                            setbag(bag)
                                                                            setquantityKey(Math.random())
                                                                            await AsyncStorage.setItem('bag', JSON.stringify(bag))
                                                                        }
                                                                    }}
                                                                />
                                                                <Text style={{ marginTop: 10 }}
                                                                // key={quantityKey}
                                                                >
                                                                    {
                                                                        item.quantity < bag.find(x => x.id == item.id).quantity ? item.quantity : bag.find(x => x.id == item.id).quantity
                                                                    }

                                                                </Text>
                                                                <RoundButton style={{ marginLeft: 10, height: 30, width: 20, justifyContent: 'center', backgroundColor: primaryColour }} text='+'
                                                                    onPress={async () => {
                                                                        let cur = bag.find(x => x.id == item.id)
                                                                        if (cur.quantity < parseInt(item.quantity)) {
                                                                            cur.quantity = cur.quantity + 1
                                                                            let curIndex = bag.findIndex(x => x.id == item.id)
                                                                            bag[curIndex] = cur
                                                                            setbag(bag)
                                                                            setquantityKey(Math.random())
                                                                            await AsyncStorage.setItem('bag', JSON.stringify(bag))
                                                                        } else {
                                                                            notify('Max quantity of this product is ' + item.quantity)
                                                                        }

                                                                    }}
                                                                />
                                                            </View>
                                                            <RoundButton style={{ flex: 0.15, backgroundColor: primaryColour, marginLeft: 10, height: 30, width: 20, justifyContent: 'center', marginTop: item.variation_name != '' ? 15 : 5 }}
                                                                onPress={() => {
                                                                    // let curIndex = bagProducts.findIndex(x => x.id === item.id)
                                                                    // let bagProducts2 = bagProducts.splice(index, 1);
                                                                    setbagProducts(bagProducts.filter((_item) => _item.id !== item.id))
                                                                    removeFromBag(item.id);
                                                                    setquantityKey(Math.random())
                                                                    // setbag(bag)
                                                                    // setquantityKey(Math.random())
                                                                }}
                                                            >
                                                                <IoTrash
                                                                    color={primarybg}
                                                                    size={14}
                                                                    style={{ padding: 3 }}
                                                                />
                                                            </RoundButton>

                                                        </View>
                                                    </View>



                                                </TouchableOpacity>
                                            )}
                                        />

                                        <View style={{ elevation: 1, backgroundColor: primarybg, marginTop: 10, paddingHorizontal: 10, paddingVertical: 10, width: '80%', alignSelf: 'center' }}>

                                            <View style={{ flexDirection: 'row' }}>
                                                <Text style={{ flex: 0.5, fontWeight: 'bold' }}>Total</Text>
                                                <Text style={{ flex: 0.5, textAlign: 'right' }}>Rs.
                                                    {typeof bagProducts.map(x => x.price * bag.find(y => y.id == x.id)) !== 'undefined' ?
                                                        bagProducts.map(x => x.price * bag.find(y => y.id == x.id).quantity).reduce((a, b) => a + b, 0) +
                                                        bagProducts.map(x => x.shipping * bag.find(y => y.id == x.id).quantity).reduce((a, b) => a + b, 0)
                                                        : null
                                                    }
                                                </Text>
                                            </View>
                                            <View style={{ flexDirection: 'row' }}>
                                                <Text style={{ flex: 0.5, fontWeight: 'bold' }}>Shipping charges</Text>
                                                <Text style={{ flex: 0.5, textAlign: 'right' }}>Rs.
                                                    {bagProducts.map(x => x.shipping * bag.find(y => y.id == x.id)) ?
                                                        bagProducts.map(x => x.shipping * bag.find(y => y.id == x.id).quantity).reduce((a, b) => a + b, 0) : null}
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                            <RoundButton
                                                text='Checkout'
                                                style={{ width: '35%', alignSelf: 'center', marginHorizontal: 20, backgroundColor: primaryColour }}
                                                onPress={() => {
                                                    console.log(bag)
                                                    if (secureToken != '@error' && secureToken != '' && secureToken != null && typeof secureToken != 'undefined') {
                                                        storeData(JSON.stringify(bag), 'productsForCheckout');
                                                        navigate('/' + window.location.pathname.substring(1).split('/')[0] + '/checkout')
                                                    } else {
                                                        getData('secure_token', setsecureToken)
                                                        if (secureToken != '@error' && secureToken != '' && secureToken != null && typeof secureToken != 'undefined') {
                                                            storeData(JSON.stringify(bag), 'productsForCheckout');
                                                            navigate('/' + window.location.pathname.substring(1).split('/')[0] + '/checkout')
                                                        } else {
                                                            setloginVisible(!loginVisible)
                                                        }
                                                    }
                                                }} />
                                        </View>
                                    </View>
                                    :
                                    <Text style={{ textAlign: 'center', marginVertical: 30, fontSize: 14 }}>No products in bag</Text>
                                }
                            </>
                            : <LoadingIndicator />
                        }
                    </Tab>
                </View>
            }
        </>
        // </PerfectModal>

    );

}

