import React, { useState } from 'react';
import {
    View,
    Text,
    Image,
    Alert,
    Dimensions,
    ImageBackground,
    Modal,
    KeyboardAvoidingView
} from 'react-native';
import PerfectModal from '../../../utils/perfectModel';
import { PerfectInput, RoundButton } from '../../../utils/utils';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import { defaultLink, primarybg } from '../../../utils/string';


export default function SearchModal(props) {
    const navigate = useNavigate();
    const { dukanId } = useParams();
    const { primaryColour, searchModal, setsearchModal, dukanLink } = props
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const [searchText, setsearchText] = useState('');
    return (
        <PerfectModal
            modalVisible={searchModal}
            style={[isDesktopOrLaptop ? { width: '30%', margin: 0, padding: 20, paddingBottom: 3, borderColor: primarybg, borderWidth: 2, borderRadius: 2 } : {}]}
        >
            <PerfectInput value={searchText} setvalue={setsearchText} placeholder='eg: nike,pepsi,shoes,shirts'
                style={[
                    isDesktopOrLaptop ? { width: '90%', alignSelf: 'center' } : {},
                    { borderRadius: 20, borderColor: primaryColour, borderWidth: 1, outlineStyle: 'none', }]}
            />
            <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
                <RoundButton text='Close' style={{ width: '30%', paddingHorizontal: 20, backgroundColor: primaryColour, borderRadius: 20 }}
                    onPress={() => { setsearchModal(false) }}
                />
                <RoundButton text='Search' style={{ width: '30%', backgroundColor: primaryColour, borderRadius: 20 }}
                    onPress={() => {
                        defaultLink == '' ?
                            navigate('/' + dukanId + '/shop', { state: { searchText: searchText } })
                            : navigate('/shop', { state: { searchText: searchText } })
                    }}
                />
            </View>
        </PerfectModal>
    )
}
