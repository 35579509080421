import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View, ScrollView, FlatList, Dimensions, ActivityIndicator, RefreshControl, Image } from "react-native";
import { borderColor, defaultLink, primarybg, serverURL } from "../../../utils/string";
import ImageLoad from "../../../utils/imageLoad";
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';

const { height, width } = Dimensions.get('window');

export default function Product(props) {
    let navigate = useNavigate()
    const { dukanId } = useParams();
    const { item, primaryColour } = props
    const [data, setdata] = useState([1, 2, 3, 5, 8, 8]);

    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1050px)'
    })

    return (
        <TouchableOpacity
            onPress={() => {
                let t = item.title.replace(/[^a-z0-9\s-]/ig, '')
                    .trim()
                    .replace(/\s+/g, '-')
                    .toLowerCase()
                defaultLink == '' ?
                    navigate('/' + dukanId + '/product/' + item.id + '/' + t)
                    : navigate('/product/' + item.id + '/' + t)
            }}
            style={{
                width: isDesktopOrLaptop ? Dimensions.get('window').width / 4.5 - 70 : Dimensions.get('window').width / 2.2,
                marginHorizontal: isDesktopOrLaptop ? 35 : 0, marginBottom: 20
            }}>
            <Image
                source={
                    data.cover !== 'null' ?
                        serverURL + item.cover
                        : 'https://images.unsplash.com/photo-1481595357459-84468f6eeaac?dpr=1&auto=format&fit=crop&w=376&h=251&q=60&cs=tinysrgb'
                }
                style={{ width: '100%', height: isDesktopOrLaptop ? 400 : 300, borderWidth: 1, borderColor: borderColor }}
            />
            <View style={{ flexDirection: 'column', marginTop: 5 }}>
                <Text style={{
                    flex: !isDesktopOrLaptop && 0.9, fontWeight: '300', fontSize: isDesktopOrLaptop ? 16 : 14,
                    marginRight: 13, letterSpacing: 1.5, color: primaryColour
                }}>
                    {item.title.length > 42 ? String(item.title.substring(0, 42) + '...').replace(/\n/g, " ") : String(item.title.replace(/\n/g, " "))}
                </Text>
                <Text style={{
                    flex: !isDesktopOrLaptop && 0.9, fontSize: isDesktopOrLaptop ? 18 : 16,
                    marginRight: 13, letterSpacing: 1.5, fontWeight: '600', color: primaryColour
                }}>Rs. {item.price}</Text>
            </View>
        </TouchableOpacity>
    );
}

