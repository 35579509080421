import { StyleSheet, Text, TouchableOpacity, View, ScrollView, Image, FlatList, Dimensions, ImageBackground } from "react-native";
import { borderColor, defaultLink, primarybg, serverURL, shadowColor, primaryColour, secondaryColor } from "../../../utils/string";
import { useMediaQuery } from 'react-responsive'
import { getData, LoadingIndicator, notify, removeFromBag, RoundButton, storeData } from "../../../utils/utils";
import ImageLoad from "../../../utils/imageLoad";
import { useEffect, useState } from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { bagLoad, getPrimaryColor, productsFromArray, getHomePage } from "../../../utils/api";
import Tab from "../tab";
import Login from "../account/login";

import { useNavigate, useParams } from "react-router-dom";
import { IoAdd, IoBagAdd, IoRemove, IoTrash, } from "react-icons/io5";
import '../templateUtils/loader.css'
import Footer from "../home/footer";
import { HomeContext } from "../../../utils/contexts";

const Icon = () => (<></>);

export default function Bag3(props) {
    const navigate = useNavigate();
    const { dukanId } = useParams();
    const { primaryColour, facebook, instagram, youtube, dukanName, dukanDescription, dukanContactNumber, secondaryColor } = props
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const [data, setdata] = useState({});

    const isFocused = '';
    const [bagProductsId, setbagProductsId] = useState([]);
    const [bagProducts, setbagProducts] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const [bag, setbag] = useState([]);
    const [quantityKey, setquantityKey] = useState(Math.random());
    const [loginVisible, setloginVisible] = useState(false);

    const [secureToken, setsecureToken] = useState('');

    const [pageLoaded, setpageLoaded] = useState(false);

    useEffect(() => {
        if (primaryColour != '#5893D4aa') {
            setpageLoaded(true)
        }
    }, [primaryColour])

    const getProductIds = async () => {
        const ba = await AsyncStorage.getItem('bag')
        if (ba !== null) {
            let bagArray = JSON.parse(ba)
            setbag(bagArray);
            setbagProductsId(bagArray.map(a => a.id))
            // console.log(bagArray)
        }
    }

    // can_resell: 1
    // city: "Abbottabad"
    // cover: "/media/product_thumbnail/thumbnail_604bb79b190a45b2b587fa11843fca1f.jpg"
    // id: 57
    // is_owner_of_product: 0
    // liked: 0
    // price: "2500"
    // quantity: 3
    // shipping: 0
    // state: "Azad Kashmir"
    // title: "PakNeed key application ap Playstore say download kr sktay hain. Is"
    // user_id: 2

    useEffect(() => {
        getProductIds();
        getData('secure_token', setsecureToken);
        setisLoading(false)
    }, []);

    useEffect(() => {
        if (bagProductsId.length > 0) {
            console.log('bag loading')
            bagLoad(
                setbagProducts, setisLoading, navigate, '', dukanId
            )
            setquantityKey(Math.random())
        }

    }, [bagProductsId])


    useEffect(() => {

        setisLoading(true)
        if (bagProductsId.length > 0) {
            console.log('bag loading2')
            bagLoad(
                setbagProducts, setisLoading, navigate, '', dukanId
            )
            setquantityKey(Math.random())
        }
        setisLoading(false);
        setquantityKey(Math.random())
    }, [dukanId]);


    return (
        <>
            <HomeContext.Provider
                value={{
                    primaryColour, data, setdata
                }}
            >
                {!pageLoaded ?
                    <View style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: Dimensions.get('window').height }}>
                        <div className="loadingio-spinner-chunk-nx0mjvmz269"><div className="ldio-q1sjvrz6fl">
                            <div><div><div></div><div></div><div></div><div></div></div></div>
                        </div></div>
                    </View>
                    :
                    // <PerfectModal modalVisible={visible}
                    //     style={[isDesktopOrLaptop ? { width: '50%' } : {}]}
                    // >

                    <View key={quantityKey}
                        style={{
                            height: Dimensions.get('window').height,
                        }}
                    >

                        <Tab primaryColour={primaryColour} dukanName={dukanName} dukanContactNumber={dukanContactNumber} secondaryColor={secondaryColor}>
                            {console.log(dukanContactNumber)}
                            {console.log('ddhfhfdfhdfh')}
                            <View>
                                {!isLoading ?
                                    <>
                                        <Login visible={loginVisible} setvisible={setloginVisible} setsecureToken={setsecureToken} />
                                        <View style={isDesktopOrLaptop ? { marginTop: 20, } : { marginTop: 45 }}>


                                            <ImageBackground source='https://images.unsplash.com/photo-1525328437458-0c4d4db7cab4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'


                                                // {serverURL + item.image}

                                                imageStyle={

                                                    isDesktopOrLaptop ? {
                                                        width: '100%',
                                                        height: 200,
                                                        overflow: 'hidden',
                                                        justifyContent: "left",
                                                        width: '100%',

                                                    } : {

                                                        width: '100%',
                                                        height: 200,
                                                        overflow: 'hidden',
                                                        justifyContent: "center",
                                                        width: '100%',

                                                    }
                                                }>

                                                <div class="jumbotron" style={{ borderRadius: 0, padding: 'none', margin: 'none', backgroundColor: 'rgb(0,0,0,0.9)', height: 200, }}>

                                                    <p style={!isDesktopOrLaptop ? { marginTop: 30, color: secondaryColor } : { marginTop: -10, color: secondaryColor }}>Home / Bag / Products</p>

                                                    <h1 style={{ fontFamily: 'sans-serif', justifyContent: 'center', textAlign: 'center', color: secondaryColor, }}>Bag</h1>
                                                </div>

                                            </ImageBackground>
                                        </View>
                                        {bagProducts.length > 0 ?
                                            <View style={isDesktopOrLaptop ? { flexDirection: 'row' } : { flexDirection: 'column' }}>



                                                <View style={isDesktopOrLaptop ? { flex: 0.70, } : { flex: 1, }}>
                                                    <View key={quantityKey}
                                                        style={[{
                                                            flexDirection: 'column', marginVertical: 30,
                                                            marginHorizontal: '5%',
                                                            backgroundColor: primarybg,
                                                            paddingTop: 20,
                                                            elevation: 4,
                                                        },
                                                        !isDesktopOrLaptop ?
                                                            { marginVertical: 3, shadowColor: primarybg, borderWidth: 0, marginTop: -20, } : {}
                                                        ]}>
                                                        <FlatList
                                                            // style={{ height: }}


                                                            horizontal={false}
                                                            // onEndReached={({ distanceFromEnd }) => {
                                                            //   if (typeof props.refreshing !== 'undefined') {
                                                            //     if (props.refreshing) return;
                                                            //   }
                                                            //   if (distanceFromEnd < 0) return;
                                                            //   props.loadMore ? props.onEndReached() : null;
                                                            // }}
                                                            // ListFooterComponent={props.listFooter}
                                                            ListEmptyComponent={() => {
                                                                return (
                                                                    <View style={{ textAlign: 'center', marginVertical: 10, fontSize: 39, marginTop: 300, marginBottom: 200, }}>No products in bag</View>
                                                                )
                                                            }}
                                                            onEndThreshold={0.1}
                                                            initialNumToRender={10}
                                                            onEndReachedThreshold={0.01}
                                                            data={bagProducts}
                                                            numColumns={1}
                                                            renderItem={({ item, index }) => (
                                                                <TouchableOpacity
                                                                    style={[{ backgroundColor: '#f4f4f4', elevation: 2, shadowColor: shadowColor, marginVertical: 10, flexDirection: 'row', height: 100, }, !isDesktopOrLaptop ? { height: 110, } : null]}
                                                                    onPress={() => {
                                                                        let t = item.title.replace(/[^a-z0-9\s-]/ig, '')
                                                                            .trim()
                                                                            .replace(/\s+/g, '-')
                                                                            .toLowerCase()
                                                                        navigate('/' + window.location.pathname.split('/')[1] + '/ProductDetails/' + item.id)
                                                                    }}
                                                                >
                                                                    <ImageLoad
                                                                        // onPress={typeof props.onPress !== 'undefined' ? props.onPress : (
                                                                        //     typeof props.profileOnPress !== 'undefined' ? () => props.profileOnPress(item.id) :
                                                                        //         () => navigation.navigate('ProductDetail', { id: item.id })
                                                                        // )
                                                                        // }
                                                                        style={[{
                                                                            // marginTop: 20,
                                                                            flex: 0.2,
                                                                            width: '100%',
                                                                            // borderBottomLeftRadius: 0,
                                                                            // borderBottomRightRadius: 0,
                                                                            // backgroundColor: '#f0f0f0'
                                                                        }, !isDesktopOrLaptop ? { flex: 0.3 } : null]}
                                                                        imageStyle={{
                                                                            // flex: 1,
                                                                            width: '100%',
                                                                            borderWidth: 1,
                                                                            borderColor: 'lightgray'
                                                                            // borderBottomLeftRadius: 0,
                                                                            // borderBottomRightRadius: 0,
                                                                        }}
                                                                        resizeMode="cover"
                                                                        source={{ uri: serverURL + item.cover }}

                                                                    // source={{ uri: serverURL + item.cover }}
                                                                    />
                                                                    <View style={isDesktopOrLaptop ? { flex: 0.8, marginLeft: 25 } : { flex: 0.8, marginLeft: 10, }}>
                                                                        <Text style={[{ position: 'relative', top: 25, textAlign: 'left', fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: 17, color: 'gray' }, !isDesktopOrLaptop ? { fontSize: 12, top: 15, } : null]}>
                                                                            {item.title.length > 42 ? String(item.title.substring(0, 42) + '...').replace(/\n/g, " ") : String(item.title.replace(/\n/g, " "))}
                                                                        </Text>

                                                                        <View style={{ flexDirection: 'row', marginTop: 1 }}
                                                                            key={bag}
                                                                        >
                                                                            <View style={[{ position: 'relative', top: 25, flex: 0.5, }, !isDesktopOrLaptop ? { top: 15, marginLeft: 0, } : null]}>
                                                                                {item.variation_name != '' &&
                                                                                    <Text style={{ marginLeft: 10, fontWeight: 'bold', color: 'gray', fontSize: 13, textAlign: 'left' }}>
                                                                                        Variation: {item.variation_name}
                                                                                    </Text>
                                                                                }
                                                                                {item.color && item.color != '' &&
                                                                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                                                        <Text style={{ marginLeft: 10, fontWeight: 'bold', color: 'gray', fontSize: 13, textAlign: 'left' }}>
                                                                                            Color:{'  '}
                                                                                        </Text>
                                                                                        <View style={{ width: 15, height: 15, backgroundColor: item.color, }} />
                                                                                    </View>
                                                                                }
                                                                                {item.size && item.size != '' &&
                                                                                    <Text style={{ marginLeft: 10, fontWeight: 'bold', color: 'gray', fontSize: 13, textAlign: 'left' }}>
                                                                                        Size: {item.size}
                                                                                    </Text>
                                                                                }
                                                                                <Text style={[{ fontFamily: 'sans-serif', color: 'gray', fontSize: 12, textAlign: 'left' }, !isDesktopOrLaptop ? { fontSize: 10 } : null]}>
                                                                                    Shiping: Rs.
                                                                                    {
                                                                                        typeof bag.find(x => x.id == item.id) !== 'undefined' ?
                                                                                            <>
                                                                                                {/* {console.log(bag.find(x => x.id == item.id).quantity, 'mm', item.quantity)} */}
                                                                                                {
                                                                                                    item.quantity < bag.find(x => x.id == item.id).quantity ?
                                                                                                        item.quantity * parseInt(item.shipping)
                                                                                                        :
                                                                                                        bag.find(x => x.id == item.id).quantity * parseInt(item.shipping)
                                                                                                }
                                                                                            </>
                                                                                            : null
                                                                                    }

                                                                                </Text>
                                                                                <Text style={[{ color: 'gray', fontSize: 12, textAlign: 'left' }, !isDesktopOrLaptop ? { fontSize: 10, } : null]}>
                                                                                    Price: Rs.

                                                                                    {item.quantity < bag.find(x => x.id == item.id).quantity ?
                                                                                        item.quantity * parseInt(item.price)
                                                                                        :
                                                                                        bag.find(x => x.id == item.id).quantity * parseInt(item.price)
                                                                                    }
                                                                                </Text>
                                                                            </View>
                                                                            <View style={[{ flex: 0.3, flexDirection: 'row', marginRight: 7, marginTop: item.variation_name != '' ? 10 : 0 }, !isDesktopOrLaptop ? { flex: 1, flexDirection: 'row', marginTop: 55, marginRight: 10, } : null]}>
                                                                                <TouchableOpacity style={[{ backgroundColor: secondaryColor, borderWidth: 1, borderColor: 'lightgray', color: primaryColour, justifyContent: 'center', height: 60, paddingHorizontal: 15, }, !isDesktopOrLaptop ? { height: 30, paddingHorizontal: 5, } : null]}
                                                                                    onPress={async () => {
                                                                                        if (bag.find(x => x.id == item.id).quantity > 1) {
                                                                                            let cur = bag.find(x => x.id == item.id)
                                                                                            cur.quantity = cur.quantity - 1
                                                                                            let curIndex = bag.findIndex(x => x.id == item.id)
                                                                                            bag[curIndex] = cur
                                                                                            setbag(bag)
                                                                                            setquantityKey(Math.random())
                                                                                            await AsyncStorage.setItem('bag', JSON.stringify(bag))
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <IoRemove size={20} color={primaryColour} solid={true} />

                                                                                </TouchableOpacity>
                                                                                <Text style={[{ backgroundColor: secondaryColor, borderWidth: 1, borderColor: 'lightgray', color: primaryColour, justifyContent: 'center', fontSize: 17, height: 60, paddingHorizontal: 25, paddingVertical: 15, }, !isDesktopOrLaptop ? { height: 30, paddingHorizontal: 20, paddingVertical: 5, fontSize: 12, } : null]}
                                                                                // key={quantityKey}
                                                                                >
                                                                                    {
                                                                                        item.quantity < bag.find(x => x.id == item.id).quantity ? item.quantity : bag.find(x => x.id == item.id).quantity
                                                                                    }

                                                                                </Text>
                                                                                <TouchableOpacity style={[{ backgroundColor: secondaryColor, borderWidth: 1, borderColor: 'lightgray', color: primaryColour, justifyContent: 'center', height: 60, paddingHorizontal: 15, }, !isDesktopOrLaptop ? { height: 30, paddingHorizontal: 5, } : null]}
                                                                                    onPress={async () => {
                                                                                        let cur = bag.find(x => x.id == item.id)
                                                                                        if (cur.quantity < parseInt(item.quantity)) {
                                                                                            cur.quantity = cur.quantity + 1
                                                                                            let curIndex = bag.findIndex(x => x.id == item.id)
                                                                                            bag[curIndex] = cur
                                                                                            setbag(bag)
                                                                                            setquantityKey(Math.random())
                                                                                            await AsyncStorage.setItem('bag', JSON.stringify(bag))
                                                                                        } else {
                                                                                            notify('Max quantity of this product is ' + item.quantity)
                                                                                        }

                                                                                    }}
                                                                                >
                                                                                    <IoAdd size={20} color={primaryColour} solid={true} />

                                                                                </TouchableOpacity>

                                                                                <TouchableOpacity style={[{ justifyContent: 'center', textAlign: 'center', alignItems: 'center', backgroundColor: secondaryColor, borderWidth: 1, borderColor: 'lightgray', color: primaryColour, justifyContent: 'center', height: 60, width: 60, marginLeft: 50 }, !isDesktopOrLaptop ? { marginLeft: 5, height: 30, width: 30, } : null]}
                                                                                    onPress={() => {
                                                                                        // let curIndex = bagProducts.findIndex(x => x.id === item.id)
                                                                                        // let bagProducts2 = bagProducts.splice(index, 1);
                                                                                        setbagProducts(bagProducts.filter((_item) => _item.id !== item.id))
                                                                                        removeFromBag(item.id);
                                                                                        setquantityKey(Math.random())
                                                                                        // setbag(bag)
                                                                                        // setquantityKey(Math.random())
                                                                                    }}
                                                                                >
                                                                                    <IoTrash size={20} color={primaryColour} solid={true} />

                                                                                </TouchableOpacity>

                                                                            </View>

                                                                        </View>
                                                                    </View>



                                                                </TouchableOpacity>
                                                            )}
                                                        />


                                                    </View>
                                                </View>

                                                <View style={isDesktopOrLaptop ? { flex: 0.25 } : { paddingHorizontal: "5%", }}>
                                                    <View style={[{ marginTop: 60, paddingVertical: 46, backgroundColor: '#f4f4f4', }, !isDesktopOrLaptop ? { marginTop: 30, } : null]}>
                                                        <View style={{ elevation: 1, marginTop: 10, paddingHorizontal: 20, paddingVertical: 10, width: '80%', alignSelf: 'center' }}>

                                                            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                                                <Text style={{ flex: 0.5, fontWeight: 'bold', color: 'gray', fontSize: 22, textAlign: 'center' }}>View Total</Text>
                                                            </View>
                                                            <hr />
                                                            <View style={{ flexDirection: 'row' }}>
                                                                <Text style={{ flex: 0.5, fontWeight: 'bold', color: 'gray' }}>Subtotal:</Text>
                                                                <Text style={{ flex: 0.5, textAlign: 'right', color: 'gray' }}>Rs.
                                                                    {typeof bagProducts.map(x => x.price * bag.find(y => y.id == x.id)) !== 'undefined' ?
                                                                        bagProducts.map(x => x.price * bag.find(y => y.id == x.id).quantity).reduce((a, b) => a + b, 0) +
                                                                        bagProducts.map(x => x.shipping * bag.find(y => y.id == x.id).quantity).reduce((a, b) => a + b, 0)
                                                                        : null
                                                                    }
                                                                </Text>
                                                            </View>
                                                            <View style={{ flexDirection: 'row' }}>
                                                                <Text style={{ flex: 0.5, fontWeight: 'bold', color: 'gray' }}>Shipping:</Text>
                                                                <Text style={{ flex: 0.5, textAlign: 'right', color: 'gray' }}>Rs.
                                                                    {bagProducts.map(x => x.shipping * bag.find(y => y.id == x.id)) ?
                                                                        bagProducts.map(x => x.shipping * bag.find(y => y.id == x.id).quantity).reduce((a, b) => a + b, 0) : null}
                                                                </Text>
                                                            </View>
                                                            <hr />
                                                            <View style={{ flexDirection: 'row' }}>
                                                                <Text style={{ flex: 0.5, fontWeight: 'bold', fontFamily: 'sans-serif', fontWeight: 'bold', color: 'gray' }}>Grand Total:</Text>
                                                                <Text style={{ flex: 0.5, textAlign: 'right', fontWeight: 'bold', fontFamily: 'sans-serif', fontWeight: 'bold', color: 'gray' }}>Rs.
                                                                    {typeof bagProducts.map(x => x.price * bag.find(y => y.id == x.id)) !== 'undefined' ?
                                                                        bagProducts.map(x => x.price * bag.find(y => y.id == x.id).quantity).reduce((a, b) => a + b, 0) +
                                                                        bagProducts.map(x => x.shipping * bag.find(y => y.id == x.id).quantity).reduce((a, b) => a + b, 0)
                                                                        : null
                                                                    }
                                                                </Text>
                                                            </View>
                                                            <hr />
                                                        </View>
                                                        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                                            <TouchableOpacity
                                                                style={{ marginTop: 10, borderRadius: 100, backgroundColor: primaryColour, color: secondaryColor, height: 50, paddingVertical: 13, paddingHorizontal: 30, }}
                                                                onPress={() => {
                                                                    console.log(bag)
                                                                    if (secureToken != '@error' && secureToken != '' && secureToken != null && typeof secureToken != 'undefined') {
                                                                        storeData(JSON.stringify(bag), 'productsForCheckout');
                                                                        navigate('/' + window.location.pathname.substring(1).split('/')[0] + '/checkout')
                                                                    } else {
                                                                        getData('secure_token', setsecureToken)
                                                                        if (secureToken != '@error' && secureToken != '' && secureToken != null && typeof secureToken != 'undefined') {
                                                                            storeData(JSON.stringify(bag), 'productsForCheckout');
                                                                            navigate('/' + window.location.pathname.substring(1).split('/')[0] + '/checkout')
                                                                        } else {
                                                                            setloginVisible(!loginVisible)
                                                                        }
                                                                    }
                                                                }}

                                                            >Checkout


                                                            </TouchableOpacity>
                                                        </View>
                                                    </View>
                                                </View>

                                            </View>
                                            :
                                            <View style={{ marginTop: 70, }}>
                                                <View style={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center', opacity: 0.7 }}>

                                                    <IoBagAdd size={150} solid={true} />

                                                </View>
                                                <View style={{ justifyContent: 'center', }}>
                                                    <Text style={{ color: primaryColour, textAlign: 'center', fontFamily: 'sans-serif', fontSize: 20, }}>Your bag is empty</Text>
                                                    <Text style={{ color: 'gray', textAlign: 'center', fontSize: 15, }}>
                                                        Looks like you haven’t made your choice yet.</Text>
                                                </View>
                                            </View>
                                        }
                                    </>
                                    : <LoadingIndicator />
                                }

                                <View style={{ marginTop: 200, }}>
                                    <Footer dukanId={dukanId} dukanContactNumber={dukanContactNumber} dukanName={dukanName} secondaryColor={secondaryColor} dukanDescription={dukanDescription} data={data} facebook={facebook} instagram={instagram} youtube={youtube} />
                                </View>

                            </View>
                        </Tab>
                    </View>
                }
            </HomeContext.Provider>
        </>


    );

}

