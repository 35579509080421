import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View, ScrollView, FlatList, Dimensions, ActivityIndicator, RefreshControl, ImageBackground } from "react-native";
import { defaultLink, primarybg, serverURL, primaryColour, secondaryColor } from "../../../utils/string";
import ImageLoad from "../../../utils/imageLoad";
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';

const { height, width } = Dimensions.get('window');

export default function Product(props) {
    let navigate = useNavigate()
    const { dukanId } = useParams();
    const { item, primaryColour, secondaryColor } = props
    const [data, setdata] = useState([1, 2, 3, 5, 8, 8]);

    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1050px)'
    })

    const isTablet = useMediaQuery({
        query: '(min-width: 768px)'
    })

    return (

        <TouchableOpacity style={[{
            // width: width / 3 - 40,

            justifyContent: 'center',
            elevation: 5,

        }, !isDesktopOrLaptop ? { flex: isTablet ? 0.32 : 0.49, marginVertical: 20, } : { flex: 0.22, marginVertical: 20, }]}
            onPress={() => {
                let t = item.title.replace(/[^a-z0-9\s-]/ig, '')
                    .trim()
                    .replace(/\s+/g, '-')
                    .toLowerCase()
                defaultLink == '' ?
                    navigate('/' + dukanId + '/product/' + item.id + '/' + t)
                    : navigate('/product/' + item.id + '/' + t)
            }}
        >

            {/* <div style={{ background: 'rgb(0,0,0,0,1)', zIndex: 5, }}>
                <ImageLoad
                    style={[{
                        // marginTop: 20,
                        width: '100%',
                        height: 250,
                        // borderRadius: 35,
                        padding: 9,
                        backgroundColor: '#f0f0f0',
                        opacity: '0.7',
                    },
                    !isDesktopOrLaptop ? { height: 170, } : {}
                    ]}
                    imageStyle={{
                        // flex: 1,
                        width: '100%',
                        height: 230,
                    }, !isDesktopOrLaptop ? {
                        height: 170,
                    } : {
                            borderRadius: 0,
                        }}
                    resizeMode="cover"
                    source={
                        data.cover !== 'null' ?
                            serverURL + item.cover
                            : 'https://images.unsplash.com/photo-1481595357459-84468f6eeaac?dpr=1&auto=format&fit=crop&w=376&h=251&q=60&cs=tinysrgb'
                    }
                >
                  
                </ImageLoad>
            </div>


            <div className="Item__title">
                {item.title.length > 42 ? String(item.title.substring(0, 42) + '...').replace(/\n/g, " ") : String(item.title.replace(/\n/g, " "))}
            </div>
            <View style={{ flexDirection: 'row', }, isDesktopOrLaptop ? { marginTop: 10, marginBottom: 10, } : { marginTop: 40, }}>
                <div className="Item__location">{item.city} {item.state}</div>

                <div className="Item__price">Rs. {item.price}</div>
            </View> */}


            <div style={{ textAlign: 'center', justifyContent: 'center', alignSelf: 'center', }}>
                <div className="grid-5"
                    style={isDesktopOrLaptop ? {

                        width: width / 4 - 50,

                    } : {

                        width: isTablet ? width / 3 - 40 : width / 2 - 15,

                    }}
                >
                    <div className="card card-elevation"
                        style={isDesktopOrLaptop ? {

                            border: 'none',
                            height: '440px',
                            width: '100%',
                            borderRadius: 10,
                            backgroundColor: 'transparent',


                        } : {
                            height: isTablet ? 300 : 260,
                            width: '100%',
                            border: 'none',
                            borderRadius: 10,
                        }}
                    >
                        <div className="">
                            <div>

                                <ImageBackground source={
                                    data.cover !== 'null' ?
                                        serverURL + item.cover
                                        : 'https://images.unsplash.com/photo-1481595357459-84468f6eeaac?dpr=1&auto=format&fit=crop&w=376&h=251&q=60&cs=tinysrgb'
                                }


                                    // {serverURL + item.image}

                                    imageStyle={

                                        isDesktopOrLaptop ? {
                                            borderRadius: 10,
                                            width: '100%',
                                            height: '330px',
                                            zIndex: 0,
                                        } : {

                                            borderRadius: 10,
                                            width: '100%',
                                            height: isTablet ? 225 : 185,
                                        }
                                    }
                                >

                                    <View style={isDesktopOrLaptop ? {
                                        background: 'rgb(0,0,0,0.4)',
                                        justifyContent: 'center',
                                        height: 330,
                                        width: '100%',
                                        borderRadius: 10,
                                    } : {
                                        background: 'rgb(0,0,0,0.4)',
                                        justifyContent: 'center',
                                        height: isTablet ? 225 : 185,
                                        width: '100%',
                                        borderRadius: 10,
                                    }}>

                                    </View>

                                </ImageBackground>
                                {/* <img className="img"
                                    style={
                                        isDesktopOrLaptop ? {
                                            borderBottomLeftRadius: 10,
                                            borderBottomRightRadius: 10,
                                            opacity: 0.7,
                                            width: '100%',
                                            height: '240px',
                                            zIndex: 0,
                                        } : {

                                            borderBottomLeftRadius: 10,
                                            borderBottomRightRadius: 10,
                                            opacity: 0.9,
                                            width: '100%',
                                            height: '150px',

                                        }}
                                    src="https://images.pexels.com/photos/298864/pexels-photo-298864.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" /> */}
                            </div>

                            <View style={{
                                marginHorizontal: 20,
                            }}>
                                <h1
                                    style={
                                        isDesktopOrLaptop ? {
                                            fontSize: '1.3em',
                                            textAlign: 'center',
                                            margin: '10px 0',
                                            color: primaryColour,
                                            fontWeight: 800,
                                        } : {
                                            fontSize: '0.8em',
                                            textAlign: 'center',
                                            margin: '7px 0',
                                            color: 'black',
                                            fontWeight: 600,
                                        }}
                                >{item.title.length > 15 ? String(item.title.substring(0, 15) + '..').replace(/\n/g, " ") : String(item.title.replace(/\n/g, " "))}</h1>

                            </View>
                            <div style={isDesktopOrLaptop ? { flex: 1, color: 'gray', fontSize: 15, } : { flex: 1, color: 'gray', fontSize: 12, }} className="Item__location">location: {item.city} {item.state}</div>

                            <View style={isDesktopOrLaptop ? { flex: 1, flexDirection: 'row', marginTop: 20, marginBottom: 10, } : { marginTop: 10, }}>
                                <div style={isDesktopOrLaptop ? { flex: 0.5, } : { flex: 0.5 }}></div>
                                <div style={isDesktopOrLaptop ? { flex: 0.5, fontWeight: 500, fontSize: 20, padding: 10, borderTopLeftRadius: 20, borderBottomLeftRadius: 20, backgroundColor: primaryColour, color: secondaryColor } : { width: '60%', position: 'absolute', right: 0, fontWeight: 500, fontSize: 12, padding: 7, borderTopLeftRadius: 10, borderBottomLeftRadius: 10, backgroundColor: primaryColour, color: secondaryColor }}>Rs. {item.price}</div>
                            </View>
                        </div>
                    </div>
                </div>
            </div>

        </TouchableOpacity >

    );
}

