import React, { useEffect, useState, useContext, useRef } from 'react';
import {
    StyleSheet,
    View,
    Text,
    TouchableOpacity,
    ScrollView,
    RefreshControl,
    ImageBackground,
    TextInput,
    ActivityIndicator,
    StatusBar,
    Linking,
    Dimensions,
    Image
} from 'react-native';
// import Icon from 'react-native-vector-icons/FontAwesome';
// import FontAwesomeIcon from 'react-native-vector-icons/dist/FontAwesome5';
import { borderColor, defaultLink, inputBackgound, primarybg, serverURL, shadowColor } from '../../../utils/string';
import { useMediaQuery } from 'react-responsive'
import Login from '../account/login';
import { AboutUsContext, HomeContext } from '../../../utils/contexts';
import { PerfectInput, RoundButton } from '../../../utils/utils';
import { IoCall, IoLogoWhatsapp, IoChatbox, IoChatboxSharp, IoSearchOutline } from "react-icons/io5";
import SearchModal from '../templateUtils/searchModal';
import Swiper from "react-native-web-swiper";
import '../templateUtils/text-style.css'
import { useNavigate, useParams } from 'react-router-dom';
// import { FontAwesome } from '@expo/vector-icons';

const Icon = () => (<></>);
// let Icon = FontAwesomeIcon;


export default function ProfileHeader1(props) {

    const navigate = useNavigate();
    const { dukanId } = useParams();
    const refRBSheet = useRef();
    const optionsRef = useRef();
    const [RITypeNumber, setRITypeNumber] = useState(0);
    const [RIType, setRIType] = useState('');
    const [RIComment, setRIComment] = useState('');
    const [reportSending, setreportSending] = useState(false);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const source =
        'https://images.unsplash.com/photo-1481595357459-84468f6eeaac?dpr=1&auto=format&fit=crop&w=376&h=251&q=60&cs=tinysrgb';

    const [bagVisible, setbagVisible] = useState(false);
    const [loginVisible, setloginVisible] = useState(false);
    const [searchModal, setsearchModal] = useState(false);


    const { data, searchText, setsearchText, primaryColour, secondaryColor } = useContext(typeof props.AboutUs == 'undefined' ? HomeContext : AboutUsContext);
    // const [searchText, setsearchText] = useState('');

    // address: "Bbbb"
    // ads_count: 4
    // city: "Bhalwal"
    // description: "We sell this and that...inbox me for details\r\nAgr ap apnay products online baichna chahtay bilkul freevvbb"
    // dp: "/media/profile_images/profile_1_djFsrp2.jpg"
    // dukan_title1: ""
    // dukan_title3: ""
    // followers_count: 0
    // following_count: 2
    // is_buyer: "true"
    // is_reseller: "true"
    // is_seller: "true"
    // same_dukan: 0
    // show_number_on_chats: "true"
    // user_following: 0
    // user_name: "Dukaa"

    return (
        < >
            {/* <Bag visible={bagVisible} setvisible={setbagVisible} /> */}
            <Login visible={loginVisible} setvisible={setloginVisible} primaryColour={primaryColour} />
            {/* <View style={[
                { flexDirection: 'row', marginHorizontal: 150 },
                isDesktopOrLaptop ? { marginHorizontal: '10%' } : {}
            ]}>
                <View style={{ height: 50, flex: 0.6, backgroundColor: primarybg, alignItems: 'flex-start' }}>
                    <Text style={{ fontWeight: 'bold', color: primaryColour, fontSize: 20, elevation: 10, }}>Dukan6</Text>
                    <Text style={{ fontWeight: 'bold', color: 'gray', marginLeft: 10, fontSize: 10 }}>by PakNeed</Text>
                </View>
                <View style={{ flex: 0.17, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }} >
                    <Text style={{ fontWeight: 'bold', color: primaryColour, fontSize: 14, elevation: 10, }}>About us</Text>
                    <Text style={{ fontWeight: 'bold', color: primaryColour, fontSize: 14, elevation: 10, }}>Trust Scores</Text>
                    <Text style={{ fontWeight: 'bold', color: primaryColour, fontSize: 14, elevation: 10, }}>Needs</Text>
                </View>
                <View style={{ flex: 0.2, marginLeft: 70, alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                    <Icon name="shopping-bag" size={25} color={primaryColour} style={{ marginHorizontal: 20 }}
                        onPress={() => setbagVisible(!bagVisible)}
                    />
                    <Icon name="user-alt" size={25} color={primaryColour} style={{}}
                        onPress={() => setloginVisible(!loginVisible)}
                    />
                </View>

            </View> */}
            {/* {isDesktopOrLaptop ? null :
                <View style={{
                    alignItems: 'center', backgroundColor: primarybg, height: 40, flexDirection: 'row', justifyContent: 'space-between',
                    borderWidth: 1, borderColor: borderColor, paddingRight: 10, paddingLeft: 10
                }}>
                    <Text style={{ fontWeight: 'bold', color: primaryColour }}>Made with bazars.pk</Text>
                    <RoundButton text='Download App'
                        onPress={() => {
                            Linking.openURL('https://play.google.com/store/apps/details?id=com.pakneed')
                        }}
                        style={{ paddingHorizontal: 10, borderRadius: 20, backgroundColor: primaryColour }} />
                </View>
            } */}
            <SearchModal primaryColour={primaryColour} searchModal={searchModal} setsearchModal={setsearchModal} />
            {!isDesktopOrLaptop &&
                <View style={{
                    backgroundColor: primarybg, flexDirection: 'row',
                    //  paddingBottom: 7,
                    paddingTop: 5,
                    paddingHorizontal: 10,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    // height: 52,
                    zIndex: 5
                }}>
                    {/* <TouchableOpacity
                        onPress={() => {
                            setsearchModal(!searchModal)
                        }}
                        style={{
                            flex: 0.2,
                            margin: 5,
                            marginLeft: 5
                        }}>
                        <IoSearchOutline
                            color={primaryColour}
                            size={21}
                            style={{ marginTop: 2 }}
                        />
                    </TouchableOpacity> */}
                    <Text
                        style={{
                            color: primaryColour,
                            fontWeight: '700',
                            fontSize: 23,
                            fontWeight: 'bold',
                            letterSpacing: 1.5,
                            flex: 0.8,
                            textTransform: 'capitalize',
                            marginLeft: 10
                            // textAlign: 'center'
                        }}>
                        {data.user_name}
                        {'  '}
                    </Text>

                    <View style={{ flex: 0.3, flexDirection: 'row' }}>
                        {data.show_number_on_chats != 'false' && data.dukan_contact_number != '' ?
                            <>
                                <TouchableOpacity
                                    onPress={() => {
                                        Linking.openURL(`tel:${data.dukan_contact_number}`);
                                    }}
                                    style={{

                                        margin: 5,
                                    }}>
                                    <IoCall
                                        color={primaryColour}
                                        size={21}
                                        style={{ marginTop: 2 }}
                                    />
                                </TouchableOpacity>

                                <TouchableOpacity
                                    onPress={() => {
                                        // Linking.openURL(
                                        //     `sms:${data.mobile_number}${Platform.OS === 'ios' ? '&' : '?'
                                        //     }body=${'PakNeed: \n'}`,
                                        // );
                                        Linking.openURL(
                                            'https://api.whatsapp.com/send?phone=+92' + data.dukan_contact_number.substring(1) + '&text=' + data.user_name + ':\n'
                                        )
                                    }}
                                    style={{

                                        margin: 5,
                                    }}>

                                    <IoLogoWhatsapp
                                        color={primaryColour}
                                        size={23}
                                    // style={{ marginTop: 2 }}
                                    />
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => {
                                        Linking.openURL('https://play.google.com/store/apps/details?id=com.pakneed')
                                    }}

                                    style={{

                                        margin: 5,

                                    }}>
                                    <IoChatboxSharp
                                        color={primaryColour}
                                        size={24}

                                    />
                                </TouchableOpacity>
                            </> : null}
                    </View>
                </View>
            }
            <div style={{ overflow: 'hidden', marginTop: '-1%', zIndex: 2, backgroundColor: 'trandparent' }} >
                <Image source={require('../../../assets/top-wave.png')}
                    style={{ width: '100%', height: 70 }}
                />
            </div>
            {Object.keys(data).length !== 0 &&
                <>{

                    data.banners.banner1 != '' || data.banners.banner2 != '' || data.banners.banner3 != '' ?
                        <View style={{
                            height: isDesktopOrLaptop ? Dimensions.get('window').width * 0.5 : Dimensions.get('window').width * 0.88, backgroundColor: '#f1f1f1',
                            marginTop: !isDesktopOrLaptop && '-19%'
                        }}>
                            <View style={{ height: '100%', backgroundColor: '#f1f1f1', justifyContent: 'center' }}>
                                <ImageBackground
                                    style={{ height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black' }}
                                    // resizeMode="contain"
                                    imageStyle={{ opacity: 0.8 }}
                                    source={data.banners.banner1 != '' ? serverURL + data.banners.banner1
                                        : data.banners.banner2 != '' ? serverURL + data.banners.banner2
                                            : data.banners.banner3 != '' && serverURL + data.banners.banner3
                                    }
                                >
                                    {isDesktopOrLaptop ?
                                        <View style={{
                                            backgroundColor: primaryColour + '33',
                                            paddingHorizontal: '5%', paddingVertical: '5%',
                                            justifyContent: 'center', alignItems: 'center',
                                        }}>
                                            {data.dukan_title1 != '' &&
                                                <div className="typewriter">
                                                    <Text style={{ color: secondaryColor, fontSize: isDesktopOrLaptop ? 60 : 27, width: '100%', textAlign: 'center', }}>
                                                        {data.dukan_title1}
                                                    </Text>
                                                </div>
                                            }
                                            {data.dukan_title3 != '' &&
                                                <Text style={{ color: secondaryColor, fontSize: isDesktopOrLaptop ? 40 : 16, width: '100%', textAlign: 'center', textTransform: 'capitalize' }}>
                                                    {data.dukan_title3}
                                                </Text>
                                            }
                                            <PerfectInput
                                                style={{
                                                    backgroundColor: 'trandparent', width: isDesktopOrLaptop ? '45%' : '70%',
                                                    borderColor: secondaryColor, borderWidth: isDesktopOrLaptop ? 2 : 1, borderRadius: 20,
                                                    shadowColor: 'transparent', marginTop: isDesktopOrLaptop && 20, color: secondaryColor
                                                }}
                                                placeholder='Search...'
                                                placeholderTextColor={secondaryColor + 'B3'}
                                            />
                                            <RoundButton text='Search'
                                                style={{
                                                    borderRadius: 20, width: isDesktopOrLaptop ? '20%' : '30%', backgroundColor: secondaryColor,
                                                    borderColor: secondaryColor, borderWidth: 2
                                                    , color: secondaryColor, height: isDesktopOrLaptop ? 40 : 40
                                                }}
                                                textStyle={{ color: primaryColour, fontWeight: 'bold' }}
                                                onPress={() => {
                                                    defaultLink == '' ?
                                                        navigate('/' + dukanId + '/shop', { state: { searchText: searchText } })
                                                        : navigate('/shop', { state: { searchText: searchText } })
                                                }}
                                            />
                                        </View>
                                        :
                                        <>
                                            {data.dukan_title1 != '' &&
                                                <div className="typewriter" >
                                                    <Text style={{ color: secondaryColor, fontSize: 20, textAlign: 'center' }}>
                                                        {data.dukan_title1}
                                                    </Text>
                                                </div>
                                            }
                                            {data.dukan_title3 != '' &&
                                                <Text style={{ color: secondaryColor, fontSize: 14, width: '50%', textAlign: 'center', textTransform: 'capitalize' }}>
                                                    {data.dukan_title3}
                                                </Text>
                                            }
                                            <PerfectInput
                                                style={{
                                                    backgroundColor: 'trandparent', width: '70%',
                                                    borderColor: secondaryColor, borderWidth: 1, borderRadius: 20,
                                                    shadowColor: 'transparent', color: secondaryColor
                                                }}
                                                placeholder='Search...'
                                                placeholderTextColor={secondaryColor + 'B3'}
                                                setvalue={setsearchText}
                                            />
                                            <RoundButton text='Search'
                                                style={{
                                                    borderRadius: 20, width: '30%', backgroundColor: secondaryColor, borderColor: secondaryColor, borderWidth: 2
                                                    , color: secondaryColor, zIndex: 200000
                                                }}
                                                textStyle={{ color: primaryColour, fontWeight: 'bold' }}
                                                onPress={() => {
                                                    defaultLink == '' ?
                                                        navigate('/' + dukanId + '/shop', { state: { searchText: searchText } })
                                                        : navigate('/shop', { state: { searchText: searchText } })
                                                }}
                                            />
                                        </>
                                    }
                                </ImageBackground>
                            </View>

                        </View>
                        :
                        <ImageBackground
                            style={{
                                // backgroundColor: primarybg,
                                backgroundColor: 'black',
                                height: isDesktopOrLaptop ? Dimensions.get('window').height / 1.7 : Dimensions.get('window').width * 0.8,
                                width: '100%',
                                // flex: 1,
                                flexDirection: 'column',
                                paddingTop: StatusBar.currentHeight,
                                paddingHorizontal: isDesktopOrLaptop && 50,
                                marginTop: !isDesktopOrLaptop && '-26%'
                                , justifyContent: 'center', alignItems: 'center', backgroundColor: primaryColour
                            }}
                            imageStyle={{
                                // backgroundColor: 'black',
                                opacity: 0.5,
                                height: isDesktopOrLaptop ? '100%' : '100%'

                            }}
                            resizeMode="cover"
                            source={data.dp !== 'null' ? serverURL + data.dp : '/static/dukanDefault.jpg'}
                        >
                            {console.log(data, 'ddddddddddddddd')}
                            {data.dukan_title1 != '' &&
                                <div class="typewriter">
                                    <Text style={{ color: secondaryColor, fontSize: 27, width: '70%', textAlign: 'center', }}>
                                        {data.dukan_title1}
                                    </Text>
                                </div>
                            }
                            {data.dukan_title3 != '' &&
                                <Text style={{ color: secondaryColor, fontSize: 16, width: '50%', textAlign: 'center', textTransform: 'capitalize' }}>
                                    {data.dukan_title3}
                                </Text>
                            }
                            <PerfectInput
                                style={{
                                    backgroundColor: 'trandparent', width: '70%',
                                    borderColor: secondaryColor, borderWidth: 1, borderRadius: 20,
                                    shadowColor: 'transparent'
                                }}
                                placeholder='Search...'
                                placeholderTextColor={secondaryColor + 80}
                            />
                            <RoundButton text='Search'
                                style={{
                                    borderRadius: 20, width: '30%', backgroundColor: secondaryColor, borderColor: secondaryColor, borderWidth: 2
                                    , color: secondaryColor
                                }}
                                textStyle={{ color: primaryColour, fontWeight: 'bold' }}
                            />

                        </ImageBackground>
                }
                </>
            }

        </>
    );
}

