import { useContext, useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View, ScrollView, FlatList, Dimensions, ActivityIndicator, ImageBackground, Image } from "react-native";
import { defaultLink, primarybg, serverURL } from "../../../utils/string";
import { getData, LoadingIndicator } from "../../../utils/utils";
import { HomeContext } from "../../../utils/contexts";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";

const { height, width } = Dimensions.get('window');

export default function Categories() {
    // const router = useRouter()
    const navigate = useNavigate()
    const { dukanId } = useParams();
    // const move = useLinkTo();
    const { categories, selectedCategory, setselectedCategory, categoriesLoading, primaryColour } = useContext(HomeContext);
    const onCategoryPress = (item) => {
        if (selectedCategory != item.id) { setselectedCategory(item.id) }
    }
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })


    return (
        <>
            {categories.length > 0 &&
                <Text style={[
                    { marginHorizontal: 30, marginVertical: 0, fontWeight: 'bold', fontSize: 19, color: primaryColour },
                    isDesktopOrLaptop ? { textAlign: 'center', marginHorizontal: 30, marginVertical: 40 } :
                        { textAlign: 'center', fontSize: 18, marginHorizontal: 10, fontWeight: '500', letterSpacing: 1.5, marginVertical: 17, marginBottom: 25 }
                ]}>Top Picks</Text>
            }
            {!categoriesLoading ?

                <FlatList
                    contentContainerStyle={{ justifyContent: 'center', paddingBottom: 0, paddingTop: 0 }}
                    data={categories}
                    numColumns={isDesktopOrLaptop ? 4 : 2}
                    // horizontal
                    indicatorStyle={{ backgroundColor: 'red' }}
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}
                    renderItem={({ item }) => (
                        <TouchableOpacity
                            onPress={() => {
                                let t = item.name.replace(/[^a-z0-9\s-]/ig, '')
                                    .trim()
                                    .replace(/\s+/g, '-')
                                    .toLowerCase()
                                defaultLink == '' ?
                                    navigate('/' + dukanId + '/catalog/' + item.id + '/' + t)
                                    : navigate('catalog/' + item.id + '/' + t)
                            }}
                            style={{
                                width: isDesktopOrLaptop ? Dimensions.get('window').width / 4.5 - 50 : Dimensions.get('window').width / 2 - 20,
                                marginHorizontal: isDesktopOrLaptop ? 25 : 10, marginBottom: 20, borderWidth: isDesktopOrLaptop ? 1 : 0.2, borderColor: primaryColour
                            }}>
                            <Image
                                source={item.image != '' ? (serverURL + item.image) : ''}
                                style={{ width: '100%', height: isDesktopOrLaptop ? 400 : 200 }}
                            />
                            <View style={{ flexDirection: 'column', marginTop: 5 }}>
                                <Text style={{
                                    flex: !isDesktopOrLaptop && 0.9, fontWeight: '300', fontSize: isDesktopOrLaptop ? 16 : 14,
                                    marginRight: 13, letterSpacing: 1.5, textAlign: 'center', paddingBottom: 10, color: primaryColour
                                }}>
                                    {item.name}
                                </Text>
                            </View>
                        </TouchableOpacity>
                    )}
                    keyExtractor={item => item.id}
                />
                :
                <LoadingIndicator />
            }
        </>
    );
}

