import { StyleSheet, Text, TouchableOpacity, View, ScrollView, Image, ActivityIndicator, ImageBackground, Dimensions } from "react-native";
import Categories from "./categories";
import Footer from "./footer";
import ProfileHeader1 from './header'
import Products from "./products";
import { borderColor, defaultLink, primarybg, serverURL, shadowColor } from "../../../utils/string";
import { useMediaQuery } from 'react-responsive'
import Tab from "../tab";
import { useEffect, useState } from "react";
import { getDukanCatalogs, getDukanCategories, getDukanProductsForHome, getHomePage, subscribeDukan, unsubscribeDukan } from "../../../utils/api";
import { ToastContainer, toast } from 'react-toastify';
import { HomeContext } from "../../../utils/contexts";
import { getData, LoadingIndicator, PerfectInput, RoundButton, storeData } from "../../../utils/utils";
import Login from "../account/login";
import banner2 from '../../../assets/banner2.jpg'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../../../utils/loader.css'


export default function Home1(props) {

    const navigate = useNavigate();
    const { dukanId } = useParams();
    const { primaryColour, dukanName, facebook,
        instagram,
        youtube, } = props
    let secondaryColor = typeof props.secondaryColor != 'undefined' ? props.secondaryColor : primarybg
    secondaryColor = secondaryColor == '#31456a' ? primarybg : secondaryColor

    const notify = (t) => toast.dark(t, {
        position: toast.POSITION.BOTTOM_CENTER
    });
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const [data, setdata] = useState({});
    const [isLoading, setisLoading] = useState(true);

    // const [dukanId, setdukanId] = useState('');

    const [categories, setcategories] = useState([]);
    const [selectedCategory, setselectedCategory] = useState('all');
    const [categoriesLoading, setcategoriesLoading] = useState(true);

    const [searchText, setsearchText] = useState('');
    const [searchSubmit, setsearchSubmit] = useState(Math.random());

    const [products, setproducts] = useState([]);
    const [productsPrevStart, setproductsPrevStart] = useState(0);
    const [productsLoadMore, setproductsLoadMore] = useState(true);
    const [loginVisible, setloginVisible] = useState(false);
    const [productsLoading, setproductsLoading] = useState(true);

    const [secureToken, setsecureToken] = useState('');
    const [subscribeKey, setSubscribeKey] = useState(Math.random());


    const [pageLoaded, setpageLoaded] = useState(false);

    useEffect(() => {
        if (primaryColour != '#5893D4aa') {
            setpageLoaded(true)
        }
    }, [primaryColour])


    useEffect(() => {
        if (defaultLink == '') {
            if (dukanId && isLoading) {
                storeData(dukanId, 'dukanLink')
                getData('secure_token', setsecureToken);
                getHomePage(setdata, setisLoading, dukanId, notify, navigate)
                setisLoading(false)
                getDukanCatalogs(dukanId, setcategories, setcategoriesLoading, navigate)
                getDukanProductsForHome(
                    setproducts, [], setproductsLoading, 0, setproductsPrevStart,
                    setproductsLoadMore, dukanId, notify, navigate, selectedCategory, searchText, dukanId)
            }
        } else {
            storeData(dukanId, 'dukanLink')
            getData('secure_token', setsecureToken);
            getHomePage(setdata, setisLoading, dukanId, notify, navigate)
            setisLoading(false)
            getDukanCatalogs(dukanId, setcategories, setcategoriesLoading, navigate)
            getDukanProductsForHome(
                setproducts, [], setproductsLoading, 0, setproductsPrevStart,
                setproductsLoadMore, dukanId, notify, navigate, selectedCategory, searchText, dukanId)
        }

    }, [dukanId])



    //on selected category change
    useEffect(() => {
        if (typeof data.id !== 'undefined') {
            getDukanProductsForHome(
                setproducts, [], setproductsLoading, 0, setproductsPrevStart,
                setproductsLoadMore, data.id, notify, navigate, selectedCategory, searchText)
        }
    }, [selectedCategory])

    //on search 
    useEffect(() => {
        if (typeof data.id !== 'undefined') {
            getDukanProductsForHome(
                setproducts, [], setproductsLoading, 0, setproductsPrevStart,
                setproductsLoadMore, data.id, notify, navigate, selectedCategory, searchText)
        }
    }, [searchSubmit])




    return (
        <>
            {!pageLoaded ?
                <View style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: Dimensions.get('window').height }}>
                    <div className="loadingio-spinner-chunk-nx0mjvmz269"><div className="ldio-q1sjvrz6fl">
                        <div><div><div></div><div></div><div></div><div></div></div></div>
                    </div></div>
                </View>
                :
                <HomeContext.Provider
                    value={{
                        data, setdata, products, categories, selectedCategory, setselectedCategory, productsLoading,
                        categoriesLoading, searchText, setsearchText, primaryColour, secondaryColor, dukanName,
                        facebook,
                        instagram,
                        youtube,
                    }}
                >
                    {/* <Head>
                <title>{data.user_name && data.user_name}</title>
                <meta property="og:title" content={data.user_name && data.user_name} />
                <meta property="og:description" content={data.description && data.description} />
                {data.dp !== 'null' &&
                    <meta property="og:image" content={serverURL + data.dp} />
                }
            </Head> */}
                    <Tab primaryColour={primaryColour} secondaryColor={secondaryColor} storeName={data.user_name}>
                        {!isLoading ?
                            <>
                                <Login visible={loginVisible} setvisible={setloginVisible} primaryColour={primaryColour} />
                                <ToastContainer autoClose={2000} />
                                {/* <ScrollView> */}

                                <ProfileHeader1 />


                                <View style={[isDesktopOrLaptop ? { marginHorizontal: '10%' } :
                                    { backgroundColor: primarybg, paddingTop: 5, borderTopLeftRadius: 0, borderTopRightRadius: 0 }]}>
                                    <Categories />
                                    <View key={searchSubmit} >
                                        <View key={selectedCategory}>
                                            <Products />
                                        </View>
                                    </View>
                                    <View style={{ width: '90%', backgroundColor: 'lightgray', height: 0, marginBottom: 30, alignSelf: 'center' }} />
                                </View>
                                <ImageBackground
                                    source={require('../../../assets/template1/s-banner.webp')}
                                    imageStyle={{ opacity: 0.8, borderRadius: 0, }}
                                    style={[isDesktopOrLaptop ? {
                                        backgroundColor: 'black', flexDirection: 'row',
                                        // shadowColor: "#000",
                                        // shadowOffset: {
                                        //     width: 0,
                                        //     height: 4,
                                        // },
                                        // shadowOpacity: 0.32,
                                        // shadowRadius: 5.46,
                                        // borderRadius: 20,
                                        padding: 60,
                                        borderColor: 'lightgray',
                                        borderWidth: 0.4,
                                        marginHorizontal: '0%'

                                    }
                                        : {
                                            backgroundColor: 'black',
                                            // shadowColor: primaryColour,
                                            // shadowOffset: {
                                            //     width: 0.5,
                                            //     height: 0.5,
                                            // },
                                            // shadowOpacity: 0.32,
                                            // shadowRadius: 5.46,
                                            // borderRadius: 5,
                                            padding: 20,
                                            paddingVertical: 50,
                                            borderColor: borderColor,
                                            borderWidth: 0.5,
                                            marginHorizontal: '0%'

                                        }
                                    ]}>
                                    <View style={{ width: '100%' }}>
                                        <Text style={{ fontSize: 27, color: primaryColour, textAlign: 'center', textTransform: 'uppercase', color: primarybg }}>{data.user_name}</Text>
                                        <Text style={[isDesktopOrLaptop ? { fontSize: 23, textAlign: 'center' } : { fontSize: 16, textAlign: 'center' },
                                        { color: primarybg, }
                                        ]}>Subscribe {data.user_name}{'\n'} Get notification of special deals</Text>
                                        {isDesktopOrLaptop ?
                                            <TouchableOpacity style={{
                                                backgroundColor: primaryColour, paddingHorizontal: 20, paddingVertical: 10,
                                                width: 200, alignSelf: 'center', marginTop: 20,
                                                borderRadius: 20
                                            }}
                                                onPress={() => {
                                                    if (secureToken != '@error' && secureToken != '') {
                                                        if (data.same_dukan !== 1) {
                                                            if (data.user_following === 0) {
                                                                subscribeDukan(data.id, navigate)
                                                                data.user_following = 1
                                                                notify('successfully subscribed')
                                                                setSubscribeKey(Math.random())
                                                            } else {
                                                                unsubscribeDukan(data.id, navigate)
                                                                data.user_following = 0
                                                                notify('successfully unsubscribed')
                                                                setSubscribeKey(Math.random())
                                                            }
                                                        } else {
                                                            notify('Cant subscribe own dukan')
                                                        }
                                                    } else { setloginVisible(!loginVisible) }
                                                }}
                                            >
                                                <Text style={{ color: primarybg, textAlign: 'center' }} key={subscribeKey}>
                                                    {secureToken != '@error' && secureToken != '' ?
                                                        (data.user_following == 0 ?
                                                            'Subscribe'
                                                            : 'Unsubscribe'
                                                        )
                                                        : 'Subscribe'
                                                    }

                                                </Text>
                                            </TouchableOpacity>
                                            : null}


                                    </View>
                                    {!isDesktopOrLaptop ?
                                        <TouchableOpacity style={{
                                            flex: 1,
                                            backgroundColor: primaryColour, paddingHorizontal: 20, paddingVertical: 10,
                                            width: 200, alignSelf: 'center', marginTop: 20,
                                            borderRadius: 20
                                        }}
                                            onPress={() => {
                                                if (secureToken != '@error' && secureToken != '') {
                                                    if (data.same_dukan !== 1) {
                                                        if (data.user_following === 0) {
                                                            subscribeDukan(data.id, navigate)
                                                            data.user_following = 1
                                                            notify('successfully subscribed')
                                                            setSubscribeKey(Math.random())
                                                        } else {
                                                            unsubscribeDukan(data.id, navigate)
                                                            data.user_following = 0
                                                            notify('successfully unsubscribed')
                                                            setSubscribeKey(Math.random())
                                                        }
                                                    } else {
                                                        notify('Cant subscribe own dukan')
                                                    }
                                                } else { setloginVisible(!loginVisible) }
                                            }}
                                        >
                                            <Text style={{ color: primarybg, textAlign: 'center' }} key={subscribeKey}>
                                                {secureToken != '@error' && secureToken != '' ?
                                                    (data.user_following == 0 ?
                                                        'Subscribe'
                                                        : 'Unsubscribe'
                                                    )
                                                    : 'Subscribe'
                                                }
                                            </Text>
                                        </TouchableOpacity>
                                        : null}

                                </ImageBackground>

                                <Footer dukanId={dukanId} data={data} />
                            </>
                            :
                            <LoadingIndicator />
                        }
                    </Tab>
                </HomeContext.Provider >
            }
        </>
    );
}

