import * as React from 'react';
import { StyleSheet, Text, TouchableOpacity, View, ScrollView, Image, FlatList, Dimensions, ActivityIndicator } from "react-native";
import { serverURL, shadowColor } from "../../utils/string";
import { useMediaQuery } from 'react-responsive'


import { useEffect, useState } from "react";
import { findDay, RoundButton } from '../../utils/utils';
import ImageLoad from '../../utils/imageLoad';
import Sidebar from '../sidebar';
import { shadow } from '../utils/main';
import { getOrdersReceived } from '../../utils/api';
import { useNavigate } from "react-router-dom";
import { primarybg, primaryColour, secondarybg } from "../utils/string";


export default function OrdersReceived(props) {

    const move = useNavigate();
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const { visible, setvisible } = props


    const [messages, setmessages] = useState([]);
    const [orders, setorders] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [orderStatus, setorderStatus] = useState('');

    const month = [
        'Jan',
        'Fab',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    useEffect(() => {
        getOrdersReceived(setorders, setisLoading, move, setmessages, orderStatus)
    }, [])

    useEffect(() => {
        getOrdersReceived(setorders, setisLoading, move, setmessages, orderStatus)
    }, [orderStatus])

    return (
        <Sidebar active='ordersReceived' notScrollView={true} title={'Orders received'}>
            {isDesktopOrLaptop ?
                <FlatList
                    style={{ backgroundColor: primarybg }}
                    ListEmptyComponent={() => (
                        <Text style={{ textAlign: 'center', marginVertical: 20, color: secondarybg }}>No one have bought anything from your dukan yet..</Text>
                    )}
                    ListEmptyComponent={() => (
                        <>
                            {isLoading ? <ActivityIndicator color={primaryColour} /> :
                                <>
                                    {orderStatus == '' ?
                                        <Text style={{ textAlign: 'center', marginVertical: 20, color: secondarybg }}>No one have bought anything from your dukan yet..</Text>
                                        : <Text style={{ textAlign: 'center', marginVertical: 20, color: secondarybg }}>No Orders</Text>}
                                </>
                            }
                        </>
                    )}
                    ListHeaderComponent={() => (
                        <>
                            <FlatList
                                contentContainerStyle={{ height: 40, paddingVertical: 5, marginTop: 6 }}
                                data={[
                                    { text: 'All', id: '' },
                                    { text: 'Pending', id: 'pending' },
                                    { text: 'Accepted', id: 'accepted' },
                                    { text: 'Shipped', id: 'shipped' },
                                    { text: 'Delivered', id: 'delivered' },
                                    { text: 'Canceled', id: 'canceled' }
                                ]}
                                horizontal

                                showsHorizontalScrollIndicator={false}
                                renderItem={({ item }) => (
                                    <TouchableOpacity style={[{
                                        backgroundColor: primarybg,
                                        shadowColor: "#000",
                                        shadowOffset: {
                                            width: 0,
                                            height: 4,
                                        },
                                        shadowOpacity: 0.32,
                                        shadowRadius: 5.46,
                                        elevation: 3,
                                        marginHorizontal: 5,
                                        borderRadius: 25,
                                        // width: 70,
                                        // height: 70,
                                        padding: 3,
                                        paddingHorizontal: 12,
                                        borderColor: 'lightgray',
                                        borderWidth: 0.1,
                                        flexDirection: 'row',
                                        height: 30
                                    },
                                    orderStatus == item.id ? { backgroundColor: primaryColour } : {}
                                    ]}
                                        onPress={() => setorderStatus(item.id)}
                                    >
                                        <Text style={[{
                                            color: primaryColour, textAlign: 'center',
                                            fontWeight: '300', fontSize: 14, textTransform: 'capitalize'
                                        },
                                        orderStatus == item.id ? { color: primarybg } : {}
                                        ]}
                                        >{item.text}</Text>
                                    </TouchableOpacity>
                                )}
                            />
                            <View style={{ flexDirection: 'row', borderRadius: 10, backgroundColor: primaryColour + '21', paddingVertical: 10, paddingHorizontal: 10, marginTop: 20 }}>
                                <Text style={{ flex: 0.3 }}>
                                    Product
                                </Text>
                                <Text style={{ flex: 0.1 }}>
                                    Status
                                </Text>
                                <Text style={{ flex: 0.1 }}>
                                    Total
                                </Text>
                                <Text style={{ flex: 0.1 }}>
                                    Quantity
                                </Text>
                                <Text style={{ flex: 0.2 }}>
                                    Date
                                </Text>
                                <Text style={{ flex: 0.2 }}>
                                    Action
                                </Text>
                            </View>
                        </>
                    )}
                    contentContainerStyle={[{ width: !isDesktopOrLaptop && Dimensions.get('window').width, marginHorizontal: isDesktopOrLaptop ? '5%' : '1%', paddingHorizontal: isDesktopOrLaptop ? '3%' : 0 }, isDesktopOrLaptop && shadow]}
                    data={orders}
                    renderItem={({ item }) => (
                        <View style={{ flexDirection: 'row', paddingVertical: 10, paddingHorizontal: 20, alignItems: 'center', marginBottom: 10 }}>
                            <View style={{ flex: 0.3, flexDirection: 'row', alignItems: 'center' }}>
                                <ImageLoad
                                    style={[{
                                        width: 50,
                                        height: 40,
                                        borderWidth: 1
                                    }
                                    ]}
                                    // imageStyle={{
                                    //     width: 60,
                                    //     height: 50,
                                    // }}
                                    resizeMode="cover"
                                    source={{ uri: serverURL + item.thumbnail }}
                                />
                                <Text style={{ fontWeight: 'bold', marginLeft: 5 }}>{item.title.length > 20 ? String(item.title.substring(0, 20) + '...').replace(/\n/g, " ") : String(item.title.replace(/\n/g, " "))}</Text>
                            </View>
                            <Text style={{ flex: 0.1, fontWeight: 'bold', textTransform: 'capitalize' }}>
                                {item.status}
                            </Text>
                            <Text style={{ flex: 0.1, fontWeight: 'bold' }}>
                                Rs. {item.price}
                            </Text>
                            <Text style={{ flex: 0.1, fontWeight: 'bold' }}>
                                {item.quantity}
                            </Text>
                            <Text style={{ flex: 0.2, fontWeight: 'bold' }}>
                                {item.date}
                            </Text>
                            <View style={{ flex: 0.1 }}>
                                <RoundButton text='View details >' style={{ height: 30 }}
                                    onPress={() => move('/order-details/' + item.id)}
                                />
                            </View>
                        </View>
                    )} />
                :
                <FlatList
                    style={{ backgroundColor: primarybg }}
                    ListEmptyComponent={() => (
                        <Text style={{ textAlign: 'center', marginVertical: 20, color: secondarybg }}>No one have bought anything from your dukan yet..</Text>
                    )}
                    ListEmptyComponent={() => (
                        <>{orderStatus == '' ?
                            <Text style={{ textAlign: 'center', marginVertical: 20, color: secondarybg }}>No one have bought anything from your dukan yet..</Text>
                            : <Text style={{ textAlign: 'center', marginVertical: 20, color: secondarybg }}>No Orders</Text>}
                        </>
                    )}
                    ListHeaderComponent={() => (
                        <FlatList
                            contentContainerStyle={{ paddingVertical: 8, marginTop: 0, marginBottom: 10, marginLeft: 10, backgroundColor: primarybg }}
                            data={[
                                { text: 'All', id: '' },
                                { text: 'Pending', id: 'pending' },
                                { text: 'Accepted', id: 'accepted' },
                                { text: 'Shipped', id: 'shipped' },
                                { text: 'Delivered', id: 'delivered' },
                                { text: 'Canceled', id: 'canceled' }
                            ]}
                            horizontal
                            showsHorizontalScrollIndicator={false}
                            renderItem={({ item }) => (
                                <TouchableOpacity style={[{
                                    backgroundColor: primarybg,
                                    shadowColor: "#000",
                                    shadowOffset: {
                                        width: 0,
                                        height: 4,
                                    },
                                    shadowOpacity: 0.32,
                                    shadowRadius: 5.46,
                                    elevation: 3,
                                    marginHorizontal: 5,
                                    marginTop: 0,
                                    borderRadius: 25,
                                    // width: 70,
                                    // height: 70,
                                    padding: 3,
                                    paddingHorizontal: 12,
                                    borderColor: 'lightgray',
                                    borderWidth: 0.1,
                                    flexDirection: 'row',
                                    height: 30, paddingTop: 5

                                },
                                orderStatus == item.id ? { backgroundColor: secondarybg } : {}
                                ]}
                                    onPress={() => setorderStatus(item.id)}
                                >
                                    <Text style={[{
                                        color: primaryColour, textAlign: 'center',
                                        fontWeight: '300', fontSize: 14, textTransform: 'capitalize'
                                    },
                                    orderStatus == item.id ? { color: primarybg } : {}
                                    ]}
                                    >{item.text}</Text>
                                </TouchableOpacity>
                            )}
                        />
                    )}
                    contentContainerStyle={{ width: !isDesktopOrLaptop && Dimensions.get('window').width, }}
                    data={orders}
                    renderItem={({ item }) => (
                        <TouchableOpacity
                            style={[shadow, {
                                alignSelf: 'center',
                                marginHorizontal: 5,
                                marginVertical: 5,
                                marginBottom: 9,
                                backgroundColor: primarybg,
                                elevation: 8,
                                shadowColor: primaryColour,
                                borderWidth: 0.3,
                                borderColor: 'lightgray',
                                width: '95%',
                                // paddingHorizontal: 10,
                                borderRadius: 10,
                                // flexDirection: 'row',
                                paddingTop: 10,

                            }]}
                            onPress={() => move('/order-details/' + item.id)}
                        >
                            <View style={{ flexDirection: 'row', borderBottomWidth: 0.2, borderColor: 'lightgray', paddingBottom: 6, paddingHorizontal: 10 }}>
                                <Text style={{ flex: 0.7, textAlign: 'left', fontWeight: 'bold', fontSize: 12, lineHeight: 13, flex: 0.7, color: primaryColour }}>
                                    {item.title.length > 25 ? String(item.title.substring(0, 25) + '...').replace(/\n/g, " ") : String(item.title.replace(/\n/g, " "))}
                                </Text>
                                <Text style={{ textAlign: 'right', flex: 0.3, color: primaryColour, fontSize: 11, fontWeight: 'bold' }}>
                                    {findDay(item.date.split(" ")[0])} {item.date.split(" ")[1]}
                                </Text>
                            </View>
                            <View style={{ flexDirection: 'row', borderBottomWidth: 0.2, borderColor: 'lightgray', paddingBottom: 6, paddingTop: 6, paddingHorizontal: 10 }}>
                                <ImageLoad
                                    style={{
                                        // marginTop: 20,
                                        height: 50,
                                        borderRadius: 7,
                                        flex: 0.3,
                                        marginTop: 2,
                                        // alignSelf: 'center',
                                        marginRight: 7
                                        // borderBottomLeftRadius: 0,
                                        // borderBottomRightRadius: 0,
                                        // backgroundColor: '#f0f0f0'
                                    }}
                                    imageStyle={{
                                        // flex: 1,
                                        borderRadius: 5,
                                        height: 50,
                                    }}
                                    resizeMode="cover"
                                    source={{ uri: serverURL + item.thumbnail }}
                                />
                                <View style={{ flex: 0.8, marginLeft: 5 }}>
                                    <Text style={{ fontWeight: 'bold', color: primaryColour }}>Rs. {item.price}</Text>
                                    <Text style={{ color: 'gray' }}>Quantity: {item.quantity}</Text>
                                    <Text style={{ color: 'gray', fontSize: 12, }}>Cash on delivery</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginHorizontal: 7 }}>
                                <Text style={{ color: 'gray', fontSize: 13 }}>Status: {item.status}</Text>
                                <Text style={{
                                    width: '30%',
                                    color: primaryColour, fontSize: 12, textAlign: 'center',
                                    backgroundColor: secondarybg, marginVertical: 5,
                                    paddingVertical: 4, borderRadius: 30,
                                    alignSelf: 'center', fontWeight: 'bold'
                                }}
                                >View details {'>'}</Text>
                            </View>


                        </TouchableOpacity>
                    )} />
            }
        </Sidebar>

    );
}

