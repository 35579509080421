import * as React from 'react';
import { useState, useEffect } from 'react';
import { ActivityIndicator, Button, Dimensions, FlatList, Image, ImageBackground, Linking, RefreshControl, ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { borderColor, inactiveColor, inputBackgound, primarybg, primaryColour, primaryText, secondarybg, secondaryColor, serverURL } from '../../utils/string';
import { PerfectInput, RoundButton } from '../../utils/utils';

import { getStoreDetailsForCustomization, saveStoreDetailsForCustomization } from '../../utils/api';
import PerfectModal from '../../utils/perfectModel';
import { SketchPicker } from 'react-color';
import { useNavigate } from "react-router-dom";
import Sidebar from '../sidebar';
import ApperanceSidebar from './sidebar';
import { shadow } from '../utils/main';
import { useMediaQuery } from 'react-responsive';

export function BannersSetting(props) {
    const move = useNavigate();
    const [banner1, setbanner1] = useState('');
    const [banner2, setbanner2] = useState('');
    const [banner3, setbanner3] = useState('');
    const [key, setkey] = useState('');
    const [uploadButton, setuploadButton] = useState(true);
    const [saving, setsaving] = useState(false);
    const [deleteBanner, setdeleteBanner] = useState([]);

    const [data, setdata] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    useEffect(() => {
        getStoreDetailsForCustomization(move, setisLoading, setdata)
    }, [])

    useEffect(() => {
        if (data != '') {
            if (typeof data.banners != 'undefined') {
                data.banners.banner1 != '' && setbanner1({ image: data.banners.banner1, type: 'uploaded' })
                data.banners.banner2 != '' && setbanner2({ image: data.banners.banner2, type: 'uploaded' })
                data.banners.banner3 != '' && setbanner3({ image: data.banners.banner3, type: 'uploaded' })
            }
        }
    }, [data])



    return (
        <ApperanceSidebar active='topBanners'>
            <View style={[{ marginHorizontal: isDesktopOrLaptop ? '5%' : '1%', paddingHorizontal: isDesktopOrLaptop ? '5%' : '2%', paddingTop: 15, paddingVertical: '2%', borderRadius: 20 }, shadow]}>
                <Text style={{ textAlign: 'center', fontSize: 12, color: 'gray' }}>
                    Banners will appear on top of your website.If you will not add banners ,cover photo of your dukan will be used as banner
                </Text>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                    <View style={{ flex: isDesktopOrLaptop ? 0.4 : 0.47, backgroundColor: primaryColour + '21', padding: 10, marginBottom: 20 }}>
                        <Image
                            style={{ width: '100%', height: 100 }}
                            source={
                                banner1 == '' ?
                                    require('../../assets/img/emptyImage.jpg')
                                    : (banner1.type == 'uploading' ? { uri: URL.createObjectURL(banner1.image) }
                                        : { uri: serverURL + banner1.image })
                            } />
                        {banner1 != '' &&
                            <RoundButton text='Delete Banner 1' style={{ alignSelf: 'center', width: '90%', height: 30, marginBottom: 0, backgroundColor: 'red' }}
                                textStyle={{ fontSize: 12 }}
                                onPress={() => {
                                    setbanner1('')
                                    console.log(deleteBanner, 'bbb')
                                    if (deleteBanner.indexOf('banner1') === -1) {
                                        setdeleteBanner([...deleteBanner, 'banner1'])
                                    }
                                }}
                            />}
                        {/* <RoundButton text='Select Banner 1' style={{ alignSelf: 'center', width: '90%', height: 30 }}
                        textStyle={{ fontSize: 12 }}
                        onPress={() => {
                            // NativeModules.ToastExample.show('hello')
                            ImagePicker.openPicker({
                                // multiple: true,
                                mediaType: 'photo',
                            }).then(image => {
                                setbanner1({ image: image, type: 'uploading' })
                                setkey(Math.random());
                            });
                        }}
                    /> */}
                        <input type="file"

                            style={{
                                backgroundColor: inputBackgound,
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: 12,
                                padding: 7,
                                paddingHorizontal: 10,
                                shadowOffset: {
                                    width: 0,
                                    height: 2,
                                },
                                borderWidth: 0.15,
                                shadowColor: 'gray',
                                elevation: 1,
                                alignContent: 'center',
                                borderRadius: 5,
                                borderColor: 'lightgray',
                                borderBottomWidth: 0,
                                color: primaryColour,
                                paddingVertical: 10,
                                marginTop: 10,
                                alignItems: 'center',
                            }}
                            onChange={(event) => {
                                let images = Array.from(event.target.files)
                                setbanner1({ image: images[0], type: 'uploading' })
                                setkey(Math.random());
                            }}
                        />

                    </View>
                    <View style={{ flex: isDesktopOrLaptop ? 0.4 : 0.47, backgroundColor: primaryColour + '21', padding: 10, marginBottom: 20 }}>
                        <Image
                            style={{ width: '100%', height: 100 }}
                            source={
                                banner2 == '' ?
                                    require('../../assets/img/emptyImage.jpg')
                                    : (banner2.type == 'uploading' ? { uri: URL.createObjectURL(banner2.image) }
                                        : { uri: serverURL + banner2.image })
                            } />
                        {banner2 != '' &&
                            <RoundButton text='Delete Banner 2' style={{ alignSelf: 'center', width: '90%', height: 30, marginBottom: 0, backgroundColor: 'red' }}
                                textStyle={{ fontSize: 12 }}
                                onPress={() => {
                                    setbanner2('')
                                    console.log(deleteBanner, 'bbb')
                                    if (deleteBanner.indexOf('banner2') === -1) {
                                        setdeleteBanner([...deleteBanner, 'banner2'])
                                    }
                                }}
                            />}

                        <input type="file"

                            style={{
                                backgroundColor: inputBackgound,
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: 12,
                                padding: 7,
                                paddingHorizontal: 10,
                                shadowOffset: {
                                    width: 0,
                                    height: 2,
                                },
                                borderWidth: 0.15,
                                shadowColor: 'gray',
                                elevation: 1,
                                alignContent: 'center',
                                borderRadius: 5,
                                borderColor: 'lightgray',
                                borderBottomWidth: 0,
                                color: primaryColour,
                                paddingVertical: 10,
                                marginTop: 10,
                                alignItems: 'center',
                            }}
                            onChange={(event) => {
                                let images = Array.from(event.target.files)
                                setbanner2({ image: images[0], type: 'uploading' })
                                setkey(Math.random());
                            }}
                        />
                    </View>
                </View>
                <View style={{ width: isDesktopOrLaptop ? '40%' : '47%', backgroundColor: primaryColour + '21', padding: 10, marginBottom: 20 }}>
                    <Image
                        style={{ width: '100%', height: 100 }}
                        source={
                            banner3 == '' ?
                                require('../../assets/img/emptyImage.jpg')
                                : (banner3.type == 'uploading' ? { uri: URL.createObjectURL(banner3.image) }
                                    : { uri: serverURL + banner3.image })
                        } />
                    {banner3 != '' &&
                        <RoundButton text='Delete Banner 3' style={{ alignSelf: 'center', width: '90%', height: 30, marginBottom: 0, backgroundColor: 'red' }}
                            textStyle={{ fontSize: 12 }}
                            onPress={() => {
                                setbanner3('')
                                if (deleteBanner.indexOf('banner3') === -1) {
                                    setdeleteBanner([...deleteBanner, 'banner3'])
                                }
                            }}
                        />}
                    <input type="file"

                        style={{
                            backgroundColor: inputBackgound,
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: 12,
                            padding: 7,
                            paddingHorizontal: 10,
                            shadowOffset: {
                                width: 0,
                                height: 2,
                            },
                            borderWidth: 0.15,
                            shadowColor: 'gray',
                            elevation: 1,
                            alignContent: 'center',
                            borderRadius: 5,
                            borderColor: 'lightgray',
                            borderBottomWidth: 0,
                            color: primaryColour,
                            paddingVertical: 10,
                            marginTop: 10,
                            alignItems: 'center',
                        }}
                        onChange={(event) => {
                            let images = Array.from(event.target.files)
                            setbanner3({ image: images[0], type: 'uploading' })
                            setkey(Math.random());
                        }}
                    />
                </View>
                <View style={{ borderTopWidth: 2, borderColor: borderColor, paddingTop: 10, flexDirection: 'row', justifyContent: 'center' }}>

                </View>
                {saving && <ActivityIndicator color={secondarybg} size={'large'} />}
                <RoundButton text='Save' style={{ backgroundColor: primaryColour, height: 40, borderRadius: 20, width: '30%', alignSelf: 'center' }}
                    onPress={() => { saveStoreDetailsForCustomization(move, setsaving, banner1, banner2, banner3, '', '', deleteBanner, '', '', '', '') }}
                />
            </View>
        </ApperanceSidebar>
    );
}