import React from 'react';

export const HomeContext = React.createContext({
    data: '',
    setdata: () => { },
    products: [],
    isLoading: true,
    productsLoading: false,
    categoriesLoading: false,
    searchText: '',
    setsearchText: () => { }
})

export const AboutUsContext = React.createContext({
    data: '',
})

export const TabContext = React.createContext({
    setscrollEnabled: () => { },
    hideBottomTab: '',
    sethideBottomTab: () => { }
})

