import { defaultLink, demo, domainName, serverURL } from "./string";
import qs from 'qs';
import axios from 'axios';
import { getData, notify, removeFromBag, storeData, validateYTBURL } from "./utils";
import AsyncStorage from "@react-native-async-storage/async-storage";


export async function addProduct(
    setIsLoading, title, settitleError, description,
    setdescriptionError, photos, setphotosError,
    ytLink, ytbError, postType, condition, tags,
    city, address, switchValue, state2, price, move,
    setError, bazaarId, setremUpload, setuploaded, subCategory,
    allowResellers, resellerCommision, userCategory, userCategoryId,
    allowOrders, quantity, shipping, area, variations, setshareText,
    sizes, colors
) {
    variations = JSON.stringify(variations)

    if (title.length < 5) {
        settitleError('A minimum length of 5 charachters is required');
        notify('Title error : A minimum length of 5 charachters is required');
        return;
    }
    if (description.length < 20) {
        setdescriptionError('A minimum length of 20 charachters is required');
        notify('Description error : A minimum length of 20 charachters is required');
        return;
    }
    if (photos.length < 1) {
        setphotosError('Please choose at least one photo');
        notify('Please choose at least one photo');
        return;
    }
    if (ytLink != '') {
        var validYTB = validateYTBURL(ytLink);
        if (!validYTB) {
            ytbError('Invalid youtube video URL');
            notify('Please choose at least one photo');
            return;
        }
    }
    setIsLoading(true);
    const secureToken = await AsyncStorage.getItem('secure_token');
    let formData = new FormData();
    // REMOVE EMOJIS
    // title = title.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
    // description = description.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
    // price = price.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
    // address = address.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
    // ytLink = ytLink.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');

    formData.append('type', '' + postType);
    formData.append('subCategory', '' + subCategory);
    formData.append('title', title);
    formData.append('description', '' + description);
    formData.append('ytlink', '' + ytLink);
    formData.append('condition', '' + condition);
    formData.append('tags', JSON.stringify(tags));
    formData.append('imagesLength', '' + photos.length);
    formData.append('city', '' + city);
    formData.append('address', '' + address);
    formData.append('show_number', '' + switchValue);
    formData.append('state', '' + state2);
    formData.append('price', '' + price);
    formData.append('allowResellers', '' + allowResellers);
    formData.append('allowOrders', '' + allowOrders);
    formData.append('resellerCommision', '' + resellerCommision);
    formData.append('bazaarId', '' + bazaarId);
    formData.append('userCategory', '' + userCategory);
    formData.append('userCategoryId', '' + userCategoryId);
    formData.append('quantity', '' + quantity);
    formData.append('shipping', '' + shipping);
    formData.append('area', '' + area);
    formData.append('variations', '' + variations);
    formData.append('colors', JSON.stringify(colors));
    formData.append('sizes', JSON.stringify(sizes));

    Array.from(photos).forEach((item, i) => {
        formData.append('images' + i, item.image);
    })
    axios.post(serverURL + '/products/post_product/', formData,
        {
            headers: {
                Authorization: 'token ' + secureToken,
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            let resp = res.data;
            setIsLoading(false);
            console.log(resp, 'rrrrrr')
            if (resp.code == 100) {
                notify("Product Added");
                // navigation.navigate('bazaarHome', { id: item.id })
                // console.log(resp.share_text)
                setshareText(resp.share_text)
            } else {
                if (resp.detail === 'Invalid token.') {
                    move('/PageNotFound')
                } else {
                    setError(resp.message);
                    notify(resp.message)
                }
            }
        }).catch(err => {
            console.log(err)
            setIsLoading(false);
            notify('..Something went wrong, please check your internet connection.If you have added any emoji ,special text or urdu alphabets .Please remove them');
        });
}


export async function updateProduct(
    setIsLoading, title, settitleError, description,
    setdescriptionError, photos, setphotosError,
    ytLink, ytbError, id, postType, condition, tags,
    city, address, switchValue, state2, price, move,
    setError, bazaarId, productId, setremUpload, setuploaded,
    allowResellers, quantity, shipping,
    sizes, colors
) {

    if (title.length < 5) {
        settitleError('A minimum length of 5 charachters is required');
        notify('Title error : A minimum length of 5 charachters is required');
        return;
    }
    if (description.length < 20) {
        setdescriptionError('A minimum length of 20 charachters is required');
        notify('Description error : A minimum length of 20 charachters is required');
        return;
    }
    if (photos.length < 1) {
        setphotosError('Please choose at least one photo');
        notify('Please choose at least one photo')
        return;
    }
    if (ytLink != '') {
        var validYTB = validateYTBURL('' + ytLink);
        if (!validYTB) {
            setIsLoading(false);
            ytbError('Invalid URL');
            return;
        }
    }
    if (productId === '') {
        setIsLoading(false);
        ytbError('Invalid request');
        return;
    }
    setIsLoading(true);

    // REMOVE EMOJIS
    title = title.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
    description = description.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
    price = price.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
    address = address.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
    ytLink = ytLink.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');


    const secureToken = await AsyncStorage.getItem('secure_token');
    let formData = new FormData();

    formData.append('name', 'user');
    // formData.append( 'type','' + id );
    formData.append('title', title);
    formData.append('description', '' + description);
    formData.append('ytlink', '' + ytLink);
    formData.append('condition', '' + condition);
    formData.append('tags', JSON.stringify(tags));
    formData.append('city', '' + city);
    formData.append('address', '' + address);
    formData.append('show_number', '' + switchValue);
    formData.append('state', '' + state2);
    formData.append('price', '' + price);
    formData.append('bazaarId', '' + bazaarId);
    formData.append('allowResellers', '' + allowResellers);
    formData.append('productId', '' + productId);
    formData.append('quantity', '' + quantity);
    formData.append('shipping', '' + shipping);
    formData.append('colors', JSON.stringify(colors));
    formData.append('sizes', JSON.stringify(sizes));


    let len = 0;
    let len2 = 0;
    let i = 0;
    let i2 = 0;

    // [{"image": "/media/product_images/product_ac0dbb453bb841049e8cd4fedd922a36.jpg", "variation": ""}]
    //  {"id": NaN, "image": {"height": 1306, "mime": "image/jpeg", "modificationDate": "1634018111000", 
    //  "path": "file:///storage/emulated/0/Android/data/com.bazaar_only_need/files/Pictures/39e4cfe4-f7f3-4203-ae70-440e40321dce.jpg", "size": 38519, "width": 628}, 
    //  "type": "uploading"}


    let thumbnail_check = false
    // if first image is new uploading make it thumbnail too 
    Array.from(photos).forEach((item, j) => {
        console.log(item.image, 'iiiiiiiiiiiiiii')
        if (item.type === 'uploading') {
            formData.append('images' + i, item.image);
            if (!thumbnail_check) {
                formData.append('new_thumbnail', item.image);
            }
            len++;
            i++;
        } else {
            formData.append('images2' + i2, '' + item.image);
            len2++;
            i2++;
        }
        thumbnail_check = true
    })

    // photos.forEach((item) => {
    //     if (item.type === 'uploading') {
    //         let photo = {
    //             name: 'images' + i,
    //             filename: item.image.path.substring(item.image.path.lastIndexOf('/') + 1),
    //             type: item.image.mime,
    //             data: RNFetchBlob.wrap(item.image.path)
    //         }
    //         if (!thumbnail_check) {
    //             new_thumbnail = {
    //                 name: 'new_thumbnail',
    //                 filename: item.image.path.substring(item.image.path.lastIndexOf('/') + 1),
    //                 type: item.image.mime,
    //                 data: RNFetchBlob.wrap(item.image.path)
    //             }
    //             data.push(new_thumbnail)
    //         }
    //         data.push(photo)
    //         len++;
    //         i++;
    //     } else {
    //         let photo = { name: 'images2' + i2, data: '' + item.image };
    //         data.push(photo);
    //         len2++;
    //         i2++;
    //     }
    //     thumbnail_check = true
    // })

    console.log('ppppppppp', photos)
    formData.append('imagesLength', '' + len)
    formData.append('images2Length', '' + len2)


    axios.post(serverURL + '/products/update_product/', formData,
        {
            headers: {
                Authorization: 'token ' + secureToken,
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            let resp = res.data;
            setIsLoading(false);
            if (resp.code === 100) {
                notify("Product Updated");
                move('/my-items')
            } else {
                if (resp.detail === 'Invalid token.') {
                    move('/dashboard-login')
                } else {
                    setError(resp.message)
                }
            }
        }).catch(error => {
            console.log(error)
            notify('Something went wrong, please check your internet connection');
        });
}



export const getOrdersReceived = async (setdata, setisLoading, navigation,
    setmessages, orderStatus
) => {

    setisLoading(true);

    const secureToken = await AsyncStorage.getItem('secure_token');

    if (secureToken != '@error' && secureToken != '' && secureToken !== null) {
        const form = new FormData();
        form.append('status', orderStatus);
        axios.post(serverURL + '/offers/received_orders/', form,
            {
                headers: {
                    Authorization: 'token ' + secureToken,
                    'Content-Type': 'multipart/form-data',
                    // 'RNFB-Response': 'utf8'
                }
            }).then(res => {
                let r = res.data;
                if (r.code == 100) {
                    setdata(r.data)
                    setmessages(r.messages)
                    console.log(r.data)

                } else {
                    if (r.detail === 'Invalid token.') {
                        // invalidTokenError2(navigation);
                    } else {
                        notify(r.message)
                        setisLoading(false);
                    }
                }
                setisLoading(false);
            })
            .catch(async error => {
                // await AsyncStorage.setItem('secure_token', '')
                console.log(error)
                notify('Something went wrong, please check your internet connection../');
            });
    } else {
        notify('Something went wrong, please check your internet connection..');
    }
}


export const profileProductsFetchSM = async (
    setData, data, setIsLoading, prevStart, setprevStart, setloadMore, move
    , userId = null
) => {
    setIsLoading(true);

    let secureToken = await AsyncStorage.getItem('secure_token');
    console.log(secureToken, 'mmmmmm')
    if (secureToken != '@error' && secureToken != '' && secureToken !== null) {
        const form = new FormData();
        form.append('start', prevStart);
        userId !== null && form.append('userId', '' + userId)
        axios.post(serverURL + '/profile/products_sm', form,
            {
                headers: {
                    Authorization: 'token ' + secureToken,
                    'Content-Type': 'multipart/form-data',
                    // 'RNFB-Response': 'utf8'
                }
            }).then(res => {
                let r = res.data;
                if (r.code == 100) {
                    let count = 0;
                    for (let d in r.data) {
                        count++;
                        data.push(r.data[d]);
                    }
                    //10 is total number of records per fetch if its less than 10 dont send request to server again because data is finished
                    if (count < 10) {
                        setloadMore(false);
                    }
                    setData(data);
                    // console.log(data)
                    setprevStart(prevStart + 10);
                    setIsLoading(false);
                } else {
                    notify(r.message)
                    // move('/PageNotFound')
                }

            })
            .catch(async error => {
                // await AsyncStorage.setItem('secure_token', '')
                console.log(error)
                notify('Invalid login details, please login again')

            });
    } else {
        notify('please login first');
    }
}


export const fetchManage = async (
    move, setdata, setisLoading, setsubscriptionDetails
) => {
    setisLoading(true);

    let secureToken = await AsyncStorage.getItem('secure_token');
    console.log(secureToken, 'mmmmmm')
    if (secureToken != '@error' && secureToken != '' && secureToken !== null) {
        const form = new FormData();


        axios.post(serverURL + '/profile/fetch_manage', form,
            {
                headers: {
                    Authorization: 'token ' + secureToken,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                let r = res.data;
                if (r.code == 100) {
                    setdata(r.data);
                    setisLoading(false);
                    setsubscriptionDetails(r.data.subscription_details)
                } else {
                    notify(r.message)
                    // move('/PageNotFound')
                }

            })
            .catch(async error => {
                // await AsyncStorage.setItem('secure_token', '')
                console.log(error)
                notify('Invalid login details, please login again')

            });
    } else {
        notify('please login first');
    }
}

export const getHomePage = async (setdata, setisLoading, link, notify, move, secureToken2) => {
    setisLoading(true);

    let secureToken = await AsyncStorage.getItem('secure_token');

    try {
        const form = new FormData();
        if (defaultLink == '') {
            form.append('link', link);
        } else {
            form.append('link', defaultLink);
        }

        axios.post(serverURL + '/profile/dukan_details', form,
            {
                headers: secureToken != '@error' && secureToken != '' && secureToken !== null ? {
                    Authorization: 'token ' + secureToken,
                    'Content-Type': 'multipart/form-data',
                } : {
                    // Authorization: 'token ' + secureToken,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                let r = res.data;
                if (r.code == 100) {
                    setdata(r.data)
                } else {

                    if (r.detail === 'Invalid token.') {
                        // invalidTokenError(props);
                        notify('please login again')
                    } else {
                        notify(r.message)
                        console.log(link, 'llllllllllllllll', typeof link)
                        move('/PageNotFound')
                    }
                }
            })
            .catch(async error => {
                await AsyncStorage.setItem('secure_token', '')
                getHomePage(setdata, setisLoading, link, notify, move, '')
            });


    } catch (error) {
        console.error('eeeeeeeeeee', error);
    } finally {
        setisLoading(false)
    }
}

export const getDukanProductsForHome = async (
    setdata, data, setisLoading, prevStart, setprevStart, setloadMore, dukanId = 'null', notify, move, categoryId,
    searchText, dukanLink = 'null'
) => {
    setisLoading(true);
    try {
        const form = new FormData();
        form.append('dukanLink', '' + dukanId);
        form.append('start', prevStart)
        form.append('categryId', categoryId)
        form.append('searchText', searchText)
        if (defaultLink == '') {
            form.append('dukanLink', dukanLink)
        } else {
            form.append('dukanLink', defaultLink);
        }

        axios.post(serverURL + '/profile/products_sm', form,
            {
                headers: {
                    // Authorization: 'token ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                let r = res.data;
                if (r.code == 100) {
                    let count = 0;
                    for (let d in r.data) {
                        count++;
                        data.push(r.data[d]);
                    }
                    //10 is total number of records per fetch if its less than 10 dont send request to server again because data is finished
                    if (count < 10) {
                        setloadMore(false);
                    }
                    setdata(data);
                    setprevStart(prevStart + 10);
                } else {
                    notify(r.message)
                    // console.log(link, 'products', typeof link)
                    // move('/PageNotFound')
                }
                setisLoading(false);
            })
            .catch(error => {
                console.log(error);
            });
    } catch (error) {
        console.error(error);
    }
}

export const generateOTP = async (setdata, setisLoading, contactNo, setError, setotpVerification, setvisible) => {
    setisLoading(true);

    try {
        const form = new FormData();
        form.append('mobile', contactNo);
        axios.post(serverURL + '/users/api/generate_otp', form,
            {
                headers: {
                    // Authorization: 'token ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                let r = res.data;
                if (r.code == 100) {
                    setotpVerification(true);
                    setvisible(false)
                } else {
                    setError(r.message);
                }
            })
            .catch(error => {
                console.log(error);
            });


    } catch (error) {
        console.error(error);
    } finally {
        setisLoading(false)
    }
}

export const verifyOTPWithFirebase = async (setisLoading, contactNo, code, setError, setotpVerification, sessionInfo, setsettingModal = '', navigate = '') => {
    setisLoading(true);
    try {
        console.log('mmmmmmmmmm')
        const form = new FormData();
        form.append('mobile', contactNo);
        form.append('OTP', code)
        form.append('web', '1')
        form.append('sessionInfo', sessionInfo)

        axios.post(serverURL + '/users/verify_otp_with_firebase/', form,
            {
                headers: {
                    // Authorization: 'token ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                console.log('mmmmmmmmmm2222222', res.data)
                let r = res.data;
                if (r.code == 100) {
                    storeData(r.token, 'secure_token')
                    setotpVerification(false);
                    if (setsettingModal != '') {
                        setsettingModal(true)
                    }
                    if (r.new_user == 1) {
                        if (navigate != '') {
                            navigate('/store-initial-settings')
                        }
                    } else {
                        notify('You are loged in')
                        window.location.href = window.location.href
                    }

                } else {
                    setError(r.message);
                    notify(r.message)
                }

            })
            .catch(error => {
                console.log('mmmmmmmmmm3333')
                console.log(error);
            });
    } catch (error) {
        console.log('mmmmmmmmmm44444')
        console.error(error);
    } finally {
        setisLoading(false)
    }
}

export const verifyOTP = async (setisLoading, contactNo, code, setError, setotpVerification, navigate = '') => {
    setisLoading(true);

    try {
        console.log('mmmmmmmmmm')
        const form = new FormData();
        form.append('mobile', contactNo);
        form.append('otp', code)
        axios.post(serverURL + '/users/api/verify', form,
            {
                headers: {
                    // Authorization: 'token ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                console.log('mmmmmmmmmm2222222', res.data)
                let r = res.data;
                if (r.code == 100) {
                    storeData(r.token, 'secure_token')
                    setotpVerification(false);
                    notify('You are loged in')
                    if (r.new_user == 1) {
                        if (navigate != '') {
                            navigate('/store-initial-settings')
                        }
                    } else {
                        window.location.href = window.location.href
                    }
                } else {
                    setError(r.message);
                    notify(r.message)
                }
            })
            .catch(error => {
                console.log('mmmmmmmmmm3333')
                console.log(error);
            });
    } catch (error) {
        console.log('mmmmmmmmmm44444')
        console.error(error);
    } finally {
        setisLoading(false)
    }
}

export const getProductDetails = async (id, setdata, setisLoading, move, setvariations) => {
    setisLoading(true);

    try {
        const form = new FormData();
        axios.post(serverURL + '/products/product_detail/' + id, form,
            {
                headers: {
                    // Authorization: 'token ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                let r = res.data;
                if (r.code == 100) {
                    setdata(r.data)
                    setvariations(r.variation_data)
                } else {
                    notify(r.message)
                    move('/PageNotFound')
                }
                setisLoading(false);
            })
            .catch(error => {
                console.log(error);
            });


    } catch (error) {
        console.error(error);
    } finally {
        // setisLoading(false)
    }
}

export const getPrimaryColor = async (link, move, setprimaryColour) => {
    try {
        const form = new FormData();
        form.append('link', link);
        if (defaultLink == '') {
            form.append('link', link);
        } else {
            form.append('link', defaultLink);
        }
        axios.post(serverURL + '/profile/get_primary_color', form,
            {
                headers: {
                    // Authorization: 'token ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                let r = res.data;
                if (r.code == 100) {
                    setprimaryColour(r.primary_color)
                } else {
                    notify(r.message)
                    move('/PageNotFound')
                }
            })
            .catch(error => {
                console.log(error);
            });
    } catch (error) {
        console.error(error);
    } finally {
        // setisLoading(false)
    }
}

export const getTemplateDetails = async (
    link, move, settemplateId, setprimaryColour, setfacebook, setinstagram, setyoutube, setdukanName, setsecondaryColor,
    setdukanDescription, setdukanContactNumber) => {

    try {
        const form = new FormData();

        if (defaultLink == '') {
            form.append('link', link);
        } else {
            form.append('link', defaultLink);
        }
        if (domainName !== '') {
            form.append('domainName', domainName);
        }
        axios.post(serverURL + '/profile/get_template', form,
            {
                headers: {
                    // Authorization: 'token ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(async res => {
                let r = res.data;
                if (r.code == 100) {
                    if (demo) {
                        let tId = await AsyncStorage.getItem('templateId');
                        if (tId != '@error' && tId != '' && tId !== null) {
                            settemplateId(tId)
                        } else {
                            await AsyncStorage.setItem('templateId', 3)
                            settemplateId(3)
                        }
                    } else {
                        settemplateId(r.template_id)
                    }
                    setprimaryColour(r.primary_color)
                    setsecondaryColor(r.secondary_color)
                    setfacebook(r.facebook)
                    setinstagram(r.instagram)
                    setyoutube(r.youtube)
                    setdukanName(r.dukan_name)
                    setdukanDescription(r.description)
                    setdukanContactNumber(r.dukan_contact_number)

                } else {
                    notify(r.message)
                    move('/PageNotFound')
                }
            })
            .catch(error => {
                console.log(error);
            });
    } catch (error) {
        console.error(error);
    } finally {
        // setisLoading(false)
    }
}

export const getDukanCategories = async (link, setdata, setisLoading, move) => {
    setisLoading(true);

    try {
        const form = new FormData();
        if (defaultLink == '') {
            form.append('link', link);
        } else {
            form.append('link', defaultLink);
        }
        axios.post(serverURL + '/categories/other_dukan_categories/', form,
            {
                headers: {
                    // Authorization: 'token ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                let r = res.data;
                if (r.code == 100) {
                    setdata(r.data)
                } else {
                    notify(r.message)
                    console.log(link, 'categories', typeof link)
                    move('/PageNotFound')
                }

            })
            .catch(error => {
                console.log(error);
            });


    } catch (error) {
        console.error(error);
    } finally {
        setisLoading(false)
    }
}

export const getDukanCatalogs = async (link, setdata, setisLoading, move) => {
    setisLoading(true);

    try {
        const form = new FormData();
        if (defaultLink == '') {
            form.append('link', link);
        } else {
            form.append('link', defaultLink);
        }
        axios.post(serverURL + '/categories/dukan_catalogs/', form,
            {
                headers: {
                    // Authorization: 'token ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                let r = res.data;
                if (r.code == 100) {
                    setdata(r.data)
                } else {
                    notify(r.message)
                    console.log(link, 'categories', typeof link)
                    move('/PageNotFound')
                }

            })
            .catch(error => {
                console.log(error);
            });
    } catch (error) {
        console.error(error);
    } finally {
        setisLoading(false)
    }
}

export const getCatalogDetails = async (
    setisLoading, move, setdata, data, prevStart,
    setprevStart, setloadMore, setcatalogData, id,
    setidsOfCatalogProducts
) => {
    setisLoading(true);
    let headers;
    let secureToken = await AsyncStorage.getItem('secure_token');
    if (secureToken != '@error' && secureToken != '' && secureToken !== null) {
        headers = {
            Authorization: 'token ' + secureToken,
            'Content-Type': 'multipart/form-data',
        }
    } else {
        headers = {
            // Authorization: 'token ' + token,
            'Content-Type': 'multipart/form-data',
        }
    }
    try {

        const form = new FormData();
        form.append('id', id);
        axios.post(serverURL + '/categories/catalog_details/', form,
            {
                headers: headers
            }).then(res => {
                let r = res.data;
                if (r.code == 100) {
                    let count = 0;
                    for (let d in r.data) {
                        count++;
                        data.push(r.data[d]);
                    }
                    //10 is total number of records per fetch if its less than 10 dont send request to server again because data is finished
                    if (count < 10) {
                        setloadMore(false);
                    }
                    setdata(data);
                    setcatalogData(r.catalog_details)
                    setprevStart(prevStart + 10);
                    setidsOfCatalogProducts(r.ids_of_catalog_products)
                } else {
                    notify(r.message)
                    move('/PageNotFound')
                }

            })
            .catch(error => {
                console.log(error);
            });
    } catch (error) {
        console.error(error);
    } finally {
        setisLoading(false)
    }
}

export const placeOrder = async (fullName, contactNumber, province, city, area, address,
    products, paymentMethod, setdata, setisLoading, move,
    setmessages
) => {
    setisLoading(true);

    let secureToken = await AsyncStorage.getItem('secure_token');
    console.log(secureToken, 'mmmmmm')
    if (secureToken != '@error' && secureToken != '' && secureToken !== null) {
        const form = new FormData();
        form.append('fullName', fullName);
        form.append('contactNumber', contactNumber);
        form.append('province', province);
        form.append('city', city);
        form.append('area', area);
        form.append('address', address);
        form.append('products', products);
        form.append('paymentMethod', paymentMethod);

        axios.post(serverURL + '/offers/place_order/', form,
            {
                headers: {
                    Authorization: 'token ' + secureToken,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                let r = res.data;
                if (r.code == 100) {
                    setdata(r.data)
                    setmessages(r.messages)
                    let ordersSuccessfull = JSON.parse(r.orders_succesfull)
                    if (ordersSuccessfull.length > 0) {
                        ordersSuccessfull.forEach((id) => {
                            removeFromBag(id, true)
                        })
                    }

                } else {
                    notify(r.message)
                    // move('/PageNotFound')
                }

            })
            .catch(async error => {
                // await AsyncStorage.setItem('secure_token', '')
                console.log(error)
                notify('Invalid login details, please login again')

            });
    } else {
        notify('please login first');
    }
}

export const placedOrders = async (setdata, setisLoading, move,
    setmessages
) => {
    setisLoading(true);

    let secureToken = await AsyncStorage.getItem('secure_token');
    if (secureToken != '@error' && secureToken != '' && secureToken !== null) {
        const form = new FormData();

        axios.post(serverURL + '/offers/placed_orders/', form,
            {
                headers: {
                    Authorization: 'token ' + secureToken,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                let r = res.data;
                if (r.code == 100) {
                    setdata(r.data)
                    setmessages(r.messages)

                } else {
                    notify(r.message)
                    // move('/PageNotFound')
                }
                setisLoading(false);
            })
            .catch(async error => {
                // await AsyncStorage.setItem('secure_token', '')
                console.log(error)
                notify('Invalid login details, please login again')

            });
    } else {
        notify('please login first');
    }
}

export const orderDetails = async (setdata, setisLoading, move,
    orderId
) => {
    setisLoading(true);

    let secureToken = await AsyncStorage.getItem('secure_token');
    if (secureToken != '@error' && secureToken != '' && secureToken !== null) {
        const form = new FormData();
        form.append('orderId', orderId)

        axios.post(serverURL + '/offers/order_details/', form,
            {
                headers: {
                    Authorization: 'token ' + secureToken,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                console.log(res, 'rrrrrrrrrrrrrrrrr')
                let r = res.data;
                if (r.code == 100) {
                    setdata(r.data)

                } else {
                    notify(r.message)
                    // move('/PageNotFound')
                }
                setisLoading(false);
            })
            .catch(async error => {
                // await AsyncStorage.setItem('secure_token', '')
                console.log(error)
                notify('Invalid login details, please login again')

            });
    } else {
        notify('please login first');
    }
}



export const bagLoad = async (
    setdata, setisLoading, move, bag = '', link = ''
) => {
    setisLoading(true);
    if (bag == '') {
        bag = await AsyncStorage.getItem('bag')
    }
    console.log('baggggggggggggggggggggg', bag)
    try {
        console.log('baggggggggggggggggggggg2', bag)
        const form = new FormData();
        form.append('bag', bag)

        if (defaultLink == '') {
            if (link != '') {
                form.append('link', link)
            }
        } else {
            form.append('link', defaultLink);
        }
        axios.post(serverURL + '/products/bag_load/', form,
            {
                headers: {
                    // Authorization: 'token ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                let r = res.data;
                console.log(r, 'rrrrrrrrrrr')
                if (r.code == 100) {
                    setdata(r.data)
                } else {
                    notify(r.message)
                    move('/PageNotFound')
                }
                // 
            })
            .catch(error => {
                console.log('baggggggggggggggggggggg3', bag)
                console.log(error);
            });


    } catch (error) {
        console.log('baggggggggggggggggggggg4', bag)
        console.error(error);
    } finally {
        console.log('baggggggggggggggggggggg5', bag)
        setisLoading(false)
    }
}



export const productsFromArray = async (
    setdata, setisLoading, move, bagArray
) => {
    setisLoading(true);
    try {
        const form = new FormData();
        form.append('productIdArray', bagArray.map(a => a.id))
        axios.post(serverURL + '/products/products_from_array_sm/', form,
            {
                headers: {
                    // Authorization: 'token ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                let r = res.data;
                if (r.code == 100) {
                    setdata(r.data)
                } else {
                    notify(r.message)
                    move('/PageNotFound')
                }
                // 
            })
            .catch(error => {
                console.log(error);
            });


    } catch (error) {
        console.error(error);
    } finally {
        setisLoading(false)
    }
}


export const subscribeDukan = async (id, move) => {

    let secureToken = await AsyncStorage.getItem('secure_token');
    if (secureToken != '@error' && secureToken != '' && secureToken !== null) {

        const form = new FormData();
        form.append('id', id)
        axios.post(serverURL + '/follow/follow/', form,
            {
                headers: {
                    Authorization: 'token ' + secureToken,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                let r = res.data;
                if (r.code == 100) {

                } else {
                    notify(r.message)
                    move('/PageNotFound')
                }

            })
            .catch(error => {
                console.log(error);
            });
    } else {
        notify('please login first')
    }
}

export const unsubscribeDukan = async (id, move) => {

    let secureToken = await AsyncStorage.getItem('secure_token');
    if (secureToken != '@error' && secureToken != '' && secureToken !== null) {

        const form = new FormData();
        form.append('id', id)
        axios.post(serverURL + '/follow/unfollow/', form,
            {
                headers: {
                    Authorization: 'token ' + secureToken,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                let r = res.data;
                if (r.code == 100) {

                } else {
                    notify(r.message)
                    move('/PageNotFound')
                }

            })
            .catch(error => {
                console.log(error);
            });
    } else {
        notify('please login first')
    }
}




export const updateOrderStatus = async (setmessage, setisLoading, move,
    orderId, orderStatus
) => {
    setisLoading(true);

    let secureToken = await AsyncStorage.getItem('secure_token');
    if (secureToken != '@error' && secureToken != '' && secureToken !== null) {
        const form = new FormData();
        form.append('offerId', orderId)
        form.append('offerStatus', orderStatus)

        axios.post(serverURL + '/offers/update_offer_status/', form,
            {
                headers: {
                    Authorization: 'token ' + secureToken,
                    'Content-Type': 'multipart/form-data',
                    // 'RNFB-Response': 'utf8'
                }
            }).then(res => {
                let r = res.data;
                if (r.code == 100) {
                    setmessage(r.message)
                } else {
                    if (r.detail === 'Invalid token.') {
                        move('/dashboard-login')
                    } else {
                        move('/PageNotFound')
                    }
                }
                setisLoading(false);
                console.log(r)
            })
            .catch(async error => {
                // await AsyncStorage.setItem('secure_token', '')
                console.log(error)
                notify('Something went wrong, please check your internet connection....');
            });
    } else {
        notify('Something went wrong, please check your internet connection..');
    }
}

export const deleteCatalog = async (
    setisLoading, move, id,
) => {
    setisLoading(true);

    let secureToken = await AsyncStorage.getItem('secure_token');
    if (secureToken != '@error' && secureToken != '' && secureToken !== null) {
        const form = new FormData();
        form.append('id', id)
        axios.post(serverURL + '/categories/delete_catalog/', form,
            {
                headers: {
                    Authorization: 'token ' + secureToken,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                let r = res.data;
                if (r.code != 100) {
                    if (r.detail === 'Invalid token.') {
                        move('/dashboard-login')
                    } else {
                        move('/PageNotFound')
                    }
                }
                setisLoading(false);
            })
            .catch(async error => {
                // await AsyncStorage.setItem('secure_token', '')
                console.log(error)
                notify('Something went wrong, please check your internet connection....');
            });
    } else {
        notify('Something went wrong, please check your internet connection..');
    }
}

export async function dukanCatalogs(
    setisLoading, move, setdata, data
) {
    setisLoading(true);
    let secureToken = await AsyncStorage.getItem('secure_token');
    if (secureToken != '@error' && secureToken != '' && secureToken !== null) {
        const form = new FormData();
        axios.post(serverURL + '/categories/dukan_catalogs/', form,
            {
                headers: {
                    Authorization: 'token ' + secureToken,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                let r = res.data;
                if (r.code != 100) {
                    if (r.detail === 'Invalid token.') {
                        move('/dashboard-login')
                    } else {
                        move('/PageNotFound')
                    }
                } else {
                    setdata(r.data);
                }
                setisLoading(false);
            })
            .catch(async error => {
                // await AsyncStorage.setItem('secure_token', '')
                console.log(error)
                notify('Something went wrong, please check your internet connection....');
            });
    } else {
        notify('Something went wrong, please check your internet connection..');
    }
}


export async function createCatalog(
    setIsLoading, name, move, setcreateCatalogModel, image,
) {
    setIsLoading(true);
    let secureToken = await AsyncStorage.getItem('secure_token');
    const form = new FormData();
    form.append('name', '' + name)

    if (image !== '') {
        form.append('image', image)
        // {
        //     name: 'image',
        //     filename: image.path.substring(image.path.lastIndexOf('/') + 1),
        //     type: image.mime,
        //     data: RNFetchBlob.wrap(image.path),
        // }
    }

    axios.post(serverURL + '/categories/create_catalog/', form,
        {
            headers: {
                Authorization: 'token ' + secureToken,
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            let r = res.data;
            if (r.code != 100) {
                if (r.detail === 'Invalid token.') {
                    move('/dashboard-login')
                } else {
                    notify(r.message)
                    // move('/PageNotFound')
                }
            } else {
                setcreateCatalogModel(false);
            }
            setIsLoading(false);
        })
        .catch(async error => {
            // await AsyncStorage.setItem('secure_token', '')
            console.log(error)
            notify('Something went wrong, please check your internet connection....');
        });
}


export async function updateProductsInCatalog(
    setIsLoading, move, productIds, catalogId, setmodal
) {
    console.log(productIds)
    setIsLoading(true);
    let secureToken = await AsyncStorage.getItem('secure_token');
    const form = new FormData();
    form.append('productsIds', '' + JSON.stringify(productIds))
    form.append('catalogId', catalogId)
    axios.post(serverURL + '/categories/update_products_in_catalog/', form,
        {
            headers: {
                Authorization: 'token ' + secureToken,
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            let r = res.data;
            if (r.code == 100) {
                setmodal(false)
            } else {
                if (r.detail === 'Invalid token.') {
                    move('/dashboard-login')
                } else {
                    move('/PageNotFound')
                }
            }
            setIsLoading(false);
        })
        .catch(error => {
            setIsLoading(false);
            notify('Something went wrong, please check your internet connection');
        });
}

export async function setStoreDesign(move, setisLoading, designId, setappliedDesign) {
    setisLoading(true)
    let secureToken = await AsyncStorage.getItem('secure_token');
    const form = new FormData();
    form.append('designId', designId)
    axios.post(serverURL + '/profile/set_store_design', form,
        {
            headers: {
                Authorization: 'token ' + secureToken,
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            let r = res.data;
            if (r.code === 100) {
                setappliedDesign(designId)
                setisLoading(false);
                notify('Website design applied')
            } else {
                setisLoading(false);
                if (r.detail === 'Invalid token.') {
                    move('/dashboard-login')
                } else {
                    notify(r.message)
                }
            }
        })
        .catch(error => {
            notify('Something went wrong, please check your internet connection');
        });
}


export async function getStoreDetailsForCustomization(move, setisLoading, setdata) {
    setisLoading(true)
    let secureToken = await AsyncStorage.getItem('secure_token');
    const form = new FormData();
    axios.post(serverURL + '/profile/get_store_details_for_customization', form,
        {
            headers: {
                Authorization: 'token ' + secureToken,
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            let r = res.data;
            if (r.code === 100) {
                setdata(r.data);
                setisLoading(false);
            } else {
                setisLoading(false);
                if (r.detail === 'Invalid token.') {
                    move('/dashboard-login')
                } else {
                    move('/PageNotFound')
                }
            }
        })
        .catch(error => {
            console.log(error)
            notify('Something went wrong, please check your internet connection');
        });
}


export async function saveStoreDetailsForCustomization(
    move, setisLoading, banner1, banner2, banner3, aboutUs,
    primaryColor, deleteBanner, secondaryColor = '',
    facebook = '', instagram = '', youtube = '',
) {
    setisLoading(true)
    let secureToken = await AsyncStorage.getItem('secure_token');
    const form = new FormData();
    let bannerUploading = []


    form.append('aboutUs', '' + aboutUs)
    form.append('primaryColor', '' + primaryColor)
    form.append('secondaryColor', '' + secondaryColor)
    form.append('facebook', '' + facebook)
    form.append('instagram', '' + instagram)
    form.append('youtube', '' + youtube)

    if (deleteBanner !== '') {
        form.append('deleteBanners', JSON.stringify(deleteBanner))
    } else {
        form.append('deleteBanners', '')
    }

    if (banner1 != '' && banner1.type == 'uploading') {
        form.append('banner1', banner1.image)
        bannerUploading.push('banner1')
    }
    if (banner2 != '' && banner2.type == 'uploading') {
        form.append('banner2', banner2.image)
        bannerUploading.push('banner2')
    }
    if (banner3 != '' && banner3.type == 'uploading') {
        form.append('banner3', banner3.image)
        bannerUploading.push('banner3')
    }

    form.append('bannersUploading', JSON.stringify(bannerUploading))


    axios.post(serverURL + '/profile/save_store_details_for_customization', form,
        {
            headers: {
                Authorization: 'token ' + secureToken,
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            let r = res.data;
            if (r.code === 100) {
                setisLoading(false);
                notify('Saved')
            } else {
                setisLoading(false);
                if (r.detail === 'Invalid token.') {
                    move('/dashboard-login')
                } else {
                    notify(r.message);
                }
            }
        })
        .catch(error => {
            console.log(error)
            notify('Something went wrong, please check your internet connection');
        });
}


export async function removeProduct(setIsLoading, productId, move) {
    setIsLoading(true);
    let secureToken = await AsyncStorage.getItem('secure_token');
    const form = new FormData();
    form.append('productId', '' + productId)
    axios.post(serverURL + '/products/remove_products/', form,
        {
            headers: {
                Authorization: 'token ' + secureToken,
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            let r = res.data;
            if (r.code === 100) {
                notify('Product deleted...');
                setIsLoading(false);
            } else {
                if (r.detail === 'Invalid token.') {
                    move('/dashboard-login')
                    setIsLoading(false)
                } else {
                    notify(r.message);
                    setIsLoading(false)
                }
            }
        })
        .catch((error) => {
            notify('Something went wrong, please check your internet connection');
        });
}


export async function SaveInitialSettings(
    setIsLoading, selectedCategories, smsNotification,
    isSeller, isBuyer, isReseller, setData,
    setError, move, city, dukanCategory, dukanName, address, physicalBazaar,
    link, cover
) {
    console.log('usamammmmmmmmm')
    setIsLoading(true);
    let secureToken = await AsyncStorage.getItem('secure_token');
    const form = new FormData();

    let sc = []
    selectedCategories.forEach(el => {
        sc.push(el.id);
    });

    form.append('SMSNotifications', '' + smsNotification)
    form.append('isBuyer', '' + isBuyer)
    form.append('isReseller', '' + isReseller)
    form.append('isSeller', '' + isSeller)
    form.append('city', '' + city)
    form.append('dukanCategory', '' + dukanCategory)
    form.append('dukanName', '' + dukanName)
    form.append('address', '' + address)
    form.append('physicalBazaar', '' + physicalBazaar)
    form.append('selectedCategories', '' + sc)
    form.append('link', '' + link)

    // if (cover != '' && cover) {
    //   data.push({
    //     name: 'cover',
    //     filename: cover.path.substring(
    //       cover.path.lastIndexOf('/') + 1,
    //     ),
    //     type: cover.mime,
    //     data: RNFetchBlob.wrap(cover.path),
    //   })
    // }


    axios.post(serverURL + '/users/initial_settings/', form,
        {
            headers: {
                Authorization: 'token ' + secureToken,
                'Content-Type': 'multipart/form-data',
            }
        }).then(async res => {
            let r = res.data;
            setIsLoading(false);
            if (r.code === 100) {
                setData(r);
                await AsyncStorage.setItem('userType', 'seller');
                notify('Store created successfully.')
                move('/dashboard')
            } else if (r.code === 404) {
                notify(r.message);
            } else {
                if (r.detail === 'Invalid token.') {
                    move('/dashboard-login')
                } else {
                    notify(r.message);
                }
            }
        })
        .catch(error => {
            console.log(error)
            setIsLoading(false);
            notify('Something went wrong, please check your internet connection');
        });
}


export async function checkDukanLinkAvailability(setmessage, setisLoading, move, link) {
    setisLoading(true);
    const form = new FormData();
    form.append('link', '' + link)
    axios.post(serverURL + '/profile/check_link_availability', form,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(async res => {
            let r = res.data;
            // {"address": "", "ads_count": 0, "city": "", "description": "",
            //  "dp": "/media/profile_images/profile_2_g20Rg7y.jpg", "followers_count": 0, "following_count": 0, "user_name": 4}
            setisLoading(false);
            if (r.code == 404 || r.code === 100) {
                console.log(r, link)
                setmessage(r.message)
            } else {
                if (r.detail === 'Invalid token.') {
                    move('/dashboard-login')
                } else {
                    notify(r.message);
                }
            }
        })
        .catch(error => {
            setisLoading(false);
            notify('Something went wrong, please check your internet connection');
        });
}



export async function profileFetch(setdata, setisLoading, move, user_id = null,) {
    setisLoading(true);
    let secureToken = await AsyncStorage.getItem('secure_token');
    const form = new FormData();
    form.append('userId', '' + user_id)
    axios.post(serverURL + '/profile/profile', form,
        {
            headers: {
                Authorization: 'token ' + secureToken,
                'Content-Type': 'multipart/form-data',
            }
        }).then(async res => {
            let r = res.data;
            // {"address": "", "ads_count": 0, "city": "", "description": "",
            //  "dp": "/media/profile_images/profile_2_g20Rg7y.jpg", "followers_count": 0, "following_count": 0, "user_name": 4}
            if (r.code == 100) {
                console.log(r.data)
                setdata(r.data);
                setisLoading(false);
            } else {
                if (r.detail === 'Invalid token.') {
                    move('/dashboard-login')
                } else {
                    notify(r.message);
                }
            }
        })
        .catch(error => {
            setisLoading(false);
            notify('Something went wrong, please check your internet connection');
        });
}


// export async function subscribeDukan(id, navigation) {
//     const token = await SecureStore.getItemAsync('secure_token');
//     RNFetchBlob.fetch(
//         'POST',
//         serverURL + '/follow/follow/',
//         {
//             Authorization: 'token ' + token,
//             'Content-Type': 'multipart/form-data',
//         },
//         [{ name: 'id', data: '' + id }],
//     )
//         .then(response => response.json())
//         .then(r => {
//             if (r.code == 100) {
//                 // notify(r.message);
//                 // setsuccess(true)
//             } else {
//                 if (r.detail === 'Invalid token.') {
//                     invalidTokenError2(navigation);
//                 } else {

//                     notify(r.message);
//                 }
//                 // setsuccess(false)
//             }
//         })
//         .catch(error => {
//             notify('Something went wrong, please check your internet connection');
//         });
// }

// export async function productsFromArray(
//     setData, setIsLoading, productsArray
//     , navigation
// ) {
//     setIsLoading(true);
//     const token = await SecureStore.getItemAsync('secure_token');
//     const ba = await SecureStore.getItemAsync('bag')
//     let bagArray = JSON.parse(ba)

//     // setIsLoading(true);
//     RNFetchBlob.fetch('POST', serverURL + '/products/products_from_array_sm/',
//         {
//             Authorization: 'token ' + token,
//             'Content-Type': 'multipart/form-data',
//         },
//         [
//             { name: 'productIdArray', data: '' + bagArray.map(a => a.id) },
//         ]
//     ).then((response) => {
//         return (response.json())
//     })
//         .then(async r => {
//             if (r.code === 100) {
//                 setData(r.data);
//                 setIsLoading(false);
//             } else {
//                 if (r.detail === 'Invalid token.') {
//                     invalidTokenError2(navigation);
//                 } else {
//                     notify(r.message);
//                     setIsLoading(false);
//                 }
//             }
//         })
//         .catch(err => {
//             setIsLoading(false);
//             notify('Something went wrong, please check your internet connection');
//         });
// }



//
// export async function productDetailFetch(id, setdata, setisLoading, setimages, setrelatedAdsData) {
//     setisLoading(true);
//     const token = await SecureStore.getItemAsync('secure_token');
//     RNFetchBlob.fetch('POST', serverURL + '/products/product_detail/' + id, {
//         Authorization: "token " + token,
//         'Content-Type': 'multipart/form-data',
//     },
//         []
//     ).then((response) => {
//         // console.log(response)
//         return response.json()

//     })
//         .then((r) => {
//             //
//             if (r.code === 100) {
//                 setdata(r.data);
//                 setrelatedAdsData(r.related_ads_data);
//                 setimages(r.data.imgs);
//                 setisLoading(false);
//             } else {
//                 if (r.detail === 'Invalid token.') {
//                     invalidTokenError2(navigation);
//                 } else {
//                     notify(r.message);
//                     setisLoading(false);
//                 }
//             }
//         })
//         .catch((err) => {
//             // console.log(err)
//             setisLoading(false);
//             notify('Something went wrong, please check your internet connection');
//         });
// }

// export async function verifyOTP(
//     setIsLoading,
//     contactNo,
//     code,
//     setData,
//     setError,
//     props
//   ) {
//     setIsLoading(true);
//     let fcmToken = await messaging().getToken();
//     return RNFetchBlob.fetch(
//       'POST',
//       serverURL + '/users/api/verify',
//       {
//         'Content-Type': 'multipart/form-data',
//       },
//       [
//         { name: 'mobile', data: contactNo },
//         { name: 'otp', data: code },
//         { name: 'firebaseToken', data: fcmToken },
//       ],
//     )
//       .then(response => response.json())
//       .then(async r => {
//         setIsLoading(false);
//         if (r.code === 100) {
//           setData(r);
//           await SecureStore.setItemAsync('secure_token', r.token);
//           ;
//           if (r.new_user == 1) {
//             props.navigation.reset({
//               index: 0,
//               routes: [{ name: 'InitialSettings' }],
//             });
//           } else {
//             notify('you are loged in');
//             props.navigation.reset({
//               index: 0,
//               routes: [{ name: 't', screen: 'main', }],
//             });
//           }


//         } else {

//           setError(r.message);
//         }
//       })
//       .catch(error => {
//         setIsLoading(false);
//         notify('Something went wrong, please check your internet connection');
//       });
//   }





// export function generateOTP(
//     setIsLoading,
//     contactNo,
//     setData,
//     setError,
//     props
// ) {
//     setIsLoading(true);

//     RNFetchBlob.fetch(
//         'POST',
//         serverURL + '/users/api/generate_otp',
//         {
//             'Content-Type': 'multipart/form-data',
//         },
//         [{ name: 'mobile', data: contactNo }],
//     )
//         .then(response => {
//             // console.log(response)
//             return response.json();
//         })
//         .then(r => {
//             setIsLoading(false);
//             if (r.code === 100) {
//                 props.navigation.navigate('OTPVerification', { number: contactNo })
//                 setData(r);
//             } else {
//                 setError(r.message);
//             }
//         })
//         .catch(error => {
//             setIsLoading(false);
//             notify('Something went wrong, please check your internet connection');
//         });
// }


// export async function profileProductsFetchSM(
//     setData, data, setIsLoading, prevStart, setprevStart, setloadMore, navigation
//     , userId = null
// ) {
//     setIsLoading(true);
//     const token = await SecureStore.getItemAsync('secure_token');
//     RNFetchBlob.fetch('POST', serverURL + '/profile/products_sm', {
//         Authorization: "token " + token,
//         'Content-Type': 'multipart/form-data',
//     },
//         [
//             { name: 'start', data: '' + prevStart },
//             userId !== null ? { name: 'userId', data: '' + userId } : {}
//         ],
//     ).then((response) => response.json())
//         .then(r => {
//             if (r.code === 100) {

//                 let count = 0;
//                 for (let d in r.data) {
//                     count++;
//                     data.push(r.data[d]);
//                 }
//                 //10 is total number of records per fetch if its less than 10 dont send request to server again because data is finished
//                 if (count < 10) {
//                     setloadMore(false);
//                 }
//                 setData(data);
//                 setprevStart(prevStart + 10);
//                 setIsLoading(false);
//             } else {
//                 if (r.detail === 'Invalid token.') {
//                     invalidTokenError2(navigation);
//                 } else {
//                     notify(r.message);
//                     setIsLoading(false);
//                 }
//             }
//         }).catch(error => {
//             setIsLoading(false);
//             notify('Something went wrong, please check your internet connection');
//         });
// }


// export async function profileFetch(setdata, setisLoading, navigation, user_id = null,) {
//     setisLoading(true);
//     const token = await SecureStore.getItemAsync('secure_token');
//     RNFetchBlob.fetch(
//         'POST',
//         serverURL + '/profile/profile',
//         {
//             Authorization: 'token ' + token,
//             'Content-Type': 'multipart/form-data',
//         },
//         [{ name: 'userId', data: '' + user_id }],
//     )
//         .then(response => response.json())
//         .then(r => {
//             // {"address": "", "ads_count": 0, "city": "", "description": "",
//             //  "dp": "/media/profile_images/profile_2_g20Rg7y.jpg", "followers_count": 0, "following_count": 0, "user_name": 4}
//             if (r.code === 100) {
//                 setdata(r.data);
//                 setisLoading(false);
//             } else {
//                 if (r.detail === 'Invalid token.') {
//                     invalidTokenError2(navigation);
//                 } else {
//                     notify(r.message);
//                 }
//             }
//         })
//         .catch(error => {
//             setisLoading(false);
//             notify('Something went wrong, please check your internet connection');
//         });
// }