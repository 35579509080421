import * as React from 'react';
import { useState, useEffect } from 'react';
import { ActivityIndicator, Button, Dimensions, FlatList, Image, ImageBackground, Linking, RefreshControl, ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { borderColor, inactiveColor, primarybg, primaryColour, primaryText, secondarybg, secondaryColor, serverURL } from '../../utils/string';
import { PerfectInput, RoundButton } from '../../utils/utils';

import { getStoreDetailsForCustomization, saveStoreDetailsForCustomization } from '../../utils/api';
import PerfectModal from '../../utils/perfectModel';
import { SketchPicker } from 'react-color';
import { useNavigate } from "react-router-dom";
import Sidebar from '../sidebar';
import ApperanceSidebar from './sidebar';
import { shadow } from '../utils/main';
import { useMediaQuery } from 'react-responsive';
// import ImagePicker from 'react-native-image-crop-picker';

const radio = 1000 / 500;
const static_width = Dimensions.get('window').width / 1.2;


export function StoreDetailsSettings(props) {
    const move = useNavigate();
    const [aboutUs, setaboutUs] = useState('');
    const [saving, setsaving] = useState(false);
    const [data, setdata] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    useEffect(() => {
        getStoreDetailsForCustomization(move, setisLoading, setdata)
    }, [])


    return (
        <ApperanceSidebar active='storeDetails'>
            <View style={[{ marginHorizontal: isDesktopOrLaptop ? '5%' : '2%', paddingTop: 15, paddingHorizontal: isDesktopOrLaptop ? '9%' : '4%', paddingVertical: '2%', borderRadius: 20 }, shadow]}>
                <Text style={{ color: primaryColour, fontWeight: 'bold' }}>Write details about your store:</Text>
                <PerfectInput
                    placeholder='We sell this and that' multiline={true}
                    style={{ height: 300, textAlignVertical: 'top', color: 'gray' }}
                    defaultValue={data.about_us}
                    maxLength={1000}
                    // value={aboutUs}
                    setvalue={setaboutUs}
                />
                {saving && <ActivityIndicator color={secondarybg} size={'large'} />}
                <RoundButton text='Save' style={{ alignSelf: 'center', width: '40%' }}
                    onPress={() => { saveStoreDetailsForCustomization(move, setsaving, '', '', '', aboutUs, '', '', '', '', '', '') }}
                />

            </View>
        </ApperanceSidebar>
    );
}

