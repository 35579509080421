import { useEffect, useState } from "react";
import { FaList, FaShareAlt } from "react-icons/fa";
import { Text, TouchableOpacity, View, TextInput, Platform, ActivityIndicator, Keyboard, ImageBackground, Linking } from "react-native";
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from "react-router-dom";
import { fetchManage } from "../utils/api";
import { borderColor, primarybg, primaryColour, secondarybg, serverURL } from "../utils/string";
import { RoundButton } from "../utils/utils";
import Sidebar from "./sidebar";
import { shadow } from "./utils/main";
import './utils/style.css'

const Icon = () => (<></>);

export default function Dashboard(props) {
    const navigate = useNavigate();
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const [data, setdata] = useState({});
    const [isLoading, setisLoading] = useState(true);
    const [subscriptionDetails, setsubscriptionDetails] = useState('');
    const [showSidebar, setshowSidebar] = useState(false);

    useEffect(() => {
        fetchManage(navigate, setdata, setisLoading, setsubscriptionDetails)
    }, [])




    return (
        <>
            <Sidebar active={'home'} title={data.dukan}>


                {/* <ImageBackground
                    style={{
                        backgroundColor: 'black',
                        width: '100%',
                        flex: 1,
                        height: 80,
                        flexDirection: 'column',
                        // 
                        // paddingVertical: 30,
                        // paddingBottom: 80,
                        // paddingTop: StatusBar.currentHeight
                    }}
                    imageStyle={{
                        opacity: 0.3,
                    }}
                    resizeMode="cover"
                    source={
                        data.cover == 'null'
                            ? require('../assets/dukanDefault.jpg') :
                            { uri: serverURL + data.cover }
                    }>
                    <View style={{ paddingHorizontal: 15, }}>
                        <Text style={{ color: primarybg, fontSize: 16, fontWeight: 'bold' }}>Share and Earn</Text>
                        <Text style={{ color: primarybg, fontSize: 14 }}>Share your own Dukan's link on social media to attract more customers every day</Text>
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 4, }}>
                            <Text style={{ color: secondarybg, marginTop: 10, fontSize: 14, marginRight: 10, fontWeight: 'bold' }} selectable={true}
                                onPress={() => Linking.openURL('https://bazars.pk/' + data.link)}
                            >
                                https://bazars.pk/{data.link ? data.link : null}
                                {data == {} && <ActivityIndicator size="small" color={secondarybg} />}
                            </Text>
                        </View>
                        <View style={{ alignItems: 'center', justifyContent: 'space-between', width: '100%', flexDirection: 'row', paddingHorizontal: '20%' }}>
                            <TouchableOpacity
                                style={{
                                    flexDirection: 'row', backgroundColor: secondarybg,
                                    borderRadius: 10, paddingHorizontal: 10, paddingVertical: 2,
                                    justifyContent: 'center', alignItems: 'center', marginTop: 10
                                }}

                            // onPress={() => {
                            //     Share.isPackageInstalled('com.whatsapp')
                            //         .then((response) => {
                            //             if (response.isInstalled) {
                            //                 Share.shareSingle({
                            //                     title: data.link,
                            //                     message: 'Now you can order our items from our online dukan \n https://bazars.pk/' + data.link,
                            //                     social: Share.Social.WHATSAPP,  // country code + phone number
                            //                 })
                            //                     .then((res) => { console.log(res) })
                            //                     .catch((err) => { err && console.log(err) })
                            //             } else {
                            //                 showToast('Please install whatsapp first')
                            //             }
                            //             // { isInstalled: true/false, message: 'Package is Installed' }
                            //         })
                            //         .catch((error) => {
                            //             console.log(error);
                            //             // { error }
                            //         });

                            // }}
                            >
                                <Text style={{ textAlign: 'center', color: primaryColour, fontWeight: 'bold' }}>
                                    Share{'  '}
                                </Text>
                                <Icon
                                    name="whatsapp"
                                    type="font-awesome-5"
                                    color={primaryColour}
                                    size={29}
                                // style={{ marginTop: 3, marginLeft: 5 }}
                                />
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={{
                                    flexDirection: 'row', backgroundColor: secondarybg,
                                    borderRadius: 10, paddingHorizontal: 10, paddingVertical: 5,
                                    justifyContent: 'center', alignItems: 'center', marginTop: 10
                                }}
                            // onPress={() => shareWebiste()}
                            >
                                <Text style={{ textAlign: 'center', color: primaryColour, fontWeight: 'bold' }}>
                                    Share{'  '}
                                </Text>
                                <Icon
                                    name="share-alt"
                                    type="font-awesome-5"
                                    color={primaryColour}
                                    size={23}
                                // style={{ marginTop: 3, marginLeft: 5 }}
                                />
                            </TouchableOpacity>
                        </View>
                    </View>
                </ImageBackground> */}


                <View style={{
                    flex: 1, flexDirection: 'row', flexWrap: 'wrap',
                    paddingHorizontal: 6, justifyContent: 'space-evenly', alignItems: 'flex-start', marginBottom: 10
                }}>
                    <View
                        style={[{
                            width: isDesktopOrLaptop ? '20%' : '45%', backgroundColor: primarybg,
                            elevation: 3,
                            paddingVertical: 10, borderRadius: 10,
                            paddingLeft: 9,
                            alignSelf: 'center', justifyContent: 'center', marginVertical: 7,

                        }, shadow]}
                    >
                        <Text style={{ color: 'gray', fontSize: 12 }}>Dukan Views</Text>
                        <Text style={{ color: secondarybg, fontWeight: 'bold', fontSize: 21 }}>{data.dukan_views && data.dukan_views} </Text>
                    </View>
                    <View
                        style={[{
                            width: isDesktopOrLaptop ? '20%' : '45%', backgroundColor: primarybg,
                            elevation: 3,
                            paddingVertical: 10, borderRadius: 10,
                            paddingLeft: 9,
                            alignSelf: 'center', justifyContent: 'center', marginVertical: 7
                        }, shadow]}
                    >
                        <Text style={{ color: 'gray', fontSize: 12 }}>Products Views</Text>
                        <Text style={{ color: secondarybg, fontWeight: 'bold', fontSize: 21 }}>{data.product_views && data.product_views}</Text>
                    </View>
                    <View
                        style={[{
                            width: isDesktopOrLaptop ? '20%' : '45%', backgroundColor: primarybg,
                            elevation: 3,
                            paddingVertical: 10, borderRadius: 10,
                            paddingLeft: 9,
                            alignSelf: 'center', justifyContent: 'center', marginVertical: 7
                        }, shadow]}
                    >
                        <Text style={{ color: 'gray', fontSize: 12 }}>Total orders</Text>
                        <Text style={{ color: secondarybg, fontWeight: 'bold', fontSize: 21 }}>{data.total_orders && data.total_orders}</Text>
                    </View>
                    <View
                        style={[{
                            width: isDesktopOrLaptop ? '20%' : '45%', backgroundColor: primarybg,
                            elevation: 3,
                            paddingVertical: 10, borderRadius: 10,
                            paddingLeft: 9,
                            alignSelf: 'center', justifyContent: 'center', marginVertical: 7
                        }, shadow]}
                    >
                        <Text style={{ color: 'gray', fontSize: 12 }}>Total sales</Text>
                        <Text style={{ color: secondarybg, fontWeight: 'bold', fontSize: 21 }}>Rs.{data.total_sale && data.total_sale}</Text>
                    </View>

                </View>

                <View style={{
                    paddingHorizontal: isDesktopOrLaptop ? '0%' : '0%', minHeight: 200, paddingVertical: 20, borderRadius: 10,
                    backgroundColor: primaryColour, width: isDesktopOrLaptop ? '30%' : '80%', alignSelf: 'center'
                }}>
                    <View style={{ paddingHorizontal: 15, }}>
                        <Text style={{ color: primarybg, fontSize: 16, fontWeight: 'bold' }}>Share and Earn</Text>
                        <Text style={{ color: primarybg, fontSize: 14 }}>Share your own Website link on social media to get more customers every day</Text>
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 4, }}>
                            <Text style={{ color: secondarybg, marginTop: 10, fontSize: 14, marginRight: 10, fontWeight: 'bold' }} selectable={true}
                                onPress={() => Linking.openURL('https://bazars.pk/' + data.link)}
                            >
                                https://bazars.pk/{data.link ? data.link : null}
                                {data == {} && <ActivityIndicator size="small" color={secondarybg} />}
                            </Text>
                        </View>
                        <RoundButton style={[shadow, { shadowColor: 'black', alignSelf: 'center', width: 100, height: 40 }]} text='Visit Website'
                            onPress={() => Linking.openURL('https://bazars.pk/' + data.link)}
                        />

                    </View>
                </View>

            </Sidebar>

        </>
    );
}

