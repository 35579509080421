import React, { useState, useEffect, useRef } from 'react';
import { Text, View, Dimensions, ScrollView, TouchableOpacity, Linking } from 'react-native';
import { defaultLink, primarybg, primaryColour, shadowColor, secondaryColor, demo } from '../../../utils/string';
import Login from '../account/login';
import { getData, IconWithText, PerfectInput, RoundButton, } from '../../../utils/utils';
import { TabContext } from '../../../utils/contexts';
import { useMediaQuery } from 'react-responsive';
// import { FontAwesome } from '@expo/vector-icons';

import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import { FaArrowUp, FaBars, FaCartPlus, FaClipboardList, FaList, FaWhatsapp } from "react-icons/fa";
import { AiFillHome, AiFillShopping, AiFillPhone } from "react-icons/ai";
import { RiFileList3Line } from 'react-icons/ri';
import { IoCallSharp, IoChatbubble, IoSearch, IoCall, IoLogoWhatsapp, IoChatbox, IoClose, IoChatboxEllipsesSharp } from 'react-icons/io5';
import SearchModal from '../templateUtils/searchModal';
import ReactModal from 'react-modal';
import '../templateUtils/style.css';
import '../templateUtils/dukanStyle.css';
import '../templateUtils/plugins/bootstrap/bootstrap.min.css';
import '../templateUtils/plugins/themify-icons/themify-icons.css';
import '../templateUtils/sideBar.css';
import { FaArrowAltCircleUp } from "react-icons/fa";
import { matchRoutes, useLocation } from "react-router-dom"

const Icon = () => (<></>);

const dimensions = Dimensions.get('window');

export default function Tab(props) {

    const navigate = useNavigate();
    const { dukanId } = useParams();
    const { pathname } = useLocation();
    const move = navigate;
    const { children, dukanName, dukanContactNumber, secondaryColor, primaryColour, notScrollView } = props
    const [bagVisible, setbagVisible] = useState(false);
    const [loginVisible, setloginVisible] = React.useState(false);
    const [dukanLink, setdukanLink] = useState(typeof window != 'undefined' ? window.location.pathname.split('/')[1] : '');
    const [secureToken, setsecureToken] = useState('');
    const [key, setKey] = useState(Math.random());

    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const [scrollEnabled, setscrollEnabled] = useState(true);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    const [bagCount, setbagCount] = useState(0);
    const [searchText, setsearchText] = useState('');
    const [searchModal, setsearchModal] = useState(false);
    const [visible, setVisible] = useState(false)
    const location = useLocation()
    const route = demo ? matchRoutes([{ path: "/:dukanId" }], location) : matchRoutes([{ path: "" }], location)


    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    React.useEffect(() => {
        getData('dukanLink', setdukanLink)
        getData('secure_token', setsecureToken);
    }, [])

    useEffect(() => {
        setKey(Math.random())
        if (typeof window === 'undefined') {
            global.window = {}
        }
    }, [secureToken])

    const [modal, setmodal] = useState(false);

    useEffect(() => { setmodal(modal) }, [])

    const ref = useRef()

    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);
    const [disableBtn, setDisableBtn] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (showSidebar && ref.current && !ref.current.contains(e.target)) {
                setSidebar(false);
                setDisableBtn(true)
            } else {
                setDisableBtn(false)
                setSidebar(false);
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [showSidebar])


    // let primaryColour = typeof props.primaryColour != 'undefined' ? props.primaryColour : '#5893D4'
    let storeName = typeof props.storeName != 'undefined' ? props.storeName : ''
    let data = typeof props.data != 'undefined' ? props.data : ''
    // dukanLink merabcollection /merabcollection /merabcollection
    // tab.js:1 dukanLink merabcollection merabcollection/Bag merabcollection/Bag
    // tab.js:1 dukanLink merabcollection merabcollection/AboutUs merabcollection/AboutUs

    //     dukanLink merabcollection merabcollection/Bag merabcollection/Bag
    // tab.js:1 dukanLink merabcollection merabcollection/AboutUs merabcollection/AboutUs
    // tab.js:1 dukanLink merabcollection /merabcollection /merabcollection

    const changePage = (link) => {
        console.log('dukanLink', window.location.pathname.split('/')[1], link, link.replace(dukanLink, window.location.pathname.split('/')[1]))
        if (dukanLink == '@error' || dukanLink == null) {
            getData('dukanLink', setdukanLink);
            // setdukanLink(window.location.pathname.split('/')[1])
            move({
                routeName: link.replace(dukanLink, window.location.pathname.split('/')[1]),
                web: { as: link },
            })
        } else {
            if (dukanLink != window.location.pathname.split('/')[1]) {
                setdukanLink(window.location.pathname.split('/')[1])
                move({
                    routeName: link.replace(dukanLink, window.location.pathname.split('/')[1]),
                    web: { as: link },
                })
            } else {
                console.log('1')
                move({
                    routeName: link.replace(dukanLink, window.location.pathname.split('/')[1]),
                    web: { as: link },
                })
            }
        }
    }

    const checkLoginThenchangePage = (link) => {
        if (dukanLink == '@error' || dukanLink == null) {
            getData('dukanLink', setdukanLink)
            secureToken != '@error' && secureToken != '' ?
                move({
                    routeName: link.replace(dukanLink, window.location.pathname.split('/')[1]),
                    web: { as: link },
                })
                : setloginVisible(!loginVisible)
        } else {
            if (dukanLink != window.location.pathname.split('/')[1]) {
                setdukanLink(window.location.pathname.split('/')[1])
                secureToken != '@error' && secureToken != '' ?
                    move({
                        routeName: link.replace(dukanLink, window.location.pathname.split('/')[1]),
                        web: { as: link },
                    })
                    : setloginVisible(!loginVisible)
            } else {
                secureToken != '@error' && secureToken != '' ?
                    move({
                        routeName: link.replace(dukanLink, window.location.pathname.split('/')[1]),
                        web: { as: link },
                    })
                    : setloginVisible(!loginVisible)
            }
        }
    }

    return (
        <>

            <TabContext.Provider
                value={{
                    setscrollEnabled
                }}
                key={key}
            >
                <Login visible={loginVisible} setvisible={setloginVisible} secondaryColor={secondaryColor} primaryColour={primaryColour} />
                <ToastContainer autoClose={2000} />



                {
                    isBigScreen || isDesktopOrLaptop ?
                        <View style={{
                            maxHeight: demo ? (route == null ? dimensions.height : dimensions.height - 55) : dimensions.height
                        }}>
                            {console.log(route, 'mmmmmmmmmmmmmmmmmppp')}
                            {dukanContactNumber != 'false' && dukanContactNumber != '' &&
                                <View style={{
                                    flexDirection: 'row',
                                    // flex: 0.05,
                                    shadowColor: "white",
                                    borderColor: '#2d043b',
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.28,
                                    shadowRadius: 8.00,
                                    elevation: 24,
                                    borderRightWidth: 0.01,
                                    background: 'white',
                                    // position: 'fixed',
                                    // top: 0,
                                    zIndex: '3',
                                    minHeight: 50,
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    alignItems: 'center', paddingHorizontal: '2%'
                                }}>


                                    <Text style={{ color: 'gray', fontSize: 15, textTransform: 'uppercase', fontFamily: 'Raleway sans-serif', }}>Contact us on:</Text>
                                    <View style={[

                                        { flexDirection: 'row', marginTop: 15, marginHorizontal: 13, justifyContent: 'center' },
                                        isDesktopOrLaptop ? {} : {}

                                    ]}>

                                        {dukanContactNumber != 'false' && dukanContactNumber != '' ?
                                            <>
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        Linking.openURL(`tel:${dukanContactNumber}`);
                                                    }}
                                                    style={{
                                                        flexDirection: 'row',
                                                        borderRadius: 3,
                                                        flexWrap: 'wrap',
                                                        shadowOpacity: 2,
                                                        shadowColor: 'black',
                                                        elevation: 2,
                                                        margin: 2,

                                                        marginLeft: 0,
                                                        marginRight: 0,
                                                        paddingHorizontal: 10,
                                                        paddingVertical: 3,
                                                    }}>

                                                    <IoCall size={15} color={primaryColour} solid={false} />

                                                </TouchableOpacity>

                                                <TouchableOpacity

                                                    onPress={() => {
                                                        // Linking.openURL(
                                                        //     `sms:${data.mobile_number}${Platform.OS === 'ios' ? '&' : '?'
                                                        //     }body=${'PakNeed: \n'}`,
                                                        // );
                                                        Linking.openURL(
                                                            'https://api.whatsapp.com/send?phone=+92' + dukanContactNumber.substring(1) + '&text=' + dukanName + ':\n'
                                                        )
                                                    }}
                                                    style={{
                                                        flexDirection: 'row',
                                                        borderRadius: 3,
                                                        flexWrap: 'wrap',
                                                        shadowOpacity: 2,
                                                        shadowColor: 'black',
                                                        elevation: 2,
                                                        margin: 2,
                                                        paddingVertical: 2,
                                                        paddingHorizontal: 4,
                                                        marginLeft: 0,
                                                        marginRight: 0,
                                                    }}>

                                                    <IoLogoWhatsapp size={15} color={primaryColour} solid={false} />

                                                </TouchableOpacity>
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        Linking.openURL('https://play.google.com/store/apps/details?id=com.pakneed')
                                                    }}

                                                    style={{
                                                        flexDirection: 'row',
                                                        borderRadius: 3,
                                                        flexWrap: 'wrap',
                                                        shadowOpacity: 2,
                                                        shadowColor: 'black',
                                                        elevation: 2,
                                                        margin: 2,
                                                        marginLeft: 0,
                                                        marginRight: 0,
                                                        paddingHorizontal: 10,
                                                        paddingVertical: 3,
                                                        // flex: 0.13,
                                                        maxWidth: 40,
                                                    }}>

                                                    <IoChatbox size={15} color={primaryColour} solid={false} />

                                                </TouchableOpacity>
                                            </>
                                            : null}
                                    </View>


                                </View>
                            }
                            <View style={{
                                flexDirection: 'row',
                                // flex: 0.05,
                                shadowColor: "#000",
                                borderColor: '#2d043b',
                                shadowOffset: {
                                    width: 0,
                                    height: 12,
                                },
                                shadowOpacity: 0.58,
                                shadowRadius: 16.00,
                                elevation: 24,
                                background: primaryColour,
                                minHeight: 80,
                                maxHeight: 80,
                                // position: 'fixed',
                                // top: 50,
                                zIndex: '2',
                                // height: 80,
                                width: '100%',
                                justifyContent: 'space-between',
                                paddingHorizontal: '2%'
                                // alignItems: 'center'
                            }}>
                                <View style={{ flexDirection: 'row', }}>
                                    <div className='navbar-menu pl-2 pr-2' style={{ justifyContent: 'center', }}>
                                        <TouchableOpacity style={{ marginTop: 30, marginRight: 10, }}
                                            onPress={() => {
                                                defaultLink == '' ?
                                                    navigate('/' + dukanId + '/') :
                                                    navigate('/')
                                            }}
                                        >
                                            <View style={{ justifyContent: 'center', textAlign: 'center' }}>

                                                <Text style={{ fontSize: 15, fontWeight: 600, color: secondaryColor, fontFamily: 'sans-serif', textTransform: 'uppercase' }}>Home</Text>
                                            </View>
                                        </TouchableOpacity>
                                    </div>

                                    <div className='navbar-menu text-center pl-2 pr-2'>

                                        <TouchableOpacity style={{ marginTop: 30, marginRight: 10, }}
                                            onPress={() => {
                                                defaultLink == '' ?
                                                    navigate('/' + dukanId + '/bag')
                                                    : navigate('/bag')
                                            }}
                                        >
                                            <View style={{ justifyContent: 'center', textAlign: 'center' }}>

                                                <Text style={{ fontSize: 15, fontWeight: 600, color: secondaryColor, fontFamily: 'sans-serif', textTransform: 'uppercase' }}>Bag</Text>
                                            </View>
                                        </TouchableOpacity>

                                    </div>

                                    <div className='navbar-menu text-center pl-2 pr-2'>


                                        <TouchableOpacity style={{ marginTop: 30, marginRight: 10, }}
                                            onPress={() => {
                                                console.log(window.location.pathname.substring(1).split('/')[0] + '/Orders')
                                                if (secureToken != '@error' && secureToken != '') {
                                                    defaultLink == '' ?
                                                        navigate('/' + dukanId + '/orders')
                                                        : navigate('/orders')
                                                } else {
                                                    setloginVisible(!loginVisible)
                                                }
                                            }}
                                        >
                                            <View style={{ justifyContent: 'center', textAlign: 'center' }}>

                                                <Text style={{ fontSize: 15, fontWeight: 600, color: secondaryColor, fontFamily: 'sans-serif', textTransform: 'uppercase' }}>Orders</Text>
                                            </View>
                                        </TouchableOpacity>

                                    </div>

                                </View>
                                <div className='text-center mt-2'>
                                    <div className='dukan-name' style={isDesktopOrLaptop ? {
                                        height: 300, width: '100%', fontWeight: 'bold',
                                        //  position: 'relative', top: -15, 
                                        color: secondaryColor,
                                    } : { width: '100%', fontWeight: 'bold', position: 'relative', top: -15, color: secondaryColor, textShadow: '1px 0px #ffa600', }}>
                                        {dukanName}
                                    </div>
                                </div>
                                <View style={{ flexDirection: 'row', }}>
                                    <div className='navbar-menu pl-2 pr-2' style={{ justifyContent: 'center', }}>
                                        <TouchableOpacity style={{ marginTop: 22, marginRight: 10, }}
                                        >
                                            <button onClick={() => { setsearchModal(true) }} style={{ border: 'none', backgroundColor: 'transparent', }}>
                                                <View style={{ justifyContent: 'center', textAlign: 'center', color: secondaryColor }}>

                                                    <IoSearch size={30} color={secondaryColor} solid={false} style={{ marginLeft: 10, marginRight: 10, }} />

                                                </View>
                                            </button>



                                        </TouchableOpacity>
                                    </div>


                                </View>

                            </View>
                            <ScrollView >
                                {children}
                            </ScrollView>
                        </View>
                        :


                        <View style={{ height: dimensions.height }}>
                            {/* <Bag visible={bagVisible} setvisible={setbagVisible} /> */}

                            <View style={{
                                flexDirection: 'row',
                                flex: 0.07,
                                shadowColor: "#000",
                                borderColor: '#2d043b',
                                shadowOffset: {
                                    width: 0,
                                    height: 2,
                                },
                                shadowOpacity: 0.28,
                                shadowRadius: 8.00,
                                elevation: 24,
                                borderRightWidth: 0.01,
                                background: 'white',
                                // position: 'fixed',
                                // top: 0,
                                zIndex: '3',
                                height: 50,
                                width: '100%',
                                justifyContent: 'center',
                            }}>
                                <div className='text-center' style={{
                                    height: 80, width: '100%', backgroundColor: primaryColour,
                                    position: 'absolute', top: 0,
                                    boxShadow: '0 8px 6px -6px black',
                                }}>


                                    <div className="navbar" style={{ backgroundColor: primaryColour, justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>

                                        <TouchableOpacity to="#" className="menu-bars" style={{ position: 'absolute', left: 5, paddingRight: 20, borderRightWidth: 1, borderColor: 'lightgray', height: 60, }}>
                                            <span style={{ marginTop: 15, }}><FaBars size={20} color={secondaryColor} solid={false} onClick={showSidebar} /></span>
                                        </TouchableOpacity>


                                        <div className='dukan-name' style={!isDesktopOrLaptop ? { textTransform: 'uppercase', fontSize: '1.3em', position: 'relative', fontWeight: 600, color: secondaryColor, justifyContent: 'center', alignItems: 'center', left: 2, } : null}>

                                            {dukanName}

                                        </div>

                                        <TouchableOpacity onPress={() => { setsearchModal(true) }} style={{ position: 'absolute', right: 15, borderLeftColor: 'lightgray', borderLeftWidth: 1, height: 60, }}>

                                            <span style={{ marginTop: 15, }}><IoSearch size={25} color={secondaryColor} solid={false} /></span>


                                        </TouchableOpacity>

                                    </div>

                                    <View style={{ position: 'fixed', bottom: 20, right: 20, }}>
                                        <TouchableOpacity>
                                            <FaArrowUp onClick={scrollToTop}
                                                style={{ display: visible ? 'inline' : 'none' }} color={primaryColour} size={30} />
                                        </TouchableOpacity>
                                    </View>

                                </div>

                                {/* All the icons now are white */}

                                <nav className={sidebar ? "nav-menu active" : "nav-menu"} ref={ref} style={{ backgroundColor: primaryColour, }}>
                                    <ul className="nav-menu-items">
                                        <li style={{ backgroundColor: primaryColour, position: 'sticky', }}>
                                            <View className="navbar-toggle" style={{ marginLeft: 230, marginTop: 10, }}>
                                                <IoClose className="menu-bars" size={25} color={secondaryColor} solid={false}
                                                    onClick={() => {

                                                        setSidebar(false);

                                                    }} />
                                            </View>
                                        </li>
                                        <li className="cName" style={{ marginTop: 30, }}>


                                            {/* <View style={{ justifyContent: 'center', textAlign: 'center' }}>
                                                   
                                                    <Text style={{ letterSpacing: 1, fontSize: 25, fontWeight: 700, color: secondaryColor, fontFamily: 'sans-serif', textTransform: 'uppercase' }}><GiCrown size={100} color={secondaryColor} solid="false" /></Text>
                                                </View> */}

                                            <View style={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
                                                {/* <TabIcon
                                                        name="home" size={30} color={primaryColour} style={{ marginHorizontal: 20 }}
                                                    // onPress={() => setbagVisible(!bagVisible)}
                                                    /> */}
                                                <Text style={{ marginBottom: 10, letterSpacing: 1, fontSize: 23, fontWeight: 700, color: secondaryColor, fontFamily: 'sans-serif', textTransform: 'uppercase' }}>{dukanName}</Text>

                                                <hr color="dimgray" width="73%" />

                                            </View>


                                        </li>
                                        <li className="cName">

                                            <View style={{ marginHorizontal: '14%', marginTop: 20, }}>
                                                {/* <TabIcon2
                                                        name="shopping-bag" size={30} color={primaryColour} style={{ marginHorizontal: 20 }}
                                                    // onPress={() => setbagVisible(!bagVisible)}
                                                    /> */}
                                                <Text style={{ letterSpacing: 3, fontSize: 15, fontWeight: 500, color: 'dimgray', fontFamily: 'sans-serif', textTransform: 'uppercase' }}>Menu</Text>
                                            </View>
                                        </li>

                                        <li className="cName">
                                            <TouchableOpacity style={{ marginTop: 20, marginRight: 10, }}
                                                onPress={() => {
                                                    defaultLink == '' ?
                                                        navigate('/' + dukanId + '/') :
                                                        navigate('/')
                                                }}
                                            >
                                                <View style={{ marginHorizontal: '15%' }}>
                                                    {/* <TabIcon
                                                        name="home" size={30} color={primaryColour} style={{ marginHorizontal: 20 }}
                                                    // onPress={() => setbagVisible(!bagVisible)}
                                                    /> */}
                                                    <Text style={{ letterSpacing: 3, fontSize: 13, fontWeight: 500, color: secondaryColor, fontFamily: 'sans-serif', textTransform: 'uppercase' }}><AiFillHome size={25} color={secondaryColor} solid={false} style={{ position: 'relative', top: -5, }} />  Home</Text>
                                                </View>
                                            </TouchableOpacity>
                                        </li>
                                        <li className="cName">
                                            <TouchableOpacity style={{ marginTop: 20, marginRight: 10, }}
                                                onPress={() => {
                                                    defaultLink == '' ?
                                                        navigate('/' + dukanId + '/bag')
                                                        : navigate('/bag')
                                                }}
                                            >
                                                <View style={{ marginHorizontal: '15%' }}>
                                                    {/* <TabIcon2
                                                        name="shopping-bag" size={30} color={primaryColour} style={{ marginHorizontal: 20 }}
                                                    // onPress={() => setbagVisible(!bagVisible)}
                                                    /> */}
                                                    <Text style={{ letterSpacing: 3, fontSize: 13, fontWeight: 500, color: secondaryColor, fontFamily: 'sans-serif', textTransform: 'uppercase' }}><AiFillShopping size={26} color={secondaryColor} solid={false} style={{ position: 'relative', top: -5, }} />  Bag</Text>
                                                </View>
                                            </TouchableOpacity>
                                        </li>
                                        <li className="cName">
                                            <TouchableOpacity style={{ marginTop: 20, marginRight: 10, }}
                                                onPress={() => {
                                                    console.log(window.location.pathname.substring(1).split('/')[0] + '/Orders')
                                                    if (secureToken != '@error' && secureToken != '') {
                                                        defaultLink == '' ?
                                                            navigate('/' + dukanId + '/orders')
                                                            : navigate('/orders')
                                                    } else {
                                                        setloginVisible(!loginVisible)
                                                    }
                                                }}
                                            >
                                                <View style={{ marginHorizontal: '15%' }}>
                                                    {/* <TabIcon
                                                        name="format-list-bulleted-type" size={30} color={primaryColour} style={{ marginHorizontal: 20 }}
                                                    // onPress={() => setbagVisible(!bagVisible)}
                                                    /> */}
                                                    <Text style={{ letterSpacing: 3, fontSize: 13, fontWeight: 500, color: secondaryColor, fontFamily: 'sans-serif', textTransform: 'uppercase' }}><RiFileList3Line size={25} color={secondaryColor} solid={false} style={{ position: 'relative', top: -5, left: 3, }} />  Orders</Text>
                                                </View>
                                            </TouchableOpacity>

                                        </li>

                                        <li className="cName">

                                            <View style={{ marginHorizontal: '14%', marginTop: 30, }}>
                                                {/* <TabIcon2
                                                        name="shopping-bag" size={30} color={primaryColour} style={{ marginHorizontal: 20 }}
                                                    // onPress={() => setbagVisible(!bagVisible)}
                                                    /> */}
                                                <Text style={{ letterSpacing: 3, fontSize: 15, fontWeight: 500, color: 'dimgray', fontFamily: 'sans-serif', textTransform: 'uppercase' }}>Shortcuts</Text>
                                            </View>
                                        </li>

                                        {dukanContactNumber != 'false' && dukanContactNumber != '' ?
                                            <>

                                                <li className='cName'>
                                                    <View style={{ marginHorizontal: '15%', marginTop: 20, }}>

                                                        <TouchableOpacity
                                                            onPress={() => {
                                                                Linking.openURL(`tel:${dukanContactNumber}`);
                                                            }}
                                                            style={{
                                                                flexDirection: 'row',
                                                                borderRadius: 3,
                                                                flexWrap: 'wrap',
                                                                shadowOpacity: 2,
                                                                shadowColor: 'black',
                                                                elevation: 2,
                                                                paddingVertical: 3,
                                                            }}>

                                                            <Text style={{ letterSpacing: 3, fontSize: 13, fontWeight: 500, color: secondaryColor, fontFamily: 'sans-serif', textTransform: 'uppercase' }}><IoCall size={25} color={secondaryColor} solid={false} style={{ position: 'relative', top: -2, left: 3, }} />  Call us</Text>

                                                        </TouchableOpacity>
                                                    </View>
                                                </li>


                                                <li className='cName'>
                                                    <View style={{ marginHorizontal: '15%', marginTop: 15, }}>

                                                        <TouchableOpacity

                                                            onPress={() => {
                                                                // Linking.openURL(
                                                                //     `sms:${data.mobile_number}${Platform.OS === 'ios' ? '&' : '?'
                                                                //     }body=${'PakNeed: \n'}`,
                                                                // );
                                                                Linking.openURL(
                                                                    'https://api.whatsapp.com/send?phone=+92' + dukanContactNumber.substring(1) + '&text=' + dukanName + ':\n'
                                                                )
                                                            }}
                                                            style={{
                                                                flexDirection: 'row',
                                                                borderRadius: 3,
                                                                flexWrap: 'wrap',
                                                                shadowOpacity: 2,
                                                                shadowColor: 'black',
                                                                elevation: 2,
                                                                paddingVertical: 2,

                                                            }}>

                                                            <Text style={{ letterSpacing: 3, fontSize: 13, fontWeight: 500, color: secondaryColor, fontFamily: 'sans-serif', textTransform: 'uppercase' }}><FaWhatsapp size={25} color={secondaryColor} solid={false} style={{ position: 'relative', top: -2, left: 3, }} />  Whatsapp</Text>

                                                        </TouchableOpacity>


                                                    </View>
                                                </li>



                                            </>
                                            : null}

                                        <li className='cName'>
                                            <View style={{ marginHorizontal: '15%', marginTop: 15, }}>

                                                <TouchableOpacity
                                                    onPress={() => {
                                                        Linking.openURL('https://play.google.com/store/apps/details?id=com.pakneed')
                                                    }}

                                                    style={{
                                                        flexDirection: 'row',
                                                        borderRadius: 3,
                                                        flexWrap: 'wrap',
                                                        shadowOpacity: 2,
                                                        shadowColor: 'black',
                                                        elevation: 2,
                                                        paddingVertical: 3,
                                                    }}>

                                                    <Text style={{ letterSpacing: 3, fontSize: 13, fontWeight: 500, color: secondaryColor, fontFamily: 'sans-serif', textTransform: 'uppercase' }}><IoChatboxEllipsesSharp size={25} color={secondaryColor} solid={false} style={{ position: 'relative', top: -1, left: 3, }} />  Chat</Text>

                                                </TouchableOpacity>
                                            </View>
                                        </li>


                                    </ul>
                                </nav>


                            </View>
                            {typeof props.hideBottomTab == 'undefined' ?
                                <>
                                    {typeof notScrollView != 'undefined' ?
                                        <View style={{ flex: 1, }} scrollEnabled={scrollEnabled}>
                                            {children}

                                        </View>
                                        :
                                        <ScrollView scrollEnabled={scrollEnabled}>
                                            {children}

                                        </ScrollView>
                                    }


                                </>
                                : null
                            }
                        </View>
                }
                <SearchModal primaryColour={primaryColour} dukanName={dukanName} secondaryColor={secondaryColor} searchModal={searchModal} setsearchModal={setsearchModal} />
            </TabContext.Provider>

        </>
    );
}