import React, { useEffect, useState, useContext, useRef } from 'react';
import {
    StyleSheet,
    View,
    Text,
    TouchableOpacity,
    ScrollView,
    RefreshControl,
    ImageBackground,
    TextInput,
    ActivityIndicator,
    StatusBar,
    Linking
} from 'react-native';
import { HomeContext } from '../../../utils/contexts';
// import { FontAwesome } from '@expo/vector-icons';
import { borderColor, inputBackgound, primarybg } from '../../../utils/string';
import { useNavigate } from 'react-router-dom';
import { FaFacebookSquare, FaInstagram, FaTwitterSquare, FaYoutube } from 'react-icons/fa';
import { isURL } from '../../../utils/utils';
// const Icon = FontAwesome;
import { useMediaQuery } from "react-responsive";

export default function Footer(props) {
    const navigate = useNavigate();
    let data = props.data
    const [tempCategories, settempCategories] = useState([]);


    const { products, productsLoading, primaryColour, categories, categoriesLoading,
        facebook,
        instagram,
        youtube,
        secondaryColor, dukanName
    } = useContext(HomeContext);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })



    return (
        <View style={{ backgroundColor: primaryColour, marginTop: '10%', paddingTop: 10 }}>
            {/* <View style={{ width: '100%', backgroundColor: 'lightgray', height: 1, marginTop: 20, alignSelf: 'center' }} /> */}
            <View style={{
                flexDirection: 'row', alignItems: isDesktopOrLaptop ? undefined : 'center',
                flexWrap: !isDesktopOrLaptop && 'wrap', paddingHorizontal: isDesktopOrLaptop ? '5%' : '1%',
                paddingBottom: isDesktopOrLaptop && '2%',
                paddingTop: isDesktopOrLaptop ? '5%' : '2%', justifyContent: 'center'
            }}>
                {/* <View style={{ flex: 0.2, justifyContent: 'center' }}>
                    <Text style={{ paddingHorizontal: 10, paddingVertical: 5, borderColor: primaryColour, borderWidth: 1, fontSize: 80, textAlign: 'center' }}>
                        {dukanName}
                    </Text>
                </View> */}

                <View style={{ flex: isDesktopOrLaptop ? 0.4 : undefined, marginLeft: '2%', width: '100%', justifyContent: 'center' }}>
                    <div className="ten" style={{ "--bg-color": primaryColour, marginBottom: isDesktopOrLaptop ? 15 : 0, textAlign: !isDesktopOrLaptop && 'center' }}>
                        <h1 style={{ color: primarybg, fontSize: 26 }}>{data.user_name}</h1>
                    </div>
                    <Text style={{ fontSize: 14, color: primarybg, textAlign: !isDesktopOrLaptop && 'center' }}>
                        {data.description}
                    </Text>
                </View>
                <View style={{ flex: isDesktopOrLaptop ? 0.2 : undefined, marginLeft: isDesktopOrLaptop && '5%', width: '42%', marginTop: isDesktopOrLaptop ? 0 : 19 }}>
                    <div className="ten" style={{ "--bg-color": secondaryColor, marginBottom: isDesktopOrLaptop ? 10 : 0 }}>
                        <h1 style={{ fontSize: !isDesktopOrLaptop && 18, fontWeight: !isDesktopOrLaptop && 'bold', color: secondaryColor }}>Links</h1>
                    </div>
                    <Text style={{ marginTop: 5, fontSize: 16, color: secondaryColor, }} onPress={() => navigate('/shop')}>Shop</Text>
                    <Text style={{ marginTop: 5, fontSize: 16, color: secondaryColor, }} onPress={() => navigate('/bag')}>Bag</Text>
                    <Text style={{ marginTop: 5, fontSize: 16, color: secondaryColor, }} onPress={() => navigate('/shop')}>Orders</Text>
                </View>
                <View style={{ flex: isDesktopOrLaptop ? 0.3 : undefined, marginLeft: isDesktopOrLaptop && '2%', width: '40%', marginTop: isDesktopOrLaptop ? 0 : 19 }}>
                    <div className="ten" style={{ "--bg-color": secondaryColor, marginBottom: isDesktopOrLaptop ? 10 : 0, }}>
                        <h1 style={{ fontSize: !isDesktopOrLaptop && 18, color: secondaryColor, fontWeight: !isDesktopOrLaptop && 'bold' }}>Contact Us</h1>
                    </div>
                    {data.show_number_on_chats == 'true' &&
                        <>
                            <Text style={{ marginTop: isDesktopOrLaptop && 5, fontSize: 16, color: secondaryColor, }}
                                onPress={() => {
                                    Linking.openURL(`tel:${data.dukan_contact_number}`);
                                }}
                            >Phone</Text>
                            <Text style={{ marginTop: 5, fontSize: 16, color: secondaryColor, }}
                                onPress={() => {
                                    Linking.openURL(
                                        'https://api.whatsapp.com/send?phone=+92' + data.dukan_contact_number.substring(1) + '&text=' + dukanName + ':\n'
                                    )
                                }}
                            >Whatsapp</Text>
                        </>
                    }
                    <Text style={{ marginTop: 5, fontSize: 16, color: secondaryColor, }}
                        onPress={() => {
                            Linking.openURL('https://play.google.com/store/apps/details?id=com.pakneed')
                        }}
                    >Bazars Chat</Text>

                </View>
            </View>
            <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center', marginVertical: 15 }}>
                {facebook != '' || instagram != '' || youtube != '' ?
                    <>
                        {!isDesktopOrLaptop &&
                            <Text style={{ fontWeight: 'bold', fontSize: 18, marginVertical: 10, textAlign: !isDesktopOrLaptop ? 'center' : undefined, color: secondaryColor }}>
                                Follow Us</Text>
                        }
                        <View
                            style={{
                                flexDirection: 'row', justifyContent: 'space-between',

                            }}>
                            {facebook != '' &&
                                <TouchableOpacity style={{ backgroundColor: secondaryColor + '21', paddingHorizontal: 10, paddingVertical: 5 }} onPress={() => { isURL(facebook) && Linking.openURL(facebook) }}>
                                    <FaFacebookSquare name="facebook-square" size={34} color={secondaryColor} />
                                </TouchableOpacity>}
                            {instagram != '' &&
                                <TouchableOpacity style={{ marginLeft: 15, backgroundColor: secondaryColor + '21', paddingHorizontal: 10, paddingVertical: 5 }}
                                    onPress={() => { isURL(instagram) && Linking.openURL(instagram) }}>
                                    <FaInstagram name="instagram" size={34} color={secondaryColor} />
                                </TouchableOpacity>}
                            {youtube != '' &&
                                <TouchableOpacity style={{ marginLeft: 15, backgroundColor: secondaryColor + '21', paddingHorizontal: 10, paddingVertical: 5 }} onPress={() => { isURL(youtube) && Linking.openURL(youtube) }}>
                                    <FaYoutube name="twitter-square" size={34} color={secondaryColor} />
                                </TouchableOpacity>}

                        </View>
                    </> : null
                }
            </View>
            <View style={{ width: '100%', backgroundColor: 'lightgray', height: 1, marginTop: 20, alignSelf: 'center' }} />
            <View style={{
                marginTop: 0,
                shadowColor: "#000",
                shadowOffset: {
                    width: 0,
                    height: 4,
                },
                shadowOpacity: 0.32,
                shadowRadius: 5.46,
                height: 30,
                paddingTop: 7,
            }}>
                <Text style={{ color: secondaryColor, textAlign: 'center', textTransform: 'uppercase' }}>©2022 {props.data.user_name}
                    <Text style={{ textTransform: 'none', fontWeight: 'bold' }} onPress={() => Linking.openURL('https://bazars.pk')}> -Powered by bazars.pk</Text>

                </Text>
            </View>

        </View>

    );
}
