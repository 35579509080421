import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View, ScrollView, FlatList, Dimensions, ActivityIndicator, RefreshControl } from "react-native";
import { defaultLink, primarybg, serverURL } from "../../../utils/string";
import ImageLoad from "../../../utils/imageLoad";
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import { shadow } from '../../../utils/style';

const { height, width } = Dimensions.get('window');

export default function Product(props) {
    let navigate = useNavigate()
    const { dukanId } = useParams();
    const { item, primaryColour } = props
    const [data, setdata] = useState([1, 2, 3, 5, 8, 8]);

    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1050px)'
    })

    return (

        <TouchableOpacity style={[shadow, {
            // width: width / 3 - 40,
            flex: 0.2,
            backgroundColor: primarybg,
            marginHorizontal: 10,
            shadowColor: primaryColour + 21, borderWidth: 0, borderColor: 'black'
        }, !isDesktopOrLaptop ? { flex: 0.5, marginHorizontal: 7, marginBottom: 20, marginTop: 0 } : { marginVertical: 20, }

        ]}
            onPress={() => {
                let t = item.title.replace(/[^a-z0-9\s-]/ig, '')
                    .trim()
                    .replace(/\s+/g, '-')
                    .toLowerCase()
                t = t.length <= 0 ? 'm' : t
                defaultLink == '' ?
                    navigate('/' + dukanId + '/product/' + item.id + '/' + t)
                    : navigate('/product/' + item.id + '/' + t)
            }}
        >
            <ImageLoad
                onPress={() => {
                    let t = item.title.replace(/[^a-z0-9\s-]/ig, '')
                        .trim()
                        .replace(/\s+/g, '-')
                        .toLowerCase()
                    t = t.length <= 0 ? 'm' : t
                    defaultLink == '' ?
                        navigate('/' + dukanId + '/product/' + item.id + '/' + t)
                        : navigate('/product/' + item.id + '/' + t)
                }}
                style={[{
                    // marginTop: 20,
                    width: '100%',
                    height: 270,
                    // borderRadius: 35,
                    // padding: 9,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    // backgroundColor: '#f0f0f0'
                },
                !isDesktopOrLaptop ? { height: 170, borderRadius: 0, } : {}
                ]}
                imageStyle={{
                    // flex: 1,
                    width: '100%',
                    height: 270,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                }, !isDesktopOrLaptop ? {
                    height: 170,
                    // borderRadius: 5,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                } : {
                        // borderRadius: 15,
                        // borderBottomLeftRadius: 0,
                        // borderBottomRightRadius: 0,
                    }}
                resizeMode="cover"
                source={
                    data.cover !== 'null' ?
                        serverURL + item.cover
                        : 'https://images.unsplash.com/photo-1481595357459-84468f6eeaac?dpr=1&auto=format&fit=crop&w=376&h=251&q=60&cs=tinysrgb'
                }
            >
                {/* <Icon name="arrow-right" size={20} color={primaryColour}
                            style={{
                                backgroundColor: primarybg,
                                marginTop: 5, width: 30, justifyContent: 'center',
                                padding: 9, borderRadius: 5, elevation: 2,
                            }} /> */}
            </ImageLoad>
            <Text style={{
                color: primaryColour, textAlign: 'center'
            }, isDesktopOrLaptop ? {
                paddingVertical: 5,
                color: primaryColour, fontSize: 15,
                // lineHeight: 14.5,
                textTransform: 'capitalize',
                fontWeight: '500', letterSpacing: 1.1,
                textAlign: 'center'
            } : {
                    textTransform: 'capitalize', fontSize: 14, textAlign: 'center', color: primaryColour, paddingTop: 5, paddingHorizontal: 1,
                    letterSpacing: 1, opacity: 0.8,
                    marginTop: 5,
                }}>
                {item.title.length > 42 ? String(item.title.substring(0, 42) + '...').replace(/\n/g, " ") : String(item.title.replace(/\n/g, " "))} </Text>
            {/* <View style={{ width: '90%', backgroundColor: '#f0ede9', height: 0.3, marginVertical: 5, alignSelf: 'center' }} /> */}
            {/* <Text style={{ color: primaryColour, fontSize: 12, marginTop: 0, textAlign: 'center' }}>{item.city} {item.state}</Text> */}
            <Text style={{ color: primaryColour, fontWeight: 'bold', marginBottom: 15, fontSize: 16, textAlign: 'center' }}>Rs. {item.price}</Text>

        </TouchableOpacity>

    );
}

