import React, { useEffect, useState, useContext, useRef } from 'react';
import { IoCall, IoChatbox, IoLogoFacebook, IoLogoInstagram, IoLogoWhatsapp, IoLogoYoutube, IoChatboxEllipsesSharp } from 'react-icons/io5';
import {
    StyleSheet,
    View,
    Text,
    TouchableOpacity,
    ScrollView,
    RefreshControl,
    ImageBackground,
    TextInput,
    ActivityIndicator,
    StatusBar,
    Linking
} from 'react-native';
import { useMediaQuery } from 'react-responsive'
import { HomeContext } from '../../../utils/contexts';
// import { FontAwesome } from '@expo/vector-icons';
import { borderColor, inputBackgound, primarybg, secondaryColor, primaryColour } from '../../../utils/string';
import Bag3 from '../bag';

// const Icon = FontAwesome;

export function isURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return pattern.test(str);
}

export default function Footer(props) {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    const isTablet = useMediaQuery({
        query: '(max-width: 768px)'
    })

    const { facebook, instagram, youtube, dukanName, dukanDescription, secondaryColor, dukanContactNumber } = props
    let data = props.data
    const source =
        'https://images.unsplash.com/photo-1481595357459-84468f6eeaac?dpr=1&auto=format&fit=crop&w=376&h=251&q=60&cs=tinysrgb';

    const { primaryColour, } = useContext(HomeContext);
    return (
        <>

            <div className="footer" style={{ marginTop: -95, backgroundColor: primaryColour, }}>
                <div className="container">
                    <div className="row">
                        <div className="footer-col-1" style={isTablet ? { textAlign: 'center' } : { textAlign: 'center' }}>
                            <h3 style={{ color: secondaryColor, marginTop: -30, }}>Download Our App</h3>
                            <p style={{ color: secondaryColor }}>& Make your online store for free</p>
                            <div className="app-logo">
                                <a href='https://play.google.com/store/apps/details?id=com.pakneed'><img src="https://i.ibb.co/KbPTYYQ/play-store.png" alt="" /></a>
                            </div>
                        </div>

                        <div className="footer-col-2" style={isDesktopOrLaptop ? { marginTop: -70, } : isTablet ? { justifyContent: 'center', alignItems: 'center', marginTop: 20, } : null}>
                            <h3 style={{ color: secondaryColor, marginTop: 3, }}>{dukanName}</h3>
                            <View style={isDesktopOrLaptop ? { textJustify: 'auto', color: secondaryColor, marginHorizontal: '5%', } : { margin: 20, textJustify: 'auto', color: secondaryColor, marginHorizontal: '20%', }}>

                                {dukanDescription}

                            </View>
                        </div>

                        <div className="footer-col-3">
                            <h3 style={{ marginTop: 5, color: secondaryColor }}>Contact us</h3>
                            <ul>
                                <li style={{ color: secondaryColor, marginTop: 10, marginBottom: 10, }}>

                                    {
                                        dukanContactNumber != 'false' && dukanContactNumber != '' ?
                                            <TouchableOpacity
                                                onPress={() => {
                                                    // Linking.openURL(
                                                    //     `sms:${data.mobile_number}${Platform.OS === 'ios' ? '&' : '?'
                                                    //     }body=${'PakNeed: \n'}`,
                                                    // );
                                                    Linking.openURL(
                                                        'https://api.whatsapp.com/send?phone=+92' + dukanContactNumber.substring(1) + '&text=' + dukanName + ':\n'
                                                    )
                                                }}
                                            >
                                                <View style={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                                    <IoLogoWhatsapp size={20} color={secondaryColor} solid="false" />
                                                </View>

                                            </TouchableOpacity>
                                            : null}

                                </li>
                                <li style={{ color: secondaryColor, marginTop: 10, marginBottom: 10, }}>

                                    {
                                        dukanContactNumber != 'false' && dukanContactNumber != '' ?

                                            <TouchableOpacity
                                                onPress={() => {
                                                    Linking.openURL(`tel:${dukanContactNumber}`);
                                                }}
                                            >

                                                <View style={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>

                                                    <IoCall size={20} color={secondaryColor} solid="false" />
                                                </View>

                                            </TouchableOpacity>

                                            : null}
                                </li>
                                <li style={{ color: secondaryColor, marginTop: 10, marginBottom: 10, }}>


                                    <TouchableOpacity
                                        onPress={() => {
                                            Linking.openURL('https://play.google.com/store/apps/details?id=com.pakneed')
                                        }}
                                    >
                                        <View style={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                            <IoChatboxEllipsesSharp size={18} color={secondaryColor} solid="false" />
                                        </View>

                                    </TouchableOpacity>


                                </li>





                            </ul>
                        </div>

                        <div className="footer-col-4">
                            <h3 style={{ marginTop: 7, color: secondaryColor }}>Follow us</h3>
                            <ul>
                                <li>
                                    {facebook != '' &&
                                        <TouchableOpacity
                                            style={{}}
                                            onPress={() => {
                                                isURL(facebook) && Linking.openURL(`${facebook}`);
                                            }}>

                                            <Text style={{ color: secondaryColor, }}> <IoLogoFacebook size={20} color={secondaryColor} solid="false" /></Text>


                                        </TouchableOpacity>}

                                </li>
                                <li style={{ marginTop: 7, marginBottom: 5, }}>
                                    {instagram != '' &&
                                        <TouchableOpacity
                                            style={{}}
                                            onPress={() => {
                                                isURL(instagram) && Linking.openURL(`${instagram}`);
                                            }}>

                                            <Text style={{ color: secondaryColor, }}> <IoLogoInstagram size={20} color={secondaryColor} solid="false" /></Text>

                                        </TouchableOpacity>}
                                </li>
                                <li style={{ marginTop: 7, marginBottom: 5, }}>
                                    {youtube != '' &&
                                        <TouchableOpacity
                                            style={{}}
                                            onPress={() => {
                                                isURL(youtube) && Linking.openURL(`${youtube}`);
                                            }}>

                                            <Text style={{ color: secondaryColor, }}><IoLogoYoutube size={20} color={secondaryColor} solid="false" /></Text>

                                        </TouchableOpacity>}

                                </li>

                            </ul>
                        </div>
                    </div>
                    <hr className="hr-1" />
                    <p className="copyright" style={{ color: secondaryColor, marginTop: 40, }}>Copyright &copy; 2022 - Bazars.pk Store</p>
                </div>
            </div>
        </>

    );
}
