import { StyleSheet, Text, TouchableOpacity, View, ScrollView, Image, FlatList } from "react-native";
import { primarybg, primaryColour, shadowColor } from "../utils/string";
import { useMediaQuery } from 'react-responsive'
import './assets/css/404.css'

export default function PageNotFound(props) {

    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const { visible, setvisible } = props

    return (

        <View>
            <section class="page_404">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 ">
                            <div class="col-sm-12 col-sm-offset-1 text-center mt-5">
                                <div class="four_zero_four_bg">
                                    <h1 class="text-center" style={{ color: 'dimgray', }}>404</h1>

                                </div>

                                <div class="contant_box_404">
                                    <h3 class="h2" style={{ color: 'dimgray', }}>
                                        Look like you're lost
                                    </h3>

                                    <p style={{ color: 'dimgray', }}>the page you are looking for not avaible!</p>

                                    <a href="/" class="link_404">Go to Home</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </View>

    );
}

