import * as React from 'react';
import { Text, View, Dimensions, ScrollView, FlatList, Image, TouchableOpacity } from 'react-native';
import { borderColor, primarybg, primaryLightColor, secondarybg, serverURL, subCategories, } from '../../../utils/string';
import { useMediaQuery } from 'react-responsive';

export default function DetailsScreen(props) {
    const {
        data, variations, selectedVariation, setselectedVariation, setselectedSize,
        selectedSize,
        selectedColor,
        setselectedColor, Footer, primaryColour } = props
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    return (
        <View style={isDesktopOrLaptop ? {
            // height: Dimensions.get('window').height - 60,
            flex: 1,
            //  alignItems: 'center', justifyContent: 'center',
            borderTopColor: 'lightgray',
            // borderTopWidth: 0.3,
            borderWidth: 0,
            // paddingVertical: 15,
            padding: 10,
            paddingTop: 20,
            backgroundColor: primarybg,
            borderRadius: 7
            , paddingLeft: 20
        } : {
            // flex: 1,
            //  alignItems: 'center', justifyContent: 'center',
            borderColor: borderColor,
            // borderTopWidth: 0.3,
            borderWidth: 0,
            // paddingVertical: 15,
            padding: 0,
            paddingTop: 0,
            backgroundColor: primarybg,
            // borderRadius: 7,

            // shadowColor: "#000",
            // shadowOffset: {
            //     width: 0,
            //     height: 2,
            // },
            // shadowOpacity: 0.23,
            // shadowRadius: 2.62,

            // elevation: 0,
            paddingLeft: 0,
            paddingBottom: 20
            // backgroundColor: 'red'
        }}>
            {variations.length > 0 ?
                <>
                    <Text
                        style={{
                            fontSize: 17,
                            color: primaryColour,
                            textAlign: 'left', letterSpacing: 1.5, fontWeight: '600'
                        }}>
                        Variations:
                    </Text>
                    <FlatList
                        horizontal
                        style={{ flex: 1, minHeight: 80 }}
                        data={variations}
                        contentContainerStyle={{
                            width: '100%', marginVertical: 10, marginLeft: 12
                        }}
                        showsHorizontalScrollIndicator={false}
                        renderItem={({ item, index }) => (
                            <TouchableOpacity
                                style={{
                                    borderWidth: selectedVariation == index ? 3 : 0,
                                    width: 70, height: 65, backgroundColor: primaryColour,
                                    marginHorizontal: 10, borderRadius: 5,
                                    alignItems: 'center', borderColor: secondarybg
                                }}
                                onPress={() => { setselectedVariation(index) }}
                            >
                                <Image
                                    loadingStyle={{ size: 'large', color: primaryColour }}
                                    source={{ uri: serverURL + item.images[0] }}
                                    style={{ width: selectedVariation == index ? 65 : 70, height: 40, resizeMode: 'cover', borderRadius: 5, }}
                                    imageStyle={{ borderRadius: 5 }}
                                />
                                <Text style={{ textAlign: 'center', color: primarybg }}>{item.name.length > 15 ? item.name.substring(0, 15) : item.name}</Text>
                            </TouchableOpacity>
                        )}
                    />
                </>
                : null
            }


            {typeof data.sizes != 'undefined' && data.sizes.length > 0 ?
                <>
                    <Text
                        style={{

                            fontSize: 17,
                            color: primaryColour,
                            textAlign: 'left'
                            , letterSpacing: 1.5, fontWeight: '600'
                        }}>
                        Sizes:
                    </Text>
                    <FlatList
                        horizontal
                        // style={{ flex: 1, height: 80 }}
                        data={data.sizes}
                        contentContainerStyle={{
                            width: '100%', marginVertical: 10, marginLeft: 12
                        }}
                        showsHorizontalScrollIndicator={false}
                        renderItem={({ item, index }) => (
                            <TouchableOpacity
                                style={selectedSize == index ?
                                    {
                                        height: 30,
                                        borderWidth: 0.2, marginHorizontal: 4,
                                        borderRadius: 5, paddingHorizontal: 10, borderColor: primaryColour,
                                        backgroundColor: primaryColour, justifyContent: 'center', alignItems: 'center', paddingBottom: 2.5
                                    } :
                                    {
                                        borderWidth: 0.2, marginHorizontal: 4,
                                        borderRadius: 5, paddingHorizontal: 10, paddingVertical: 5, borderColor: primaryColour
                                        , justifyContent: 'center', alignItems: 'center', paddingBottom: 5, height: 30,
                                    }
                                }
                                onPress={() => { setselectedSize(index) }}
                            >
                                <Text style={{ textAlign: 'center', color: selectedSize == index ? primarybg : primaryColour }}>{item.size}</Text>
                            </TouchableOpacity>
                        )}
                    />
                </>
                : null}
            {typeof data.colors != 'undefined' && data.colors.length > 0 ?
                <>
                    <Text
                        style={{

                            fontSize: 17,
                            color: primaryColour,
                            textAlign: 'left'
                            , letterSpacing: 1.5, fontWeight: '600'
                        }}>
                        Colors:
                    </Text>
                    <FlatList
                        horizontal
                        // style={{ flex: 1 }}
                        data={data.colors}
                        contentContainerStyle={{
                            width: '100%', marginVertical: 10, marginLeft: 12
                        }}
                        showsHorizontalScrollIndicator={false}
                        renderItem={({ item, index }) => (
                            <TouchableOpacity
                                style={{
                                    borderWidth: selectedColor == index ? 3 : 1,
                                    width: 35, height: 35, backgroundColor: item.color,
                                    marginHorizontal: 10, borderRadius: 5,
                                    alignItems: 'center', borderColor: selectedColor == index ? secondarybg : borderColor
                                }}
                                onPress={() => { setselectedColor(index) }}
                            >

                            </TouchableOpacity>
                        )}
                    />
                </>
                : null}
            <Footer />
            <Text
                style={{

                    fontSize: 15,
                    color: primaryColour,
                    marginTop: 10,
                    textAlign: 'left'
                    , letterSpacing: 1.5, fontWeight: '600'
                }}>
                Details:
            </Text>
            {variations.length > 0 && variations[selectedVariation].custom_feilds.map((item) => (
                <View style={{ flex: 1, alignSelf: 'stretch', flexDirection: 'row', borderBottomWidth: 0.17, borderColor: '#ede9e4', paddingVertical: 5, marginLeft: 12 }}>
                    <View style={{ flex: 1, alignSelf: 'stretch' }} >
                        <Text style={{ fontSize: 14, color: primaryColour, textTransform: 'capitalize' }}>{item.name}</Text>
                    </View>
                    <View style={{ flex: 1, alignSelf: 'stretch' }} >
                        <Text style={{ color: primaryLightColor, fontSize: 12, textTransform: 'capitalize' }}>{item.detail}</Text>
                    </View>
                </View>))
            }
            {
                typeof data.sub_category !== 'undefined' ?

                    [
                        { title: 'Price', value: variations.length > 0 ? "Rs. " + variations[selectedVariation].price : "Rs. " + data.price },
                        { title: 'Condition', value: data.condition === '1' ? 'New' : 'Used' },
                        { title: 'Shipping', value: 'Rs.' + data.shipping },
                        { title: 'Quantity', value: data.quantity + ' item' },
                    ].map((item) => {
                        return (
                            <>
                                {item.title == 'Condition' && item.value == 'New' ?
                                    <>{console.log(item.title, data.condition)}</> :
                                    <View style={{ flex: 1, alignSelf: 'stretch', flexDirection: 'row', borderBottomWidth: 0, borderColor: '#f0ede9', paddingVertical: 5 }}>
                                        <View style={{ flex: 1, alignSelf: 'stretch' }} >
                                            <Text style={{ fontSize: 14, color: primaryColour, textAlign: 'left', letterSpacing: 1.5 }}>{item.title}</Text>
                                        </View>
                                        <View style={{ flex: 1, alignSelf: 'stretch' }} >
                                            <Text style={{ color: primaryLightColor, fontSize: 12, textAlign: 'left', letterSpacing: 1.5 }}>{item.value}</Text>
                                        </View>
                                    </View>
                                }
                            </>
                        );
                    })
                    : null
            }

            <Text
                style={{
                    fontWeight: 'bold',
                    fontSize: 15,
                    color: primaryColour,
                    marginTop: 10,
                    textAlign: 'left'
                    , letterSpacing: 1.5, fontWeight: '600'
                }}>
                Description:
            </Text>
            <Text style={{ fontSize: 15, color: primaryLightColor, textTransform: 'none', textAlign: 'left' }}>
                {data.description}
            </Text>

        </View>
    );
}