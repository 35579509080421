import { StyleSheet, Text, TouchableOpacity, View, ScrollView, Image, FlatList, Dimensions, ImageBackground } from "react-native";
import { defaultLink, primarybg, primaryColour, serverURL, shadowColor, secondaryColor } from "../../../utils/string";
import { useMediaQuery } from 'react-responsive'
import { LoadingIndicator, RoundButton } from "../../../utils/utils";
// import FontAwesomeIcon from 'react-native-vector-icons/dist/FontAwesome5';
import ImageLoad from "../../../utils/imageLoad";
import Tab from "../tab";
import { useEffect, useState } from "react";
import { getPrimaryColor, placedOrders } from "../../../utils/api";
import { useNavigate, useParams } from "react-router-dom";
import '../templateUtils/loader.css'
import Footer from '../home/footer'
import { HomeContext } from "../../../utils/contexts";
// import HomeTabs from './pages/homeTabs';
import { FaShoppingBag } from "react-icons/fa";
// let Icon = FontAwesomeIcon;

function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export default function Orders3(props) {
    const navigate = useNavigate();
    const { dukanId } = useParams();
    const { primaryColour, data, facebook, instagram, youtube, dukanName, dukanDescription, dukanContactNumber, secondaryColor } = props

    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const { visible, setvisible } = props

    // const { dukanId } = props.route.params

    const [messages, setmessages] = useState([]);
    const [orders, setorders] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const [pageLoaded, setpageLoaded] = useState(false);

    useEffect(() => {
        if (primaryColour != '#5893D4aa') {
            setpageLoaded(true)
        }
    }, [primaryColour])

    const month = [
        'Jan',
        'Fab',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    useEffect(() => {
        placedOrders(setorders, setisLoading, navigate, setmessages)
    }, [])

    return (
        <>
            <HomeContext.Provider
                value={{
                    primaryColour
                }}
            >
                {!pageLoaded ?
                    <View style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: Dimensions.get('window').height }}>
                        <div class="loadingio-spinner-chunk-nx0mjvmz269"><div class="ldio-q1sjvrz6fl">
                            <div><div><div></div><div></div><div></div><div></div></div></div>
                        </div></div>
                    </View>
                    :
                    <Tab primaryColour={primaryColour} dukanName={dukanName} data={data} dukanContactNumber={dukanContactNumber} secondaryColor={secondaryColor}>
                        <View>
                            <View style={isDesktopOrLaptop ? { marginTop: 20, } : { marginTop: 50, }}>

                                <ImageBackground source='https://images.unsplash.com/photo-1591085686350-798c0f9faa7f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1031&q=80'


                                    // {serverURL + item.image}

                                    imageStyle={

                                        isDesktopOrLaptop ? {
                                            width: '100%',
                                            height: 200,
                                            overflow: 'hidden',
                                            width: '100%',

                                        } : {

                                            width: '100%',
                                            height: 200,
                                            overflow: 'hidden',
                                            justifyContent: "center",
                                            width: '100%',

                                        }
                                    }>

                                    <div class="jumbotron" style={{ borderRadius: 0, backgroundColor: 'rgb(0,0,0,0.9)', height: 200, }}>

                                        <p style={isDesktopOrLaptop ? { color: secondaryColor, marginTop: -15, } : { color: secondaryColor, marginTop: 10, }}>Home / Products / Orders</p>

                                        <h1 style={{ fontFamily: 'sans-serif', justifyContent: 'center', textAlign: 'center', color: secondaryColor, }}>Placed Orders</h1>
                                        <p style={{ fontFamily: 'sans-serif', justifyContent: 'center', textAlign: 'center', color: secondaryColor, }}>Orders History</p>
                                    </div>

                                </ImageBackground>
                            </View>

                            {
                                orders.length > 0 ?

                                    <View style={[{
                                        flexDirection: 'row', marginVertical: 20,
                                        elevation: 4,
                                        marginHorizontal: "5%",

                                    },
                                    !isDesktopOrLaptop ?
                                        { flexDirection: 'column', marginHorizontal: '1%', paddingHorizontal: '1%', marginVertical: 3, borderWidth: 0 } : {}
                                    ]}>
                                        {/* <Text
                        style={{
                            fontWeight: 'bold',
                            fontSize: 15,
                            color: primaryColour,
                            marginTop: 10,
                            textAlign: 'left',
    
                        }}>
                        Account No:
                    </Text> */}
                                        {/* <Text style={{ textAlign: 'left', color: primaryColour }}></Text> */}

                                        <View style={isDesktopOrLaptop ? { flex: 0.70, } : { flex: 1, }}>

                                            <View
                                                style={[{
                                                    flexDirection: 'column', marginVertical: 30,
                                                    marginHorizontal: '5%',
                                                    paddingTop: 20,
                                                    elevation: 4,
                                                },
                                                !isDesktopOrLaptop ?
                                                    { marginHorizontal: '1%', paddingHorizontal: '1%', marginVertical: 3, shadowColor: primarybg, borderWidth: 0 } : {}
                                                ]}>

                                                {!isLoading ?
                                                    <FlatList
                                                        contentContainerStyle={{ justifyContent: 'center', paddingBottom: 20 }}
                                                        data={orders}
                                                        renderItem={({ item }) => (

                                                            <TouchableOpacity style={{
                                                                marginVertical: 10,
                                                                elevation: 3,
                                                                width: '100%',
                                                                flexDirection: 'row',
                                                                flexWrap: 'wrap',
                                                                backgroundColor: '#f4f4f4', elevation: 2, marginVertical: 10, height: 120,


                                                            }}
                                                                onPress={() => {
                                                                    navigate('/' + window.location.pathname.substring(1).split('/')[0] + '/order-details/' + item.id)
                                                                }}
                                                            >
                                                                <ImageLoad
                                                                    style={[{
                                                                        // marginTop: 20,
                                                                        flex: 0.2,
                                                                        width: '100%',
                                                                        // borderBottomLeftRadius: 0,
                                                                        // borderBottomRightRadius: 0,
                                                                        // backgroundColor: '#f0f0f0'
                                                                    }, !isDesktopOrLaptop ? { flex: 0.4, height: 120, } : null]}
                                                                    imageStyle={{
                                                                        // flex: 1,
                                                                        width: '100%',
                                                                        borderWidth: 1,
                                                                        borderColor: 'lightgray'
                                                                        // borderBottomLeftRadius: 0,
                                                                        // borderBottomRightRadius: 0,
                                                                    }}
                                                                    resizeMode="cover"
                                                                    source={serverURL + item.thumbnail}
                                                                />
                                                                <View style={[{ flex: 0.85, marginLeft: 25 }, !isDesktopOrLaptop ? { marginLeft: 5, } : null]}>
                                                                    <View style={{ flexDirection: 'row' }}>
                                                                        <Text style={[{ textAlign: 'left', flex: 1, flexShrink: 1, position: 'relative', top: 38, marginLeft: 10, textAlign: 'left', fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: 25, color: 'gray' }, !isDesktopOrLaptop ? { fontSize: 20, top: 20, } : null]}>

                                                                            {item.title.length > 17 ? String(item.title.substring(0, 17) + '...').replace(/\n/g, " ") : String(item.title.replace(/\n/g, " "))}

                                                                        </Text>


                                                                        {isDesktopOrLaptop ?

                                                                            <View style={[{ textAlign: 'right', flex: 1, marginRight: 50, }, !isDesktopOrLaptop ? { marginRight: 10, } : null]}>

                                                                                <Text style={[{ position: 'relative', marginLeft: 15, top: 30, fontFamily: 'sans-serif', fontSize: 14, color: 'gray' }, !isDesktopOrLaptop ? { fontSize: 9, top: 10, } : null]}>#Tracking_Order ({item.order_number})</Text>
                                                                                <Text style={[{ position: 'relative', top: 30, fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: 35, color: primaryColour }, !isDesktopOrLaptop ? { fontSize: 12, } : null]}>Rs. {item.price}</Text>
                                                                                <Text style={[{ position: 'relative', marginLeft: 15, top: 30, fontFamily: 'sans-serif', fontSize: 14, color: 'gray' }, !isDesktopOrLaptop ? { fontSize: 9, top: 60, } : null]}>#Date ({item.date})</Text>


                                                                                {/* <Text style={{ textAlign: 'left', flex: 0.6, position: 'relative', marginLeft: 15, fontFamily: 'sans-serif', fontSize: 14, color: 'gray' }}>
                                                        {item.date}
                                                        {formatAMPM(item.date)}
                        {typeof item.date !== 'undefined' ? item.date.split('-')[2].split('T')[0] : null}{' '}
                        {typeof item.date !== 'undefined' ? month[parseInt(item.date.split('-')[1], 10) - 1] : null}{' '}
                        {typeof item.date !== 'undefined' ? item.date.split('-')[2].split('T')[1].split(':')[0] : null}:
                        {typeof item.date !== 'undefined' ? item.date.split('-')[2].split('T')[1].split(':')[1] : null}
                                                    </Text> */}

                                                                            </View>
                                                                            : null}
                                                                    </View>

                                                                    <View style={isDesktopOrLaptop ? { flexDirection: 'row' } : { flexDirection: 'column' }}>

                                                                        {!isDesktopOrLaptop ?

                                                                            <View style={[{ textAlign: 'left', flex: 1, marginRight: 50, }, !isDesktopOrLaptop ? { marginRight: 10, } : null]}>

                                                                                <Text style={[{ textAlign: 'left', flex: 1, position: 'relative', marginLeft: 15, fontFamily: 'sans-serif', fontSize: 14, color: 'gray' }, !isDesktopOrLaptop ? { top: 30, fontSize: 15, } : null]}>Price : {item.price}</Text>
                                                                                <Text style={[{ textAlign: 'left', flex: 1, position: 'relative', marginLeft: 15, fontFamily: 'sans-serif', fontSize: 14, color: 'gray' }, !isDesktopOrLaptop ? { top: 40, fontSize: 10, } : null]}>#Placed Date : {item.date}</Text>


                                                                            </View>
                                                                            : null}

                                                                        <Text style={[{ textAlign: 'left', flex: 0.6, position: 'relative', marginLeft: 15, fontFamily: 'sans-serif', fontSize: 14, color: 'orange' }, !isDesktopOrLaptop ? { top: 45, fontSize: 11, } : null]}>Status : {item.status}</Text>

                                                                        {/* <Text style={{ textAlign: 'left', flex: 0.8, color: primaryColour }}>Order # {item.number}</Text> */}
                                                                    </View>

                                                                </View>

                                                            </TouchableOpacity>
                                                        )} />
                                                    : <LoadingIndicator />}
                                            </View>

                                        </View>
                                        {!isLoading ?
                                            <View style={isDesktopOrLaptop ? { flex: 0.30 } : { paddingHorizontal: "2%", }}>

                                                <View style={[{ marginTop: 60, marginLeft: 50, paddingVertical: 46, backgroundColor: '#f4f4f4', }, !isDesktopOrLaptop ? { marginLeft: 0, marginTop: 0, marginBottom: 100, } : null]}>
                                                    <View style={{ elevation: 1, paddingHorizontal: 20, paddingVertical: 5, width: '80%', alignSelf: 'center' }}>

                                                        <View style={{ flexDirection: 'row', justifyContent: 'center', marginBottom: 30, }}>
                                                            <Text style={{ flex: 1, fontWeight: 'bold', color: 'gray', fontSize: 17, textAlign: 'center' }}>Total Orders History</Text>
                                                        </View>
                                                        <hr />
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <Text style={{ flex: 0.5, fontWeight: 'bold', color: 'gray' }}>Pending:</Text>
                                                            <Text style={{ flex: 0.5, textAlign: 'right', color: 'gray' }}>Items.
                                                                {orders.length}
                                                            </Text>
                                                        </View>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <Text style={{ flex: 0.5, fontWeight: 'bold', color: 'gray' }}>Shipped:</Text>
                                                            <Text style={{ flex: 0.5, textAlign: 'right', color: 'gray' }}>Items.
                                                                {orders.length}
                                                            </Text>
                                                        </View>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <Text style={{ flex: 0.5, fontWeight: 'bold', color: 'gray' }}>Delivered:</Text>
                                                            <Text style={{ flex: 0.5, textAlign: 'right', color: 'gray' }}>Items.
                                                                {orders.length}
                                                            </Text>
                                                        </View>
                                                        <hr />
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <Text style={{ flex: 0.5, fontWeight: 'bold', color: 'gray' }}>Total:</Text>
                                                            <Text style={{ flex: 0.5, textAlign: 'right', color: 'gray' }}>Items.
                                                                {orders.length}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                                        <TouchableOpacity
                                                            style={{ marginTop: 50, borderRadius: 100, backgroundColor: primaryColour, height: 50, paddingVertical: 14, paddingHorizontal: 30, }}
                                                            onPress={() => {
                                                                defaultLink == '' ?
                                                                    navigate('/' + dukanId + '/bag')
                                                                    : navigate('/bag')
                                                            }}
                                                        >

                                                            <Text style={{ color: secondaryColor, }}>Go to Bag</Text>
                                                        </TouchableOpacity>
                                                    </View>
                                                </View>

                                            </View>
                                            : <LoadingIndicator />}
                                    </View>
                                    :
                                    <View style={{ marginTop: 70, }}>
                                        <View style={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center', opacity: 0.7 }}>

                                            <FaShoppingBag size={150} solid="true" />

                                        </View>
                                        <View style={{ justifyContent: 'center', }}>
                                            <Text style={{ color: primaryColour, textAlign: 'center', fontFamily: 'sans-serif', fontSize: 20, }}>Your did'nt placed any order yet</Text>
                                            <Text style={{ color: 'gray', textAlign: 'center', fontSize: 15, }}>
                                                Looks like you haven’t made your choice yet.</Text>
                                        </View>
                                    </View>
                            }

                            <View style={{ marginTop: 200, }}>
                                <Footer dukanId={dukanId} dukanContactNumber={dukanContactNumber} secondaryColor={secondaryColor} dukanName={dukanName} dukanDescription={dukanDescription} data={data} facebook={facebook} instagram={instagram} youtube={youtube} />
                            </View>
                        </View>


                    </Tab>

                }
            </HomeContext.Provider>
        </>
    );
}

