import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View, ScrollView, FlatList, Dimensions, ActivityIndicator, RefreshControl } from "react-native";
import { defaultLink, primarybg, serverURL } from "../../../utils/string";
import { HomeContext } from "../../../utils/contexts";
import { getData, LoadingIndicator, storeData } from "../../../utils/utils";
import { useMediaQuery } from 'react-responsive';
import Product from '../templateUtils/product';
// import { FontAwesome } from '@expo/vector-icons';
import { useNavigate, useParams } from 'react-router-dom';
// import { useRouting } from 'expo-next-react-navigation';
// import { useRouter } from 'next/router'

const Icon = () => (<></>);
// import HomeTabs from './pages/homeTabs';


const { height, width } = Dimensions.get('window');

export default function Products() {
    // const router = useRouter()
    const navigate = useNavigate();
    const { dukanId } = useParams();
    // const { navigate, push, getParam, goBack } = useRouting()
    const [data, setdata] = useState([1, 2, 3, 5, 8, 8]);
    const { products, productsLoading, primaryColour, secondaryColor } = useContext(HomeContext);
    const [dukanLink, setdukanLink] = React.useState('');
    const [secureToken, setsecureToken] = useState('');

    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1050px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const [numCol, setnumCol] = useState(2);
    const [loaded, setloaded] = useState(false);

    // city: "Adezai"
    // cover: "/media/product_thumbnail/thumbnail_69712b687e704f1e8de7a595ee10a63b.jpg"
    // id: 63
    // is_owner_of_product: 0
    // liked: 0
    // price: "25500"
    // state: "Punjab"
    // title: "Text you copy will automatically show hereText you copy will automatic"
    // user_id: 2

    useEffect(() => {
        getData('dukanLink', setdukanLink)
        if (dukanLink != window.location.pathname.split('/')[1]) {
            storeData(window.location.pathname.split('/')[1], 'dukanLink')
        }
        getData('secure_token', setsecureToken);
        // if (isDesktopOrLaptop && numCol != 4) {
        //     setnumCol(4)
        // }
        setloaded(true)
    }, [])



    return (
        <>
            {/* <Text style={[
                { marginHorizontal: 30, marginVertical: 0, fontWeight: 'bold', fontSize: 19, color: primaryColour },
                isDesktopOrLaptop ? { textAlign: 'center', marginHorizontal: 30, marginVertical: 40 } :
                    { textAlign: 'center', fontSize: 18, marginHorizontal: 10, fontWeight: '500', letterSpacing: 1.5, marginBottom: 13, marginTop: 10 }
            ]}>Latest Items</Text> */}
            <div class="two alt-two" style={{ marginTop: 10, marginBottom: 20 }}>
                <h1 style={{ color: primaryColour, fontSize: !isDesktopOrLaptop && 27, "--bg-color": primaryColour }}>Latest
                    <span style={{ color: primaryColour + 80, fontSize: !isDesktopOrLaptop && 9 }}>LATEST ON SALE</span>
                </h1>
            </div>
            {!productsLoading ?
                <FlatList
                    contentContainerStyle={{ marginTop: 0, justifyContent: 'center', paddingBottom: 20 }}
                    data={products}

                    ListFooterComponent={() => {
                        return (
                            <>
                                {
                                    products.length > 9 ?
                                        <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
                                            onPress={() => {
                                                if (defaultLink != '') {
                                                    navigate('/AllProducts/')
                                                } else {
                                                    navigate('/' + dukanId + '/AllProducts/')
                                                }
                                            }}
                                        >
                                            <Text style={{ color: primaryColour, }}>See All</Text>
                                            <Icon name="arrow-right" size={25} color={primaryColour} style={{ marginHorizontal: 20 }} />
                                        </TouchableOpacity>
                                        : null
                                }
                            </>
                        )
                    }}
                    ListEmptyComponent={() => {
                        return (
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <Text style={{ color: 'red', fontSize: 19 }}>No products</Text>
                                {/* <Icon name="arrow-right" size={25} color={primaryColour} style={{ marginHorizontal: 20 }} /> */}
                            </View>
                        )
                    }}
                    numColumns={isDesktopOrLaptop ? 5 : numCol}
                    columnWrapperStyle={[{
                        flex: 1,
                        backgroundColor: primarybg,

                    },
                    isDesktopOrLaptop ? { marginHorizontal: '1.3%', justifyContent: 'center' } : { marginHorizontal: 3 }
                    ]}
                    renderItem={({ item }) => (
                        <Product item={item} primaryColour={primaryColour} />
                    )}
                    keyExtractor={item => item.id}
                />
                :
                <LoadingIndicator />
            }
        </>
    );
}

