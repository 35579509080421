import React, { useEffect, useState, useContext } from 'react';
import { FaBars, FaGamepad, FaHamburger } from 'react-icons/fa';
import {
    StyleSheet,
    View,
    Text,
    TouchableOpacity,
    ScrollView,
    Image,
    Dimensions,
    FlatList,
    RefreshControl,
    ActivityIndicator,
    Animated
} from 'react-native';
import ImageLoad from '../../../utils/imageLoad';
import {
    inputBackgound,
    primarybg,
    primaryColour,
} from '../../../utils/string';
import Sidebar from '../../sidebar';
import { shadow } from '../../utils/main';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

let Icon = () => (<></>)

const dimensions = Dimensions.get('window');

const otherCategories = [
    { id: 8, title: 'Cell Phones & Accessories', image: require('../../../assets/img/categories/cell_phone.png'), icon: false },
    { id: 4, title: 'Vehicles', image: require('../../../assets/img/categories/vehicles.png'), icon: false },
    { id: 2, title: 'Property For Sale', image: require('../../../assets/img/categories/property_for_sale.png'), icon: false },
    { id: 10, title: 'Property For Rent', image: require('../../../assets/img/categories/property_for_rent.png'), icon: false },
    { id: 1, title: 'Electronics & Home appliances', image: require('../../../assets/img/categories/home_appliance.png'), icon: false },
    { id: 11, title: 'Bikes', image: require('../../../assets/img/categories/bikes.png'), icon: false },
    { id: 12, title: 'Speciality Services', image: require('../../../assets/img/categories/services.png'), icon: false },
    { id: 3, title: 'Food & Fruits', image: () => (<FaHamburger color={primaryColour} size={90} />), icon: true },
    // { id: 13, title: 'Jobs', image: require('../assets/img/categories/jobs.png'), icon: false },
    { id: 5, title: 'Pet Supplies', image: require('../../../assets/img/categories/animals.png') },
    { id: 14, title: 'Furniture & Home Decoration', image: require('../../../assets/img/categories/furniture.png'), icon: false },
    { id: 6, title: 'Clothing, Shoes & Accessories', image: require('../../../assets/img/categories/fashion.jpg'), icon: false },
    { id: 15, title: 'Kids', image: require('../../../assets/img/categories/kids.png'), icon: false },
    { id: 16, title: 'Books & Hobbies', image: require('../../../assets/img/categories/books.png'), icon: false },
    { id: 17, title: 'Health & Beauty', image: require('../../../assets/img/categories/health.png'), icon: false },
    { id: 18, title: 'Jewelery & Watches', image: require('../../../assets/img/categories/watch.png'), icon: false },
    { id: 19, title: 'Art', image: require('../../../assets/img/categories/art.png'), icon: false },
    { id: 20, title: 'Video Games & Consoles', image: () => (<FaGamepad color={primaryColour} size={90} />), icon: true },
    { id: 7, title: 'Everything Else', image: () => (<FaBars color={primaryColour} size={90} />), icon: true },
];






export default function SelectProductCategory(props) {
    const [userCategories, setuserCategories] = useState([1, 2, 3]);

    const [createCategoryModel, setcreateCategoryModel] = useState(false);
    const [newCategoryName, setnewCategoryName] = useState('');
    const [dukanCategoriesLoading, setdukanCategoriesLoading] = useState();
    const [dukanCategoriesList, setdukanCategoriesList] = useState([]);
    const [dukanCategoriesKey, setdukanCategoriesKey] = useState(Math.random());
    const navigate = useNavigate();

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })


    return (
        <>
            <Sidebar active='myItems' notScrollView={true} title={'Select item category:'}>
                <ScrollView style={{ backgroundColor: primarybg, height: '100%', paddingTop: 0 }}
                    nestedScrollEnabled={true}
                >

                    <View style={{ paddingTop: 0, width: !isDesktopOrLaptop && dimensions.width, }}>
                        <FlatList
                            // horizontal={true}
                            // numColumns={4}
                            data={otherCategories}
                            persistentScrollbar={true}
                            indicatorStyle={{ color: 'yellow' }}
                            keyExtractor={(item, id) => {
                                return (id)
                            }}
                            contentContainerStyle={{
                                paddingRight: 30,
                                paddingLeft: 10,
                                paddingVertical: 3,
                                backgroundColor: primarybg,
                                paddingBottom: 40,
                            }}
                            columnWrapperStyle={{ justifyContent: 'space-between', marginLeft: !isDesktopOrLaptop ? '6%' : 0 }}
                            showsVerticalScrollIndicator={false}
                            showsHorizontalScrollIndicator={false}
                            numColumns={isDesktopOrLaptop ? 3 : 2}
                            renderItem={({ item }) => (
                                <TouchableOpacity style={[{
                                    width: isDesktopOrLaptop ? '30%' : '48%',
                                    //  marginHorizontal: 5,
                                    borderBottomWidth: 0,
                                    borderColor: '#f0f0f0',
                                    marginBottom: 20,
                                    elevation: 12,
                                    backgroundColor: primarybg,
                                    borderRadius: 11,
                                }, shadow]}
                                    onPress={async () => {
                                        console.log('idddd', item)
                                        // 7 is id of everything else category
                                        if (item.id === 7) {
                                            props.navigation.navigate('AddProductForm', { category: '' + item.id, subCategory: '', userCategory: false, userCategoryId: '' });
                                        } else {
                                            navigate('/select-subcategory/' + item.id)
                                        }


                                    }}
                                >
                                    {!item.icon ?
                                        <Image
                                            onPress={() => {
                                                if (item.id === 7) {
                                                    props.navigation.navigate('AddProductForm', { category: '' + item.id, subCategory: '', userCategory: false, userCategoryId: '' });
                                                } else {
                                                    props.navigation.navigate('SelectProductSubCategory', { category: item.id })
                                                }
                                            }}
                                            style={{
                                                // marginTop: 20,
                                                width: '100%',
                                                height: 130,


                                                // borderBottomLeftRadius: 0,
                                                // borderBottomRightRadius: 0,
                                                // backgroundColor: '#f0f0f0'
                                            }}
                                            imageStyle={{
                                                // flex: 1,
                                                width: '100%',
                                                height: 130,
                                                borderRadius: 1,
                                                // borderBottomLeftRadius: 0,
                                                // borderBottomRightRadius: 0,
                                            }}
                                            resizeMode="contain"
                                            source={
                                                item.image
                                            }
                                        />
                                        :
                                        <View
                                            style={{
                                                // marginTop: 20,
                                                width: '100%',
                                                height: 130,
                                                borderRadius: 7,
                                                // borderBottomLeftRadius: 0,
                                                // borderBottomRightRadius: 0,
                                                // backgroundColor: '#f0f0f0',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <item.image />
                                            {/* <Icon
                                                name={}
                                                type="font-awesome-5"
                                                color={primaryColour}
                                                size={90}
                                                style={{ marginTop: 2, alignSelf: 'center' }}
                                            /> */}
                                        </View>
                                    }
                                    <Text style={{ textAlign: 'center', marginVertical: 5, width: '100%', color: primaryColour }}>{item.title}</Text>
                                </TouchableOpacity>
                            )
                            }
                        />
                    </View>
                </ScrollView>
            </Sidebar>
        </>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: primarybg,
        flexDirection: 'column',
    },
    mediaImages: {
        width: dimensions.width / 3 - 77,
        height: dimensions.width / 3 - 77,
        borderRadius: 10,
        marginRight: 3,
        marginBottom: 3,
    },
    mediaImages2: {
        width: '100%',
        height: '70%',
        borderRadius: 20,
    },
    inputs: {
        // borderColor: primarybg, shadowColor:'black',elevation: 1, borderWidth: 5, borderRadius: 20,
        backgroundColor: inputBackgound,
        alignItems: 'center',
        justifyContent: 'center',
        margin: 12,
        padding: 7,
        paddingHorizontal: 10,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        borderWidth: 0.15,
        shadowColor: 'gray',
        elevation: 1,
        alignContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        borderColor: 'lightgray',
        borderBottomWidth: 0,
        color: primaryColour
        // borderTopWidth: 1,
    }
});
