import { StyleSheet, Text, TouchableOpacity, View, ScrollView, Image, FlatList, ImageBackground, Dimensions, TextInput, Platform, ActivityIndicator, Keyboard, } from "react-native";
import { primarybg, shadowColor, primaryColour, textColor } from "../../../utils/string";
import { useMediaQuery } from 'react-responsive'
import PerfectModal from "../../../utils/perfectModel";
import { getData, RoundButton } from "../../../utils/utils";
import { useEffect, useState } from "react";
import OTPVerification from './OTPVerification'
import { firebase, auth } from '../../../utils/firebase';

const Icon = () => (<></>);

export default function Login(props) {

    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const { visible, setvisible } = props
    const [contactNo, setContactNo] = useState('');
    const [privacyCheckBox, setprivacyCheckBox] = useState(true);
    const [error, setError] = useState('');
    const [otpVerification, setotpVerification] = useState(false);
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [final, setfinal] = useState('');
    const [recaptcha, setrecaptcha] = useState();
    const { primaryColour, secondaryColor } = props

    // Sent OTP
    const signin = () => {
        setisLoading(true)
        if (/^((?:00|\+)92)?(0?3(?:[0-46]\d|55)\d{7})$/.test(contactNo)) {

            if (!recaptcha) {
                const verifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                    size: "invisible",
                });
                setrecaptcha(verifier)
                auth.signInWithPhoneNumber('+92' + contactNo, verifier).then((result) => {
                    setfinal(result);
                    setotpVerification(true);
                    setvisible(false)
                    setisLoading(false)
                }).catch((err) => {
                    setError(err)
                });
            } else {
                auth.signInWithPhoneNumber('+92' + contactNo, recaptcha).then((result) => {
                    setfinal(result);
                    setotpVerification(true);
                    setvisible(false)
                    setisLoading(false)
                }).catch((err) => {
                    setError(err)
                });
            }
        } else {
            setError('Invalid phone number')
        }
    }

    // useEffect(() => {
    //     const verifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
    //         size: "invisible",
    //     });
    //     setrecaptcha(verifier)
    //     // if (!recaptcha) {
    //     //     verifier.verify().then(() => setrecaptcha(verifier));
    //     // }
    //     // return () => {
    //     //     verifier.clear()
    //     // }
    // });
    return (
        <>
            <OTPVerification visible={otpVerification} setvisible={setotpVerification} contactNo={contactNo} final={final} primaryColour={primaryColour} secondaryColor={secondaryColor} />
            <PerfectModal
                modalVisible={visible}
                onRequestClose={() => {
                    setvisible(false)
                }}


                // height={isDesktopOrLaptop ? '80%' : Dimensions.get('window').height - 30}
                style={[isDesktopOrLaptop ? { width: '60%', height: 570, margin: 0, padding: 0, } :
                    { width: '95%', height: 500, margin: 0, padding: 0, borderColor: "lightgray", borderWidth: 0.5, }
                    // { width: '100%', margin: 0, padding: 0, height: Dimensions.get('window').height - 30, borderColor: primarybg, borderWidth: 2, borderRadius: 20 }
                ]}
            >

                <View style={isDesktopOrLaptop ? { flexDirection: 'row', } : { flexDirection: 'column', }}>
                    <View style={isDesktopOrLaptop ? { flex: 0.5, } : { flex: 0.5 }}>

                        <ImageBackground source='https://images.unsplash.com/photo-1630626988793-c06b32c346a3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=480&q=80'


                            // {serverURL + item.image}

                            imageStyle={

                                isDesktopOrLaptop ? {
                                    width: '100%',
                                    height: '100%',
                                    overflow: 'hidden',
                                    justifyContent: "left",
                                    width: '100%',

                                } : {

                                    width: '100%',
                                    height: '100%',
                                    overflow: 'hidden',
                                    justifyContent: "center",
                                    width: '100%',
                                    borderBottomLeftRadius: 30,
                                    borderBottomRightRadius: 30,

                                }
                            }>

                            <View style={isDesktopOrLaptop ? { height: 570, background: 'rgb(0,0,0,0.8)' } : { height: 150, background: 'rgb(0,0,0,0.8)', borderBottomLeftRadius: 30, borderBottomRightRadius: 30, }}>

                                {!isDesktopOrLaptop ?

                                    <TouchableOpacity style={{ color: textColor, marginLeft: 5, }}
                                        onPress={() => setvisible(false)}
                                    >
                                        {/* <Text style={{ textAlign: 'center', color: bgWhite }}><TabIcon
                                            name="close" size={20} color={bgWhite}
                                        /></Text> */}
                                        close
                                    </TouchableOpacity>

                                    : null}

                                <View style={isDesktopOrLaptop ? { marginTop: 250, height: 50, flexDirection: 'row', justifyContent: 'center', } : { marginTop: -13, flexDirection: 'row', justifyContent: 'center', }}>
                                    <span class='section-title' style={isDesktopOrLaptop ? { justifyContent: 'center', fontFamily: 'sans-serif', fontSize: 30, color: secondaryColor, textTransform: 'capitalize' } : { justifyContent: 'center', textAlign: 'center', marginTop: 10, marginBottom: 5, fontSize: 20, color: secondaryColor }}>Login to your Account</span>

                                </View>
                                <Text
                                    style={isDesktopOrLaptop ? {
                                        fontSize: 14,
                                        textAlign: 'center',
                                        marginBottom: 10,
                                        color: secondaryColor,
                                    } : {
                                        fontSize: 10,
                                        textAlign: 'center',
                                        marginBottom: 10,
                                        color: secondaryColor,
                                    }}>
                                    To keep connected with us. <br /> Please login with your personal phone no.
                                </Text>
                            </View>



                        </ImageBackground>




                    </View>
                    <View style={{ flex: 0.5, }}>

                        <View style={{ width: '100%', }}>

                            {isDesktopOrLaptop ?

                                <TouchableOpacity style={{ textAlign: 'right', marginBottom: 20, position: 'absolute', right: 10, top: 5, }}
                                    onPress={() => setvisible(false)}
                                >
                                    {/* <Text style={{ textAlign: 'center', color: primarybg }}><TabIcon
                                        name="close" size={30} color={primaryColour}
                                    /></Text> */}
                                    close
                                </TouchableOpacity>

                                : null}

                            <View style={isDesktopOrLaptop ? {
                                display: "flex",
                                flexDirection: "vertical",
                                justifyContent: "space-around",
                                alignItems: "center",
                                padding: 10,
                                marginTop: 80,
                            } : {
                                alignItems: "center",
                                marginTop: -40,
                            }}>
                                <Image
                                    style={isDesktopOrLaptop ? {
                                        resizeMode: "center",
                                        height: 100,
                                        width: 300
                                    } : {
                                        resizeMode: "center",
                                        height: 70,
                                        width: 200
                                    }}
                                    source='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMCIlibT_fyh3UwzIUzL4zZb_cnsmk0LQoyPVtnv1HebNnAEXJ--wAU8mAL707cL8CtL4&usqp=CAU' />
                            </View>
                            <Text
                                style={[{
                                    fontSize: 20,
                                    textAlign: 'center',
                                    marginBottom: 10,
                                    color: primaryColour,
                                    // fontWeight:'bold'
                                },
                                isDesktopOrLaptop ? { marginTop: 40, } : {}
                                ]}>
                                Mobile Verification
                            </Text>
                            <Text
                                style={{
                                    marginTop: 10,
                                    fontSize: 14,
                                    textAlign: 'center',
                                    marginBottom: 10,
                                    color: 'gray',
                                }}>
                                Please Enter your phone no <br /> to verify your account.
                            </Text>

                            {error ? (
                                <Text
                                    style={{
                                        fontSize: 14,
                                        textAlign: 'center',
                                        marginBottom: 10,
                                        color: 'red',
                                    }}>
                                    {error}
                                </Text>
                            ) : null}

                            <View
                                style={{
                                    borderColor: 'lightgray',
                                    // backgroundColor: 'white',
                                    backgroundColor: 'rgba(247, 223, 242, 0.1)',
                                    borderRadius: 30,
                                    borderWidth: 1,
                                    width: 250,
                                    height: 45,
                                    marginBottom: 25,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginTop: 10,
                                    alignSelf: 'center'
                                }}>
                                <Text style={{
                                    width: 30,
                                    height: 30,
                                    marginLeft: 15,
                                    justifyContent: 'center',
                                    color: primaryColour,
                                    marginTop: 9,
                                    fontWeight: 'bold',
                                    fontSize: 16
                                }}>
                                    +92
                                </Text>
                                <TextInput
                                    style={{
                                        height: 45,
                                        marginLeft: 16,
                                        borderBottomColor: primaryColour,
                                        flex: 1,
                                        color: 'black',
                                        outlineStyle: 'none',

                                    }}
                                    returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
                                    placeholderTextColor={'lightgray'}
                                    placeholder="3*********"
                                    // keyboardType="email-address"
                                    underlineColorAndroid="transparent"
                                    onChangeText={text => setContactNo(text)}
                                    value={contactNo}
                                    maxLength={11} //setting limit of input
                                    keyboardType={'phone-pad'}
                                    onSubmitEditing={Keyboard.dismiss}
                                />
                            </View>
                            <div id="recaptcha-container"></div>
                            {/* <View style={{ flexDirection: 'row', marginBottom: 20, }}> */}
                            {/* <BouncyCheckbox
                                size={20}
                                fillColor={'rgba(51, 51, 48, 0.5)'}
                                unfillColor={'rgba(247, 223, 242, 0.1)'}
                                // text=""
                                iconStyle={{ borderColor: primarybg }}
                                textStyle={{ fontFamily: "JosefinSans-Regular" }}
                                onPress={(isChecked) => {
                                    setprivacyCheckBox(isChecked);
                                    Keyboard.dismiss()

                                }}
                            /> */}
                            {/* <input
                                        type='checkbox'
                                        style={{ marginRight: 5 }}
                                        checked={privacyCheckBox}
                                        onClick={() => {
                                            setprivacyCheckBox(!privacyCheckBox);
                                            Keyboard.dismiss()
                                        }}
                                    />
                                    <Text
                                        style={{
                                            fontSize: 14,
                                            textAlign: 'center',
                                            color: primarybg,
                                        }}>

                                        I agree to the

                                    </Text>
                                    <TouchableOpacity
                                        onPress={() => {
                                            // setVisible1(false);
                                            // setVisible2(false)
                                            // navigation.navigate('Privacy');
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontSize: 14,
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                                textDecorationLine: 'underline',
                                                color: primarybg,
                                            }}>
                                            {' '}Privacy Policy
                                        </Text>
                                    </TouchableOpacity>
                                </View> */}
                            <TouchableOpacity
                                disabled={!privacyCheckBox}
                                style={[{
                                    height: 45,
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    alignSelf: 'center',
                                    marginBottom: 20,
                                    width: 100,
                                    borderRadius: 30,
                                    marginTop: 10,
                                    borderColor: primaryColour,
                                    borderWidth: 1,
                                },
                                privacyCheckBox === true ? { backgroundColor: primaryColour } : { backgroundColor: primaryColour }
                                ]}
                                onPress={() => {
                                    // generateOTP(setdata, setisLoading, contactNo, setError, setotpVerification, setvisible)
                                    signin()

                                }}>
                                {isLoading ? (
                                    <View style={{ flex: 0.9 }}>
                                        <ActivityIndicator size="small" color={secondaryColor} />
                                    </View>
                                ) : null}
                                <Text style={[
                                    { fontWeight: 'bold' },
                                    privacyCheckBox === true ? { color: secondaryColor, fontSize: 17, } : { color: secondaryColor, fontSize: 15, }

                                ]}>Next</Text>
                                <Icon
                                    style={[{
                                        marginLeft: 15,
                                    },
                                    privacyCheckBox === true ? { color: primaryColour } : { color: primaryColour, }
                                    ]}
                                    name="arrow-right"
                                    type="font-awesome-5"
                                    color={primaryColour}
                                    size={15}
                                />
                            </TouchableOpacity>


                        </View>

                    </View>
                </View>


            </PerfectModal>
        </>
    );
}

