import { Dimensions, View } from "react-native";
import './1/templateUtils/loader.css'


export default function Loading() {
    return (<View style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: Dimensions.get('window').height }}>
        <div className="loadingio-spinner-chunk-nx0mjvmz269"><div className="ldio-q1sjvrz6fl">
            <div><div><div></div><div></div><div></div><div></div></div></div>
        </div></div>
    </View>)
}