import React, { useEffect, useState, useContext, useRef } from 'react';
import { FaFacebookSquare, FaInstagram, FaTwitterSquare, FaYoutube } from 'react-icons/fa';
import {
    StyleSheet,
    View,
    Text,
    TouchableOpacity,
    ScrollView,
    RefreshControl,
    ImageBackground,
    TextInput,
    ActivityIndicator,
    StatusBar,
    Linking,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { HomeContext } from '../../../utils/contexts';
// import { FontAwesome } from '@expo/vector-icons';
import { borderColor, inputBackgound, primarybg } from '../../../utils/string';

// const Icon = FontAwesome;
export function isURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return pattern.test(str);
}

export default function Footer(props) {
    const { data, secureToken, onSubscribe } = props
    const source =
        'https://images.unsplash.com/photo-1481595357459-84468f6eeaac?dpr=1&auto=format&fit=crop&w=376&h=251&q=60&cs=tinysrgb';

    const {
        products, productsLoading, primaryColour, facebook,
        instagram,
        youtube,
        secondaryColor,
        dukanName, } = useContext(HomeContext);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    return (

        <>

            <View style={{ paddingHorizontal: '15%', paddingTop: 40, backgroundColor: '#f4f4f4' }}>
                <View style={{ flexDirection: isDesktopOrLaptop ? 'row' : 'column', justifyContent: isDesktopOrLaptop ? 'space-between' : 'center', paddingBottom: '5%' }}>
                    <View style={{ flex: isDesktopOrLaptop ? 0.6 : undefined }}>
                        <Text style={{ fontWeight: '400', fontSize: 25, marginVertical: 10, textAlign: !isDesktopOrLaptop ? 'center' : undefined, color: primaryColour }}>Sign up now & Get exclusive deals</Text>
                        <Text style={{ color: 'gray', fontSize: 17, paddingTop: 5, textAlign: !isDesktopOrLaptop ? 'center' : undefined, color: secondaryColor }}>Be the first to know about our new arrivals and exclusive offers</Text>
                        <TouchableOpacity onPress={() => { onSubscribe() }}>
                            <Text
                                className='template1AllCatButtom'
                                style={{
                                    paddingHorizontal: 5, paddingVertical: 5, fontWeight: '300', width: isDesktopOrLaptop ? '15%' : undefined, fontSize: 17, marginRight: 13,
                                    // transform: [{ rotate: '90deg' }],
                                    borderWidth: 1, textAlign: 'center', backgroundColor: 'black', color: 'white', marginTop: 15, color: primarybg,
                                    backgroundColor: primaryColour
                                }}>
                                {secureToken != '@error' && secureToken != '' ?
                                    (data.user_following == 0 ?
                                        'Subscribe'
                                        : 'Unsubscribe'
                                    )
                                    : 'Subscribe'
                                }
                            </Text>
                        </TouchableOpacity>
                    </View>
                    {/* <View style={{ flex: isDesktopOrLaptop && 0.2 }}>
                        <Text style={{ fontWeight: '400', fontSize: 25, marginVertical: 10, textAlign: !isDesktopOrLaptop && 'center' }}>Help</Text>
                        <Text style={{ color: 'gray', fontSize: 17, marginTop: 5, textAlign: !isDesktopOrLaptop && 'center' }}>Order Tracking</Text>
                        <Text style={{ color: 'gray', fontSize: 17, marginVertical: 7, textAlign: !isDesktopOrLaptop && 'center' }}>Whatsapp</Text>
                    </View> */}
                    <View style={{ flex: isDesktopOrLaptop ? 0.2 : undefined }}>
                        {facebook != '' || instagram != '' || youtube != '' ?
                            <>
                                <Text style={{ fontWeight: '400', fontSize: 25, marginVertical: 10, textAlign: !isDesktopOrLaptop ? 'center' : undefined, color: primaryColour }}>Follow Us</Text>
                                <View
                                    style={{
                                        width: !isDesktopOrLaptop ? '60%' : undefined, flexDirection: 'row', justifyContent: 'space-between',
                                        paddingRight: isDesktopOrLaptop ? '40%' : undefined, paddingTop: 5, alignItems: !isDesktopOrLaptop && 'center', alignSelf: !isDesktopOrLaptop && 'center'
                                    }}>
                                    {facebook != '' &&
                                        <TouchableOpacity onPress={() => { isURL(facebook) && Linking.openURL(facebook) }}>
                                            <FaFacebookSquare name="facebook-square" size={34} color={secondaryColor} />
                                        </TouchableOpacity>}
                                    {instagram != '' &&
                                        <TouchableOpacity onPress={() => { isURL(instagram) && Linking.openURL(instagram) }}>
                                            <FaInstagram name="instagram" size={34} color={secondaryColor} />
                                        </TouchableOpacity>}
                                    {youtube != '' &&
                                        <TouchableOpacity onPress={() => { isURL(youtube) && Linking.openURL(youtube) }}>
                                            <FaYoutube name="twitter-square" size={34} color={secondaryColor} />
                                        </TouchableOpacity>}

                                </View>
                            </> : null
                        }
                    </View>
                </View>
                <Text style={{ marginTop: 'auto', textAlign: 'center', fontSize: 17, color: 'gray', marginBottom: 20, fontWeight: '400' }}>©2022 bazars.pk - All rights reserved</Text>
            </View>
        </>

    );
}
