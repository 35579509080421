import { StyleSheet, Text, TouchableOpacity, View, ScrollView, Image, FlatList, Dimensions } from "react-native";
import { useMediaQuery } from 'react-responsive'
import PerfectModal from "../../../utils/perfectModel";
import { AddToBag, getData, LoadingIndicator, notify, RoundButton, setTitle, storeData, useTitle } from "../../../utils/utils";
// import FontAwesomeIcon from 'react-native-vector-icons/dist/FontAwesome5';

import ImageLoad from "../../../utils/imageLoad";
import Tab from "../tab";
import { borderColor, defaultLink, primarybg, secondarybg, serverURL, shadowColor } from "../../../utils/string";
import DetailsScreen from "./details";
import { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { getProductDetails } from "../../../utils/api";
import { TabContext } from "../../../utils/contexts";
import Login from "../account/login";
import Swiper from 'react-native-web-swiper';
import { useParams, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa";
import '../templateUtils/loader.css'
import { BiArrowBack } from "react-icons/bi";

const Icon = () => (<></>);


const handleDragStart = (e) => e.preventDefault();
const dimensions = Dimensions.get('window');

export default function ProductDetails4(props) {

    const navigate = useNavigate();
    const { dukanId, productId } = useParams();


    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const { primaryColour, secondaryColor } = props
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const [images, setimages] = useState(false);
    const [loginVisible, setloginVisible] = useState(false);
    const [placeOrderQuantity, setplaceOrderQuantity] = useState(1);
    const [variations, setvariations] = useState([]);
    const [selectedVariation, setselectedVariation] = useState(0);
    const [dukanLink, setdukanLink] = useState('');
    // const isFocused = useIsFocused();
    const { hideBottomTab, sethideBottomTab } = useContext(TabContext);

    const [secureToken, setsecureToken] = useState('');
    const [pageRefresh, setPageRefresh] = useState(Math.random());
    const [selectedColor, setselectedColor] = useState(0);
    const [selectedSize, setselectedSize] = useState(0);

    const [pageLoaded, setpageLoaded] = useState(false);

    useEffect(() => {
        if (primaryColour != '#5893D4aa') {
            setpageLoaded(true)
        }
    }, [primaryColour])

    useEffect(() => {
        if (typeof productId !== 'undefined') {
            getProductDetails(productId, setdata, setisLoading, navigate, setvariations)

            storeData(dukanId, 'dukanLink')
            setisLoading(false)
        }
        getData('secure_token', setsecureToken);
        sethideBottomTab(true)
    }, [])

    useEffect(() => {
        console.log(hideBottomTab, 'uuuuuuuuuuu')
        setPageRefresh(Math.random())
    }, [hideBottomTab])

    useEffect(() => {
        if (typeof productId !== 'undefined') {

            getProductDetails(productId, setdata, setisLoading, navigate, setvariations)
        }
        getData('secure_token', setsecureToken);
        setPageRefresh(Math.random())
    }, [productId])





    useEffect(() => {
        if (typeof data.imgs !== 'undefined') {
            setimages(true)
        }
    }, [data])



    return (
        <>
            {!pageLoaded ?
                <View style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: Dimensions.get('window').height }}>
                    <div className="loadingio-spinner-chunk-nx0mjvmz269"><div className="ldio-q1sjvrz6fl">
                        <div><div><div></div><div></div><div></div><div></div></div></div>
                    </div></div>
                </View>
                :
                <Tab hideBottomTab={true} primaryColour={primaryColour} secondaryColor={secondaryColor}>
                    <View style={{
                        maxHeight: dimensions.height, flexDirection: 'column', height: dimensions.height, marginTop: isDesktopOrLaptop ? 20 : undefined,
                        paddingHorizontal: isDesktopOrLaptop ? '10%' : undefined
                    }} key={pageRefresh} >
                        {isDesktopOrLaptop ? null :
                            <View style={{
                                alignItems: 'center', backgroundColor: primarybg, flex: 0.08, flexDirection: 'row', justifyContent: 'space-between',
                                borderWidth: 1, borderColor: borderColor, paddingRight: 10, paddingLeft: 10
                            }}>
                                <TouchableOpacity
                                    style={{ marginTop: 2, flex: 0.2 }}
                                    onPress={() => defaultLink == '' ? navigate('/' + dukanId) : navigate('/')}>
                                    <BiArrowBack
                                        name="arrow-left"
                                        type="font-awesome-5"
                                        color={primaryColour}
                                        size={20}

                                    />
                                </TouchableOpacity>
                                <Text style={{ color: primaryColour, fontSize: 24, fontWeight: '500' }}>
                                    {data.username && data.username.length > 22 ? String(data.username.substring(0, 22)) : data.username}
                                </Text>
                                <RoundButton text='Bag'
                                    onPress={() => {
                                        navigate('/' + window.location.pathname.substring(1).split('/')[0] + '/bag')
                                    }}
                                    style={{ paddingHorizontal: 10, flex: 0.45, borderRadius: 2, backgroundColor: primaryColour }} />
                            </View>
                        }
                        <ScrollView style={{ flex: isDesktopOrLaptop ? 1 : 0.84 }}>
                            {!isLoading ?
                                <>
                                    <Login visible={loginVisible} setvisible={setloginVisible} primaryColour={primaryColour} />
                                    {!isDesktopOrLaptop &&
                                        <Text style={[{ marginVertical: 10, },
                                        !isDesktopOrLaptop ? { textAlign: 'center', fontWeight: 'bold', color: primaryColour, fontSize: 20, letterSpacing: 1.5, fontWeight: '600' } :
                                            { fontSize: 29, textAlign: 'left', fontWeight: 'bold', paddingLeft: 20, color: primaryColour }]}>
                                            {data.title.replace(/(\r\n|\n|\r)/gm, "")}
                                        </Text>}
                                    {typeof data.imgs != 'undefined' ?
                                        <View style={isDesktopOrLaptop ? { flexDirection: 'row' } : {}}>
                                            <View
                                                style={isDesktopOrLaptop ?
                                                    { height: Dimensions.get('window').height / 1.25, flex: 0.7, paddingHorizontal: 30, marginTop: 30 }
                                                    : { height: Dimensions.get('window').width + 50 }}>
                                                {/* <AliceCarousel mouseTracking items={images} autoWidth disableButtonsControls disableDotsControls /> */}
                                                {
                                                    images ?
                                                        <Swiper
                                                            // from={1}
                                                            minDistanceForAction={0.1}
                                                            controlsProps={{
                                                                dotsTouchable: true,
                                                                prevPos: 'left',
                                                                nextPos: 'right',
                                                                nextTitle: '>',
                                                                dotActiveStyle: { color: 'black', backgroundColor: 'black' },
                                                                nextTitleStyle: { color: primaryColour, fontSize: 24, fontWeight: '500' },
                                                                PrevComponent: ({ onPress }) => (
                                                                    <TouchableOpacity onPress={onPress}>
                                                                        <Text style={{ color: primaryColour, fontSize: 24, fontWeight: '500' }}>
                                                                            {'<'}
                                                                        </Text>
                                                                    </TouchableOpacity>
                                                                ),
                                                            }}
                                                        >
                                                            {
                                                                data.imgs.map((i, k) => {
                                                                    return (
                                                                        // <View style={{ height: Dimensions.get('window').height, }}>
                                                                        <ImageLoad
                                                                            source={{ uri: serverURL + i.image }}
                                                                            style={isDesktopOrLaptop ? { flex: 1 } : { height: Dimensions.get('window').width + 50 }}
                                                                        >
                                                                            <Text style={[{
                                                                                textAlign: 'center',
                                                                                margin: 50,
                                                                                backgroundColor: secondarybg,
                                                                                width: 70,
                                                                                borderRadius: 20,
                                                                                paddingHorizontal: 5,
                                                                                color: primarybg,
                                                                                alignSelf: 'center'
                                                                            }, !isDesktopOrLaptop && {
                                                                                position: 'absolute',
                                                                                bottom: -15,
                                                                                marginLeft: 30,
                                                                                alignSelf: 'flex-start'
                                                                            }]}>
                                                                                {i.variation}
                                                                            </Text>
                                                                        </ImageLoad>
                                                                        // </View>

                                                                    )
                                                                })
                                                            }

                                                        </Swiper>
                                                        : null
                                                }
                                                {/* <View style={{
                        flexDirection: 'row', position: 'absolute', top: Dimensions.get('window').height - 70,
                        justifyContent: 'center'
                        , alignItems: 'center', marginHorizontal: '40%', paddingTop: 0
                    }}>
                        {images.map((i, k) => (
                            <Icon name="circle" size={20} color={'#888'}
                                solid
                                style={{ marginHorizontal: 3 }}
                            // onPress={() => setbagVisible(!bagVisible)}
                            />
                        ))}
                    </View> */}

                                            </View>

                                            <View style={{ flex: 0.5, paddingHorizontal: 10, paddingTop: isDesktopOrLaptop ? 20 : undefined }}>
                                                <View style={{ flex: 0.95, paddingTop: 10, height: Dimensions.get('window').height - 200 }}>
                                                    {isDesktopOrLaptop &&
                                                        <Text style={[{
                                                            marginVertical: 5, fontSize: 28, textAlign: 'left',
                                                            fontWeight: '600', letterSpacing: 1.5, paddingLeft: 20, color: primaryColour, textTransform: 'capitalize'
                                                        }]}>
                                                            {data.title.replace(/(\r\n|\n|\r)/gm, "")}
                                                        </Text>}
                                                    {isDesktopOrLaptop &&
                                                        <Text
                                                            style={[{
                                                                marginVertical: 0, fontSize: 20, textAlign: 'left', fontWeight: '400',
                                                                letterSpacing: 1.6, paddingLeft: 20, color: primaryColour
                                                            }]}>
                                                            Rs.{data.price}
                                                        </Text>}
                                                    {/* <Text style={{ fontSize: 20, color: 'gray', textAlign: 'left', paddingLeft: 20 }}>{data.type}</Text> */}
                                                    {/* <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', alignSelf: 'center' }}>
                                            <Text style={
                                                !isDesktopOrLaptop ? { fontSize: 18, textAlign: 'center', fontWeight: 'bold', color: primaryColour } :
                                                    { fontSize: 29, textAlign: 'left', fontWeight: 'bold', paddingLeft: 20, color: primaryColour }}>
                                                Rs. {data.price}
                                            </Text>
                                        </View> */}


                                                    {/* <View style={{ flexDirection: 'row', paddingLef t: 20 }}>
                            <ReactStars
                                count={5}
                                onChange={() => { }}
                                size={24}
                                activeColor="#ffd700"
                                edit={false}
                                value={4}
                            />
                            <Text style={{ marginTop: 7, fontWeight: 'bold' }}>{'  '}200 reviews</Text>
                        </View> */}
                                                    <DetailsScreen
                                                        data={data}
                                                        variations={variations}
                                                        selectedVariation={selectedVariation}
                                                        setselectedVariation={setselectedVariation}
                                                        setselectedSize={setselectedSize}
                                                        selectedSize={selectedSize}
                                                        selectedColor={selectedColor}
                                                        setselectedColor={setselectedColor}
                                                        primaryColour={primaryColour}
                                                        Footer={() => (
                                                            <>
                                                                {isDesktopOrLaptop ?
                                                                    <View style={{
                                                                        // flex: 0.05,
                                                                        // flexDirection: 'row',
                                                                        paddingVertical: 10,
                                                                        // backgroundColor: 'red',
                                                                        // alignSelf: 'center',
                                                                        width: '100%',
                                                                        // borderTopWidth: 0.1,



                                                                        elevation: 5,
                                                                    }}>
                                                                        <View style={{ flexDirection: 'row' }}>
                                                                            <RoundButton
                                                                                style={{
                                                                                    borderRadius: 0, justifyContent: 'center',
                                                                                    backgroundColor: primarybg, width: 40, borderColor: borderColor
                                                                                }} text='-'
                                                                                textStyle={{ color: primaryColour }}
                                                                                onPress={() => { placeOrderQuantity > 1 ? setplaceOrderQuantity(placeOrderQuantity - 1) : setplaceOrderQuantity(placeOrderQuantity) }}
                                                                            />
                                                                            <RoundButton
                                                                                style={{
                                                                                    borderRadius: 0, justifyContent: 'center',
                                                                                    backgroundColor: primarybg, width: 40, borderColor: borderColor
                                                                                }} text={placeOrderQuantity}
                                                                                textStyle={{ color: primaryColour }}
                                                                            />

                                                                            <RoundButton
                                                                                style={{
                                                                                    borderRadius: 0, justifyContent: 'center',
                                                                                    backgroundColor: primarybg, width: 40, borderColor: borderColor
                                                                                }} text='+'
                                                                                textStyle={{ color: primaryColour }}
                                                                                onPress={() => {
                                                                                    placeOrderQuantity < parseInt(data.quantity) ? setplaceOrderQuantity(placeOrderQuantity + 1)
                                                                                        : notify('Max quantity of this product is ' + data.quantity)
                                                                                }}
                                                                            />
                                                                        </View>
                                                                        <RoundButton text='Add to bag'
                                                                            onPress={() => {
                                                                                console.log(selectedVariation, 'ssssss')
                                                                                AddToBag(productId, selectedVariation,)
                                                                            }}
                                                                            style={{ paddingHorizontal: 10, backgroundColor: primaryColour, borderRadius: 5, }} />
                                                                        {/* <RoundButton text='Chat & Bargain' onPress={() => { }} style={{ paddingHorizontal: 20 }} /> */}

                                                                        <RoundButton text='Place order'
                                                                            onPress={() => {
                                                                                if (secureToken != '@error' && secureToken != '' && secureToken != null && typeof secureToken != 'undefined') {
                                                                                    storeData(JSON.stringify([{ id: productId, quantity: placeOrderQuantity }]), 'productsForCheckout')
                                                                                    navigate('/' + window.location.pathname.substring(1).split('/')[0] + '/Checkout')
                                                                                } else {
                                                                                    setloginVisible(!loginVisible)
                                                                                }

                                                                            }}
                                                                            style={{ paddingHorizontal: 10, backgroundColor: primaryColour, borderRadius: 5 }} >

                                                                        </RoundButton>
                                                                    </View>

                                                                    : null}
                                                            </>
                                                        )}
                                                    />
                                                    {/* <ProductDetailTabs /> */}

                                                </View>

                                            </View>
                                        </View>
                                        : null}
                                </>
                                : <LoadingIndicator />}
                        </ScrollView>
                        {isDesktopOrLaptop ? null :
                            <View style={{ alignItems: 'center', backgroundColor: primarybg, flex: 0.08, flexDirection: 'row', justifyContent: 'space-between', borderTopWidth: 1, borderColor: borderColor }}>
                                <View style={{ flexDirection: 'row', flex: 0.3, justifyContent: 'center' }}>
                                    <RoundButton style={{ marginRight: 10, height: 30, width: 20, justifyContent: 'center', backgroundColor: primaryColour }} text='-'
                                        onPress={() => { placeOrderQuantity > 1 ? setplaceOrderQuantity(placeOrderQuantity - 1) : setplaceOrderQuantity(placeOrderQuantity) }}
                                    />
                                    <Text style={{ marginTop: 10 }}
                                    // key={quantityKey}
                                    >
                                        {placeOrderQuantity}
                                    </Text>
                                    <RoundButton style={{ marginLeft: 10, height: 30, width: 20, justifyContent: 'center', backgroundColor: primaryColour }} text='+'
                                        onPress={() => {
                                            placeOrderQuantity < parseInt(data.quantity) ? setplaceOrderQuantity(placeOrderQuantity + 1)
                                                : notify('Max quantity of this product is ' + data.quantity)
                                        }}
                                    />
                                </View>
                                <View style={{ flexDirection: 'row', flex: 0.7, justifyContent: 'space-between', paddingHorizontal: '4%' }}>
                                    <RoundButton text='Add to bag'
                                        onPress={() => {
                                            let tmpVariation = ''
                                            let tmpColor = ''
                                            let tmpSize = ''
                                            if (variations.length > 0) {
                                                tmpVariation = variations[selectedVariation].id
                                            } else if (typeof data.sizes != 'undefined' && typeof data.colors != 'undefined') {
                                                if (data.sizes.length > 0) {
                                                    tmpSize = data.sizes[selectedSize].id
                                                }
                                                if (data.colors.length > 0) {
                                                    tmpColor = data.colors[selectedColor].id
                                                }
                                            }
                                            AddToBag(productId, tmpVariation, parseInt(data.quantity), tmpColor, tmpSize)
                                        }}
                                        style={{ paddingHorizontal: 10, flex: 0.45, borderRadius: 5, backgroundColor: primaryColour }} />
                                    {/* <RoundButton text='Chat & Bargain' onPress={() => { }} style={{ paddingHorizontal: 20 }} /> */}
                                    <RoundButton text='Place order'
                                        onPress={() => {
                                            if (secureToken != '@error' && secureToken != '' && secureToken != null && typeof secureToken != 'undefined') {
                                                storeData(JSON.stringify([{ id: productId, quantity: placeOrderQuantity }]), 'productsForCheckout')
                                                navigate('/' + window.location.pathname.substring(1).split('/')[0] + '/Checkout')
                                            } else {
                                                setloginVisible(!loginVisible)
                                            }

                                        }}
                                        style={{ paddingHorizontal: 10, flex: 0.45, borderRadius: 5, backgroundColor: primaryColour }} >

                                    </RoundButton>
                                </View>
                            </View>
                        }
                    </View>
                </Tab>
            }
        </>
    );
}


const styles = StyleSheet.create({
    myStarStyle: {
        color: 'yellow',
        backgroundColor: 'transparent',
        textShadowColor: 'black',
        textShadowOffset: { width: 1, height: 1 },
        textShadowRadius: 2,
    },
    myEmptyStarStyle: {
        color: 'white',
    }
});
