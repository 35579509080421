import { StyleSheet, Text, TouchableOpacity, View, ScrollView, Image, ActivityIndicator, ImageBackground, FlatList, Dimensions } from "react-native";
import { borderColor, defaultLink, primarybg, serverURL, shadowColor } from "../../../utils/string";
import { useMediaQuery } from 'react-responsive'
import Tab from "../tab";
import { useEffect, useState } from "react";
import { getCatalogDetails, getDukanCatalogs, getDukanCategories, getDukanProductsForHome, getHomePage, subscribeDukan, unsubscribeDukan } from "../../../utils/api";
import { ToastContainer, toast } from 'react-toastify';
import { getData, LoadingIndicator, PerfectInput, RoundButton, storeData } from "../../../utils/utils";
import Product from "../templateUtils/product";
import { useNavigate, useParams } from "react-router-dom";
import '../templateUtils/loader.css'

export default function CatalogPage3(props) {

    const { dukanId, catalogId } = useParams();
    const navigate = useNavigate();
    const { primaryColour, secondaryColor, dukanName } = props

    const notify = (t) => toast.dark(t, {
        position: toast.POSITION.BOTTOM_CENTER
    });
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTablet = useMediaQuery({
        query: '(min-width: 768px)'
    })

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const [prevStart, setprevStart] = useState(0);
    const [loadMore, setloadMore] = useState(true);
    const [idsOfCatalogProducts, setidsOfCatalogProducts] = useState([]);
    const [catalogData, setcatalogData] = useState({});
    const [pageLoaded, setpageLoaded] = useState(false);

    useEffect(() => {
        if (primaryColour != '#5893D4aa') {
            setpageLoaded(true)
        }
    }, [primaryColour])

    useEffect(() => {
        if (defaultLink == '') {
            if (typeof dukanId !== 'undefined' && isLoading) {
                storeData(dukanId, 'dukanLink')
                getCatalogDetails(setisLoading, navigate, setdata, data, prevStart, setprevStart, setloadMore, setcatalogData, catalogId, setidsOfCatalogProducts)
                setisLoading(false)
            }
        } else {
            storeData(dukanId, 'dukanLink')
            getCatalogDetails(setisLoading, navigate, setdata, data, prevStart, setprevStart, setloadMore, setcatalogData, catalogId, setidsOfCatalogProducts)
            setisLoading(false)
        }

    }, [])




    return (
        <>
            {!pageLoaded ?
                <View style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: Dimensions.get('window').height }}>
                    <div class="loadingio-spinner-chunk-nx0mjvmz269"><div class="ldio-q1sjvrz6fl">
                        <div><div><div></div><div></div><div></div><div></div></div></div>
                    </div></div>
                </View>
                :
                <Tab primaryColour={primaryColour} secondaryColor={secondaryColor} dukanName={dukanName}>
                    <View style={isDesktopOrLaptop ? { marginTop: 130, } : { marginTop: 70, }}></View>
                    {!isLoading ?
                        <>
                            <FlatList

                                ListHeaderComponent={
                                    <ImageBackground
                                        source={catalogData.image != '' ? { uri: serverURL + catalogData.image } : ''}
                                        style={{
                                            height: isDesktopOrLaptop ? 300 : 400, width: '100%',
                                            borderRadius: 0, borderWidth: catalogData.image == '' ? 1 : 0, borderColor: borderColor,
                                            backgroundColor: 'black', marginBottom: 20, marginTop: -20
                                        }}
                                        imageStyle={{ borderRadius: 5, opacity: 0.7 }}
                                        resizeMode='cover'
                                    >

                                        <View style={isDesktopOrLaptop ? {

                                            background: 'rgb(0,0,0,0.5)',
                                            height: 300,


                                        } : {
                                            background: 'rgb(0,0,0,0.5)',
                                            height: 400,
                                        }}>
                                            <View style={isDesktopOrLaptop ? { flex: 1, justifyContent: 'center', alignItems: 'center', } : isTablet ? { flex: 1, justifyContent: 'center', alignItems: 'center', border: '5px solid white', marginHorizontal: '10%', marginVertical: '10%', position: 'relative', top: 20, } : { flex: 1, justifyContent: 'center', alignItems: 'center', border: '5px solid white', marginHorizontal: '10%', marginVertical: '20%', position: 'relative', top: 20, }}>

                                                <div class="two alt-two">

                                                    <h1><View style={{ marginHorizontal: '5%' }}>{catalogData.name}</View>
                                                        <h4> <Text style={{
                                                            fontSize: 20,
                                                            fontWeight: 500,
                                                            textTransform: 'uppercase',
                                                            letterSpacing: 4,
                                                            lineHeight: '3em',
                                                            color: 'white',
                                                            paddingBottom: 10,
                                                        }}> {catalogData.total_items} </Text> items</h4>
                                                    </h1>
                                                </div>

                                            </View>
                                        </View>

                                    </ImageBackground>
                                }

                                contentContainerStyle={{ marginTop: 0, paddingBottom: 20, height: Dimensions.get('window').height, }}
                                data={data}
                                scrollEnabled={true}
                                showsVerticalScrollIndicator={false}

                                ListFooterComponent={() => {
                                    return (
                                        <>
                                            {
                                                data.length > 9 ?
                                                    <div style={{ justifyContent: 'center', textAlign: 'center', marginTop: 30, }}>
                                                        <TouchableOpacity style={{}}
                                                            onPress={() => {
                                                                if (dukanId != window.location.pathname.split('/')[1]) {
                                                                    storeData(window.location.pathname.split('/')[1], 'dukanLink')
                                                                    navigate('/' + window.location.pathname.split('/')[1] + '/AllProducts/')
                                                                } else {
                                                                    navigate('/' + dukanId + '/AllProducts/')
                                                                }

                                                            }}
                                                        >
                                                            <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 50, marginBottom: 30, opacity: 0.9 }}>
                                                                <div style={{ width: 200, backgroundColor: primaryColour, padding: 10, borderRadius: 50, textAlign: 'center' }}>
                                                                    <Text style={{ color: primaryColour, position: 'relative', top: -3, }}>See All</Text>
                                                                    {/* <Icon name="arrow-right" size={25} color={primaryColour} style={{ marginHorizontal: 10 }} /> */}
                                                                </div>
                                                            </View>
                                                        </TouchableOpacity>
                                                    </div>
                                                    : null
                                            }
                                        </>
                                    )
                                }}
                                ListEmptyComponent={() => {
                                    return (
                                        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                            <Text style={isDesktopOrLaptop ? { marginTop: 50, backgroundColor: primaryColour, color: secondaryColor, fontSize: 19, padding: 10, } : { marginTop: 30, backgroundColor: primaryColour, color: secondaryColor, fontSize: 15, padding: 10, }}>
                                                No products
                                            </Text>
                                            {/* <Icon name="arrow-right" size={25} color={primaryColour} style={{ marginHorizontal: 20 }} /> */}
                                        </View>
                                    )
                                }}
                                numColumns={isDesktopOrLaptop ? 4 : isTablet ? 3 : 2}
                                columnWrapperStyle={[{
                                    flex: 1,
                                    justifyContent: 'center',

                                },
                                isDesktopOrLaptop ? {} : { marginHorizontal: 3 }
                                ]}
                                renderItem={({ item }) => (
                                    <Product item={item} primaryColour={primaryColour} secondaryColor={secondaryColor} />
                                )}
                                keyExtractor={item => item.id}
                            />
                        </>
                        :
                        <LoadingIndicator />
                    }
                </Tab>
            }
        </>
    );
}

