import { Text, TouchableOpacity, View, Dimensions, TextInput, Platform, ActivityIndicator, Keyboard, } from "react-native";
import { primarybg, } from "../../../utils/string";
import { useMediaQuery } from 'react-responsive'
import PerfectModal from "../../../utils/perfectModel";
import { RoundButton } from "../../../utils/utils";
import { useEffect, useState } from "react";
import { verifyOTPWithFirebase } from "../../../utils/api";

const Icon = () => (<></>);

export default function OTPVerification(props) {
    // const move = useLinkTo();
    const { visible, setvisible, contactNo, final } = props
    let verificationId = final.verificationId
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })


    // const [contactNo, setContactNo] = useState('');
    const [privacyCheckBox, setprivacyCheckBox] = useState(true);
    const [error, setError] = useState('');
    const [data, setData] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const [code, setCode] = useState('');
    const [primaryColour, setPrimaryColour] = useState('#5893D4aa');

    useEffect(() => {
        typeof props.primaryColour != 'undefined' && setPrimaryColour(props.primaryColour)
    }, [props])


    return (

        <PerfectModal
            modalVisible={visible}
            onRequestClose={() => {
                setvisible(false)
            }}
            height={isDesktopOrLaptop ? '80%' : Dimensions.get('window').height - 30}
            style={[isDesktopOrLaptop ? { width: '30%', margin: 0, padding: 0, height: '50%', borderColor: primarybg, borderWidth: 2, borderRadius: 20, justifyContent: 'center', alignItems: 'center' } :
                {}
                // { width: '100%', margin: 0, padding: 0, height: Dimensions.get('window').height - 30, borderColor: primarybg, borderWidth: 2, borderRadius: 20 }
            ]}
        >
            <Text
                style={{
                    fontSize: 40,
                    textAlign: 'center',
                    marginBottom: 10,
                    marginTop: 20,
                    color: primaryColour,
                    fontWeight: 'bold'
                }}>
                Bazars.pk
            </Text>
            <Text
                style={{
                    fontSize: 20,
                    textAlign: 'center',
                    marginBottom: 10,
                    marginTop: 10,
                    color: primaryColour,
                    // fontWeight:'bold'
                }}>
                Mobile Verification
            </Text>
            <Text
                style={{
                    fontSize: 14,
                    textAlign: 'center',
                    marginBottom: 10,
                    color: 'lightgray',
                }}>
                Please type the verification code sent to your number:
            </Text>
            {/* {error ? (
                            <Text
                                style={{
                                    fontSize: 14,
                                    textAlign: 'center',
                                    marginBottom: 10,
                                    color: 'red',
                                }}>
                                {error}
                            </Text>
                        ) : null} */}
            {/* {this.state.toolTipVisible ? <Text style={{ textAlign: "center" }}>Please enter valid Contact number!! {'\n'} eg. 03123456789</Text> : <Text></Text>} */}

            <View
                style={{
                    borderColor: 'lightgray',
                    // backgroundColor: 'white',
                    backgroundColor: 'rgba(247, 223, 242, 0.1)',
                    borderRadius: 30,
                    borderWidth: 1,
                    width: 250,
                    height: 45,
                    marginBottom: 20,
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: 10
                }}>

                <Icon
                    style={{
                        width: 30,
                        height: 30,
                        marginLeft: 15,
                        justifyContent: 'center',
                        color: primaryColour,
                        marginTop: 5,
                    }}
                    name="phone"
                    type="font-awesome-5"
                    color={primaryColour}
                    size={22}
                />
                <TextInput
                    style={{
                        height: 45,
                        marginLeft: 16,
                        borderBottomColor: primaryColour,
                        flex: 1,
                        color: 'lightgray',
                    }}
                    returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
                    placeholder="Enter OTP"
                    placeholderTextColor={'lightgray'}
                    keyboardType="email-address"
                    underlineColorAndroid="transparent"
                    onChangeText={text => setCode(text.replace(/[^0-9]/g, ''))}
                    value={code}
                    maxLength={11} //setting limit of input
                    keyboardType={'phone-pad'}
                    onSubmitEditing={Keyboard.dismiss}
                />
            </View>

            <TouchableOpacity
                disabled={!privacyCheckBox}
                style={[{
                    height: 45,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center',
                    marginBottom: 20,
                    width: 100,
                    borderRadius: 30,
                    marginTop: 10,
                    borderColor: primarybg,
                    borderWidth: 1,
                },
                privacyCheckBox === true ? { backgroundColor: primaryColour } : { backgroundColor: 'rgba(247, 223, 242, 0.1)', }
                ]}
                onPress={() => {
                    console.log('kkkkkkkk')
                    verifyOTPWithFirebase(setisLoading, contactNo, code, setError, setvisible, verificationId)
                }}>
                {isLoading ? (
                    <View style={{ flex: 0.9 }}>
                        <ActivityIndicator size="small" color={primarybg} />
                    </View>
                ) : null}
                <Text style={[
                    { fontWeight: 'bold' },
                    privacyCheckBox === true ? { color: primarybg } : { color: primarybg, }

                ]}>Verify</Text>
                {/* <Icon
                    style={[{
                        marginLeft: 15,
                    },
                    privacyCheckBox === true ? { color: primarybg } : { color: primarybg, }
                    ]}
                    name="arrow-right"
                    type="font-awesome-5"
                    color={primarybg}
                    size={15}
                /> */}
            </TouchableOpacity>
            {/* <View style={{ flexDirection: 'row', justifyContent: 'center', paddingBottom: '8%', marginTop: 30 }}>
                <Text style={{ textAlign: 'center', color: primarybg, fontWeight: 'bold' }}>Made in Pakistan {' '}</Text>
                <Image
                    style={{
                        width: 24,
                        height: 15,
                        marginTop: 3
                    }}
                    source={'/static/Pakistan.png'}
                />
            </View> */}
            <RoundButton style={{ flexDirection: 'row', width: 90, alignSelf: 'center', backgroundColor: primaryColour }}
                onPress={() => setvisible(false)}
            >
                <Text style={{ textAlign: 'center', color: primarybg }}>Close</Text>
            </RoundButton>
        </PerfectModal >
    );
}

