import React, { useState, useEffect, } from 'react';
import { Text, View, Dimensions, ScrollView, TouchableOpacity } from 'react-native';
import { defaultLink, demo, primarybg } from '../../../utils/string';
import Login from '../account/login';
import { getData, IconWithText, PerfectInput, RoundButton } from '../../../utils/utils';
import { TabContext } from '../../../utils/contexts';
import { useMediaQuery } from 'react-responsive';
// import { FontAwesome } from '@expo/vector-icons';

import { ToastContainer, toast } from 'react-toastify';
import { matchRoutes, useNavigate, useParams } from 'react-router-dom';
import { BiHomeSmile } from "react-icons/bi";
import { BsFillBagFill, BsBag } from "react-icons/bs";
import { FaClipboardList } from "react-icons/fa";
import { GrDocumentUser } from "react-icons/gr";
import { RiFileList3Fill } from "react-icons/ri";
import { FaShoppingBag } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
import { IconContext } from "react-icons";
import PerfectModal from '../../../utils/perfectModel';
import { IoSearch } from 'react-icons/io5';
import SearchModal from '../templateUtils/searchModal';
import { useLocation } from "react-router-dom"
import 'animate.css';
const Icon = () => (<></>);

const dimensions = Dimensions.get('window');

export default function Tab(props) {

    const navigate = useNavigate();
    const { dukanId } = useParams();
    const location = useLocation();
    const move = navigate;
    const { children, activeTab } = props
    const [bagVisible, setbagVisible] = useState(false);
    const [loginVisible, setloginVisible] = React.useState(false);
    const [dukanLink, setdukanLink] = useState(typeof window != 'undefined' ? window.location.pathname.split('/')[1] : '');
    const [secureToken, setsecureToken] = useState('');
    const [key, setKey] = useState(Math.random());

    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const [scrollEnabled, setscrollEnabled] = useState(true);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    const [bagCount, setbagCount] = useState(0);

    const [searchText, setsearchText] = useState('');
    const [searchModal, setsearchModal] = useState(false);

    const route = demo ? matchRoutes([{ path: "/:dukanId" }], location) : matchRoutes([{ path: "" }], location)

    React.useEffect(() => {
        getData('dukanLink', setdukanLink)
        getData('secure_token', setsecureToken);
    }, [])

    useEffect(() => {
        setKey(Math.random())
        if (typeof window === 'undefined') {
            global.window = {}
        }
    }, [secureToken])

    let primaryColour = typeof props.primaryColour != 'undefined' ? props.primaryColour : '#5893D4'
    let storeName = typeof props.storeName != 'undefined' ? props.storeName : ''
    // dukanLink merabcollection /merabcollection /merabcollection
    // tab.js:1 dukanLink merabcollection merabcollection/Bag merabcollection/Bag
    // tab.js:1 dukanLink merabcollection merabcollection/AboutUs merabcollection/AboutUs

    //     dukanLink merabcollection merabcollection/Bag merabcollection/Bag
    // tab.js:1 dukanLink merabcollection merabcollection/AboutUs merabcollection/AboutUs
    // tab.js:1 dukanLink merabcollection /merabcollection /merabcollection

    const changePage = (link) => {
        console.log('dukanLink', window.location.pathname.split('/')[1], link, link.replace(dukanLink, window.location.pathname.split('/')[1]))
        if (dukanLink == '@error' || dukanLink == null) {
            getData('dukanLink', setdukanLink);
            // setdukanLink(window.location.pathname.split('/')[1])
            move({
                routeName: link.replace(dukanLink, window.location.pathname.split('/')[1]),
                web: { as: link },
            })
        } else {
            if (dukanLink != window.location.pathname.split('/')[1]) {
                setdukanLink(window.location.pathname.split('/')[1])
                move({
                    routeName: link.replace(dukanLink, window.location.pathname.split('/')[1]),
                    web: { as: link },
                })
            } else {
                console.log('1')
                move({
                    routeName: link.replace(dukanLink, window.location.pathname.split('/')[1]),
                    web: { as: link },
                })
            }
        }
    }

    const checkLoginThenchangePage = (link) => {
        if (dukanLink == '@error' || dukanLink == null) {
            getData('dukanLink', setdukanLink)
            secureToken != '@error' && secureToken != '' ?
                move({
                    routeName: link.replace(dukanLink, window.location.pathname.split('/')[1]),
                    web: { as: link },
                })
                : setloginVisible(!loginVisible)
        } else {
            if (dukanLink != window.location.pathname.split('/')[1]) {
                setdukanLink(window.location.pathname.split('/')[1])
                secureToken != '@error' && secureToken != '' ?
                    move({
                        routeName: link.replace(dukanLink, window.location.pathname.split('/')[1]),
                        web: { as: link },
                    })
                    : setloginVisible(!loginVisible)
            } else {
                secureToken != '@error' && secureToken != '' ?
                    move({
                        routeName: link.replace(dukanLink, window.location.pathname.split('/')[1]),
                        web: { as: link },
                    })
                    : setloginVisible(!loginVisible)
            }
        }
    }

    const activeTabStyle = {}

    return (
        <>

            <TabContext.Provider
                value={{
                    setscrollEnabled
                }}
                key={key}
            >
                <ToastContainer autoClose={2000} />
                <Login visible={loginVisible} setvisible={setloginVisible} primaryColour={primaryColour} primaryColour={primaryColour} storeName={storeName} />
                {
                    isBigScreen || isDesktopOrLaptop ?
                        <View style={{ height: dimensions.height }}>
                            {/* <Bag visible={bagVisible} setvisible={setbagVisible} /> */}
                            <View
                                style={{
                                    flex: 0.02,
                                    zIndex: 2000,
                                    backgroundColor: location.pathname == '/' ? primaryColour + '82' : primaryColour,
                                    // shadowColor: "#000",
                                    // shadowOffset: {
                                    //     width: 0,
                                    //     height: 12,
                                    // },
                                    // shadowOpacity: 0.58,
                                    // shadowRadius: 16.00,
                                    // elevation: 24,
                                    // borderRightWidth: 0.01,
                                    // borderColor: 'lightgray',

                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingHorizontal: '10%',
                                    minHeight: 65,
                                    marginBottom: location.pathname == '/' && '-2%',

                                }}>
                                <View style={{ flex: 0.3, flexDirection: 'row', marginTop: 3 }}>
                                    <TouchableOpacity
                                        onPress={() => {
                                            defaultLink == '' ?
                                                navigate('/' + dukanId + '/') :
                                                navigate('/')
                                        }}
                                    >
                                        <Text style={{ fontSize: 16, letterSpacing: 1.5, marginRight: 30, fontWeight: '600', color: primarybg }}>Home</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={() => {
                                            defaultLink == '' ?
                                                navigate('/' + dukanLink + '/shop')
                                                : navigate('/shop')
                                        }}
                                    >
                                        <Text style={{ fontSize: 16, letterSpacing: 1.5, fontWeight: '600', color: primarybg }}>Shop</Text>
                                    </TouchableOpacity>
                                </View>
                                <Text style={{ fontSize: 29, letterSpacing: 1.5, fontWeight: '600', marginHorizontal: '20%', color: primarybg }}>
                                    {storeName}
                                </Text>
                                <View style={{ flex: 0.3, flexDirection: 'row', marginTop: 3 }}>
                                    <TouchableOpacity
                                        onPress={() => {
                                            defaultLink == '' ?
                                                navigate('/' + dukanId + '/bag')
                                                : navigate('/bag')
                                        }}
                                    >
                                        <FaShoppingBag size={21} color={primaryColour} style={{ marginLeft: 10, marginRight: 10, color: primarybg }} />
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={() => {
                                            console.log(window.location.pathname.substring(1).split('/')[0] + '/Orders')
                                            if (secureToken != '@error' && secureToken != '') {
                                                defaultLink == '' ?
                                                    navigate('/' + dukanId + '/orders')
                                                    : navigate('/orders')
                                            } else {
                                                setloginVisible(!loginVisible)
                                            }
                                        }}
                                    >
                                        <RiFileList3Fill size={20} color={primaryColour} style={{ marginLeft: 10, marginRight: 10, color: primarybg }} />
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={() => {
                                            setsearchModal(!searchModal)
                                        }}
                                    >
                                        <IoSearch size={20} color={primaryColour} style={{ marginLeft: 10, marginRight: 10, color: primarybg }} />
                                    </TouchableOpacity>
                                    {/* <FontAwesome name="search" size={24} color="black" style={{ marginHorizontal: 10, marginTop: 5 }} />
                                    <MaterialCommunityIcons name="account" size={30} color="black" style={{ marginHorizontal: 10, }} />
                                    <FontAwesome name="shopping-cart" size={24} color="black" style={{ marginHorizontal: 10, marginTop: 5 }} /> */}
                                </View>



                                {/* <TouchableOpacity
                                            onPress={() => {
                                                console.log(window.location.pathname.substring(1).split('/')[0] + '/AboutUs')
                                                navigate('/' + window.location.pathname.substring(1).split('/')[0] + '/AboutUs')
                                            }}

                                        >
                                            <Icon
                                                name="address-card" size={23} color={primaryColour} style={{ marginHorizontal: 20 }}
                                                solid
                                            // onPress={() => setbagVisible(!bagVisible)}
                                            />
                                            <Text style={{ fontSize: 12, textAlign: 'center' }}>About us</Text>
                                        </TouchableOpacity> */}
                            </View>
                            <View style={{ flex: 0.95 }}>
                                {children}
                            </View>
                        </View>
                        :
                        <View style={{
                            maxHeight: demo ? (route == null ? dimensions.height : dimensions.height - 55) : dimensions.height,
                            flexDirection: 'column',
                            height: demo ? (route == null ? dimensions.height : dimensions.height - 50) : dimensions.height,
                        }} >
                            {typeof props.hideBottomTab == 'undefined' ?
                                <>
                                    <ScrollView style={{ flex: 0.98 }} scrollEnabled={scrollEnabled}>
                                        {children}
                                        {/* <View style={{ height: 300, backgroundColor: 'red' }} />
                            <View style={{ height: 300, backgroundColor: 'pink' }} />
                            <View style={{ height: 300, backgroundColor: 'black' }} />
                            <View style={{ height: 300, backgroundColor: 'red' }} /> */}
                                    </ScrollView>
                                    <View
                                        style={{
                                            flex: 0.02,
                                            backgroundColor: primarybg,
                                            shadowColor: demo ? primarybg : "#000",
                                            shadowOffset: {
                                                width: 0,
                                                height: 3,
                                            },
                                            shadowOpacity: 0.27,
                                            shadowRadius: 4.65,

                                            elevation: 6,
                                            borderTopWidth: 1.4,
                                            borderColor: '#f0ede9',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            paddingHorizontal: '20%',
                                            minHeight: 50,

                                        }}>
                                        <div className={activeTab == 'home' && "animate__animated animate__bounce"}>
                                            <TouchableOpacity
                                                style={[{
                                                    flexDirection: 'row', paddingTop: 2, alignItems: 'center', textAlign: 'center',
                                                }, activeTab == 'home' && {
                                                    backgroundColor: primaryColour + '21', paddingHorizontal: 8, paddingVertical: 5,
                                                    borderRadius: 20
                                                }]}
                                                onPress={() => {
                                                    defaultLink == '' ?
                                                        navigate('/' + dukanId + '/') :
                                                        navigate('/')
                                                }}

                                            >
                                                <AiFillHome size={20} color={primaryColour} />
                                                {activeTab == 'home' && <Text style={{ fontSize: 11, textAlign: 'center', color: primaryColour }}>{' '}Home</Text>}
                                            </TouchableOpacity>
                                        </div>
                                        <div className={activeTab == 'bag' && "animate__animated animate__bounce"}>
                                            <TouchableOpacity
                                                style={[{
                                                    flexDirection: 'row', flex: 0.25, paddingTop: 2, alignItems: 'center', textAlign: 'center'
                                                }, activeTab == 'bag' && {
                                                    backgroundColor: primaryColour + '21', paddingHorizontal: 8, paddingVertical: 5,
                                                    borderRadius: 20
                                                }]}
                                                onPress={() => {
                                                    defaultLink == '' ?
                                                        navigate('/' + dukanId + '/bag')
                                                        : navigate('/bag')
                                                }}
                                            >
                                                {/* <BsFillBagFill size={23} color={primaryColour} /> */}
                                                <IconContext.Provider value={{ bagCount: bagCount, setbagCount: setbagCount }}>
                                                    <IconWithText
                                                        Icon={() => <FaShoppingBag size={21} color={primaryColour} />}
                                                        text={'' + bagCount}
                                                        color='red'

                                                    />
                                                </IconContext.Provider>
                                                {activeTab == 'bag' && <Text style={{ fontSize: 12, textAlign: 'center', color: primaryColour }}>{'  '}Bag</Text>}
                                            </TouchableOpacity>
                                        </div>
                                        <div className={activeTab == 'orders' && "animate__animated animate__bounce"}>
                                            <TouchableOpacity
                                                style={[{
                                                    flexDirection: 'row', flex: 0.25, paddingTop: 2, alignItems: 'center', textAlign: 'center'
                                                }, activeTab == 'orders' && {
                                                    backgroundColor: primaryColour + '21', paddingHorizontal: 8, paddingVertical: 5,
                                                    borderRadius: 20
                                                }]}
                                                onPress={() => {
                                                    console.log(window.location.pathname.substring(1).split('/')[0] + '/Orders')
                                                    if (secureToken != '@error' && secureToken != '') {
                                                        defaultLink == '' ?
                                                            navigate('/' + dukanId + '/orders')
                                                            : navigate('/orders')
                                                    } else {
                                                        setloginVisible(!loginVisible)
                                                    }
                                                }}
                                            >
                                                <RiFileList3Fill size={20} color={primaryColour} />
                                                <Icon
                                                    name="list" size={23} color={primaryColour} style={{ marginHorizontal: 20 }}
                                                // onPress={() => setbagVisible(!bagVisible)}
                                                />
                                                {activeTab == 'orders' && <Text style={{ fontSize: 11, textAlign: 'center', color: primaryColour }}>{' '}Orders</Text>}
                                            </TouchableOpacity>
                                        </div>
                                        {/* <TouchableOpacity
                                            onPress={() => {
                                                console.log(window.location.pathname.substring(1).split('/')[0] + '/AboutUs')
                                                navigate('/' + window.location.pathname.substring(1).split('/')[0] + '/AboutUs')
                                            }}

                                        >
                                            <Icon
                                                name="address-card" size={23} color={primaryColour} style={{ marginHorizontal: 20 }}
                                                solid
                                            // onPress={() => setbagVisible(!bagVisible)}
                                            />
                                            <Text style={{ fontSize: 12, textAlign: 'center' }}>About us</Text>
                                        </TouchableOpacity> */}
                                    </View>
                                </>
                                : <>{children}</>
                            }
                        </View>

                }
                <SearchModal primaryColour={primaryColour} searchModal={searchModal} setsearchModal={setsearchModal} />
            </TabContext.Provider>

        </>
    );
}