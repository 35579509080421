import { StyleSheet, Text, TouchableOpacity, View, ScrollView, Image, FlatList, Dimensions, RefreshControl } from "react-native";
import { primarybg, serverURL, shadowColor } from "../../../utils/string";
import { useMediaQuery } from 'react-responsive'
import { getData, LoadingIndicator, notify, renavigateFromBag, RoundButton, storeData } from "../../../utils/utils";
import { useContext, useEffect, useState } from "react";
import { getDukanProductsForHome, productsFromArray } from "../../../utils/api";
import Tab from "../tab";
import Product from "../templateUtils/product";
import { TabContext } from "../../../utils/contexts";
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import '../templateUtils/loader.css'
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";


export default function Shop3(props) {
    const navigate = useNavigate();
    const { dukanId } = useParams();
    const { primaryColour, secondaryColor, dukanName } = props
    let location = useLocation();

    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const [isLoading, setisLoading] = useState(true);
    const [quantityKey, setquantityKey] = useState(Math.random());


    const [products, setproducts] = useState([]);
    const [productsPrevStart, setproductsPrevStart] = useState(0);
    const [productsLoadMore, setproductsLoadMore] = useState(true);
    const [loginVisible, setloginVisible] = useState(false);
    const [productsLoading, setproductsLoading] = useState(true);

    const [categories, setcategories] = useState([]);
    const [selectedCategory, setselectedCategory] = useState('all');
    const [categoriesLoading, setcategoriesLoading] = useState(true);

    const [searchText, setsearchText] = useState('');
    const [searchSubmit, setsearchSubmit] = useState(Math.random());

    const { setscrollEnabled } = useContext(TabContext);
    const [pageLoaded, setpageLoaded] = useState(false);

    useEffect(() => {
        if (primaryColour != '#5893D4aa') {
            setpageLoaded(true)
        }
    }, [primaryColour])

    // can_resell: 1
    // city: "Abbottabad"
    // cover: "/media/product_thumbnail/thumbnail_604bb79b190a45b2b587fa11843fca1f.jpg"
    // id: 57
    // is_owner_of_product: 0
    // liked: 0
    // price: "2500"
    // quantity: 3
    // shipping: 0
    // state: "Azad Kashmir"
    // title: "PakNeed key application ap Playstore say download kr sktay hain. Is"
    // user_id: 2

    useEffect(() => {

        if (typeof location.state != 'undefined' && location.state != null) {
            if (typeof location.state.searchText != 'undefined') {
                getDukanProductsForHome(
                    setproducts, [], setproductsLoading, 0, setproductsPrevStart,
                    setproductsLoadMore, 'null', notify, navigate, selectedCategory, location.state.searchText, dukanId)
                console.log(location)
                window.history.replaceState(null, '')
            }
        } else {
            getDukanProductsForHome(
                setproducts, [], setproductsLoading, 0, setproductsPrevStart,
                setproductsLoadMore, 'null', notify, navigate, selectedCategory, searchText, dukanId)

        }
        setscrollEnabled(false);

    }, []);




    return (
        <>
            {!pageLoaded ?


                <View style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: Dimensions.get('window').height }}>
                    <div className="loadingio-spinner-chunk-nx0mjvmz269"><div className="ldio-q1sjvrz6fl">
                        <div><div><div></div><div></div><div></div><div></div></div></div>
                    </div></div>
                </View>
                :
                <Tab primaryColour={primaryColour} secondaryColor={secondaryColor} dukanName={dukanName} notScrollView={true}>
                    <View style={isDesktopOrLaptop ? { paddingTop: 0, } : { marginTop: 70, }}></View>

                    <FlatList
                        contentContainerStyle={{ paddingTop: 20, }}
                        data={products}
                        scrollEnabled={true}

                        onEndReached={({ distanceFromEnd }) => {
                            console.log('loadmore')
                            if (productsLoading) return;
                            if (distanceFromEnd < 0) return;
                            if (productsLoadMore) {
                                console.log('loadmore2')
                                getDukanProductsForHome(
                                    setproducts, products, setproductsLoading, productsPrevStart, setproductsPrevStart,
                                    setproductsLoadMore, 'null', notify, navigate, selectedCategory, searchText, dukanId)
                            }
                        }}


                        ListEmptyComponent={() => {
                            return (
                                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                    <Text style={isDesktopOrLaptop ? { marginTop: 50, backgroundColor: primaryColour, color: secondaryColor, fontSize: 19, padding: 10, }
                                        : { marginTop: 30, backgroundColor: primaryColour, color: secondaryColor, fontSize: 15, padding: 10, }}>No products</Text>
                                    {/* <Icon name="arrow-right" size={25} color={primaryColour} style={{ marginHorizontal: 20 }} /> */}
                                </View>
                            )
                        }}
                        numColumns={isDesktopOrLaptop ? 4 : 2}
                        ListFooterComponent={() => {
                            return (
                                <>
                                    {/* {products.length <= 10 && productsLoadMore && <View style={{ height: isDesktopOrLaptop && Dimensions.get(('window')).height / 9 }} />} */}
                                    {productsLoading ? <LoadingIndicator color={primaryColour} /> : console.log(productsLoading, 'mmm2')}
                                </>
                            )
                        }}

                        initialNumToRender={10}
                        onEndReachedThreshold={0.6}
                        style={{ flex: 1 }}
                        columnWrapperStyle={[{
                            flex: 1,
                            justifyContent: 'center',

                        },
                        isDesktopOrLaptop ? {} : { marginHorizontal: 3 }
                        ]}
                        renderItem={({ item }) => (
                            <Product item={item} primaryColour={primaryColour} secondaryColor={secondaryColor} />
                        )}
                        keyExtractor={item => item.id}
                    />

                </Tab>
            }
        </>
    );
}

