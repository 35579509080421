import { useContext, useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View, ScrollView, FlatList, Dimensions, ActivityIndicator, ImageBackground } from "react-native";
import { defaultLink, primarybg, serverURL } from "../../../utils/string";
import { getData, LoadingIndicator } from "../../../utils/utils";
import { HomeContext } from "../../../utils/contexts";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";

const { height, width } = Dimensions.get('window');

export default function Categories() {
    // const router = useRouter()
    const navigate = useNavigate()
    const { dukanId } = useParams();
    // const move = useLinkTo();
    const { categories, selectedCategory, setselectedCategory, categoriesLoading, primaryColour, secondaryColor } = useContext(HomeContext);
    const onCategoryPress = (item) => {
        if (selectedCategory != item.id) { setselectedCategory(item.id) }
    }
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })


    return (
        <>
            {categories.length > 0 &&
                <div class="two alt-two" style={{ marginTop: !isDesktopOrLaptop ? 0 : 20, marginBottom: 20 }}>
                    <h1 style={{ color: primaryColour, fontSize: !isDesktopOrLaptop && 27, "--bg-color": primaryColour }}>Categories
                        <span style={{ color: primaryColour + 80, fontSize: !isDesktopOrLaptop && 9 }}>Collections of our top items</span>
                    </h1>
                </div>
            }
            {!categoriesLoading ?

                <FlatList
                    contentContainerStyle={{ justifyContent: 'center', paddingBottom: 0, paddingTop: 0 }}
                    data={categories}
                    numColumns={isDesktopOrLaptop ? 4 : 4}
                    // horizontal
                    indicatorStyle={{ backgroundColor: 'red' }}
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}
                    renderItem={({ item }) => (
                        <TouchableOpacity style={{
                            marginHorizontal: 10,
                            marginVertical: 5, width: isDesktopOrLaptop ? Dimensions.get('window').width / 5.2 - 20 : Dimensions.get('window').width / 4 - 20
                        }}
                            onPress={() => {
                                let t = item.name.replace(/[^a-z0-9\s-]/ig, '')
                                    .trim()
                                    .replace(/\s+/g, '-')
                                    .toLowerCase()
                                defaultLink == '' ?
                                    navigate('/' + dukanId + '/catalog/' + item.id + '/' + t)
                                    : navigate('catalog/' + item.id + '/' + t)
                            }}
                        >
                            <ImageBackground
                                style={{
                                    // marginTop: 20,
                                    width: '100%',
                                    height: isDesktopOrLaptop ? 180 : 60,
                                    borderRadius: 7,
                                    backgroundColor: item.image != '' ? primaryColour : 'lightgray',
                                    justifyContent: 'center'
                                    // borderBottomLeftRadius: 0,
                                    // borderBottomRightRadius: 0,
                                    // backgroundColor: '#f0f0f0'
                                }}
                                imageStyle={{
                                    opacity: isDesktopOrLaptop ? 0.7 : 1,
                                    borderRadius: 7,
                                }}
                                resizeMode="cover"
                                source={item.image != '' ? (serverURL + item.image) : ''}

                            >
                                {isDesktopOrLaptop &&
                                    <Text style={{ marginTop: isDesktopOrLaptop && 10, textTransform: 'capitalize', fontSize: 19, letterSpacing: 1.2, fontWeight: '500', color: primarybg, textAlign: 'center' }}>
                                        {item.name}</Text>}
                            </ImageBackground>
                            {!isDesktopOrLaptop &&
                                <Text style={{ marginTop: isDesktopOrLaptop && 10, textTransform: 'capitalize', fontWeight: '500', color: primaryColour, textAlign: 'center' }}>
                                    {item.name}</Text>}
                        </TouchableOpacity>
                    )}
                    keyExtractor={item => item.id}
                />
                :
                <LoadingIndicator />
            }
        </>
    );
}

