import * as React from 'react';
import { useState, useEffect } from 'react';
import { ActivityIndicator, Button, Dimensions, FlatList, Image, ImageBackground, Linking, RefreshControl, ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { borderColor, inactiveColor, primarybg, primaryColour, primaryText, secondarybg, secondaryColor, serverURL } from '../../utils/string';
import { PerfectInput, RoundButton } from '../../utils/utils';

import { getStoreDetailsForCustomization, saveStoreDetailsForCustomization } from '../../utils/api';
import PerfectModal from '../../utils/perfectModel';
import { SketchPicker } from 'react-color';
import { useNavigate } from "react-router-dom";
import Sidebar from '../sidebar';
import ApperanceSidebar from './sidebar';
import { shadow } from '../utils/main';
import { useMediaQuery } from 'react-responsive';

export function SocialLinksSetting(props) {
    const move = useNavigate();
    const [saving, setsaving] = useState(false);
    const [fbLink, setfbLink] = useState('');
    const [instaLink, setinstaLink] = useState('');
    const [youtubeLink, setyoutubeLink] = useState('');
    const [data, setdata] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    useEffect(() => {
        getStoreDetailsForCustomization(move, setisLoading, setdata)
    }, [])

    useEffect(() => {
        if (data != '') {
            setfbLink(data.facebook)
            setinstaLink(data.instagram)
            setyoutubeLink(data.youtube)
        }
    }, [data])


    return (
        <ApperanceSidebar active='socialLinks'>
            <View style={[{ marginHorizontal: '5%', paddingTop: 15, paddingHorizontal: '9%', paddingVertical: '2%', borderRadius: 20 }, shadow]}>
                <Text style={{ color: primaryColour, fontWeight: 'bold', fontSize: 15 }}>Social Profiles</Text>
                <Text style={{ color: 'gray', fontSize: 12 }}>
                    Enter your social media links. Leave blank if you don't want to display
                </Text>
                <Text style={{ color: 'gray', fontSize: 13, marginTop: 15, fontWeight: 'bold' }}>
                    Facebook URL
                </Text>
                <PerfectInput placeholder={'facebook.com/*****'} value={fbLink} setvalue={setfbLink} />
                <Text style={{ color: 'gray', fontSize: 13, marginTop: 15, fontWeight: 'bold' }}>
                    Instagram URL
                </Text>
                <PerfectInput placeholder={'instagram.com/*****'} value={instaLink} setvalue={setinstaLink} />
                <Text style={{ color: 'gray', fontSize: 13, marginTop: 15, fontWeight: 'bold' }}>
                    Youtube URL
                </Text>
                <PerfectInput placeholder={'youtube.com/*****'} value={youtubeLink} setvalue={setyoutubeLink} />
                {saving && <ActivityIndicator color={secondarybg} size={'large'} />}
                <RoundButton text='Save' style={{ alignSelf: 'center', width: '40%' }}
                    onPress={() => { saveStoreDetailsForCustomization(move, setsaving, '', '', '', '', '', '', '', fbLink, instaLink, youtubeLink) }}
                />
            </View>
        </ApperanceSidebar>
    );
}