import * as React from "react";
import './App.css';
import { Routes, Route, Outlet, Link, BrowserRouter as Router, } from "react-router-dom";
import Home1 from "./templates/1/home";
import Shop1 from "./templates/1/shop";
import ProductDetails1 from "./templates/1/productDetail";
import Bag1 from "./templates/1/bag";
import Orders1 from "./templates/1/orders";
import OrderDetails1 from "./templates/1/orderDetails";
import Checkout1 from "./templates/1/checkout";
import CatalogPage1 from "./templates/1/catalog";
import PakNeedHome from "./home";
import { GetBagPage, GetCatalogPage, GetCheckoutPage, GetHomePage, GetOrderDetailsPage, GetOrdersPage, GetProductDetailsPage, GetShopPage } from "./templates/get";
import { defaultLink } from "./utils/string";
import DashboardLogin from "./dashboard/account/login";
import Dashboard from "./dashboard";
import MyItems from "./dashboard/myItems";
import SelectProductCategory from "./dashboard/add/addItems/selectCategory";
import SelectProductSubCategory from "./dashboard/add/addItems/selectSubCategory";
import AddProductForm from "./dashboard/add/addItems/addProductForm";
import OrdersReceived from "./dashboard/orders/ordersReceived";
import OrdersPlaced from "./dashboard/orders/orders";
import PageNotFound from "./home/pageNotFound";
import OrderDetails from "./dashboard/orders/orderDetails";
import MyCatalogs from "./dashboard/myCatalogs";
import CatalogDetails from "./dashboard/myCatalogs/catalogDetails";
import { ToastContainer } from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';
import EditProduct from "./dashboard/add/addItems/editProduct";
import { ColorsSetting } from "./dashboard/appearance/colors";
import { BannersSetting } from "./dashboard/appearance/banners";
import { StoreDetailsSettings } from "./dashboard/appearance/about";
import { SocialLinksSetting } from "./dashboard/appearance/socialLinks";
import OTPVerification from "./templates/3/account/OTPVerification";
import { InitialSettingScreen } from "./dashboard/account/initialSettings";
import Themes from "./dashboard/appearance/themes";
import Settings from "./dashboard/settings";


function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}



export default function App() {
  const [templateId, settemplateId] = React.useState(0);
  if (typeof window == 'undefined') {
    return null
  }


  return (
    <div>
      <ToastContainer autoClose={2000} />
      <Router>
        <Routes>
          <Route path="/" >
            <Route path="web" element={<DashboardLogin />} />
            <Route path="dashboard-login" element={<DashboardLogin />} />
            <Route path="store-initial-settings" element={<InitialSettingScreen />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="my-items" element={<MyItems />} />
            <Route path="select-category" element={<SelectProductCategory />} />
            <Route path="select-subcategory/:category" element={<SelectProductSubCategory />} />
            <Route path="add-item/:category/:subCategory" element={<AddProductForm />} />
            <Route path="edit-item/:productId" element={<EditProduct />} />
            <Route path="orders-received" element={<OrdersReceived />} />
            <Route path="orders-placed" element={<OrdersPlaced />} />
            <Route path="order-details/:orderId" element={<OrderDetails />} />
            <Route path="my-catalogs" element={<MyCatalogs />} />
            <Route path="catalog-details/:catalogId" element={<CatalogDetails />} />
            <Route path="colors-setting" element={<ColorsSetting />} />
            <Route path="top-banners-setting" element={<BannersSetting />} />
            <Route path="store-details-setting" element={<StoreDetailsSettings />} />
            <Route path="social-links-setting" element={<SocialLinksSetting />} />
            <Route path="themes-setting" element={<Themes />} />
            <Route path="settings" element={<Settings />} />

            <Route path="PageNotFound" element={<PageNotFound />} />

            {defaultLink == '' ?
              <>
                <Route index element={<PakNeedHome />} />
                <Route path=":dukanId" element={<GetHomePage />} />

                <Route path=":dukanId/AllProducts" element={<GetShopPage />} />
                <Route path=":dukanId/shop" element={<GetShopPage />} />

                <Route path=":dukanId/catalog/:catalogId/:title" element={<GetCatalogPage />} />

                <Route path=":dukanId/ProductDetails/:productId" element={<GetProductDetailsPage />} />
                <Route path=":dukanId/product/:productId/:title" element={<GetProductDetailsPage />} />
                <Route path=":dukanId/product/:productId" element={<GetProductDetailsPage />} />

                <Route path=":dukanId/bag" element={<GetBagPage />} />

                <Route path=":dukanId/orders" element={<GetOrdersPage />} />

                <Route path=":dukanId/order-details/:orderId" element={<GetOrderDetailsPage />} />

                <Route path=":dukanId/checkout" element={< GetCheckoutPage />} />
              </>
              :
              <>
                <Route index element={<GetHomePage />} />
                <Route path="" element={<GetHomePage />} />

                <Route path="AllProducts" element={<GetShopPage />} />
                <Route path="shop" element={<GetShopPage />} />

                <Route path="catalog/:catalogId/:title" element={<GetCatalogPage />} />

                <Route path="ProductDetails/:productId" element={<GetProductDetailsPage />} />
                <Route path="product/:productId/:title" element={<GetProductDetailsPage />} />

                <Route path="bag" element={<GetBagPage />} />

                <Route path="orders" element={<GetOrdersPage />} />

                <Route path="order-details/:orderId" element={<GetOrderDetailsPage />} />

                <Route path="checkout" element={< GetCheckoutPage />} />

                <Route path=":dukanId" element={<GetHomePage />} />

                <Route path=":dukanId/AllProducts" element={<GetShopPage />} />
                <Route path=":dukanId/shop" element={<GetShopPage />} />

                <Route path=":dukanId/catalog/:catalogId/:title" element={<GetCatalogPage />} />

                <Route path=":dukanId/ProductDetails/:productId" element={<GetProductDetailsPage />} />
                <Route path=":dukanId/product/:productId/:title" element={<GetProductDetailsPage />} />

                <Route path=":dukanId/bag" element={<GetBagPage />} />

                <Route path=":dukanId/orders" element={<GetOrdersPage />} />

                <Route path=":dukanId/order-details/:orderId" element={<GetOrderDetailsPage />} />

                <Route path=":dukanId/checkout" element={< GetCheckoutPage />} />
              </>
            }

            {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
            <Route path="*" element={<NoMatch />} />


          </Route>
        </Routes>
      </Router>



      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}

    </div>
  );

}


