import { StyleSheet, Text, TouchableOpacity, View, ScrollView, Image, ActivityIndicator, ImageBackground, Dimensions } from "react-native";
import Categories from "./categories";
import Footer from "./footer";
import ProfileHeader1 from './header'
import Products from "./products";
import { borderColor, defaultLink, primarybg, serverURL, shadowColor } from "../../../utils/string";
import { useMediaQuery } from 'react-responsive'
import Tab from "../tab";
import { useEffect, useState } from "react";
import { getDukanCatalogs, getDukanCategories, getDukanProductsForHome, getHomePage, subscribeDukan, unsubscribeDukan } from "../../../utils/api";
import { ToastContainer, toast } from 'react-toastify';
import { HomeContext } from "../../../utils/contexts";
import { getData, LoadingIndicator, PerfectInput, RoundButton, storeData } from "../../../utils/utils";
import Login from "../account/login";
import banner2 from '../../../assets/banner2.jpg'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../../../utils/loader.css'
import '../templateUtils/movingImage.css'


export default function Home2(props) {

    const navigate = useNavigate();
    const { dukanId } = useParams();
    const { primaryColour, facebook,
        instagram, youtube, secondaryColor, dukanName, } = props

    const notify = (t) => toast.dark(t, {
        position: toast.POSITION.BOTTOM_CENTER
    });
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const [data, setdata] = useState({});
    const [isLoading, setisLoading] = useState(true);

    // const [dukanId, setdukanId] = useState('');

    const [categories, setcategories] = useState([]);
    const [selectedCategory, setselectedCategory] = useState('all');
    const [categoriesLoading, setcategoriesLoading] = useState(true);

    const [searchText, setsearchText] = useState('');
    const [searchSubmit, setsearchSubmit] = useState(Math.random());

    const [products, setproducts] = useState([]);
    const [productsPrevStart, setproductsPrevStart] = useState(0);
    const [productsLoadMore, setproductsLoadMore] = useState(true);
    const [loginVisible, setloginVisible] = useState(false);
    const [productsLoading, setproductsLoading] = useState(true);

    const [secureToken, setsecureToken] = useState('');
    const [subscribeKey, setSubscribeKey] = useState(Math.random());


    const [pageLoaded, setpageLoaded] = useState(false);

    useEffect(() => {
        if (primaryColour != '#5893D4aa') {
            setpageLoaded(true)
        }
    }, [primaryColour])


    useEffect(() => {
        if (defaultLink == '') {
            if (dukanId && isLoading) {
                storeData(dukanId, 'dukanLink')
                getData('secure_token', setsecureToken);
                getHomePage(setdata, setisLoading, dukanId, notify, navigate)
                setisLoading(false)
                getDukanCatalogs(dukanId, setcategories, setcategoriesLoading, navigate)
                getDukanProductsForHome(
                    setproducts, [], setproductsLoading, 0, setproductsPrevStart,
                    setproductsLoadMore, dukanId, notify, navigate, selectedCategory, searchText, dukanId)
            }
        } else {
            storeData(dukanId, 'dukanLink')
            getData('secure_token', setsecureToken);
            getHomePage(setdata, setisLoading, dukanId, notify, navigate)
            setisLoading(false)
            getDukanCatalogs(dukanId, setcategories, setcategoriesLoading, navigate)
            getDukanProductsForHome(
                setproducts, [], setproductsLoading, 0, setproductsPrevStart,
                setproductsLoadMore, dukanId, notify, navigate, selectedCategory, searchText, dukanId)
        }

    }, [dukanId])



    //on selected category change
    useEffect(() => {
        if (typeof data.id !== 'undefined') {
            getDukanProductsForHome(
                setproducts, [], setproductsLoading, 0, setproductsPrevStart,
                setproductsLoadMore, data.id, notify, navigate, selectedCategory, searchText)
        }
    }, [selectedCategory])

    //on search 
    useEffect(() => {
        if (typeof data.id !== 'undefined') {
            getDukanProductsForHome(
                setproducts, [], setproductsLoading, 0, setproductsPrevStart,
                setproductsLoadMore, data.id, notify, navigate, selectedCategory, searchText)
        }
    }, [searchSubmit])




    return (
        <>
            {!pageLoaded ?
                <View style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: Dimensions.get('window').height }}>
                    <div className="loadingio-spinner-chunk-nx0mjvmz269"><div className="ldio-q1sjvrz6fl">
                        <div><div><div></div><div></div><div></div><div></div></div></div>
                    </div></div>
                </View>
                :
                <HomeContext.Provider
                    value={{
                        data, setdata, products, categories, selectedCategory, setselectedCategory, productsLoading,
                        categoriesLoading, searchText, setsearchText, primaryColour, facebook,
                        instagram, youtube, secondaryColor, dukanName,
                    }}
                >
                    <Tab primaryColour={primaryColour} storeName={data.user_name} activeTab='home'>
                        {!isLoading ?
                            <>
                                <Login visible={loginVisible} setvisible={setloginVisible} primaryColour={primaryColour} />
                                <ToastContainer autoClose={2000} />
                                {/* <ScrollView> */}

                                <ProfileHeader1 />


                                <View style={[isDesktopOrLaptop ? { marginHorizontal: '5%' } :
                                    { backgroundColor: primarybg, paddingTop: 5, borderTopLeftRadius: 0, borderTopRightRadius: 0 }]}>
                                    <Categories />
                                    <View key={searchSubmit} >
                                        <View key={selectedCategory}>
                                            <Products />
                                        </View>
                                    </View>
                                    <View style={{ width: '90%', backgroundColor: 'lightgray', height: 0, marginBottom: 30, alignSelf: 'center' }} />
                                </View>
                                {/* <Text style={{ marginTop: 20, fontSize: 60, color: primaryColour, textAlign: 'center' }}>;;</Text> */}
                                {/* <Text style={{ opacity: 0.6, marginVertical: 20, fontSize: 20, color: primaryColour, textAlign: 'center', fontWeight: 'bold', fontStyle: 'italic' }}> {data.description}</Text> */}
                                {/* <Text style={{ marginBottom: 20, fontSize: 60, color: primaryColour, textAlign: 'center' }}>;;</Text> */}
                                {Object.keys(data).length !== 0 &&
                                    <View style={{ marginBottom: 90, backgroundColor: 'black' }}>
                                        <section className="hero-3" style={{ height: 720, width: '100%' }}>
                                            <View style={{ height: '65%' }} />
                                            <View style={{ width: '90%', marginTop: 'auto', marginHorizontal: '5%', alignSelf: 'center' }}>
                                                <Text style={{ fontSize: isDesktopOrLaptop ? 80 : 40, color: 'white', textTransform: 'uppercase' }}>{dukanName}</Text>
                                                {/* <Text style={{ fontSize: isDesktopOrLaptop ? 30 : 20, color: 'white' }}>Street art salvia irony wolf waistcoat actually lomo meh fap jean shorts.</Text> */}
                                            </View>
                                        </section>
                                    </View>
                                }
                                <Footer
                                    dukanId={dukanId} data={data}
                                    secureToken={secureToken}
                                    onSubscribe={() => {
                                        if (secureToken != '@error' && secureToken != '') {
                                            if (data.same_dukan !== 1) {
                                                if (data.user_following === 0) {
                                                    subscribeDukan(data.id, navigate)
                                                    data.user_following = 1
                                                    notify('successfully subscribed')
                                                    setSubscribeKey(Math.random())
                                                } else {
                                                    unsubscribeDukan(data.id, navigate)
                                                    data.user_following = 0
                                                    notify('successfully unsubscribed')
                                                    setSubscribeKey(Math.random())
                                                }
                                            } else {
                                                notify('Cant subscribe own dukan')
                                            }
                                        } else { setloginVisible(!loginVisible) }
                                    }}

                                />
                            </>
                            :
                            <LoadingIndicator />
                        }
                    </Tab>
                </HomeContext.Provider >
            }
        </>
    );
}

