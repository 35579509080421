import React, { useState } from 'react';
import {
    View,
    Text,
    Image,
    Alert,
    Dimensions,
    ImageBackground,
    Modal,
    KeyboardAvoidingView
} from 'react-native';

const dimensions = Dimensions.get('window');


export default function PerfectModal(props) {
    const { style, modalVisible, onRequestClose } = props
    return (
        <Modal
            // backdropColor={'green'}
            backdropOpacity={0.21}
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={typeof onRequestClose !== 'undefined' ? onRequestClose : null}
            style={{ backgroundColor: 'rgba(0, 255, 0, 0.5)', width: '90%' }}
        >
            <KeyboardAvoidingView
                behavior="position"
                enabled
                style={{
                    flex: 1,
                    justifyContent: 'center',
                    padding: 20,
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {/* <View > */}
                <View style={[{
                    margin: 20,
                    width: '90%',
                    backgroundColor: "white",
                    borderRadius: 3,
                    padding: 35,
                    shadowColor: "#000",
                    shadowOffset: {
                        width: 0,
                        height: 2
                    },
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,
                    elevation: 5,
                    marginHorizontal: 7
                },
                typeof style !== 'undefined' ? style : {}
                ]}>
                    {props.children}
                </View>
                {/* </View> */}

            </KeyboardAvoidingView>
        </Modal>
    )
}