

// export const primaryColour = "#5893D4";

import { FaBars, FaGamepad, FaHamburger } from 'react-icons/fa';

// export const primaryColour = "#5893D4";
export const primaryColour = "#5893D4";
export const versionExpiryDate = new Date('2021-09-6');;

// export const primaryColour = "white";
// export const primaryColour = 'black';
// export const serverURL = 'http://localhost:8080';
// export const serverURL = 'https://server.pakneed.com';
export const serverURL = 'https://demo-s.bazars.pk';
export const domainName = ''
export const defaultLink = ''
export const demo = serverURL == 'https://demo-s.bazars.pk'
export const textColor = 'white'
// export const primarybg = '#e3edf7'

export const primarybg = '#ffffff';
export const shadowColor = "black";
export const lightTextColor = 'white';
export const inputBackgound = '#fafafc';
export const secondarybg = '#F7B633';
// export const primaryLightColor = "#8f9db8"
// export const primaryLightColor = '#cfc6b8';
// export const primaryLightColor = '#e4144d8a';
export const primaryLightColor = '#403D39';
export const dukanDefaultImage = 'dukanDefault.jpg'
export const primaryLightColorButton = '#80807a';
export const borderColor = '#ede9e4';

export const otherCategories = [
  { id: 8, title: 'Cell Phones & Accessories', image: require('../assets/img/categories/cell_phone.png'), icon: false },
  { id: 4, title: 'Vehicles', image: require('../assets/img/categories/vehicles.png'), icon: false },
  { id: 2, title: 'Property For Sale', image: require('../assets/img/categories/property_for_sale.png'), icon: false },
  { id: 10, title: 'Property For Rent', image: require('../assets/img/categories/property_for_rent.png'), icon: false },
  { id: 1, title: 'Electronics & Home appliances', image: require('../assets/img/categories/home_appliance.png'), icon: false },
  { id: 11, title: 'Bikes', image: require('../assets/img/categories/bikes.png'), icon: false },
  { id: 12, title: 'Speciality Services', image: require('../assets/img/categories/services.png'), icon: false },
  { id: 3, title: 'Food & Fruits', image: () => (<FaHamburger color={primaryColour} size={90} />), icon: true },
  // { id: 13, title: 'Jobs', image: require('../assets/img/categories/jobs.png'), icon: false },
  { id: 5, title: 'Pet Supplies', image: require('../assets/img/categories/animals.png') },
  { id: 14, title: 'Furniture & Home Decoration', image: require('../assets/img/categories/furniture.png'), icon: false },
  { id: 6, title: 'Clothing, Shoes & Accessories', image: require('../assets/img/categories/fashion.jpg'), icon: false },
  { id: 15, title: 'Kids', image: require('../assets/img/categories/kids.png'), icon: false },
  { id: 16, title: 'Books & Hobbies', image: require('../assets/img/categories/books.png'), icon: false },
  { id: 17, title: 'Health & Beauty', image: require('../assets/img/categories/health.png'), icon: false },
  { id: 18, title: 'Jewelery & Watches', image: require('../assets/img/categories/watch.png'), icon: false },
  { id: 19, title: 'Art', image: require('../assets/img/categories/art.png'), icon: false },
  { id: 20, title: 'Video Games & Consoles', image: () => (<FaGamepad color={primaryColour} size={90} />), icon: true },
  { id: 7, title: 'Everything Else', image: () => (<FaBars color={primaryColour} size={90} />), icon: true },
];

export const dukanTypes = [
  'Fashion', 'Food', 'Pharmacies and hospitals ', 'Jewelry Stores',
  'Electronics', 'Animals and birds', 'Kiryana Stores',
  'Cosmetics Stores', 'Computers & Accessories', 'Phones & Accessories'
  , 'Property', 'Vehicles and Repairing', 'Book Stores',
  'Khokha', 'My dukan category is not in list'
]

export const categoryId8 = [
  { id: 1, title: 'Tablets' },
  { id: 2, title: 'Accessories' },
  { id: 3, title: 'Mobile phones' }
]
export const categoryId4 = [
  { id: 4, title: 'Cars' },
  { id: 5, title: 'Cars on installments' },
  { id: 6, title: 'Cars Accessories' },
  { id: 7, title: 'Spare parts' },
  { id: 8, title: 'Buses, vans & Trucks' },
  { id: 9, title: 'Rikshaw & Chandgari' },
  { id: 10, title: 'Boats' },
  { id: 11, title: 'Tractors & Trailers' },
  { id: 12, title: 'Other' }
]

export const categoryId2 = [
  { id: 13, title: 'Houses' },
  { id: 14, title: 'Offices & shops' },
  { id: 15, title: 'Portion & Floors' },
  { id: 16, title: 'Appartments & Flats' },
  { id: 17, title: 'Lands & Plots' },
]
export const categoryId10 = [
  { id: 18, title: 'Houses' },
  { id: 19, title: 'Offices & shops' },
  { id: 20, title: 'Portion & Floors' },
  { id: 21, title: 'Appartments & Flats' },
  { id: 22, title: 'Lands & Plots' },
]

export const categoryId1 = [
  { id: 23, title: 'TV,Video & Audio' },
  { id: 24, title: 'Cameras & Accessories' },
  { id: 25, title: 'Computer & Accessories' },
  { id: 26, title: 'Games & Entertainment' },
  { id: 27, title: 'Other Home appliances' },
  { id: 28, title: 'Generator,UPS & Power Solutions' },
  { id: 29, title: 'Kitchen Appliances' },
  { id: 30, title: 'AC & Coolers' },
  { id: 31, title: 'Freezers & Fridges' },
  { id: 32, title: 'Dryers & Washing Machine' },
]

export const categoryId11 = [
  { id: 33, title: 'Bikes & Motorcycles' },
  { id: 34, title: 'Spare parts' },
  { id: 35, title: 'Bicycles' },
  { id: 36, title: 'Scooty' },
]

export const categoryId12 = [
  { id: 37, title: 'Education & Classes' },
  { id: 38, title: 'Travel & Visa' },
  { id: 39, title: 'Car Rental' },
  { id: 40, title: 'Driver & Taxi' },
  { id: 41, title: 'Web Development' },
  { id: 42, title: 'App Development' },
  { id: 43, title: 'Electronics & Computer repairs' },
  { id: 44, title: 'Event Services' },
  { id: 45, title: 'Health & Beauty' },
  { id: 46, title: 'Maids & Domestic help' },
  { id: 47, title: 'Movers & Packers' },
  { id: 48, title: 'Education & Classes' },
  { id: 49, title: 'Home & Office repair' },
  { id: 50, title: 'Catering & Restaurant' },
  { id: 51, title: 'Farm & Fresh Food' },
  { id: 52, title: 'Other' },
]

export const categoryId5 = [
  { id: 111, title: 'Goat' },
  { id: 112, title: 'Cow' },
  { id: 113, title: 'Camel' },
  { id: 53, title: 'Pet food & Acessories' },
  { id: 54, title: 'Horses' },
  { id: 55, title: 'Livestock' },
  { id: 56, title: 'Dogs' },
  { id: 57, title: 'Cats' },
  { id: 58, title: 'Hens' },
  { id: 59, title: 'Birds' },
  { id: 60, title: 'Fish' },
  { id: 61, title: 'Other' },
]

export const categoryId14 = [
  { id: 62, title: 'Sofa & Chairs' },
  { id: 63, title: 'Beds & Wardrobes' },
  { id: 64, title: 'Home decoration' },
  { id: 65, title: 'Tables & Dining' },
  { id: 66, title: 'Garden & outdoor' },
  { id: 67, title: 'Painting & Mirrors' },
  { id: 68, title: 'Rugs & Carpets' },
  { id: 69, title: 'Curtains' },
  { id: 70, title: 'Other' },
]

export const categoryId15 = [
  { id: 71, title: 'Params & Walkers' },
  { id: 72, title: 'Slides & Swings' },
  { id: 73, title: 'Kids Bikes' },
  { id: 74, title: 'Kids Accessories' },
  { id: 75, title: 'Toys' },
  { id: 76, title: 'Kids Furniture' },
  { id: 77, title: 'Other' },
]

export const categoryId16 = [
  { id: 78, title: 'Magzines & Books' },
  { id: 79, title: 'Musical Instruments' },
  { id: 80, title: 'Sports Equipment' },
  { id: 81, title: 'Other' },
]

export const categoryId17 = [
  { id: 82, title: 'Gym & Fitness' },
  { id: 83, title: 'Makeup' },
  { id: 84, title: 'Skin & Hair' },
  { id: 85, title: 'Other' },
]

export const categoryId18 = [
  { id: 86, title: 'Jewelry' },
  { id: 87, title: 'Watches' },
  { id: 88, title: 'Other' },
]

export const categoryId19 = [
  { id: 89, title: 'Paintings' },
  { id: 90, title: 'Other' },
]

export const categoryId20 = [
  { id: 91, title: 'Video Games' },
  { id: 92, title: 'Consoles' },
  { id: 93, title: 'Other' },
]
export const categoryId7 = [
  { id: 94, title: 'Other' },
]
export const categoryIdOther = [
  { id: 95, title: 'Other' }
]
export const categoryId9 = [
  { id: 96, title: 'All' },
]

export const categoryId6 = [
  { id: 97, title: 'Acessories' },
  { id: 98, title: 'Clothes' },
  { id: 99, title: 'Footwear' },
  { id: 100, title: 'Skin & Hair' },
  { id: 101, title: 'Other' },
]

export const categoryId3 = [
  { id: 102, title: 'Food' },
  { id: 103, title: 'Fruits' },
  { id: 104, title: 'Other' },
]

export const subCategories = [
  { id: 1, title: 'Tablets' },
  { id: 2, title: 'Accessories' },
  { id: 3, title: 'Mobile phones' },
  { id: 4, title: 'Cars' },
  { id: 5, title: 'Cars on installments' },
  { id: 6, title: 'Cars Accessories' },
  { id: 7, title: 'Spare parts' },
  { id: 8, title: 'Buses, vans & Trucks' },
  { id: 9, title: 'Rikshaw & Chandgari' },
  { id: 10, title: 'Boats' },
  { id: 11, title: 'Tractors & Trailers' },
  { id: 12, title: 'Other' },
  { id: 13, title: 'Houses' },
  { id: 14, title: 'Offices & shops' },
  { id: 15, title: 'Portion & Floors' },
  { id: 16, title: 'Appartments & Flats' },
  { id: 17, title: 'Lands & Plots' },
  { id: 18, title: 'Houses' },
  { id: 19, title: 'Offices & shops' },
  { id: 20, title: 'Portion & Floors' },
  { id: 21, title: 'Appartments & Flats' },
  { id: 22, title: 'Lands & Plots' },
  { id: 23, title: 'Houses' },
  { id: 24, title: 'Offices & shops' },
  { id: 25, title: 'Portion & Floors' },
  { id: 26, title: 'Appartments & Flats' },
  { id: 27, title: 'Lands & Plots' },
  { id: 28, title: 'Portion & Floors' },
  { id: 29, title: 'Appartments & Flats' },
  { id: 30, title: 'Lands & Plots' },
  { id: 31, title: 'Appartments & Flats' },
  { id: 32, title: 'Lands & Plots' },
  { id: 33, title: 'Bikes & Motorcycles' },
  { id: 34, title: 'Spare parts' },
  { id: 35, title: 'Bicycles' },
  { id: 36, title: 'Scooty' },
  { id: 37, title: 'Education & Classes' },
  { id: 38, title: 'Travel & Visa' },
  { id: 39, title: 'Car Rental' },
  { id: 40, title: 'Driver & Taxi' },
  { id: 41, title: 'Web Development' },
  { id: 42, title: 'App Development' },
  { id: 43, title: 'Electronics & Computer repairs' },
  { id: 44, title: 'Event Services' },
  { id: 45, title: 'Health & Beauty' },
  { id: 46, title: 'Maids & Domestic help' },
  { id: 47, title: 'Movers & Packers' },
  { id: 48, title: 'Education & Classes' },
  { id: 49, title: 'Home & Office repair' },
  { id: 50, title: 'Catering & Restaurant' },
  { id: 51, title: 'Farm & Fresh Food' },
  { id: 52, title: 'Other' },
  { id: 53, title: 'Pet food & Acessories' },
  { id: 54, title: 'Horses' },
  { id: 55, title: 'Livestock' },
  { id: 56, title: 'Dogs' },
  { id: 57, title: 'Cats' },
  { id: 58, title: 'Hens' },
  { id: 59, title: 'Birds' },
  { id: 60, title: 'Fish' },
  { id: 61, title: 'Other' },

  { id: 62, title: 'Sofa & Chairs' },
  { id: 63, title: 'Beds & Wardrobes' },
  { id: 64, title: 'Home decoration' },
  { id: 65, title: 'Tables & Dining' },
  { id: 66, title: 'Garden & outdoor' },
  { id: 67, title: 'Painting & Mirrors' },
  { id: 68, title: 'Rugs & Carpets' },
  { id: 69, title: 'Curtains' },
  { id: 70, title: 'Other' },
  { id: 71, title: 'Params & Walkers' },
  { id: 72, title: 'Slides & Swings' },
  { id: 73, title: 'Kids Bikes' },
  { id: 74, title: 'Kids Accessories' },
  { id: 75, title: 'Toys' },
  { id: 76, title: 'Kids Furniture' },
  { id: 77, title: 'Other' },
  { id: 78, title: 'Magzines & Books' },
  { id: 79, title: 'Musical Instruments' },
  { id: 80, title: 'Sports Equipment' },
  { id: 81, title: 'Other' },
  { id: 82, title: 'Gym & Fitness' },
  { id: 83, title: 'Makeup' },
  { id: 84, title: 'Skin & Hair' },
  { id: 85, title: 'Other' },
  { id: 86, title: 'Jewelry' },
  { id: 87, title: 'Watches' },
  { id: 88, title: 'Other' },
  { id: 89, title: 'Paintings' },
  { id: 90, title: 'Other' },
  { id: 91, title: 'Video Games' },
  { id: 92, title: 'Consoles' },
  { id: 93, title: 'Other' },
  { id: 94, title: 'Other' },
  { id: 95, title: 'Other' },
  { id: 96, title: 'All' },
  { id: 97, title: 'Acessories' },
  { id: 98, title: 'Clothes' },
  { id: 99, title: 'Footwear' },
  { id: 100, title: 'Skin & Hair' },
  { id: 101, title: 'Other' },
  { id: 102, title: 'Food' },
  { id: 103, title: 'Fruits' },
  { id: 104, title: 'Other' },
  { id: 111, title: 'Goat' },
  { id: 112, title: 'Cow' },
  { id: 113, title: 'Camel' },
]


export const cityList = [
  '',
  'Abbottabad',
  'Adezai',
  'Ali Bandar',
  'Amir Chah',
  'Attock',
  'Ayubia',
  'Bahawalpur',
  'Baden',
  'Bagh',
  'Bahawalnagar',
  'Burewala',
  'Banda Daud Shah',
  'Bannu district|Bannu',
  'Batagram',
  'Bazdar',
  'Bela',
  'Bellpat',
  'Bhag',
  'Bhakkar',
  'Bhalwal',
  'Bhimber',
  'Birote',
  'Buner',
  'Burj',
  'Chiniot',
  'Chachro',
  'Chagai',
  'Chah Sandan',
  'Chailianwala',
  'Chakdara',
  'Chakku',
  'Chakwal',
  'Chaman',
  'Charsadda',
  'Chhatr',
  'Chichawatni',
  'Chitral',
  'Dadu',
  'Dera Ghazi Khan',
  'Dera Ismail Khan',
  'Dalbandin',
  'Dargai',
  'Darya Khan',
  'Daska',
  'Dera Bugti',
  'Dhana Sar',
  'Digri',
  'Dina City|Dina',
  'Dinga',
  ', Pakistan|Diplo',
  'Diwana',
  'Dokri',
  'Drosh',
  'Duki',
  'Dushi',
  'Duzab',
  'Faisalabad',
  'Fateh Jang',
  'Ghotki',
  'Gwadar',
  'Gujranwala',
  'Gujrat',
  'Gadra',
  'Gajar',
  'Gandava',
  'Garhi Khairo',
  'Garruck',
  'Ghakhar Mandi',
  'Ghanian',
  'Ghauspur',
  'Ghazluna',
  'Girdan',
  'Gulistan',
  'Gwash',
  'Hyderabad',
  'Hala',
  'Haripur',
  'Hab Chauki',
  'Hafizabad',
  'Hameedabad',
  'Hangu',
  'Harnai',
  'Hasilpur',
  'Haveli Lakha',
  'Hinglaj',
  'Hoshab',
  'Islamabad',
  'Islamkot',
  'Ispikan',
  'Jacobabad',
  'Jamshoro',
  'Jhang',
  'Jhelum',
  'Jamesabad',
  'Jampur',
  'Janghar',
  'Jati(Mughalbhin)',
  'Jauharabad',
  'Jhal',
  'Jhal Jhao',
  'Jhatpat',
  'Jhudo',
  'Jiwani',
  'Jungshahi',
  'Karachi',
  'Kotri',
  'Kalam',
  'Kalandi',
  'Kalat',
  'Kamalia',
  'Kamararod',
  'Kamber',
  'Kamokey',
  'Kanak',
  'Kandi',
  'Kandiaro',
  'Kanpur',
  'Kapip',
  'Kappar',
  'Karak City',
  'Karodi',
  'Kashmor',
  'Kasur',
  'Katuri',
  'Keti Bandar',
  'Khairpur',
  'Khanaspur',
  'Khanewal',
  'Kharan',
  'kharian',
  'Khokhropur',
  'Khora',
  'Khushab',
  'Khuzdar',
  'Kikki',
  'Klupro',
  'Kohan',
  'Kohat',
  'Kohistan',
  'Kohlu',
  'Korak',
  'Korangi',
  'Kot Sarae',
  'Kotli',
  'Lahore',
  'Larkana',
  'Lahri',
  'Lakki Marwat',
  'Lasbela',
  'Latamber',
  'Layyah',
  'Leiah',
  'Liari',
  'Lodhran',
  'Loralai',
  'Lower Dir',
  'Shadan Lund',
  'Multan',
  'Mandi Bahauddin',
  'Mansehra',
  'Mian Chanu',
  'Mirpur',
  ', Pakistan|Moro',
  'Mardan',
  'Mach',
  'Madyan',
  'Malakand',
  'Mand',
  'Manguchar',
  'Mashki Chah',
  'Maslti',
  'Mastuj',
  'Mastung',
  'Mathi',
  'Matiari',
  'Mehar',
  'Mekhtar',
  'Merui',
  'Mianwali',
  'Mianez',
  'Mirpur Batoro',
  'Mirpur Khas',
  'Mirpur Sakro',
  'Mithi',
  'Mongora',
  'Murgha Kibzai',
  'Muridke',
  'Musa Khel Bazar',
  'Muzaffar Garh',
  'Muzaffarabad',
  'Nawabshah',
  'Nazimabad',
  'Nowshera',
  'Nagar Parkar',
  'Nagha Kalat',
  'Nal',
  'Naokot',
  'Nasirabad',
  'Nauroz Kalat',
  'Naushara',
  'Nur Gamma',
  'Nushki',
  'Nuttal',
  'Okara',
  'Ormara',
  'Peshawar',
  'Panjgur',
  'Pasni City',
  'Paharpur',
  'Palantuk',
  'Pendoo',
  'Piharak',
  'Pirmahal',
  'Pishin',
  'Plandri',
  'Pokran',
  'Pounch',
  'Quetta',
  'Qambar',
  'Qamruddin Karez',
  'Qazi Ahmad',
  'Qila Abdullah',
  'Qila Ladgasht',
  'Qila Safed',
  'Qila Saifullah',
  'Rawalpindi',
  'Rabwah',
  'Rahim Yar Khan',
  'Rajan Pur',
  'Rakhni',
  'Ranipur',
  'Ratodero',
  'Rawalakot',
  'Renala Khurd',
  'Robat Thana',
  'Rodkhan',
  'Rohri',
  'Sialkot',
  'Sadiqabad',
  'Safdar Abad- (Dhaban Singh)',
  'Sahiwal',
  'Saidu Sharif',
  'Saindak',
  'Sakrand',
  'Sanjawi',
  'Sargodha',
  'Saruna',
  'Shabaz Kalat',
  'Shadadkhot',
  'Shahbandar',
  'Shahpur',
  'Shahpur Chakar',
  'Shakargarh',
  'Shangla',
  'Sharam Jogizai',
  'Sheikhupura',
  'Shikarpur',
  'Shingar',
  'Shorap',
  'Sibi',
  'Sohawa',
  'Sonmiani',
  'Sooianwala',
  'Spezand',
  'Spintangi',
  'Sui',
  'Sujawal',
  'Sukkur',
  'Suntsar',
  'Surab',
  'Swabi',
  'Swat',
  'Tando Adam',
  'Tando Bago',
  'Tangi',
  'Tank City',
  'Tar Ahamd Rind',
  'Thalo',
  'Thatta',
  'Toba Tek Singh',
  'Tordher',
  'Tujal',
  'Tump',
  'Turbat',
  'Umarao',
  'Umarkot',
  'Upper Dir',
  'Uthal',
  'Vehari',
  'Veirwaro',
  'Vitakri',
  'Wadh',
  'Wah Cantt',
  'Warah',
  'Washap',
  'Wasjuk',
  'Wazirabad',
  'Yakmach',
  'Zhob',
  'Other',
];
