import { borderColor, primaryColour } from "../../utils/string";
import { Alert, Platform } from 'react-native'

export const shadow = {
    shadowColor: '#91a8eb',
    shadowOffset: {
        width: 0,
        height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,

    elevation: 5,
    borderColor: borderColor,
    borderWidth: 1
}




const alertPolyfill = (title, description, options, extra) => {
    const result = window.confirm([title, description].filter(Boolean).join('\n'))

    if (result) {
        const confirmOption = options.find(({ style }) => style !== 'cancel')
        confirmOption && confirmOption.onPress()
    } else {
        const cancelOption = options.find(({ style }) => style === 'cancel')
        cancelOption && cancelOption.onPress()
    }
}

export const PerfectAlert = Platform.OS === 'web' ? alertPolyfill : Alert.alert

