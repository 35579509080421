import { StyleSheet, Text, TouchableOpacity, View, ScrollView, Image, ActivityIndicator, ImageBackground, Dimensions, ImageBackgroundBase, Button } from "react-native";
import Categories from "./categories";
import Footer from "./footer";
import ProfileHeader1 from './header'
import Products from "./products";
import { borderColor, defaultLink, primarybg, serverURL, shadowColor, primaryColour, textColor } from "../../../utils/string";
import { useMediaQuery } from 'react-responsive'
import Tab from "../tab";
import { useEffect, useState } from "react";
import { getDukanCatalogs, getDukanCategories, getDukanProductsForHome, getHomePage, subscribeDukan, unsubscribeDukan } from "../../../utils/api";
import { ToastContainer, toast } from 'react-toastify';
import { HomeContext } from "../../../utils/contexts";
import { getData, LoadingIndicator, PerfectInput, RoundButton, storeData } from "../../../utils/utils";
import Login from "../account/login";
import banner2 from '../../../assets/banner2.jpg'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../../../utils/loader.css'
import { IoNotifications } from "react-icons/io5";




export default function Home3(props) {

    const navigate = useNavigate();
    const { dukanId } = useParams();
    const { primaryColour, facebook, instagram, youtube, dukanName, dukanDescription, dukanContactNumber, secondaryColor } = props

    const notify = (t) => toast.dark(t, {
        position: toast.POSITION.BOTTOM_CENTER
    });
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const [data, setdata] = useState({});
    const [isLoading, setisLoading] = useState(true);

    // const [dukanId, setdukanId] = useState('');

    const [categories, setcategories] = useState([]);
    const [selectedCategory, setselectedCategory] = useState('all');
    const [categoriesLoading, setcategoriesLoading] = useState(true);

    const [searchText, setsearchText] = useState('');
    const [searchSubmit, setsearchSubmit] = useState(Math.random());
    const [products, setproducts] = useState([]);
    const [productsPrevStart, setproductsPrevStart] = useState(0);
    const [productsLoadMore, setproductsLoadMore] = useState(true);
    const [loginVisible, setloginVisible] = useState(false);
    const [productsLoading, setproductsLoading] = useState(true);
    const [secureToken, setsecureToken] = useState('');
    const [subscribeKey, setSubscribeKey] = useState(Math.random());
    const [pageLoaded, setpageLoaded] = useState(false);



    useEffect(() => {
        if (primaryColour != '#5893D4aa') {
            setpageLoaded(true)
        }
    }, [primaryColour])


    useEffect(() => {

        console.log('getting products', dukanId)
        if (defaultLink == '') {
            if (dukanId && isLoading && dukanId != 'null') {

                storeData(dukanId, 'dukanLink')
                getData('secure_token', setsecureToken);
                getHomePage(setdata, setisLoading, dukanId, notify, navigate)
                setisLoading(false)
                getDukanCatalogs(dukanId, setcategories, setcategoriesLoading, navigate)
                getDukanProductsForHome(
                    setproducts, [], setproductsLoading, 0, setproductsPrevStart,
                    setproductsLoadMore, dukanId, notify, navigate, selectedCategory, searchText, dukanId)
            }
        } else {
            storeData(dukanId, 'dukanLink')
            getData('secure_token', setsecureToken);
            getHomePage(setdata, setisLoading, dukanId, notify, navigate)
            setisLoading(false)
            getDukanCatalogs(dukanId, setcategories, setcategoriesLoading, navigate)
            getDukanProductsForHome(
                setproducts, [], setproductsLoading, 0, setproductsPrevStart,
                setproductsLoadMore, dukanId, notify, navigate, selectedCategory, searchText, dukanId)
        }

    }, [dukanId])



    //on selected category change
    // useEffect(() => {
    //     if (typeof data.id !== 'undefined') {
    //         getDukanProductsForHome(
    //             setproducts, [], setproductsLoading, 0, setproductsPrevStart,
    //             setproductsLoadMore, data.id, notify, navigate, selectedCategory, searchText, dukanId)
    //     }
    // }, [selectedCategory])

    //on search 
    // useEffect(() => {
    //     if (typeof data.id !== 'undefined') {
    //         getDukanProductsForHome(
    //             setproducts, [], setproductsLoading, 0, setproductsPrevStart,
    //             setproductsLoadMore, data.id, notify, navigate, selectedCategory, searchText, dukanId)
    //     }
    // }, [searchSubmit])




    return (
        <>
            {!pageLoaded ?
                <View style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: Dimensions.get('window').height }}>
                    <div className="loadingio-spinner-chunk-nx0mjvmz269"><div className="ldio-q1sjvrz6fl">
                        <div><div><div></div><div></div><div></div><div></div></div></div>
                    </div></div>
                </View>
                :
                <HomeContext.Provider
                    value={{
                        data, setdata, products, categories, selectedCategory, setselectedCategory, productsLoading,
                        categoriesLoading, searchText, setsearchText, primaryColour
                    }}
                >
                    {/* <Head>
                <title>{data.user_name && data.user_name}</title>
                <meta property="og:title" content={data.user_name && data.user_name} />
                <meta property="og:description" content={data.description && data.description} />
                {data.dp !== 'null' &&
                    <meta property="og:image" content={serverURL + data.dp} />
                }
            </Head> */}
                    <Tab primaryColour={primaryColour} dukanName={dukanName} dukanContactNumber={dukanContactNumber} secondaryColor={secondaryColor}>
                        {!isLoading ?
                            <>
                                <Login visible={loginVisible} setvisible={setloginVisible} secondaryColor={secondaryColor} primaryColour={primaryColour} />
                                <ToastContainer autoClose={2000} />
                                {/* <ScrollView> */}

                                <ProfileHeader1 secondaryColor={secondaryColor} />






                                {/* <View style={isDesktopOrLaptop ? { marginTop: -20, } : { marginTop: -20, }}>

                                    <View style={isDesktopOrLaptop ? { flexDirection: 'row', flex: 1, marginHorizontal: '1%', } : { flex: 2, marginHorizontal: '1.3%', }}>

                                        <View className="sales-banner" style={isDesktopOrLaptop ? { flex: 0.49, height: 500, } : { flex: 1, height: 400, }}>

                                            <ImageBackground source='https://images.unsplash.com/photo-1630626988793-c06b32c346a3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=480&q=80'


                                                // {serverURL + item.image}

                                                imageStyle={

                                                    isDesktopOrLaptop ? {
                                                        width: '100%',
                                                        height: '100%',
                                                        overflow: 'hidden',
                                                        justifyContent: "left",
                                                        borderRadius: 5,
                                                        width: '100%',

                                                    } : {

                                                        width: '100%',
                                                        height: '100%',
                                                        overflow: 'hidden',
                                                        justifyContent: "center",
                                                        borderRadius: 10,
                                                        width: '100%',

                                                    }
                                                }
                                            >
                                                <View style={[isDesktopOrLaptop ? {


                                                    background: 'rgb(0,0,0,0.7)',
                                                    flexDirection: 'row',
                                                    padding: 20,
                                                    height: 500,
                                                    borderColor: 'lightgray',
                                                    borderRadius: 5,

                                                }
                                                    : {
                                                        background: 'rgb(0,0,0,0.7)',
                                                        borderRadius: 10,
                                                        padding: 20,
                                                        height: 400,
                                                        borderColor: borderColor,

                                                    }
                                                ]}>


                                                    <View style={isDesktopOrLaptop ? { width: '100%', marginTop: 90, } : { width: '100%', marginTop: 25, }}>


                                                        <View style={isDesktopOrLaptop ? { margin: 0, flexDirection: 'row', justifyContent: 'center', } : { marginTop: 0, flexDirection: 'row', justifyContent: 'center', }}>
                                                            <span className='section-title' style={isDesktopOrLaptop ? { margin: 0, fontSize: 35, color: 'white', textTransform: 'uppercase', letterSpacing: 20, } : { fontSize: 28, color: 'white', textTransform: 'uppercase', letterSpacing: 10, }}>Special Offer</span>

                                                        </View>

                                                        <View style={isDesktopOrLaptop ? { justifyContent: 'center', margin: 0 } : { justifyContent: 'center', margin: 0, }}>
                                                            <h1 style={isDesktopOrLaptop ? { margin: 0, fontFamily: 'sans-serif', fontWeight: 800, textAlign: 'center', fontSize: 60, color: 'white', textTransform: 'uppercase', letterSpacing: 20, } : { margin: 0, marginTop: -60, fontFamily: 'sans-serif', fontWeight: 700, textAlign: 'center', fontSize: 40, color: 'white', textTransform: 'uppercase', letterSpacing: 20, }}>Just</h1>
                                                            <h1 style={isDesktopOrLaptop ? { margin: 0, fontFamily: 'sans-serif', fontWeight: 800, textAlign: 'center', fontSize: 60, color: 'white', textTransform: 'uppercase', letterSpacing: 20, } : { margin: 0, fontFamily: 'sans-serif', fontWeight: 700, textAlign: 'center', fontSize: 40, color: 'white', textTransform: 'uppercase', letterSpacing: 20, }}>Buy</h1>

                                                        </View>

                                                        <View style={isDesktopOrLaptop ? { flexDirection: 'row', justifyContent: 'center', margin: 0, marginTop: 20, } : { flexDirection: 'row', margin: 0, justifyContent: 'center', marginTop: 50, }}>
                                                            <h1 style={isDesktopOrLaptop ? { margin: 0, fontFamily: 'sans-serif', fontWeight: 800, textAlign: 'center', fontSize: 90, color: 'white', textTransform: 'uppercase', letterSpacing: 20, } : { margin: 0, fontFamily: 'sans-serif', fontWeight: 800, textAlign: 'center', fontSize: 40, color: 'white', textTransform: 'uppercase', letterSpacing: 20, }}>30</h1>
                                                            <h1 style={isDesktopOrLaptop ? { margin: 0, fontFamily: 'sans-serif', fontWeight: 800, textAlign: 'center', fontSize: 60, color: 'white', textTransform: 'uppercase', letterSpacing: 20, } : { margin: 0, fontFamily: 'sans-serif', fontWeight: 800, textAlign: 'center', fontSize: 30, color: 'white', textTransform: 'uppercase', letterSpacing: 20, }}>%</h1>
                                                            <h1 style={isDesktopOrLaptop ? { margin: 0, fontFamily: 'sans-serif', fontWeight: 800, textAlign: 'center', fontSize: 60, color: 'white', letterSpacing: 20, } : { margin: 0, fontFamily: 'sans-serif', fontWeight: 800, textAlign: 'center', fontSize: 30, color: 'white', letterSpacing: 20, }}>off</h1>

                                                        </View>

                                                    </View>


                                                </View>
                                            </ImageBackground>

                                        </View>

                                        {isDesktopOrLaptop ?
                                            <View style={{ flex: 0.02 }}></View>
                                            : null}



                                        <View className="sales-banner" style={isDesktopOrLaptop ? { flex: 0.49, height: 500, } : { flex: 1, height: 400, marginTop: 30, }}>


                                            <ImageBackground resizeMode="cover" source='https://images.unsplash.com/photo-1626071466175-79ab723e9fdd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=459&q=80'


                                                // {serverURL + item.image}

                                                imageStyle={

                                                    isDesktopOrLaptop ? {

                                                        backgroundPosition: 'top',
                                                        width: '100%',
                                                        height: '100%',
                                                        overflow: 'hidden',
                                                        justifyContent: "left",
                                                        borderRadius: 5,
                                                        width: '100%',

                                                    } : {

                                                        width: '100%',
                                                        height: '100%',
                                                        overflow: 'hidden',
                                                        justifyContent: "center",
                                                        borderRadius: 10,
                                                        width: '100%',
                                                        height: 400,

                                                    }
                                                }
                                            >
                                                <View style={[isDesktopOrLaptop ? {


                                                    background: 'rgb(0,0,0,0.7)',
                                                    flexDirection: 'row',
                                                    padding: 20,
                                                    height: 500,
                                                    borderColor: 'lightgray',
                                                    borderRadius: 5,

                                                }
                                                    : {
                                                        background: 'rgb(0,0,0,0.7)',
                                                        borderRadius: 10,
                                                        padding: 20,
                                                        borderColor: borderColor,
                                                        height: 400,

                                                    }
                                                ]}>


                                                    <View style={isDesktopOrLaptop ? { width: '100%', marginTop: 90, } : { width: '100%', marginTop: 25, }}>


                                                        <View style={isDesktopOrLaptop ? { margin: 0, flexDirection: 'row', justifyContent: 'center', } : { marginTop: 0, flexDirection: 'row', justifyContent: 'center', }}>
                                                            <span className='section-title' style={isDesktopOrLaptop ? { margin: 0, fontSize: 35, color: 'white', textTransform: 'uppercase', letterSpacing: 20, } : { fontSize: 28, color: 'white', textTransform: 'uppercase', letterSpacing: 10, }}>One day Sale</span>

                                                        </View>

                                                        <View style={isDesktopOrLaptop ? { justifyContent: 'center', margin: 0 } : { justifyContent: 'center', margin: 0, }}>
                                                            <h1 style={isDesktopOrLaptop ? { margin: 0, fontFamily: 'sans-serif', fontWeight: 800, textAlign: 'center', fontSize: 60, color: 'white', textTransform: 'uppercase', letterSpacing: 20, } : { margin: 0, marginTop: -60, fontFamily: 'sans-serif', fontWeight: 700, textAlign: 'center', fontSize: 40, color: 'white', textTransform: 'uppercase', letterSpacing: 20, }}>UP</h1>
                                                            <h1 style={isDesktopOrLaptop ? { margin: 0, fontFamily: 'sans-serif', fontWeight: 800, textAlign: 'center', fontSize: 60, color: 'white', textTransform: 'uppercase', letterSpacing: 20, } : { margin: 0, fontFamily: 'sans-serif', fontWeight: 700, textAlign: 'center', fontSize: 40, color: 'white', textTransform: 'uppercase', letterSpacing: 20, }}>TO</h1>

                                                        </View>

                                                        <View style={isDesktopOrLaptop ? { flexDirection: 'row', justifyContent: 'center', margin: 0, marginTop: 20, } : { flexDirection: 'row', margin: 0, justifyContent: 'center', marginTop: 50, }}>
                                                            <h1 style={isDesktopOrLaptop ? { margin: 0, fontFamily: 'sans-serif', fontWeight: 800, textAlign: 'center', fontSize: 90, color: 'white', textTransform: 'uppercase', letterSpacing: 20, } : { margin: 0, fontFamily: 'sans-serif', fontWeight: 800, textAlign: 'center', fontSize: 40, color: 'white', textTransform: 'uppercase', letterSpacing: 20, }}>70</h1>
                                                            <h1 style={isDesktopOrLaptop ? { margin: 0, fontFamily: 'sans-serif', fontWeight: 800, textAlign: 'center', fontSize: 60, color: 'white', textTransform: 'uppercase', letterSpacing: 20, } : { margin: 0, fontFamily: 'sans-serif', fontWeight: 800, textAlign: 'center', fontSize: 30, color: 'white', textTransform: 'uppercase', letterSpacing: 20, }}>%</h1>
                                                            <h1 style={isDesktopOrLaptop ? { margin: 0, fontFamily: 'sans-serif', fontWeight: 800, textAlign: 'center', fontSize: 60, color: 'white', letterSpacing: 20, } : { margin: 0, fontFamily: 'sans-serif', fontWeight: 800, textAlign: 'center', fontSize: 30, color: 'white', letterSpacing: 20, }}>off</h1>

                                                        </View>

                                                    </View>


                                                </View>
                                            </ImageBackground>
                                        </View>


                                    </View>


                                </View> */}


                                <View style={[isDesktopOrLaptop ? { marginTop: -40, } :
                                    { paddingTop: 5, borderTopLeftRadius: 0, borderTopRightRadius: 0, marginTop: -60, }]}>

                                    {/* Category Banner */}

                                    <View>

                                        <View style={[isDesktopOrLaptop ? {


                                            flexDirection: 'row',
                                            padding: 18,
                                            height: 100,
                                            borderColor: 'lightgray',

                                        }
                                            : {
                                                borderRadius: 3,
                                                padding: 10,
                                                borderColor: borderColor,
                                                marginHorizontal: '2%',

                                            }
                                        ]}>





                                        </View>

                                    </View>

                                    <View style={isDesktopOrLaptop ? { marginTop: -60, marginHorizontal: '5%', marginBottom: 30, } : { marginTop: 10, marginHorizontal: '5%' }}>


                                        <View>
                                            {/* <span className='section-title' style={isDesktopOrLaptop ? { fontFamily: 'sans-serif', fontSize: 35, color: '#282828', textTransform: 'capitalize' } : { marginTop: 10, marginBottom: 20, fontSize: 28, color: '#282828', }}>Categories</span> */}
                                            <div className="cat-header-1">
                                                <h1 style={!isDesktopOrLaptop ? { fontSize: 30, color: 'dimgray', } : { color: 'dimgray' }}>Categories<span style={!isDesktopOrLaptop ? { fontSize: 10, color: 'dimgray', marginLeft: -2, } : { color: 'dimgray', marginLeft: -7, }}>Select any Category</span></h1>
                                            </div>

                                        </View>

                                    </View>



                                    <Categories primaryColour={primaryColour} secondaryColor={secondaryColor} />
                                    <View key={searchSubmit} >
                                        <View key={selectedCategory}>

                                            <View style={{ marginTop: 10, }}>

                                                <View style={[isDesktopOrLaptop ? {


                                                    flexDirection: 'row',
                                                    padding: 18,
                                                    height: 100,
                                                    borderColor: 'lightgray',
                                                    marginHorizontal: '20%',

                                                }
                                                    : {
                                                        borderRadius: 3,
                                                        padding: 10,
                                                        borderColor: borderColor,
                                                        marginHorizontal: '2%',

                                                    }
                                                ]}>





                                                </View>
                                            </View>


                                            <View style={isDesktopOrLaptop ? { marginTop: -60, marginHorizontal: '5%', marginBottom: 30, } : { marginHorizontal: '5%' }}>


                                                <View>
                                                    {/* <span className='section-title' style={isDesktopOrLaptop ? { fontFamily: 'sans-serif', fontSize: 35, color: '#282828', textTransform: 'capitalize' } : { marginTop: 10, marginBottom: 20, fontSize: 28, color: '#282828', }}>Categories</span> */}
                                                    <div className="cat-header-1">
                                                        <h1 style={!isDesktopOrLaptop ? { fontSize: 30, color: 'dimgray', } : { color: 'dimgray' }}>Products<span style={!isDesktopOrLaptop ? { fontSize: 10, color: 'dimgray', marginLeft: -3, } : { color: 'dimgray', marginLeft: -9, }}>Select any item</span></h1>
                                                    </div>

                                                </View>

                                            </View>

                                            <Products primaryColour={primaryColour} secondaryColor={secondaryColor} />

                                        </View>
                                    </View>
                                    <View style={{ width: '90%', backgroundColor: 'lightgray', height: 0, marginBottom: 30, alignSelf: 'center' }} />
                                </View>


                                <View style={isDesktopOrLaptop ? { marginTop: 80, } : { marginTop: 30, }}>

                                </View>


                                {Object.keys(data).length !== 0 &&

                                    <>{

                                        data.banners.banner1 != '' ?

                                            <ImageBackground source={serverURL + data.banners.banner1}


                                                // {serverURL + item.image}

                                                imageStyle={

                                                    isDesktopOrLaptop ? {
                                                        width: '100%',
                                                        height: '100%',
                                                        overflow: 'hidden',
                                                        justifyContent: "center",
                                                        borderRadius: 5,
                                                        width: '96%',
                                                        marginLeft: '2%',
                                                        height: 300,

                                                    } : {

                                                        width: '100%',
                                                        height: '100%',
                                                        overflow: 'hidden',
                                                        justifyContent: "center",
                                                        borderRadius: 5,
                                                        width: '96%',
                                                        marginLeft: '2%',
                                                        height: 200,
                                                    }
                                                }
                                            >
                                                <View style={[isDesktopOrLaptop ? {


                                                    background: 'rgb(0,0,0,0.6)',
                                                    flexDirection: 'row',
                                                    shadowColor: "#000",
                                                    shadowOffset: {
                                                        width: 0,
                                                        height: 4,
                                                    },
                                                    shadowOpacity: 0.32,
                                                    shadowRadius: 5.46,
                                                    borderRadius: 10,
                                                    padding: 20,
                                                    marginHorizontal: '2%',
                                                    height: 300,

                                                }
                                                    : {
                                                        background: 'rgb(0,0,0,0.6)',
                                                        shadowColor: primaryColour,
                                                        shadowOffset: {
                                                            width: 0.5,
                                                            height: 0.5,
                                                        },
                                                        shadowOpacity: 0.32,
                                                        shadowRadius: 5.46,
                                                        borderRadius: 5,
                                                        padding: 20,
                                                        marginHorizontal: '2%',
                                                        height: 200,

                                                    }
                                                ]}>


                                                    <View style={{ flexDirection: 'row', justifyContent: 'center', width: '100%', }}>


                                                        <View style={isDesktopOrLaptop ? { justifyContent: 'center', marginHorizontal: 30, } : { marginTop: 20, justifyContent: 'center', marginHorizontal: 30, }}>

                                                            <Text style={isDesktopOrLaptop ? { fontSize: 23, textAlign: 'center', color: 'white' } : { marginTop: 10, marginBottom: 20, fontSize: 15, textAlign: 'center', color: 'white', }}><span style={isDesktopOrLaptop ? { fontSize: 30, textTransform: 'uppercase', fontWeight: 700, } : { fontSize: 20, textTransform: 'uppercase', fontWeight: 700, }}>Subscribe ({data.user_name})</span> {isDesktopOrLaptop ? <br /> : <br />}Get notification

                                                                (<IoNotifications size={20} solid={true} style={{ color: 'white' }} />)

                                                                of special deals</Text>

                                                            {isDesktopOrLaptop ?
                                                                <TouchableOpacity style={{
                                                                    backgroundColor: 'white', paddingHorizontal: 20, paddingVertical: 10,
                                                                    width: 200, alignSelf: 'center', marginTop: 20,
                                                                    borderRadius: 20
                                                                }}
                                                                    onPress={() => {
                                                                        if (secureToken != '@error' && secureToken != '') {
                                                                            if (data.same_dukan !== 1) {
                                                                                if (data.user_following === 0) {
                                                                                    subscribeDukan(data.id, navigate)
                                                                                    data.user_following = 1
                                                                                    notify('successfully subscribed')
                                                                                    setSubscribeKey(Math.random())
                                                                                } else {
                                                                                    unsubscribeDukan(data.id, navigate)
                                                                                    data.user_following = 0
                                                                                    notify('successfully unsubscribed')
                                                                                    setSubscribeKey(Math.random())
                                                                                }
                                                                            } else {
                                                                                notify('Cant subscribe own dukan')
                                                                            }
                                                                        } else { setloginVisible(!loginVisible) }
                                                                    }}
                                                                >
                                                                    <Text style={{ color: primaryColour, textAlign: 'center', fontWeight: 800, textTransform: 'uppercase', letterSpacing: 3, fontFamily: 'sans-serif', }} key={subscribeKey}>

                                                                        {secureToken != '@error' && secureToken != '' ?
                                                                            (data.user_following == 0 ?
                                                                                'Subscribe'
                                                                                : 'Unsubscribe'
                                                                            )
                                                                            : 'Subscribe'
                                                                        }


                                                                    </Text>
                                                                </TouchableOpacity>
                                                                : null}
                                                        </View>

                                                    </View>
                                                    {!isDesktopOrLaptop ?
                                                        <TouchableOpacity style={{
                                                            backgroundColor: 'white', paddingHorizontal: 20, paddingVertical: 10,
                                                            width: 200, alignSelf: 'center',
                                                            borderRadius: 20,
                                                        }}
                                                            onPress={() => {
                                                                if (secureToken != '@error' && secureToken != '') {
                                                                    if (data.same_dukan !== 1) {
                                                                        if (data.user_following === 0) {
                                                                            subscribeDukan(data.id, navigate)
                                                                            data.user_following = 1
                                                                            notify('successfully subscribed')
                                                                            setSubscribeKey(Math.random())
                                                                        } else {
                                                                            unsubscribeDukan(data.id, navigate)
                                                                            data.user_following = 0
                                                                            notify('successfully unsubscribed')
                                                                            setSubscribeKey(Math.random())
                                                                        }
                                                                    } else {
                                                                        notify('Cant subscribe own dukan')
                                                                    }
                                                                } else { setloginVisible(!loginVisible) }
                                                            }}
                                                        >
                                                            <Text style={{ color: primaryColour, textAlign: 'center', fontWeight: 600, textTransform: 'uppercase', letterSpacing: 3, fontFamily: 'sans-serif', }} key={subscribeKey}>
                                                                {secureToken != '@error' && secureToken != '' ?
                                                                    (data.user_following == 0 ?
                                                                        'Subscribe'
                                                                        : 'Unsubscribe'
                                                                    )
                                                                    : 'Subscribe'
                                                                }

                                                            </Text>
                                                        </TouchableOpacity>
                                                        : null}

                                                </View>
                                            </ImageBackground>

                                            :


                                            <ImageBackground source='https://images.unsplash.com/photo-1462392246754-28dfa2df8e6b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'


                                                // {serverURL + item.image}

                                                imageStyle={

                                                    isDesktopOrLaptop ? {
                                                        width: '100%',
                                                        height: '100%',
                                                        overflow: 'hidden',
                                                        justifyContent: "center",
                                                        borderRadius: 5,
                                                        width: '96%',
                                                        marginLeft: '2%',
                                                        height: 300,

                                                    } : {

                                                        width: '100%',
                                                        height: '100%',
                                                        overflow: 'hidden',
                                                        justifyContent: "center",
                                                        borderRadius: 5,
                                                        width: '96%',
                                                        marginLeft: '2%',
                                                        height: 200,
                                                    }
                                                }
                                            >
                                                <View style={[isDesktopOrLaptop ? {


                                                    background: 'rgb(0,0,0,0.6)',
                                                    flexDirection: 'row',
                                                    shadowColor: "#000",
                                                    shadowOffset: {
                                                        width: 0,
                                                        height: 4,
                                                    },
                                                    shadowOpacity: 0.32,
                                                    shadowRadius: 5.46,
                                                    borderRadius: 10,
                                                    padding: 20,
                                                    marginHorizontal: '2%',
                                                    height: 300,

                                                }
                                                    : {
                                                        background: 'rgb(0,0,0,0.6)',
                                                        shadowColor: primaryColour,
                                                        shadowOffset: {
                                                            width: 0.5,
                                                            height: 0.5,
                                                        },
                                                        shadowOpacity: 0.32,
                                                        shadowRadius: 5.46,
                                                        borderRadius: 5,
                                                        padding: 20,
                                                        marginHorizontal: '2%',
                                                        height: 200,

                                                    }
                                                ]}>


                                                    <View style={{ flexDirection: 'row', justifyContent: 'center', width: '100%', }}>


                                                        <View style={isDesktopOrLaptop ? { justifyContent: 'center', marginHorizontal: 30, } : { marginTop: 20, justifyContent: 'center', marginHorizontal: 30, }}>

                                                            <Text style={isDesktopOrLaptop ? { fontSize: 23, textAlign: 'center', color: 'white' } : { marginTop: 10, marginBottom: 20, fontSize: 15, textAlign: 'center', color: 'white', }}><span style={isDesktopOrLaptop ? { fontSize: 30, textTransform: 'uppercase', fontWeight: 700, } : { fontSize: 20, textTransform: 'uppercase', fontWeight: 700, }}>Subscribe ({data.user_name})</span> {isDesktopOrLaptop ? <br /> : <br />}Get notification

                                                                (<IoNotifications size={20} solid={true} style={{ color: 'white' }} />)

                                                                of special deals</Text>

                                                            {isDesktopOrLaptop ?
                                                                <TouchableOpacity style={{
                                                                    backgroundColor: 'white', paddingHorizontal: 20, paddingVertical: 10,
                                                                    width: 200, alignSelf: 'center', marginTop: 20,
                                                                    borderRadius: 20
                                                                }}
                                                                    onPress={() => {
                                                                        if (secureToken != '@error' && secureToken != '') {
                                                                            if (data.same_dukan !== 1) {
                                                                                if (data.user_following === 0) {
                                                                                    subscribeDukan(data.id, navigate)
                                                                                    data.user_following = 1
                                                                                    notify('successfully subscribed')
                                                                                    setSubscribeKey(Math.random())
                                                                                } else {
                                                                                    unsubscribeDukan(data.id, navigate)
                                                                                    data.user_following = 0
                                                                                    notify('successfully unsubscribed')
                                                                                    setSubscribeKey(Math.random())
                                                                                }
                                                                            } else {
                                                                                notify('Cant subscribe own dukan')
                                                                            }
                                                                        } else { setloginVisible(!loginVisible) }
                                                                    }}
                                                                >
                                                                    <Text style={{ color: primaryColour, textAlign: 'center', fontWeight: 800, textTransform: 'uppercase', letterSpacing: 3, fontFamily: 'sans-serif', }} key={subscribeKey}>

                                                                        {secureToken != '@error' && secureToken != '' ?
                                                                            (data.user_following == 0 ?
                                                                                'Subscribe'
                                                                                : 'Unsubscribe'
                                                                            )
                                                                            : 'Subscribe'
                                                                        }


                                                                    </Text>
                                                                </TouchableOpacity>
                                                                : null}
                                                        </View>

                                                    </View>
                                                    {!isDesktopOrLaptop ?
                                                        <TouchableOpacity style={{
                                                            backgroundColor: 'white', paddingHorizontal: 20, paddingVertical: 10,
                                                            width: 200, alignSelf: 'center',
                                                            borderRadius: 20,
                                                        }}
                                                            onPress={() => {
                                                                if (secureToken != '@error' && secureToken != '') {
                                                                    if (data.same_dukan !== 1) {
                                                                        if (data.user_following === 0) {
                                                                            subscribeDukan(data.id, navigate)
                                                                            data.user_following = 1
                                                                            notify('successfully subscribed')
                                                                            setSubscribeKey(Math.random())
                                                                        } else {
                                                                            unsubscribeDukan(data.id, navigate)
                                                                            data.user_following = 0
                                                                            notify('successfully unsubscribed')
                                                                            setSubscribeKey(Math.random())
                                                                        }
                                                                    } else {
                                                                        notify('Cant subscribe own dukan')
                                                                    }
                                                                } else { setloginVisible(!loginVisible) }
                                                            }}
                                                        >
                                                            <Text style={{ color: primaryColour, textAlign: 'center', fontWeight: 800, textTransform: 'uppercase', letterSpacing: 3, fontFamily: 'sans-serif', }} key={subscribeKey}>
                                                                {secureToken != '@error' && secureToken != '' ?
                                                                    (data.user_following == 0 ?
                                                                        'Subscribe'
                                                                        : 'Unsubscribe'
                                                                    )
                                                                    : 'Subscribe'
                                                                }

                                                            </Text>
                                                        </TouchableOpacity>
                                                        : null}

                                                </View>
                                            </ImageBackground>


                                    }
                                    </>

                                }



                                <View style={{ marginTop: 130, }}>

                                    <Footer dukanId={dukanId} dukanContactNumber={dukanContactNumber} secondaryColor={secondaryColor} dukanName={dukanName} dukanDescription={dukanDescription} data={data} facebook={facebook} instagram={instagram} youtube={youtube} />
                                </View>
                            </>
                            :
                            <LoadingIndicator />
                        }
                    </Tab>
                </HomeContext.Provider >
            }
        </>
    );
}

