import React, { useEffect, useState } from 'react';
import {
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    ScrollView,
    FlatList,
    TextInput,
    ImageBackground,
    Switch,
    RefreshControl,
    ActivityIndicator,
    Platform
} from 'react-native';

import { filterById, PerfectInput, RoundButton, notify } from '../../../utils/utils';
import { inputBackgound, cityList, primaryLightColorButton, otherCategories, subCategories, borderColor, serverURL, shadowColor } from '../../../utils/string';
import PerfectModal from '../../../utils/perfectModel';
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from '../../sidebar';
import { shadow } from '../../utils/main';
import { SketchPicker } from 'react-color';
import { FaAngleDown, FaCheckCircle, FaPencilAlt, FaTimes, FaTrash } from 'react-icons/fa';
import { addProduct, getProductDetails, updateProduct } from '../../../utils/api';
import { primarybg, primaryColour, secondarybg } from "../../utils/string";
import { HexColorPicker } from 'react-colorful';
import { useMediaQuery } from 'react-responsive';

const checkLogin = () => (<></>)

const OptionalDetailsButton = (props) => {

    return (
        <TouchableOpacity
            style={{
                width: '100%', backgroundColor: inputBackgound, paddingHorizontal: 5, paddingVertical: 1, borderRadius: 5, flexDirection: 'row',

            }}
            onPress={props.onPress}
        >
            <Text style={[styles.title, { flex: 0.95, fontWeight: 'bold' }]}>{props.text} </Text>
            <FaAngleDown
                color={primaryColour}
                size={19}
            />
        </TouchableOpacity>
    )
}

export default function EditProduct(props) {
    let id, postType;
    const { productId } = useParams();
    const move = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const cities = cityList;
    const [logedIn, setLogedIn] = useState(true);
    const [condition, setcondition] = useState(1);
    const [tag, settag] = useState('');
    const [tags, settags] = useState([]);
    const [ytLink, setytLink] = useState('');
    const [description, setdescription] = useState('');
    const [key, setkey] = useState(Math.random());
    const [key2, setkey2] = useState(Math.random());
    const [photos, setphotos] = useState([]);
    const [switchValue, setswitchValue] = useState(false);
    const [city, setcity] = useState('');
    const [address, setaddress] = useState('');
    const [choosePhotosButton, setchoosePhotosButton] = useState(false);
    const [ytbError, setytbError] = useState('');
    const [descriptionError, setdescriptionError] = useState('');
    const [title, settitle] = useState('');
    const [titleError, settitleError] = useState('');
    const [photosError, setphotosError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [price, setprice] = useState('');
    const [priceError, setpriceError] = useState('');
    const [addressError, setaddressError] = useState();
    const [state2, setstate2] = useState('');
    const [showCities, setshowCities] = useState(false);
    const [showState, setshowState] = useState(false);
    const [error, setError] = useState('');
    const [uploaded, setuploaded] = useState(0);
    const [remainingUpload, setremainingUpload] = useState(0);
    const [allowResellers, setallowResellers] = useState(false);
    const [allowOrders, setallowOrders] = useState(true);
    const [resellerCommision, setresellerCommision] = useState('');
    const [quantity, setquantity] = useState('1');
    const [shipping, setshipping] = useState('0');
    const [area, setarea] = useState('');


    const [bazaarId, setbazaarId] = useState('');
    const [bazaarTitle, setbazaarTitle] = useState('');


    const [customFeilds, setcustomFeilds] = useState([]);
    const [customFeildModel, setcustomFeildModel] = useState(false);
    const [newCustomFeild, setnewCustomFeild] = useState('');

    const [data, setData] = useState('');

    // not used
    const [relatedAdsData, setrelatedAdsData] = useState([]);
    const [firstRun, setfirstRun] = useState(true);


    const [images, setimages] = useState([]);

    const [stockAndChargesAdd, setstockAndChargesAdd] = useState(false);
    const [otherDetailsAdd, setotherDetailsAdd] = useState(false);
    const [subProductsAdd, setsubProductsAdd] = useState(false);

    const [variationKey, setvariationKey] = useState(Math.random());
    const [variations, setvariations] = useState([]);
    const [variationModal, setvariationModal] = useState(false);
    //selected variation for editing or addition
    const [currentVariationIndex, setcurrentVariationIndex] = useState(0);
    const [editingVariation, seteditingVariation] = useState(false);

    const [colors, setcolors] = useState([]);
    const [sizes, setsizes] = useState([]);
    const [sizeKey, setsizeKey] = useState(Math.random());
    const [colorsKey, setColorsKey] = useState(Math.random());
    const [colorPickerModal, setcolorPickerModal] = useState(false);
    const [currentColor, setcurrentColor] = useState('');

    useEffect(() => {
        if (typeof productId !== 'undefined') {
            getProductDetails(productId, setData, setIsLoading, move, setvariations)
        }
        // checkLogin().then(v => {
        //     if (v === true) {
        //         setLogedIn(true);
        //     } else {
        //         setLogedIn(false);
        //     }
        // });
    }, []);

    useEffect(() => {
        if (data !== '') {
            console.log(data, 'dddddddddddddddd')
            // var p = []
            setquantity('' + data.quantity)
            settitle(data.title);
            setdescription(data.description);
            setprice(data.price);
            setaddress(data.address);
            setstate2(data.state);
            setcity(data.city);
            setarea(data.area)
            setytLink(data.ytlink);
            settags(data.tags);
            setimages(data.imgs);
            setphotos(data.imgs);
            setfirstRun(false);
            setswitchValue(data.show_number);
            setallowResellers(data.can_resell == 1);
            setshipping('' + data.shipping)

            let c = []
            data.colors.map((item) => {
                c.push(item.color)
            })
            setcolors(c)
            let s = []
            data.sizes.map((item) => {
                console.log(item, 'itmmmmmmmmmmmmm')
                s.push({ name: item.size, price: '' + item.price })
            })
            console.log(s, 'sssssssss')
            setsizes(s);
            setsizeKey(Math.random())
            setimages(data.imgs)
            // setresellerCommision(data.seller_commision)
        }
    }, [data])



    return (
        <>
            <Sidebar active='myItems' title={'Update item'} >
                <View style={{ paddingHorizontal: '5%' }}>

                    <Text style={styles.title}>Title </Text>
                    <View>
                        <TextInput
                            style={styles.inputs}
                            returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
                            placeholder="Mention features of your item"
                            keyboardType="email-address"
                            underlineColorAndroid="transparent"
                            onChangeText={text => {
                                settitle(text);
                                setdescriptionError(''); settitleError(''); setytbError('');
                            }}
                            value={title}
                            maxLength={70} //setting limit of input
                        />
                        <Text style={{ textAlign: 'right' }}>{title.length}/70</Text>
                        {titleError != '' ? (
                            <Text style={{ textAlign: 'right', color: 'red', fontSize: 12 }}>
                                {titleError}
                            </Text>
                        ) : null}
                    </View>

                    <Text style={styles.title}>Price </Text>
                    <View>
                        <TextInput
                            style={styles.inputs}
                            returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
                            placeholder="Mention price of your item"
                            underlineColorAndroid="transparent"
                            onChangeText={text => {
                                setprice(text.replace(/[^0-9]/g, ''));
                            }}
                            value={price}
                            maxLength={12} //setting limit of input
                            keyboardType={'phone-pad'}
                        />
                        <Text style={{ textAlign: 'right' }}>{price.length}/12</Text>
                        {priceError != '' ? (
                            <Text style={{ textAlign: 'right', color: 'red', fontSize: 12 }}>
                                {priceError}
                            </Text>
                        ) : null}
                    </View>

                    <Text style={styles.title}>Description </Text>
                    <View>
                        <TextInput
                            style={[
                                styles.inputs,
                                { height: 100, textAlignVertical: 'top', marginTop: 10 },
                            ]}
                            multiline={true}
                            placeholder="Details about your product"
                            numberOfLines={10}
                            multiline={true}
                            numberOfLines={4}
                            returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
                            underlineColorAndroid="transparent"
                            returnKeyType="none"
                            onChangeText={text => {
                                setdescription(text);
                                setdescriptionError(''); settitleError(''); setytbError('');
                            }}
                            value={description}
                            maxLength={5000}
                        // maxLength={11}  //setting limit of input
                        // keyboardType={'phone-pad'}
                        />
                        <Text style={{ textAlign: 'right' }}>{description.length}/5000</Text>
                        {descriptionError != '' ? (
                            <Text style={{ textAlign: 'right', color: 'red', fontSize: 12 }}>
                                {descriptionError}
                            </Text>
                        ) : null}
                    </View>




                    <View key={key}>
                        {photos.length > 0 ? (
                            <View>
                                <Text style={styles.title}>Selected Photos</Text>
                                <View
                                    key={key}
                                    style={[
                                        styles.inputs,
                                        { padding: 5, flexDirection: 'row', flexWrap: 'wrap' },
                                    ]}>
                                    {photos.map((obj, key) => (
                                        <ImageBackground
                                            style={styles.tinyLogo}
                                            imageStyle={{ borderRadius: 9 }}
                                            source={
                                                obj.type === 'uploading'
                                                    ? { uri: URL.createObjectURL(obj.image) }
                                                    : { uri: serverURL + obj.image }
                                            }>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    photos.splice(key, 1);
                                                    setphotos(photos);
                                                    setkey(Math.random());
                                                }}
                                                style={{
                                                    backgroundColor: '#00000020',
                                                    marginBottom: '50%',
                                                    // opacity: 0.7
                                                }}>
                                                <FaTimes
                                                    name="times"
                                                    type="font-awesome-5"
                                                    color="white"
                                                />
                                            </TouchableOpacity>
                                        </ImageBackground>
                                    ))}
                                </View>
                            </View>
                        ) : null}
                    </View>

                    <Text style={styles.title}>Photos </Text>
                    <View>
                        {/* <TouchableOpacity
                        disabled={choosePhotosButton}
                        onPress={() => {
                            // NativeModules.ToastExample.show('hello')
                            ImagePicker.openPicker({
                                multiple: true,
                                mediaType: 'photo',
                                compressImageQuality: 0.5
                            }).then(images => {
                                var avlSpace = 5 - photos.length;
                                var images = images.slice(0, avlSpace);
                                let imagesWithId = []
                                images.forEach((item, i) => {
                                    imagesWithId.push({ id: photos.length < 1 ? i : photos[i].id + 1, image: item, type: 'uploading' })
                                });
                                var y = photos.concat(imagesWithId);
                                setphotos(y);
                                setkey(Math.random());
                                if (y.length >= 5) {
                                    setchoosePhotosButton(true);
                                }
                                console.log(photos)
                            });
                        }}
                        style={[
                            styles.inputs,
                            {
                                paddingVertical: 10,
                                marginTop: 10,
                                alignItems: 'center',
                            },
                        ]}>
                        <Text style={{ color: primaryColour }}>
                            Choose photos
                            {choosePhotosButton ? (
                                <Text style={{ color: 'red' }}>(Max selected)</Text>
                            ) : null}
                        </Text>
                    </TouchableOpacity> */}
                        <input type="file" multiple

                            style={{
                                backgroundColor: inputBackgound,
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: 12,
                                padding: 7,
                                paddingHorizontal: 10,
                                shadowOffset: {
                                    width: 0,
                                    height: 2,
                                },
                                borderWidth: 0.15,
                                shadowColor: 'gray',
                                elevation: 1,
                                alignContent: 'center',
                                borderRadius: 5,
                                borderColor: 'lightgray',
                                borderBottomWidth: 0,
                                color: primaryColour,
                                paddingVertical: 10,
                                marginTop: 10,
                                alignItems: 'center',
                            }}
                            onChange={(event) => {
                                // NativeModules.ToastExample.show('hello')
                                let images = Array.from(event.target.files)
                                var avlSpace = 5 - photos.length;
                                images = images.slice(0, avlSpace);
                                let imagesWithId = []
                                images.forEach((item, i) => {
                                    imagesWithId.push({ id: photos.length < 1 ? i : photos[i].id + 1, image: item, type: 'uploading' })
                                });
                                var y = photos.concat(imagesWithId);
                                setphotos(y);
                                setkey(Math.random());
                                if (y.length >= 5) {
                                    setchoosePhotosButton(true);
                                }
                                console.log(photos)
                            }}

                        />


                        <Text style={{ textAlign: 'right', color: 'gray', fontSize: 12 }}>
                            ({photos.length}/ 5)
                        </Text>
                        {photosError != '' ? (
                            <Text style={{ textAlign: 'right', color: 'red', fontSize: 12 }}>
                                {photosError}
                            </Text>
                        ) : null}
                    </View>
                    <Text style={[styles.title, { marginTop: 10 }]}>Delivery Charges</Text>
                    <View>
                        <TextInput
                            style={styles.inputs}
                            returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
                            placeholder="Mention delivery charges of your product"
                            underlineColorAndroid="transparent"
                            onChangeText={text => {
                                setshipping(text.replace(/[^0-9]/g, ''));
                            }}
                            value={shipping}
                            maxLength={12} //setting limit of input
                            keyboardType={'phone-pad'}
                        />
                        <Text style={{ textAlign: 'right' }}>{price.length}/12</Text>
                        {/* <Text style={{ textAlign: 'right', color: 'gray' }}>delivery charges per item</Text> */}
                    </View>
                    <Text style={styles.title}>Quantity </Text>
                    <TextInput
                        style={styles.inputs}
                        returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
                        // placeholder="Mention price of your item"
                        underlineColorAndroid="transparent"
                        onChangeText={text => {
                            setquantity(text.replace(/[^0-9]/g, ''));
                        }}
                        value={quantity}
                        maxLength={12} //setting limit of input
                        keyboardType={'phone-pad'}
                    />


                    <Text style={[styles.title, { color: secondarybg, fontWeight: 'bold', marginBottom: 10 }]}>Optional </Text>

                    <View style={styles.optionalDetails}>

                        <OptionalDetailsButton onPress={() => { setsubProductsAdd(!subProductsAdd) }} text='Colors & Sizes' />
                        {subProductsAdd ?
                            <View style={{ marginTop: 5 }}>
                                <Text style={{ textAlign: 'right', color: 'gray', fontSize: 12 }}>If your product have different colors,sizes or other details</Text>
                                <Text style={{ textAlign: 'center', color: secondarybg, fontWeight: 'bold' }}>Sizes</Text>
                                <View key={sizeKey}>
                                    {sizes.length > 0 &&
                                        <>
                                            {sizes.map((item, index) => (
                                                <View style={{ paddingBottom: 5, marginTop: 10, backgroundColor: primarybg, borderWidth: 1, borderColor: borderColor, paddingHorizontal: 10 }}>
                                                    <PerfectInput
                                                        onChangeText={(text) => {
                                                            sizes[index].name = text;
                                                            setsizes(sizes);
                                                        }}
                                                        defaultValue={sizes[index].name}
                                                        maxLength={30}
                                                        placeholder={'Size Name'}
                                                    />
                                                    <PerfectInput
                                                        onChangeText={(text) => {
                                                            sizes[index].price = text.replace(/[^0-9]/g, '');
                                                            setsizes(sizes);
                                                        }}
                                                        defaultValue={sizes[index].price}
                                                        maxLength={30}
                                                        placeholder={'Price'}
                                                        keyboardType={'phone-pad'}
                                                    />
                                                    <Text style={{ textAlign: 'center', color: 'red', fontWeight: 'bold' }}
                                                        onPress={() => { sizes.splice(index, 1); setsizes(sizes); setsizeKey(Math.random()) }}
                                                    >- Delete</Text>
                                                </View>
                                            ))}
                                        </>
                                    }
                                </View>
                                <RoundButton
                                    text={sizes.length > 0 ? 'Add another size' : 'Add Size'}
                                    onPress={() => {
                                        sizes.push({ name: '', price: price });
                                        setsizes(sizes);
                                        setsizeKey(Math.random())
                                    }}
                                    style={{ width: '45%', alignSelf: 'center', height: 32, backgroundColor: primaryColour, borderRadius: 20 }}
                                />

                                <Text style={{ textAlign: 'center', color: secondarybg, fontWeight: 'bold' }}>Colors</Text>
                                <View key={colorsKey}>
                                    {colors.length > 0 &&
                                        <>
                                            {colors.map((item, index) => (
                                                <View
                                                    style={{
                                                        flexDirection: 'row', paddingBottom: 5,
                                                        marginTop: 10, backgroundColor: primarybg,
                                                        borderWidth: 1, borderColor: borderColor,
                                                        paddingHorizontal: 10, justifyContent: 'space-between',
                                                        alignItems: 'center', paddingTop: 5
                                                    }}>
                                                    <View
                                                        style={{
                                                            backgroundColor: item, width: 30, height: 30,
                                                            borderColor: 'lightgray', borderWidth: 1, marginLeft: 10
                                                        }} />
                                                    <Text style={{ textAlign: 'center', color: 'red', fontWeight: 'bold' }}
                                                        onPress={() => { colors.splice(index, 1); setcolors(colors); setColorsKey(Math.random()) }}
                                                    >- Delete</Text>
                                                </View>
                                            ))}
                                        </>
                                    }
                                </View>
                                <RoundButton
                                    text={colors.length > 0 ? 'Add Another Color' : 'Add Color'}
                                    onPress={() => {
                                        setcolorPickerModal(true)
                                    }}
                                    style={{ width: '46%', alignSelf: 'center', height: 32, backgroundColor: primaryColour, borderRadius: 20, marginBottom: 10 }}
                                />
                            </View>

                            : null}

                    </View>



                    {/* <View style={styles.optionalDetails}>
          <OptionalDetailsButton onPress={() => { setlocationAdd(!locationAdd) }} text='Location' />

          {locationAdd ?
            <ShowLocations
              province={state2} setprovince={setstate2}
              city={city} setcity={setcity}
              area={area} setarea={setarea}
              cityLabel='Please select province first'
              notAll
            // notArea
            />
            : null}
        </View> */}



                    <View style={styles.optionalDetails}>
                        <OptionalDetailsButton onPress={() => { setstockAndChargesAdd(!stockAndChargesAdd) }} text='Stock & Charges' />
                        {stockAndChargesAdd ?
                            <>
                                {allowOrders ?
                                    <>


                                        <Text style={styles.title}>Condition</Text>
                                        <View style={styles.bodyContent}>
                                            <TouchableOpacity
                                                onPress={() => setcondition(1)}
                                                style={condition == 1 ? styles.activeCondtion : styles.menuBox}>
                                                <Text
                                                    style={condition == 1 ? styles.activeText : styles.conditionText}>
                                                    New
                                                </Text>
                                            </TouchableOpacity>

                                            <TouchableOpacity
                                                onPress={() => setcondition(2)}
                                                style={condition == 2 ? styles.activeCondtion : styles.menuBox}>
                                                <Text
                                                    style={condition == 2 ? styles.activeText : styles.conditionText}>
                                                    Used
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                    </>
                                    : null}

                            </>

                            : null}
                    </View>

                    <View style={styles.optionalDetails}>
                        <OptionalDetailsButton onPress={() => { setotherDetailsAdd(!otherDetailsAdd) }} text='Other' />
                        {otherDetailsAdd ?
                            <View style={{ paddingHorizontal: 2, paddingVertical: 7, paddingBottom: 10 }}>
                                {/* <View style={{ flex: 1, flexDirection: 'row', marginRight: 5 }}>
                <Text style={{ flex: 0.7, color: primaryColour }}>Show number on product</Text>
                <Switch
                  style={{ flex: 0.3 }}
                  trackColor={{ true: primaryLightColorButton, false: 'grey' }}
                  thumbColor={switchValue ? primaryColour : "#f4f3f4"}
                  value={switchValue}
                  onValueChange={switchValue => setswitchValue(switchValue)}
                />
              </View> */}


                                <View key={key2}>
                                    {tags.length > 0 ? (
                                        <View>
                                            <Text style={styles.title}>Added tags</Text>
                                            <View
                                                key={key2}
                                                style={[
                                                    styles.inputs,
                                                    {
                                                        padding: 5,
                                                        flexDirection: 'row',
                                                        flexWrap: 'wrap',
                                                    },
                                                ]}>
                                                {tags.map((obj, index) => (
                                                    <View
                                                        style={{
                                                            backgroundColor: primaryColour,
                                                            borderRadius: 12,
                                                            paddingVertical: 10,
                                                            paddingLeft: 10,
                                                            flexDirection: 'row',
                                                            marginLeft: 2,
                                                            marginTop: 2,
                                                        }}>
                                                        <Text
                                                            style={{ color: 'white', textAlign: 'left', fontSize: 15 }}>
                                                            {obj.tag}
                                                        </Text>
                                                        <TouchableOpacity
                                                            onPress={() => {
                                                                tags.splice(index, 1);
                                                                settags(tags);
                                                                setkey2(Math.random());
                                                            }}
                                                            style={{ color: 'white', paddingLeft: '10%', padding: 3 }}>
                                                            <FaTimes
                                                                name="times"
                                                                type="font-awesome-5"
                                                                color="white"
                                                                size={16}
                                                                marginTop={2}
                                                            />
                                                        </TouchableOpacity>
                                                    </View>
                                                ))}
                                            </View>
                                        </View>
                                    ) : null}
                                </View>

                                <Text style={styles.title}>Tags </Text>
                                <View>
                                    <TextInput
                                        style={[styles.inputs, { textAlignVertical: 'top', marginTop: 10 }]}
                                        placeholder="Thease tags will be used in search"
                                        returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
                                        underlineColorAndroid="transparent"
                                        returnKeyType="none"
                                        onChangeText={text => settag(text)}
                                        onKeyPress={text => {
                                            if (tags.length < 5) {
                                                if (text.nativeEvent.key === ' ') {
                                                    var cmpltTag = {
                                                        id: tags.length > 0 ? tags[tags.length - 1].id + 1 : 0,
                                                        tag: tag,
                                                    };
                                                    tags.push(cmpltTag);
                                                    settag('');
                                                }
                                            }
                                        }}
                                        value={tag}
                                    />
                                    <Text style={{ textAlign: 'right' }}>{tags.length}/5</Text>
                                    <Text style={{ textAlign: 'right', color: 'gray', fontSize: 12 }}>
                                        Please only add tags relevent to your product eg.
                                        mobile,dell,toyota(MAX 5)
                                    </Text>
                                </View>
                                {/* <Text style={styles.title}>Link </Text>
              <TextInput
                style={[styles.inputs, { textAlignVertical: 'top', marginTop: 10 }]}
                placeholder="Enter youtube video link"
                returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
                underlineColorAndroid="transparent"
                returnKeyType="none"
                onChangeText={text => {
                  setytLink(text);
                  setdescriptionError(''), settitleError(''), setytbError('');
                }}
                value={ytLink}
              // maxLength={11}  //setting limit of input
              // keyboardType={'phone-pad'}
              />
              <Text style={{ textAlign: 'right', color: 'gray', fontSize: 12 }}>
                Youtube video link (optional){'\n'}
                eg.https://www.youtube.com/watch?v=DI6Fy2fHpOQ
              </Text> */}
                                {ytbError != '' ? (
                                    <Text style={{ textAlign: 'right', color: 'red', fontSize: 12 }}>
                                        {ytbError}
                                    </Text>
                                ) : null}
                            </View>
                            : null}
                    </View>



                    {/* <View style={{ flex: 1, flexDirection: 'row', marginRight: 5 }}>
        <Text style={{ flex: 0.7, color: primaryColour }}>Allow buyers to place orders</Text>
        <Switch
          style={{ flex: 0.3 }}
          trackColor={{ true: primaryLightColorButton, false: 'grey' }}
          thumbColor={allowOrders ? primaryColour : "#f4f3f4"}
          value={allowOrders}
          onValueChange={switchValue => setallowOrders(switchValue)}
        />
      </View> */}





                    {/* {
                        isLoading ?
                            <View style={[
                                styles.inputs,
                                {
                                    backgroundColor: primarybg,
                                    elevation: 0,
                                    borderWidth: 0,
                                    alignItems: 'center',
                                    flexDirection: 'row'
                                },
                            ]}>
                                <Text style={{ color: primaryColour }}> Uploading
                                    {'  '}{uploaded}/{remainingUpload} %{' '}
                                </Text>
                                <ActivityIndicator size="small" color={primaryColour} />
                            </View>
                            : null
                    } */}

                    <View style={{ flexDirection: 'row', marginTop: 20 }}>
                        <TouchableOpacity
                            style={[styles.inputs, {
                                flex: 1,
                                height: 45,
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: 40,
                                width: 100,
                                borderRadius: 15,
                                borderColor: primarybg,
                                borderWidth: 1,
                                elevation: 1,
                                backgroundColor: primaryColour
                            },
                            ]}
                            onPress={() => {
                                if (title.length < 5) { settitleError('A minimum length of 5 charachters is required'); notify('A minimum length of 5 charachters is required'); return; }
                                else { settitleError('') }
                                if (description.length < 20) {
                                    setdescriptionError('A minimum length of 20 charachters description is required'); notify('A minimum length of 20 charachters description is required');
                                    return;
                                } else { setdescriptionError('') }
                                if (price === '') { setpriceError('Please add price'); notify('Please add price'); return; }
                                else { settitleError('') }
                                // if (state2 === '') { notify('Please select state'); return; }
                                if (quantity === '') { notify('Please add quantity'); return; }
                                if (shipping === '') { notify('Please add delivery charges'); return; }
                                // if (city === '') { notify('Please select city'); return; }
                                // if (area === '') { notify('Please select area'); return; }
                                if (photos.length < 1) {
                                    setphotosError('Please add at least one photo');
                                    notify('Please add at least one photo')
                                    return;
                                } else {
                                    setphotosError('')
                                }
                                if ((sizes.length > 0)) {
                                    let error = 0
                                    sizes.map((item, index) => {
                                        if (item.name == '') {
                                            if (error == 0) {
                                                notify('Please add name of added size or delete it');
                                                error = 1
                                                return;
                                            }
                                        }
                                        if (item.price == '') {
                                            if (error == 0) {
                                                notify('Please add price of added size or delete it');
                                                error = 1
                                                return;
                                            }
                                        }
                                    })
                                    if (error == 1) {
                                        return;
                                    }
                                }
                                updateProduct(
                                    setIsLoading, title, settitleError, description, setdescriptionError, photos,
                                    setphotosError, ytLink, setytbError, id, postType, condition,
                                    tags, city, address, switchValue, state2, price,
                                    move, setError, bazaarId, productId,
                                    setremainingUpload, setuploaded, allowResellers, quantity, shipping,
                                    sizes, colors
                                );

                            }}
                        >
                            <Text style={[{ fontWeight: 'bold', color: primarybg, fontSize: 12 }]}>Save</Text>
                        </TouchableOpacity>

                    </View>


                    <PerfectModal modalVisible={colorPickerModal}
                        padding={0} internalPadding={0}
                        style={{ width: isDesktopOrLaptop ? '30%' : '90%' }}
                    >
                        <ScrollView
                            persistentScrollbar={true}
                            style={{ padding: 20, margin: 5 }}
                        >
                            <Text style={{ fontWeight: 'bold', fontSize: 16, color: primaryColour, marginBottom: 10 }}>
                                Select Color
                            </Text>
                            <View style={{ alignSelf: 'center', }}>
                                <HexColorPicker color={currentColor} onChange={setcurrentColor} />

                            </View>
                            <RoundButton text='Select' style={{ width: '50%', alignSelf: 'center', marginTop: 20, backgroundColor: primaryColour, borderRadius: 20 }}
                                onPress={() => {
                                    colors.push(currentColor); setcolors(colors);
                                    setColorsKey(Math.random()); setcolorPickerModal(false)
                                }}
                            />
                            <RoundButton text='Cancel' style={{ width: '50%', alignSelf: 'center', backgroundColor: primaryColour, borderRadius: 20 }}
                                onPress={() => {
                                    setcolorPickerModal(false)
                                }}
                            />
                        </ScrollView>
                    </PerfectModal>
                    {/* <PerfectModal modalVisible={variationModal} internalPadding={0}>
                <VariationForm
                    photos={photos}
                    variations={variations}
                    setvariations={setvariations}
                    currentVariationIndex={currentVariationIndex}
                    setvariationModal={setvariationModal}
                    seteditingVariation={seteditingVariation}
                    editingVariation={editingVariation}
                />
            </PerfectModal> */}
                </View>
            </Sidebar>
        </>
    );
}

const styles = StyleSheet.create({
    optionalDetails: {
        backgroundColor: inputBackgound,
        marginVertical: 6,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        paddingVertical: 5,
        borderWidth: 0.25,
        shadowColor: 'gray',
        elevation: 1,
        borderRadius: 7,
        borderColor: 'lightgray',
        borderBottomWidth: 0,
        marginHorizontal: 5,
        paddingHorizontal: 5,
        shadowColor: '#91a8eb',
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.34,
        shadowRadius: 6.27,

        elevation: 5,
    },
    loading: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        color: primaryColour,
    },
    tinyLogo: {
        width: 70,
        height: 60,
        resizeMode: 'cover',
        marginVertical: 10,
        marginLeft: 5,
        borderRadius: 9,
    },
    container: {
        flex: 1,
        paddingTop: 20,
        backgroundColor: primarybg,
        paddingHorizontal: 20,
        flexDirection: 'column',
    },
    inputs: {
        backgroundColor: inputBackgound,
        alignItems: 'center',
        justifyContent: 'center',
        width: '90%',
        alignSelf: 'center',
        // margin: 12,
        padding: 7,
        paddingHorizontal: 10,
        shadowColor: shadowColor,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
        alignContent: 'center',
        borderRadius: 5,
        borderColor: 'lightgray',
        borderBottomWidth: 0,
        color: primaryColour,
        marginBottom: 10,
        marginTop: 10,
        outlineStyle: 'none',
    },
    bodyContent: {
        alignItems: 'center',
        alignContent: 'center',
        padding: 1,
        paddingHorizontal: 25,
        flexDirection: 'row',
    },
    activeCondtion: {
        backgroundColor: primaryColour,
        width: 100,
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
        margin: 12,
        shadowColor: 'white',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        borderWidth: 0.01,
        shadowColor: 'black',
        elevation: 20,
        alignContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        borderColor: 'white',
    },
    inactiveCondtion: {
        width: 100,
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
        margin: 12,
        shadowColor: 'white',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        borderWidth: 0.01,
        shadowColor: 'black',
        elevation: 20,
        alignContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        borderColor: 'white',
        backgroundColor: primarybg,
    },
    menuBox: {
        width: 100,
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
        margin: 12,
        shadowColor: 'white',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        borderWidth: 0.01,
        shadowColor: 'black',
        elevation: 4,
        alignContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        borderColor: 'white',
        backgroundColor: primarybg,
    },
    conditionText: {
        color: primaryColour,

    },
    activeText: {
        color: 'white',
    },
});
