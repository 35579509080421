import { useEffect, useState } from "react";
import { BiPaint, BiPowerOff } from "react-icons/bi";
import { BsGrid, BsMenuApp, BsMenuButton } from "react-icons/bs";
import { FaPowerOff } from "react-icons/fa";
import { IoListSharp, IoReceiptSharp } from "react-icons/io5";
import { RiHomeSmileLine } from "react-icons/ri";
import { Text, TouchableOpacity, View, TextInput, Platform, ActivityIndicator, Keyboard, Dimensions, ScrollView, FlatList } from "react-native";
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from "react-router-dom";
import { profileProductsFetchSM, removeProduct } from "../../utils/api";
import ImageLoad from "../../utils/imageLoad";
import PerfectModal from "../../utils/perfectModel";
import { serverURL } from "../../utils/string";
import { RoundButton } from "../../utils/utils";
import Sidebar from "../sidebar";
import { shadow } from "../utils/main";
import { primarybg, primaryColour } from "../utils/string";


const Icon = () => (<></>);

export default function MyItems(props) {

    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const [data, setData] = useState([]);
    const [refreshing, setrefreshing] = useState(false);
    const [prevStart, setprevStart] = useState(0);
    const [loadMore, setloadMore] = useState(true);
    const [pageRefreshKey, setpageRefreshKey] = useState(Math.random());
    let userId = null;
    const [deleteAlert, setdeleteAlert] = useState(true);

    useEffect(() => {
        profileProductsFetchSM(setData, data, setrefreshing, prevStart, setprevStart, setloadMore, navigate, userId)
        console.log(data)
    }, [])

    useEffect(() => {
        if (!refreshing) {
            setdeleteAlert(false)
        }
    }, [refreshing])



    return (
        <Sidebar active='myItems' notScrollView={true} title={'My Items'}>

            <View style={{ width: !isDesktopOrLaptop ? Dimensions.get('window').width : undefined }}>
                {refreshing && <ActivityIndicator size={'small'} color={primaryColour} />}
                <RoundButton text='Add item' style={{ width: '20%', alignSelf: 'center', marginBottom: 20, borderRadius: 20, backgroundColor: primaryColour }} onPress={() => { navigate('/select-category') }} />
                <FlatList
                    contentContainerStyle={[{
                        marginHorizontal: isDesktopOrLaptop ? '3%' : 0, backgroundColor: primarybg,
                        paddingHorizontal: '1%', paddingVertical: 10
                    }, shadow]}
                    ListHeaderComponent={() => (
                        <View style={{ flexDirection: 'row', backgroundColor: primaryColour + '21', paddingVertical: 10, paddingHorizontal: 10 }}>
                            <Text style={{ flex: isDesktopOrLaptop ? 0.8 : 0.7, }}>
                                Product
                            </Text>

                            <Text style={{ flex: isDesktopOrLaptop ? 0.2 : 0.3, }}>
                                Action
                            </Text>
                        </View>
                    )}
                    data={data}
                    renderItem={({ item }) => (
                        <>
                            <TouchableOpacity style={[{ backgroundColor: primarybg, flexDirection: 'row', paddingVertical: 10, paddingHorizontal: 10 }]}>
                                <View style={{ flex: isDesktopOrLaptop ? 0.8 : 0.7, flexDirection: 'row' }}>
                                    <ImageLoad
                                        style={[{
                                            width: 70,
                                            height: 60,
                                            borderWidth: 1
                                        }
                                        ]}
                                        // imageStyle={{
                                        //     width: 60,
                                        //     height: 50,
                                        // }}
                                        resizeMode="cover"
                                        source={
                                            data.cover !== 'null' ?
                                                serverURL + item.cover
                                                : 'https://images.unsplash.com/photo-1481595357459-84468f6eeaac?dpr=1&auto=format&fit=crop&w=376&h=251&q=60&cs=tinysrgb'
                                        }
                                    ></ImageLoad>
                                    <View style={{ justifyContent: 'center' }}>
                                        <Text style={{ paddingLeft: 10, fontSize: 16, color: primaryColour, fontWeight: 'bold' }}>
                                            {isDesktopOrLaptop ? item.title :
                                                (item.title.length > 18 ? String(item.title.substring(0, 18) + '...').replace(/\n/g, " ") : String(item.title.replace(/\n/g, " ")))
                                            }
                                        </Text>
                                        <Text style={{ paddingLeft: 10, fontSize: 14, color: primaryColour, }}>Rs.{item.price}</Text>
                                    </View>

                                </View>
                                <View style={{ flex: isDesktopOrLaptop ? 0.2 : 0.3, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <RoundButton text='edit' style={{ width: '47%', height: 30, backgroundColor: primaryColour, borderRadius: 20 }}
                                        onPress={() => navigate('/edit-item/' + item.id)}
                                    />
                                    <RoundButton text='Delete' style={{ width: '47%', height: 30, backgroundColor: primaryColour, borderRadius: 20 }}
                                        onPress={() => setdeleteAlert(true)}
                                    />
                                </View>
                            </TouchableOpacity>
                            <PerfectModal modalVisible={deleteAlert}
                                style={{ width: '30%' }}
                            >
                                <Text style={{ textAlign: 'center', color: primaryColour, }}>Are you sure you want to remove this item?</Text>
                                {refreshing && <ActivityIndicator size={'small'} color={primaryColour} />}
                                <View style={{ flexDirection: 'row', marginTop: 10, justifyContent: 'center' }}>
                                    <RoundButton text='close' style={{ width: 70, height: 30 }} onPress={() => setdeleteAlert(false)} />
                                    <RoundButton text='Yes' style={{ width: 70, marginLeft: 30, height: 30 }}
                                        onPress={() => { removeProduct(setrefreshing, item.id, navigate); window.location.reload(); }}
                                    />
                                </View>
                            </PerfectModal>
                        </>
                    )}
                />
            </View>
        </Sidebar>
    );
}

