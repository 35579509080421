import React, { useState, useEffect } from 'react';
import {
    StyleSheet,
    Text,
    View,
    TextInput,
    Modal,
    ActivityIndicator,
    TouchableOpacity,
    ScrollView,
    ImageBackground,
    Dimensions,
    Image,
    FlatList,
    LogBox,
    Keyboard,
    Picker
} from 'react-native';

import { PerfectInput, RoundButton, notify } from '../../utils/utils';
import { checkDukanLinkAvailability, SaveInitialSettings, } from '../../utils/api';
import { borderColor, dukanTypes, inputBackgound, primaryLightColor, primaryLightColorButton, shadowColor } from '../../utils/string';

import areas from '../../utils/areas.json';
import DropDown from '../../utils/dropDown';
import ImageLoad from '../../utils/imageLoad';
// import ImagePicker from 'react-native-image-crop-picker';
import { otherCategories } from '../../utils/string';
import { FaAngleDown, FaArrowRight, FaPencilAlt } from 'react-icons/fa';
import PerfectModal from '../../utils/perfectModel';
import { useMediaQuery } from 'react-responsive';
import { shadow } from '../utils/main';
import { primarybg, primaryColour, secondarybg } from '../utils/string';
import { useNavigate } from "react-router-dom";

let showToast = notify
const { width, height } = Dimensions.get('window')
const checkLogin = () => (<></>)

const inputStyle = {
    backgroundColor: inputBackgound,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    // margin: 12,
    padding: 7,
    paddingHorizontal: 10,
    shadowColor: shadowColor,
    shadowOffset: {
        width: 0,
        height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
    alignContent: 'center',
    borderRadius: 5,
    borderColor: 'lightgray',
    borderBottomWidth: 0,
    color: primaryColour,
    marginBottom: 10,
    marginTop: 10,
}

export const InitialSettingScreen = props => {
    const navigate = useNavigate();
    const { visible, setvisible, } = props
    const categories = otherCategories
    const [selectedCategories, setselectedCategories] = useState([]);
    const [key, setkey] = useState(Math.random());
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [newdata, setNewData] = useState([]);
    const [visible1, setVisible1] = useState(true);
    const [visible2, setVisible2] = useState(false);
    const [code, setCode] = useState('');
    const [privacyCheckBox, setprivacyCheckBox] = useState(false);
    const [logedIn, setlogedIn] = useState(false);

    const [isBuyer, setisBuyer] = useState(true);
    const [isSeller, setisSeller] = useState(true);
    const [isReseller, setisReseller] = useState(false);
    const [city, setcity] = useState('');
    const [cities, setcities] = useState([]);
    const [physicalBazaar, setphysicalBazaar] = useState('');
    const [dukanName, setdukanName] = useState('');
    const [address, setaddress] = useState('.');
    const [dukanCategory, setdukanCategory] = useState('');
    const [manualCategory, setmanualCategory] = useState(false);
    const [optional, setoptional] = useState(false);
    const [createDukan, setcreateDukan] = useState(false);

    const [isLogin, setisLogin] = useState(false);
    const [loginChecking, setloginChecking] = useState(true);

    const [dukanLink, setdukanLink] = useState('');
    const [linkloading, setlinkloading] = useState(false);
    const [linkMessage, setlinkMessage] = useState('');

    const [smsNotification, setsmsNotification] = useState(false);

    const [isKeyboardVisible, setKeyboardVisible] = useState(false);

    //1 is login and 2 is otp and 3 is dukan details enter
    const [miniPage, setminiPage] = useState(1);
    const [referral, setreferral] = useState('');
    const [contactNo, setContactNo] = useState('');
    const [sessionInfo, setsessionInfo] = useState('');
    const [cover, setcover] = useState('');
    const [triedToSelectImage, settriedToSelectImage] = useState(false);
    const [savedFToken, setsavedFToken] = useState(false);

    useEffect(() => {
        const keyboardDidShowListener = Keyboard.addListener(
            'keyboardDidShow',
            () => {
                setKeyboardVisible(true); // or some other action
            }
        );
        const keyboardDidHideListener = Keyboard.addListener(
            'keyboardDidHide',
            () => {
                setKeyboardVisible(false); // or some other action
            }
        );

        return () => {
            keyboardDidHideListener.remove();
            keyboardDidShowListener.remove();
        };
    }, []);

    useEffect(() => { setminiPage(1) }, [isSeller])




    useEffect(() => {
        LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
        // doo()
        areas.forEach((item, index) => {
            cities.push(item.city)
            setcities(cities)
        })
        if (!savedFToken) {
            // saveFirebaseToken()
            setsavedFToken(true)
        }

    }, []);

    useEffect(() => {
        if (isSeller) {
            // checkLogin().then(v => {
            //     if (v === true) {
            //         setisLogin(true)
            //         setminiPage(3)
            //     } else {
            //         setminiPage(1)
            //     }
            //     setloginChecking(false)
            // })
        }
    }, [isSeller])

    return (
        <PerfectModal modalVisible={true}
            style={[{
                margin: 0, padding: 0, borderColor: secondarybg, borderWidth: 2,
                borderRadius: 20, justifyContent: 'center', alignItems: 'center', backgroundColor: primaryColour
            }, isDesktopOrLaptop ? {
                width: '30%', height: '70%',
            } :
                { borderRadius: 20, width: '95%' }
                // { width: '100%', margin: 0, padding: 0, height: Dimensions.get('window').height - 30, borderColor: primarybg, borderWidth: 2, borderRadius: 20 }
            ]}
        >
            <ScrollView
                style={{ width: '100%', paddingHorizontal: '5%' }}
                nestedScrollEnabled={true}
                keyboardShouldPersistTaps="always"
            >
                <link href='https://fonts.googleapis.com/css?family=Lobster' rel='stylesheet'></link>
                <Text style={{ fontSize: 40, fontFamily: 'Lobster', textAlign: 'center', color: secondarybg, marginVertical: 15, marginTop: 30 }}>Bazars.pk</Text>

                <Text style={{ color: primarybg, paddingHorizontal: 10, fontWeight: 'bold', marginTop: 5, letterSpacing: 1.4 }}>City:</Text>

                <DropDown
                    label={'Select Your City'}
                    data={cities}
                    textWithListText=' '
                    selected={city} setSelected={setcity}
                    onPress={async (item,) => {
                        setcity(item);
                    }}
                    labelColor={primarybg}
                    style={[{
                        marginTop: 10, marginBottom: 5,
                        borderColor: borderColor, borderRadius: 5, justifyContent: 'center',
                        alignItems: 'center', paddingRight: 20, borderRadius: 20, width: '90%', alignSelf: 'center',
                        backgroundColor: 'rgba(247, 223, 242, 0.1)', color: primarybg
                    }, shadow, { borderWidth: 0, shadowColor: 'gray' }]}
                />
                <Text style={{ color: primarybg, paddingHorizontal: 10, fontWeight: 'bold', marginTop: 19, marginBottom: 3, letterSpacing: 1.4 }}>Category :</Text>
                <DropDown
                    label={'Select Business Category'}
                    labelColor={primarybg}
                    data={dukanTypes}
                    listHeight={230}
                    selected={dukanCategory} setSelected={setdukanCategory}
                    onPress={async (item) => {
                        if (item == 'My dukan category is not in list') {
                            setmanualCategory(true)
                            setdukanCategory('')
                        } else {
                            setdukanCategory(item);
                            setmanualCategory(false)
                        }
                    }}
                    style={[{
                        marginTop: 10, marginBottom: 5,
                        borderColor: borderColor, borderRadius: 5, justifyContent: 'center',
                        alignItems: 'center', paddingRight: 20, borderRadius: 20, width: '90%', alignSelf: 'center',
                        backgroundColor: 'rgba(247, 223, 242, 0.1)', color: primarybg
                    }, shadow, { borderWidth: 0, shadowColor: 'gray' }]}
                />
                {manualCategory &&
                    <PerfectInput
                        onChangeText={(text) => {
                            setdukanCategory(text)
                        }}
                        placeholder={'Enter dukan category name'}
                        style={[{ marginTop: 15, width: '90%', alignSelf: 'center', borderRadius: 20, backgroundColor: 'rgba(247, 223, 242, 0.1)', },
                            shadow, { borderWidth: 0, shadowColor: 'gray' }]}
                    />
                }
                <Text style={{ color: primarybg, paddingHorizontal: 10, fontWeight: 'bold', marginTop: 19, marginBottom: 3, letterSpacing: 1.2 }}>Enter Business Name:</Text>
                <PerfectInput
                    onChangeText={(text) => {
                        setdukanName(text)
                    }}
                    placeholder={'Bismillah store,City hospital etc etc'}
                    placeholderTextColor={'lightgray'}
                    style={[{
                        marginTop: 10, width: '90%', alignSelf: 'center', borderRadius: 20, backgroundColor: 'rgba(247, 223, 242, 0.1)',
                        color: primarybg
                    }, shadow, { borderWidth: 0, shadowColor: 'gray' }]}
                />
                <Text style={{ color: primarybg, paddingHorizontal: 10, fontWeight: 'bold', marginTop: 10, marginBottom: 2, letterSpacing: 1.2 }}>Set Website Link:</Text>
                <View
                    style={[inputStyle, {
                        flexDirection: 'row', backgroundColor: inputBackgound,
                        marginHorizontal: 0, justifyContent: 'center',
                        alignItems: 'center', borderWidth: 0, height: 40, borderColor: borderColor, borderRadius: 20, width: '90%', alignSelf: 'center',
                        backgroundColor: 'rgba(247, 223, 242, 0.1)'
                    }, shadow, { borderWidth: 0, shadowColor: 'gray' }]}>
                    <Text style={{ textAlign: 'right', color: primarybg, fontWeight: 'bold', }}>https://bazars.pk/</Text>
                    <TextInput
                        style={{ flex: 0.9, color: primarybg, fontWeight: 'bold', marginLeft: 5, width: '100%' }}
                        onChangeText={(text) => {
                            setdukanLink(text.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').replace(/\s/g, ''))
                            if (dukanLink.length >= 5) {
                                checkDukanLinkAvailability(
                                    setlinkMessage, setlinkloading, props.navigation,
                                    text.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').replace(/\s/g, '').toLowerCase())
                            }
                            // console.log('')
                        }}
                        value={dukanLink}
                        placeholder={'food,cityhospital,toys etc etc'}
                        placeholderTextColor={'lightgray'}
                        autoCapitalize="none"
                    />

                    {linkloading ? <ActivityIndicator size="small" color={primaryColour} style={{ flex: 0.1 }} /> : null}
                </View>
                {linkMessage != '' ? (
                    <Text style={{ textAlign: 'center', color: secondarybg, fontSize: 14, fontWeight: 'bold', marginTop: 10 }}>
                        {linkMessage}
                    </Text>
                ) : null}
                {/* <Text style={{ color: secondarybg, paddingHorizontal: 10, fontWeight: 'bold', marginTop: 5 }}>Street address:</Text>
                  <PerfectInput
                    onChangeText={(text) => {
                      setaddress(text)
                    }}
                    placeholder={'street # , area, city, province'}
                    style={{ marginTop: 15, width: '90%', alignSelf: 'center' }}
                  /> */}
                <View
                    style={{
                        // backgroundColor: inputBackgound,
                        // marginVertical: 6,
                        // shadowOffset: {
                        //     width: 0,
                        //     height: 2,
                        // },
                        // // borderWidth: 0.25,
                        // shadowColor: 'gray',
                        // elevation: 1,
                        // borderRadius: 5,
                        // borderColor: 'lightgray',
                        // borderBottomWidth: 0,
                        // marginHorizontal: 5,
                        // paddingHorizontal: 5, width: '93%', alignSelf: 'center', marginBottom: 100
                    }}>
                    {/* <TouchableOpacity
                        style={{
                            width: '100%', backgroundColor: inputBackgound, paddingHorizontal: 5, paddingVertical: 1, borderRadius: 5, flexDirection: 'row',

                        }}
                        onPress={() => setoptional(!optional)}
                    >
                        <Text style={{ color: secondarybg, paddingHorizontal: 10, fontWeight: 'bold', fontSize: 14, flex: 0.95 }}>Optional:</Text>
                        <FaAngleDown
                            name="angle-down"
                            type="font-awesome"
                            color={primaryColour}
                            size={19}
                        />
                    </TouchableOpacity> */}
                    {optional &&
                        <>
                            <Text style={{ color: primaryColour, paddingHorizontal: 10, fontWeight: 'bold', marginTop: 5 }}>Physical bazaar:</Text>
                            <PerfectInput
                                onChangeText={(text) => {
                                    setphysicalBazaar(text)
                                }}
                                placeholder={'Name of physical bazaar of your dukan'}
                                style={{ marginTop: 15, width: '90%', alignSelf: 'center' }}
                            />
                            <Text style={{ color: primaryColour, paddingHorizontal: 10, fontWeight: 'bold', marginTop: 5, marginBottom: 5 }}>Select Categories of products that you want to sell:</Text>
                            <Text style={{ color: 'gray', textAlign: 'center', fontSize: 12 }}>
                                These categories will be used to increase your sales by sending you notifications when related need will be added by buyer
                            </Text>
                            <View key={key}>
                                <FlatList
                                    data={categories}
                                    horizontal={false}
                                    numColumns={5}
                                    columnWrapperStyle={{ flexWrap: "wrap" }}

                                    keyExtractor={item => {
                                        return item.id;
                                    }}
                                    scrollEnabled={false}
                                    renderItem={({ item }) => {
                                        return (
                                            <TouchableOpacity
                                                onPress={() => {
                                                    const map = selectedCategories.filter(
                                                        x => x.id === item.id,
                                                    );
                                                    if (map.length > 0) {
                                                        setselectedCategories(
                                                            selectedCategories.filter(function (obj) {
                                                                return obj.id !== item.id;
                                                            })
                                                        )
                                                        setkey(Math.random());
                                                    } else {
                                                        selectedCategories.push(item);
                                                        setkey(Math.random());
                                                    }
                                                }}
                                                style={[{
                                                    elevation: 1,
                                                    borderWidth: 2,
                                                    marginVertical: 5,
                                                    padding: 5,
                                                    marginHorizontal: 2,
                                                    borderRadius: 100,
                                                    borderColor: primaryColour, backgroundColor: primarybg,
                                                },
                                                selectedCategories.filter(
                                                    x => x.id === item.id,
                                                ).length > 0 ?
                                                    { backgroundColor: primaryColour }
                                                    : {}
                                                ]}>
                                                <Text style={[
                                                    selectedCategories.filter(
                                                        x => x.id === item.id,
                                                    ).length > 0 ?
                                                        { color: primarybg }
                                                        : { color: primaryColour }
                                                ]}>{item.title}</Text>
                                            </TouchableOpacity>
                                        );
                                    }}
                                />
                            </View>
                        </>}
                </View>
                <TouchableOpacity
                    style={[{
                        height: isDesktopOrLaptop ? 45 : 35,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                        marginBottom: isDesktopOrLaptop ? 20 : 40,
                        width: isDesktopOrLaptop ? 100 : 90,
                        borderRadius: 30,
                        marginTop: isDesktopOrLaptop ? 30 : 15,
                        borderColor: primarybg,
                        borderWidth: 1,
                        elevation: 1,
                        backgroundColor: secondarybg
                    },
                    ]}
                    onPress={() => {
                        if (isSeller) {
                            if (city == '') { showToast('Please select city'); return; }
                            if (dukanCategory == '') { showToast('Please select category'); return; }
                            if (dukanName == '') { showToast('Please add Business name'); return; }
                            if (address == '') { showToast('Please add your address'); return; }
                            if (dukanLink == '') { showToast('Please enter link'); return; }
                            if (dukanLink.length < 5) { showToast('Link length should be at least 5'); return; }
                            // if (!triedToSelectImage) { showToast('Please select cover photo'); return; }
                        }
                        SaveInitialSettings(
                            setIsLoading, selectedCategories, smsNotification,
                            isSeller, isBuyer, isReseller, setData,
                            setError, navigate, city, dukanCategory, dukanName, address, physicalBazaar, dukanLink, cover
                        );
                    }}>
                    {isLoading ? (
                        <View style={{ flex: 0.9 }}>
                            <ActivityIndicator size="small" color={primarybg} />
                        </View>
                    ) : null}
                    <Text style={[
                        { fontWeight: 'bold', fontSize: isDesktopOrLaptop ? 16 : 14, letterSpacing: 1.3, color: primaryColour },

                    ]}>Save</Text>
                    <FaArrowRight
                        style={{
                            marginLeft: 10,
                            color: primaryColour, alignSelf: 'center'
                        }}
                        color={primarybg}
                        size={isDesktopOrLaptop ? 15 : 13}
                    />
                </TouchableOpacity>




            </ScrollView>

            {/* <View style={{ backgroundColor: primarybg, elevation: 10, flexDirection: 'row', justifyContent: 'center', paddingHorizontal: 20 }}>

                <TouchableOpacity
                    style={[{
                        height: 45,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 20,
                        width: 100,
                        borderRadius: 30,
                        marginTop: 10,
                        borderColor: primarybg,
                        borderWidth: 1,
                        elevation: 1,
                        backgroundColor: primaryColour
                    },
                    ]}
                    onPress={() => {
                        if (isSeller) {
                            if (city == '') { showToast('Please select city'); return; }
                            if (dukanCategory == '') { showToast('Please select category'); return; }
                            if (dukanName == '') { showToast('Please select bazaar'); return; }
                            if (address == '') { showToast('Please add your address'); return; }
                            if (dukanLink == '') { showToast('Please enter link'); return; }
                            if (dukanLink.length < 5) { showToast('Link length should be at least 5'); return; }
                            if (!triedToSelectImage) { showToast('Please select cover photo'); return; }
                        }
                        SaveInitialSettings(
                            setIsLoading, selectedCategories, smsNotification,
                            isSeller, isBuyer, isReseller, setData,
                            setError, props, city, dukanCategory, dukanName, address, physicalBazaar, dukanLink, cover
                        );
                    }}>
                    {isLoading ? (
                        <View style={{ flex: 0.9 }}>
                            <ActivityIndicator size="small" color={primarybg} />
                        </View>
                    ) : null}
                    <Text style={[
                        { fontWeight: 'bold' },
                        privacyCheckBox === true ? { color: primaryColour } : { color: primarybg, }

                    ]}>Save</Text>
                    <FaArrowRight
                        style={[{
                            marginLeft: 15,
                        },
                        privacyCheckBox === true ? { color: primaryColour } : { color: primarybg, }
                        ]}
                        name="arrow-right"
                        type="font-awesome-5"
                        color={primarybg}
                        size={15}
                    />
                </TouchableOpacity>
            </View> */}

        </PerfectModal >
        // {/* </ImageBackground> */}

    );
};



