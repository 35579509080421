import { Text, TouchableOpacity, View, TextInput, Platform, ActivityIndicator, Keyboard, } from "react-native";
import { primarybg } from "../../../utils/string";
import { useMediaQuery } from 'react-responsive'
import PerfectModal from "../../../utils/perfectModel";
import { RoundButton } from "../../../utils/utils";
import { useEffect, useState } from "react";
import OTPVerification from './OTPVerification'
import { firebase, auth } from '../../../utils/firebase';

const Icon = () => (<></>);

export default function Login(props) {

    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const { visible, setvisible } = props
    const [contactNo, setContactNo] = useState('');
    const [privacyCheckBox, setprivacyCheckBox] = useState(true);
    const [error, setError] = useState('');
    const [otpVerification, setotpVerification] = useState(false);
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [final, setfinal] = useState('');
    const [recaptcha, setrecaptcha] = useState();
    const [primaryColour, setPrimaryColour] = useState('#5893D4aa');

    useEffect(() => {
        typeof props.primaryColour != 'undefined' && setPrimaryColour(props.primaryColour)
    }, [props])

    // Sent OTP
    const signin = () => {
        setisLoading(true)
        if (/^((?:00|\+)92)?(0?3(?:[0-46]\d|55)\d{7})$/.test(contactNo)) {

            if (!recaptcha) {
                const verifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                    size: "invisible",
                });
                setrecaptcha(verifier)
                auth.signInWithPhoneNumber('+92' + contactNo, verifier).then((result) => {
                    setfinal(result);
                    setotpVerification(true);
                    setvisible(false)
                    setisLoading(false)
                }).catch((err) => {
                    setError(err)
                });
            } else {
                auth.signInWithPhoneNumber('+92' + contactNo, recaptcha).then((result) => {
                    setfinal(result);
                    setotpVerification(true);
                    setvisible(false)
                    setisLoading(false)
                }).catch((err) => {
                    setError(err)
                });
            }
        } else {
            setError('Invalid phone number')
        }
    }

    // useEffect(() => {
    //     const verifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
    //         size: "invisible",
    //     });
    //     setrecaptcha(verifier)
    //     // if (!recaptcha) {
    //     //     verifier.verify().then(() => setrecaptcha(verifier));
    //     // }
    //     // return () => {
    //     //     verifier.clear()
    //     // }
    // });
    return (
        <>
            <OTPVerification visible={otpVerification} setvisible={setotpVerification} contactNo={contactNo} final={final} primaryColour={primaryColour} />
            <PerfectModal
                modalVisible={visible}
                onRequestClose={() => {
                    setvisible(false)
                }}
                // height={isDesktopOrLaptop ? '80%' : Dimensions.get('window').height - 30}
                style={[isDesktopOrLaptop ? { width: '30%', margin: 0, padding: 0, height: '70%', borderColor: primarybg, borderWidth: 2, borderRadius: 20, justifyContent: 'center', alignItems: 'center' } :
                    { borderRadius: 20, }
                    // { width: '100%', margin: 0, padding: 0, height: Dimensions.get('window').height - 30, borderColor: primarybg, borderWidth: 2, borderRadius: 20 }
                ]}
            >
                <Text
                    style={{
                        fontSize: 40,
                        textAlign: 'center',
                        marginBottom: 10,
                        marginTop: 20,
                        color: primaryColour,
                        fontWeight: 'bold'
                    }}>
                    Bazars
                </Text>
                <Text
                    style={{
                        fontSize: 20,
                        textAlign: 'center',
                        marginBottom: 10,
                        marginTop: 10,
                        color: primaryColour,
                        // fontWeight:'bold'
                    }}>
                    Mobile Verification
                </Text>
                <Text
                    style={{
                        fontSize: 14,
                        textAlign: 'center',
                        marginBottom: 10,
                        color: 'lightgray',
                    }}>
                    Please enter your mobile number to{'\n'} verify your account
                </Text>
                {error ? (
                    <Text
                        style={{
                            fontSize: 14,
                            textAlign: 'center',
                            marginBottom: 10,
                            color: 'red',
                        }}>
                        {error}
                    </Text>
                ) : null}

                <View
                    style={{
                        borderColor: 'lightgray',
                        // backgroundColor: 'white',
                        backgroundColor: 'rgba(247, 223, 242, 0.1)',
                        borderRadius: 30,
                        borderWidth: 1,
                        width: 250,
                        height: 45,
                        marginBottom: 25,
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: 10,
                        alignSelf: 'center'
                    }}>
                    <Text style={{
                        width: 30,
                        height: 30,
                        marginLeft: 15,
                        justifyContent: 'center',
                        color: primaryColour,
                        marginTop: 9,
                        fontWeight: 'bold',
                        fontSize: 16
                    }}>
                        +92
                    </Text>
                    <TextInput
                        style={{
                            height: 45,
                            marginLeft: 16,
                            borderBottomColor: primaryColour,
                            flex: 1,
                            color: 'lightgray',
                            outlineStyle: 'none',

                        }}
                        returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
                        placeholderTextColor={'lightgray'}
                        placeholder="3*********"
                        // keyboardType="email-address"
                        underlineColorAndroid="transparent"
                        onChangeText={text => setContactNo(text)}
                        value={contactNo}
                        maxLength={10} //setting limit of input
                        keyboardType={'phone-pad'}
                        onSubmitEditing={Keyboard.dismiss}
                    />
                </View>
                <div id="recaptcha-container"></div>
                {/* <View style={{ flexDirection: 'row', marginBottom: 20, }}> */}
                {/* <BouncyCheckbox
                                size={20}
                                fillColor={'rgba(51, 51, 48, 0.5)'}
                                unfillColor={'rgba(247, 223, 242, 0.1)'}
                                // text=""
                                iconStyle={{ borderColor: primarybg }}
                                textStyle={{ fontFamily: "JosefinSans-Regular" }}
                                onPress={(isChecked) => {
                                    setprivacyCheckBox(isChecked);
                                    Keyboard.dismiss()

                                }}
                            /> */}
                {/* <input
                                        type='checkbox'
                                        style={{ marginRight: 5 }}
                                        checked={privacyCheckBox}
                                        onClick={() => {
                                            setprivacyCheckBox(!privacyCheckBox);
                                            Keyboard.dismiss()
                                        }}
                                    />
                                    <Text
                                        style={{
                                            fontSize: 14,
                                            textAlign: 'center',
                                            color: primarybg,
                                        }}>

                                        I agree to the

                                    </Text>
                                    <TouchableOpacity
                                        onPress={() => {
                                            // setVisible1(false);
                                            // setVisible2(false)
                                            // navigation.navigate('Privacy');
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontSize: 14,
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                                textDecorationLine: 'underline',
                                                color: primarybg,
                                            }}>
                                            {' '}Privacy Policy
                                        </Text>
                                    </TouchableOpacity>
                                </View> */}
                <TouchableOpacity
                    disabled={!privacyCheckBox}
                    style={[{
                        height: 45,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                        marginBottom: 20,
                        width: 100,
                        borderRadius: 30,
                        marginTop: 10,
                        borderColor: primarybg,
                        borderWidth: 1,
                    },
                    privacyCheckBox === true ? { backgroundColor: primaryColour } : { backgroundColor: 'rgba(247, 223, 242, 0.1)', }
                    ]}
                    onPress={() => {
                        // generateOTP(setdata, setisLoading, contactNo, setError, setotpVerification, setvisible)
                        signin()

                    }}>
                    {isLoading ? (
                        <View style={{ flex: 0.9 }}>
                            <ActivityIndicator size="small" color={primarybg} />
                        </View>
                    ) : null}
                    <Text style={[
                        { fontWeight: 'bold' },
                        privacyCheckBox === true ? { color: primarybg } : { color: primarybg, }

                    ]}>Next</Text>
                    <Icon
                        style={[{
                            marginLeft: 15,
                        },
                        privacyCheckBox === true ? { color: primarybg } : { color: primarybg, }
                        ]}
                        name="arrow-right"
                        type="font-awesome-5"
                        color={primarybg}
                        size={15}
                    />
                </TouchableOpacity>
                <RoundButton style={{ flexDirection: 'row', width: 90, alignSelf: 'center', marginBottom: 20, backgroundColor: primaryColour }}
                    onPress={() => setvisible(false)}
                >
                    <Text style={{ textAlign: 'center', color: primarybg }}>Close</Text>
                </RoundButton>
            </PerfectModal>
        </>
    );
}

