import React, { useState } from 'react';
import {
    View,
    Text,
    Image,
    Alert,
    Dimensions,
    ImageBackground,
    Modal,
    KeyboardAvoidingView,
    TouchableOpacity
} from 'react-native';
import PerfectModal from '../../../utils/perfectModel';
import { PerfectInput, RoundButton } from '../../../utils/utils';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import { defaultLink, primarybg, shadowColor } from '../../../utils/string';
import ReactModal from 'react-modal';
import { BiCloset } from 'react-icons/bi';
import 'animate.css';
import { GrClose } from 'react-icons/gr';
import { IoClose } from 'react-icons/io5';


export default function SearchModal(props) {

    const dimensions = Dimensions.get('window');
    const navigate = useNavigate();
    const { dukanId } = useParams();
    const { primaryColour, searchModal, setsearchModal, dukanLink, secondaryColor, dukanName } = props
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const [searchText, setsearchText] = useState('');
    let bgWhite = 'white'
    return (

        <ReactModal
            isOpen={searchModal}
            closeTimeoutMS={500}
            contentLabel="Minimal Modal Example"
            style={{

                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'rgb(211,211,211,0.8)',
                },
                content: {
                    position: 'absolute',
                    border: '1px solid black',
                    background: primaryColour,
                    overflow: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    outline: 'none',
                    padding: 10,
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '99.9%',
                    height: dimensions.height,
                    marginLeft: -40,
                    marginTop: -40,
                    border: 'none',
                    borderRadius: 0,
                },

            }}


        >

            <View>
                <TouchableOpacity
                    onPress={() => { setsearchModal(false) }}
                    style={{ color: secondaryColor, }}
                >

                    <IoClose size={25} color={secondaryColor} solid="true" />

                </TouchableOpacity>
            </View>

            <View style={isDesktopOrLaptop ? { marginTop: '15%' } : { marginTop: '50%' }}>
                <div className='text-center pt-3' style={{ height: 60, width: '100%', backgroundColor: primaryColour, }}>
                    <div className='dukan-name' style={isDesktopOrLaptop ? { color: secondaryColor, marginLeft: -20, } : { marginLeft: -8, textTransform: 'uppercase', fontSize: '1.7em', position: 'relative', fontWeight: 600, color: secondaryColor, }}>

                        {dukanName}


                    </div>
                </div>
            </View>
            <View style={[
                { height: 100, color: primarybg, justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'row', },
                isDesktopOrLaptop ? { marginTop: 30, } : { marginTop: 0, }


            ]}>
                <PerfectInput
                    value={searchText} setvalue={setsearchText} placeholder='eg: T-shirt, Jackets, Shoes, Bags'
                    style={
                        isDesktopOrLaptop ?
                            { flexDirection: 'row', flex: 0.5, backgroundColor: 'white', color: primaryColour, padding: 15, outlineStyle: 'none', borderTopLeftRadius: 20, }
                            : {

                                flexDirection: 'row', flex: 0.65, backgroundColor: 'white',

                                outlineStyle: 'none',
                                shadowColor: "#000",
                                shadowOffset: {
                                    width: 2,
                                    height: 2,
                                },
                                marginTop: 60,
                                padding: 10,
                                shadowRadius: 5.46,
                                elevation: 5,
                                borderTopLeftRadius: 20,
                                color: primaryColour,
                                marginLeft: 10,
                            }
                    }
                />
                <RoundButton
                    style={[{

                        flexDirection: 'row',
                        flex: 0.08,
                        alignSelf: 'center',
                        shadowColor: shadowColor,
                        backgroundColor: primaryColour,
                        color: secondaryColor,
                        shadowOffset: {
                            width: 0,
                            height: 2,
                        },
                        height: 100,
                        position: 'relative',
                        left: -10,
                        borderWidth: 2,
                        borderColor: secondaryColor,
                        shadowOpacity: 0.25,
                        shadowRadius: 3.84,
                        borderRadius: 100,
                        elevation: 5,
                    }, !isDesktopOrLaptop ? {

                        flexDirection: 'row',
                        flex: 0.26,
                        alignSelf: 'center',
                        shadowColor: shadowColor,
                        backgroundColor: primaryColour,
                        color: secondaryColor,
                        shadowOffset: {
                            width: 0,
                            height: 2,
                        },
                        height: 80,
                        position: 'relative',
                        left: -10,
                        borderWidth: 2,
                        borderColor: secondaryColor,
                        shadowOpacity: 0.25,
                        shadowRadius: 3.84,
                        borderRadius: 100,
                        marginTop: 50,
                        elevation: 5,
                    } : { color: secondaryColor, }]}
                    onPress={() => {
                        defaultLink == '' ?
                            navigate('/' + dukanId + '/shop', { state: { searchText: searchText } })
                            : navigate('/shop', { state: { searchText: searchText } })
                    }}
                >Search</RoundButton>

            </View>
        </ReactModal>

    )
}
