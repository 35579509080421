import { Text, View, ImageBackground, FlatList, Dimensions } from "react-native";
import { borderColor, defaultLink, primarybg, primaryColour, serverURL } from "../../utils/string";
import { useMediaQuery } from 'react-responsive'
import React, { useEffect, useState } from "react";
import { getCatalogDetails } from "../../utils/api";
import { toast } from 'react-toastify';
import { LoadingIndicator, RoundButton, storeData } from "../../utils/utils";
import Product from "../../utils/product";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../sidebar";
import PerfectModal from "../../utils/perfectModel";
import ProductSelector from "./productSelector";
// import '../templateUtils/loader.css'

export default function CatalogDetails(props) {

    const { catalogId } = useParams();
    const navigate = useNavigate();

    const notify = (t) => toast.dark(t, {
        position: toast.POSITION.BOTTOM_CENTER
    });
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState();
    const [prevStart, setprevStart] = useState(0);
    const [loadMore, setloadMore] = useState(true);
    const [pageRefreshKey, setpageRefreshKey] = useState(0);
    const [catalogData, setcatalogData] = useState({});
    const id = catalogId
    const addProducts = React.useRef();

    const [allProductsLoading, setallProductsLoading] = useState();
    const [idsOfCatalogProducts, setidsOfCatalogProducts] = useState([]);
    const [isUpdated, setisUpdated] = useState(false);
    const [productSelectorModal, setproductSelectorModal] = useState(false);

    useEffect(() => {
        setisUpdated(false)
        getCatalogDetails(setisLoading, navigate, setdata, data, prevStart, setprevStart, setloadMore, setcatalogData, catalogId, setidsOfCatalogProducts)
    }, [])

    useEffect(() => {
        if (isUpdated) {
            setloadMore(true)
            getCatalogDetails(setisLoading, navigate, setdata, [], 0, setprevStart, setloadMore, setcatalogData, catalogId, setidsOfCatalogProducts)
            setisUpdated(false)
        }
    }, [isUpdated])




    return (

        <Sidebar active={'myCatalogs'} notHeaderBottom={true}>
            {!isLoading ?
                <>
                    <FlatList
                        ListHeaderComponent={
                            <ImageBackground
                                source={catalogData.image != '' ? { uri: serverURL + catalogData.image } : ''}
                                style={{
                                    height: isDesktopOrLaptop ? 300 : 200, width: '100%',
                                    borderRadius: 0, borderWidth: catalogData.image == '' ? 1 : 0, borderColor: borderColor,
                                    backgroundColor: 'black', marginBottom: 20, marginTop: -20
                                }}
                                imageStyle={{ borderRadius: 5, opacity: 0.7 }}
                                resizeMode='cover'
                            >
                                <View style={{ flex: 0.9, justifyContent: 'center', alignItems: 'center', }}>
                                    <Text style={{ fontSize: 25, fontWeight: '600', color: primarybg, textTransform: 'capitalize' }}>{catalogData.name}</Text>
                                    <Text style={{ fontSize: 15, color: primarybg, color: 'lightgray', textTransform: 'capitalize' }}>
                                        {catalogData.total_items} items || {catalogData.views} views
                                    </Text>

                                    {/* <RoundButton style={{
                                                backgroundColor: 'transparent', flexDirection: 'row', marginVertical: 0, height: 30, width: '30%',
                                                marginBottom: 0, marginTop: 9
                                            }} >
                                                <Icon
                                                    name="share-alt"
                                                    type="font-awesome-5"
                                                    color={primarybg}
                                                    size={18}
                                                />
                                                <Text style={{ fontSize: 16, color: primarybg }}>{'  '}Share</Text>
                                            </RoundButton> */}
                                    <RoundButton style={{
                                        backgroundColor: 'transparent', flexDirection: 'row', marginVertical: 0, height: 30, width: 180,
                                        marginBottom: 0, marginTop: 9, justifyContent: 'center', borderRadius: 20
                                    }}
                                        texStyle={{ fontSize: 16 }}
                                        text='Add/Remove products'
                                        onPress={() => setproductSelectorModal(true)}
                                    />


                                </View>
                            </ImageBackground>
                        }
                        numColumns={isDesktopOrLaptop ? 4 : 2}
                        columnWrapperStyle={[{
                            flex: 1,
                            backgroundColor: primarybg,
                        },
                        isDesktopOrLaptop ? { justifyContent: "center", marginHorizontal: '10%' } : { marginHorizontal: 3 }
                        ]}
                        contentContainerStyle={[
                            { justifyContent: 'center', paddingBottom: 50 },
                            isDesktopOrLaptop && { marginHorizontal: '0%' }]}
                        horizontal={false}
                        data={data}
                        onEndReached={() => {
                            if (loadMore) {
                                getCatalogDetails(setisLoading, navigate, setdata, data, prevStart, setprevStart, setloadMore, setcatalogData, catalogId, setidsOfCatalogProducts)
                            }
                        }}
                        ListEmptyComponent={() => {
                            return (
                                <View style={{ marginTop: 20, paddingBottom: 20 }}>
                                    <Text style={{ textAlign: 'center', color: primaryColour }}>No Products in this catalog</Text>
                                </View>
                            )
                        }}

                        renderItem={({ item, index }) => (
                            <Product item={item} primaryColour={primaryColour}
                                onPressDisabled={true}
                            />
                        )}

                    />
                </>
                :
                <LoadingIndicator />
            }
            <PerfectModal modalVisible={productSelectorModal}>
                <ProductSelector idsOfCatalogProducts={idsOfCatalogProducts} catalogId={id}
                    productSelectorModal={productSelectorModal} setproductSelectorModal={setproductSelectorModal} setisUpdated={setisUpdated} />
            </PerfectModal>
        </Sidebar>
    );
}

