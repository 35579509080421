import { borderColor } from "./string";

export const shadow = {
    shadowColor: "#000",
    shadowOffset: {
        width: 0,
        height: 12,
    },
    shadowOpacity: 0.58,
    shadowRadius: 16.00,
    borderWidth: 1,
    borderColor: borderColor
}