import React, { useEffect, useState, } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, ScrollView, Image, ActivityIndicator, ImageBackground, Dimensions, ImageBackgroundBase } from "react-native";
import ReactModal from 'react-modal';
import { useMediaQuery } from 'react-responsive'
// import logo from '../assets/logo.png';
// import appSplash from '../../public/static/phones/app-splash.png';
// import dukan from '../../public/static/phones/dukan.png';
// import dukans from '../../public/static/phones/dukans.png';
// import products from '../../public/static/phones/products.png';
// import bgBanner from '../../public/static/backgrounds/bg-banner.png';
// import bgElipseTwo from '../../public/static/backgrounds/elipse-bg-two.png';
// import bgElipseThree from '../../public/static/backgrounds/elipse-bg-three.png';
// import '../App.css'
import 'animate.css';
import './assets/css/bootstrap.min.css'
import './assets/css/LineIcons.2.0.css'
import './assets/css/animate.css'
import './assets/css/tiny-slider.css'
import './assets/css/glightbox.min.css'
import './assets/css/main.css'
import { primaryColour } from '../utils/string';



function PakNeedHome(props) {

    const [modal, setmodal] = useState(true);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    useEffect(() => {
        setmodal(true)
    }, [])

    return (
        <View>

            {/* <div>
                <div id="overlayer"></div>
                <div className="preloader justify-content-center align-center">

                    <div className="loader">
                        <span className="loader-inner"></span>
                    </div>

                    <p> Loading...</p>
                </div>
            </div> */}


            <ReactModal
                isOpen={modal}
                contentLabel="Minimal Modal Example"
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.75)',
                        zIndex: 1000,
                    },
                    content: {
                        position: 'absolute',
                        top: '40px',
                        left: '40px',
                        right: '40px',
                        bottom: '40px',
                        border: '1px solid #ccc',
                        backgroundImage: 'linear-gradient(to bottom, #5893d4 , #f7b633)',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        borderRadius: '4px',
                        outline: 'none',
                        padding: '20px',
                        zIndex: 1000,
                    }
                }}
            >

                <View style={isDesktopOrLaptop ? { flexDirection: 'row' } : { flexDirection: 'column' }}>

                    <View style={isDesktopOrLaptop ? { flex: 0.6, borderRightWidth: 2, borderColor: '#f9f9f9', lineHeight: 50, justifyContent: 'center', alignItems: 'center' } : { flex: 1 }}>


                        <img src='static/web_dukan.png' style={isDesktopOrLaptop ? { height: 500, } : { height: 250, }} />


                    </View>
                    <View style={isDesktopOrLaptop ? { flex: 0.4 } : { flex: 1 }}>

                        <div className='animate__animated animate__fadeInUp'>
                            {
                                isDesktopOrLaptop ?

                                    <div className='mt-5 text-center'><img className='modal-logo' src="static/bazars.pk/logo.png" alt="logo" style={{ height: '200px', width: '170px' }} /></div>

                                    :

                                    null
                            }
                            <h4 className='modal-text text-center mt-3' style={isDesktopOrLaptop ? { color: 'white', lineHeight: '40px', fontSize: 20, } : { color: 'white', lineHeight: '30px', fontSize: 20, }}>کیا آپ اپنی آنلائن ای کامرس ویب سائٹ بنانا چاہتے ہیں <br />؟؟ Rs/200  وہ بھی صرف  ماہانہ</h4>
                            <div className='text-center p-3'>
                                <a >
                                    <button
                                        onClick={() => { setmodal(!modal) }}
                                        className='mr-2 btn btn-md pl-3 pr-3 text-bold pb-3'
                                        style={{ backgroundColor: '#F7B633', borderRadius: '50px', color: 'black' }}
                                    >مزید  جانئیے
                                    </button>
                                </a>
                                <a href='https://d.bazars.pk'>
                                    <button className='btn btn-md pl-3 pr-3 text-bold pb-3'
                                        style={{ backgroundColor: '#F7B633', borderRadius: '50px', color: 'black' }}>
                                        ہاں</button>
                                </a>
                            </div>
                        </div>

                    </View>

                </View>


            </ReactModal>


            <header class="header" style={{ marginTop: -50, }}>
                <div class="navbar-area">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-12">

                                {
                                    !isDesktopOrLaptop ?
                                        <View>
                                            <h1 style={{ color: '#f7b633', fontSize: 40, fontWeight: 700, justifyContent: 'center', alignItems: 'center' }}>Bazars.pk</h1>
                                            <View style={{ flexDirection: 'row', alignSelf: 'center', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                                <ul id="nav" class="navbar-nav me-auto" style={{ flexDirection: 'row', alignSelf: 'center', justifyContent: 'center', }}>
                                                    <li class="nav-item">
                                                        <a class="page-scroll active" href="#home" style={{ color: '#f7b633', textDecoration: 'none', border: 'none', }}>Home</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="page-scroll" href="#benefits" style={{ color: '#f7b633', textDecoration: 'none', border: 'none', }}>Benefits</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="page-scroll" href="#features" style={{ color: '#f7b633', textDecoration: 'none', border: 'none', }}>Features</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="page-scroll" href="#download" style={{ color: '#f7b633', textDecoration: 'none', border: 'none', }}>Download</a>
                                                    </li>

                                                </ul>
                                            </View>
                                        </View>
                                        :
                                        null
                                }


                                {
                                    isDesktopOrLaptop ?
                                        <nav class="navbar navbar-expand-lg">

                                            <a class="navbar-brand logo">
                                                <h1 style={{ color: '#f7b633', marginTop: 35, fontSize: 40, fontWeight: 700, }}>Bazars.pk</h1>
                                                {/* <img class="primary-logo" src="static/logo.png" alt="Logo" style={{ height: 80, widht: 90, }} /> */}
                                            </a>
                                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                                aria-expanded="false" aria-label="Toggle navigation">
                                                <span class="toggler-icon"></span>
                                                <span class="toggler-icon"></span>
                                                <span class="toggler-icon"></span>
                                            </button>
                                            <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                                <ul id="nav" class="navbar-nav me-auto">
                                                    <li class="nav-item">
                                                        <a class="page-scroll active" href="#home" style={{ color: '#f7b633', textDecoration: 'none', border: 'none', }}>Home</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="page-scroll" href="#benefits" style={{ color: '#f7b633', textDecoration: 'none', border: 'none', }}>Benefits</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="page-scroll" href="#features" style={{ color: '#f7b633', textDecoration: 'none', border: 'none', }}>Features</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="page-scroll" href="#download" style={{ color: '#f7b633', textDecoration: 'none', border: 'none', }}>Download</a>
                                                    </li>

                                                </ul>
                                            </div>
                                            <div class="button">
                                                <a href={"https://d.bazars.pk"} class="btn page-scroll" target="_blank">Buy Premium</a>
                                            </div>
                                        </nav>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <section id="home" class="hero-area">
                <div class="hero-inner">
                    <div class="container">
                        <div class="row ">
                            <div class="col-lg-6 co-12">
                                <div class="home-slider">
                                    <div class="hero-text" style={!isDesktopOrLaptop ? { marginTop: 200, } : null}>

                                        <h5 class="wow fadeInUp" data-wow-delay=".3s">Introducing Bazars.Pk</h5>
                                        <h1 class="wow fadeInUp" data-wow-delay=".5s">Grow your Business<br /> with
                                            Bazars.pk</h1>
                                        <p class="wow fadeInUp" data-wow-delay=".7s">1. Create Your E-commerce Website <br /> 2. Create Your Catalogues
                                            <br />3. Sell your Products</p>
                                        <div class="button wow fadeInUp" data-wow-delay=".9s">
                                            <a href="https://play.google.com/store/apps/details?id=com.pakneed" class="btn"><i class="lni lni-android-original"></i> Play Store</a>
                                        </div>
                                        <div class="button wow fadeInUp" data-wow-delay=".9s">
                                            <a href="https://bazars.pk/web" class="btn">
                                                {/* <i class="lni lni-website-alt"
                                                style={{ fontSize: 35 }}
                                            ></i> */}
                                                Web Dashboard
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-12">
                                <div class="hero-image">
                                    <div class="waves-block">
                                        <div class="waves wave-1"></div>
                                        <div class="waves wave-2"></div>
                                    </div>
                                    <img src="static/bazars.pk/bazarsPk.png" alt="#" style={isDesktopOrLaptop ? { position: 'relative', left: 70, } : null} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>


            <section id="benefits" class="features section">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="section-title-home">
                                <span class="wow fadeInDown" data-wow-delay=".2s">Benefits</span>
                                <h2 class="wow fadeInUp" data-wow-delay=".4s">Checkout Bazars.pk<br />
                                    Benefits Below.</h2>

                            </div>
                        </div>
                    </div>
                    <div class="single-head">
                        <div class="row">
                            <div class="col-lg-3 col-md-6 col-12">
                                <div class="single-feature wow fadeInUp" data-wow-delay=".2s">
                                    <div class="feature-icon">
                                        <i class="lni lni-home"></i>
                                    </div>
                                    <h3>Create Online Dukan Free in Few Seconds</h3>
                                    <p>Create dukan in digital Bazaar of your own city in just 40 SECONDS <br /> and Start Selling on Bazars.pk</p>
                                </div>

                            </div>

                            <div class="col-lg-3 col-md-6 col-12">

                                <div class="single-feature wow fadeInUp" data-wow-delay=".6s">
                                    <div class="feature-icon">
                                        <i class="lni lni-layers"></i>
                                    </div>
                                    <h3>Create Your Ecommerce Website</h3>
                                    <p>Through Bazars.pk create your own beautiful Ecommerce website and manage your products and orders with ease.</p>
                                </div>

                            </div>
                            <div class="col-lg-3 col-md-6 col-12">

                                <div class="single-feature wow fadeInUp" data-wow-delay=".8s">
                                    <div class="feature-icon">
                                        <i class="lni lni-timer"></i>
                                    </div>
                                    <h3>Get Free Hosting with design of your choice</h3>
                                    <p>Send us Custom Hosting and Design Request if you dont like already available domain and designs of website</p>
                                </div>

                            </div>

                            <div class="col-lg-3 col-md-6 col-12">

                                <div class="single-feature wow fadeInUp" data-wow-delay=".4s">
                                    <div class="feature-icon">
                                        <i class="lni lni-wechat"></i>
                                    </div>
                                    <h3>24/7 support by Bazars.pk</h3>
                                    <p>24/7 supportSellers/Buyers can get help and find answers to questions <br /> We will help you to grow your Business</p>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section class="our-achievement section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-12">
                            <div class="single-achievement wow fadeInUp" data-wow-delay=".2s">
                                <i class="lni lni-user"></i>
                                <h3 class="counter"><span id="secondo1" class="countup" cup-end="264">10000</span>+</h3>
                                <p>Total Download</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-12">
                            <div class="single-achievement wow fadeInUp" data-wow-delay=".4s">
                                <i class="lni lni-users"></i>
                                <h3 class="counter"><span id="secondo2" class="countup" cup-end="100">50000</span>+</h3>
                                <p>Total Products</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-12">
                            <div class="single-achievement wow fadeInUp" data-wow-delay=".6s">
                                <i class="lni lni-code"></i>
                                <h3 class="counter"><span id="secondo3" class="countup" cup-end="855">5000</span>+</h3>
                                <p>Total Dukans</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-12">
                            <div class="single-achievement wow fadeInUp" data-wow-delay=".6s">
                                <i class="lni lni-coffee-cup"></i>
                                <h3 class="counter"><span id="secondo3" class="countup" cup-end="295">300</span>+</h3>
                                <p>Total Bazars</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="download" class="app-download section">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="section-title-home">
                                <span class="wow fadeInDown" data-wow-delay=".2s">Get the apps</span>
                                <h2 class="wow fadeInUp" data-wow-delay=".4s">Download Platform</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="single-download wow fadeInUp" data-wow-delay=".2s">
                                <h3 class="counter">Download from Google Play</h3>
                                <p>Download Bazars.pk Today Register it with your Phone No and get a free website for your products and services</p>
                                <div class="button">
                                    <a href="#" class="btn">
                                        <i class="lni lni-play-store"></i>
                                        <span>get it on</span>
                                        Google Play
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="single-download wow fadeInUp" data-wow-delay=".4s">
                                <h3 style={{ color: primaryColour, fontSize: 30, }}>Bazars.pk</h3>
                                <div>
                                    <img src="static/bazars.pk/main.png" alt="#" style={{ width: 260, height: 500, }} />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="single-download wow fadeInUp" data-wow-delay=".4s">
                                <h3 class="counter">Desktop & IOS</h3>
                                <p>You can manage your website/online store using our web dashboard</p>
                                <div class="button">
                                    <a href="#" class="btn">
                                        <i class="lni lni-dashboard"></i>
                                        <span>Login now on</span>
                                        Web Dashboard
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section id="call-action" class="call-action section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="inner-content">
                                <h4 class="wow fadeInUp p-3" data-wow-delay=".2s">Bazars.pk Premium Plans</h4>
                                <h2 class="wow fadeInUp mt-5" data-wow-delay=".4s">If you want different kind of themes just <br />Contact us on bazars.pk or you can mail us on our support</h2>
                                <p class="wow fadeInUp" data-wow-delay=".6s"><br />For Premium plan just visit</p>
                                <div class="button style3 wow fadeInUp" data-wow-delay=".8s">
                                    <a href="https://d.bazars.pk" class="btn">Visit</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="features" class="features section">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="section-title-home">
                                <span class="wow fadeInDown" data-wow-delay=".2s">Features</span>
                                <h2 class="wow fadeInUp" data-wow-delay=".4s">Checkout Bazars.pk<br />
                                    Feature Below.</h2>

                            </div>
                        </div>
                    </div>
                    <div class="single-head">
                        <div class="row">
                            <div class="col-lg-3 col-md-6 col-12">
                                <div class="single-feature wow fadeInUp" data-wow-delay=".2s">
                                    <div class="feature-icon">
                                        <i class="lni lni-home"></i>
                                    </div>
                                    <h3>Beautiful Design</h3>
                                    <p>User can understand and interact with UI easily. User
                                        interface design is to make the user's interaction as simple and efficient as possible.
                                    </p>
                                </div>

                            </div>

                            <div class="col-lg-3 col-md-6 col-12">

                                <div class="single-feature wow fadeInUp" data-wow-delay=".6s">
                                    <div class="feature-icon">
                                        <i class="lni lni-layers"></i>
                                    </div>
                                    <h3>Unlimited Features</h3>
                                    <p>For Sellers and Buyers<br />
                                        1. Free Website for sellers <br />
                                        2. Sellers can manage their dukans.<br />
                                        3. Seller can manage orders<br /></p>
                                </div>

                            </div>
                            <div class="col-lg-3 col-md-6 col-12">

                                <div class="single-feature wow fadeInUp" data-wow-delay=".8s">
                                    <div class="feature-icon">
                                        <i class="lni lni-timer"></i>
                                    </div>
                                    <h3>Full free chat</h3>
                                    <p>Sellers and buyers can contact each other through chat, also they can contact bazars.pk
                                        through live chat to solve their problems.</p>
                                </div>

                            </div>

                            <div class="col-lg-3 col-md-6 col-12">

                                <div class="single-feature wow fadeInUp" data-wow-delay=".4s">
                                    <div class="feature-icon">
                                        <i class="lni lni-wechat"></i>
                                    </div>
                                    <h3>Retina ready graphics</h3>
                                    <p>Bazars.pk will support any type of android versions, you can use it in any android device.

                                        Just Download it from Play Store.</p>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <footer class="footer-style-1">
                <div class="footer-middle">
                    <div class="container">
                        <div class="row" style={{ marginTop: 50, }}>
                            <div class="col-lg-5 col-md-5 col-12" style={isDesktopOrLaptop ? { marginTop: -115, } : { marginTop: -30, justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
                                <div class="f-about single-footer" style={isDesktopOrLaptop ? {} : { justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
                                    <div class="logo">
                                        <a href="https://bazars.pk"><img src="static/bazars.pk/logo.png" alt="Logo" style={{ height: 50, width: 40 }} /></a>
                                    </div>
                                    <p>Download Bazars.pk Today Register it with your Phone No and get a free website for your products and services</p>

                                    <div class="footer-social">
                                        <ul>
                                            <li><a href="https://api.whatsapp.com/send?phone=+923308080009&text=Bazars.pk:" target="_blank"><i class="lni lni-whatsapp"></i></a></li>
                                            <li><a href="https://web.facebook.com/bazarsdotpk" target="_blank"><i class="lni lni-facebook-original"></i></a></li>
                                            <li><a href="https://instagram.com/bazars.pk" target="_blank"><i class="lni lni-instagram-original"></i></a></li>
                                            <li><a href="https://twitter.com/bazarspk" target="_blank"><i class="lni lni-twitter-original"></i></a></li>
                                            <li><a href="mailto:support@bazars.pk" target="_blank"><i class="lni lni-google"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-7 col-12">
                                <div class="row">
                                    <div class="col-lg-3 col-md-6 col-12">
                                        <div class="single-footer sm-custom-border f-link">
                                            <h3 style={!isDesktopOrLaptop ? { marginTop: 50, } : null}>About Us</h3>
                                            <ul>
                                                <li><a href="#home">Home</a></li>
                                                <li><a href="#feature">Features</a></li>
                                                <li><a href="#download">Download</a></li>
                                            </ul>
                                        </div>

                                    </div>
                                    <div class="col-lg-5 col-md-6 col-12">

                                        <div class="single-footer sm-custom-border f-link">
                                            <h3 style={!isDesktopOrLaptop ? { marginTop: 50, } : null}>Features</h3>
                                            <ul>
                                                <li><a href="#">Easy to use</a></li>
                                                <li><a href="#">Work on all androids</a></li>
                                                <li><a href="#">Always up to date</a></li>
                                            </ul>
                                        </div>

                                    </div>

                                    <div class="col-lg-4 col-md-6 col-12">
                                        <div class="single-footer sm-custom-border f-link">
                                            <h3 style={!isDesktopOrLaptop ? { marginTop: 50, } : null}>Contact Info</h3>


                                            <ul>
                                                <li><a href="#">Made in Pakistan</a></li>
                                                <li><a href="#">support@bazars.pk</a></li>
                                                <li><a href="#">+92330-8080009</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom">
                    <div class="container">
                        <div class="inner">
                            <div class="row">
                                <div class="col-12">
                                    <div class="left">
                                        <p>Designed and Developed by<a href="https://bazars.pk" rel="nofollow"
                                            target="_blank">Bazars.pk</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </footer>

            <a href="#" class="scroll-top btn-hover">
                <i class="lni lni-chevron-up"></i>
            </a>










        </View >
    )
}

PakNeedHome.propTypes = {

}

export default PakNeedHome

