import * as React from 'react';
import { Text, View, Dimensions, ScrollView, FlatList, Image, TouchableOpacity } from 'react-native';
import { borderColor, primarybg, primaryLightColor, secondarybg, serverURL, subCategories, } from '../../../utils/string';
import { useMediaQuery } from 'react-responsive';

export default function DetailsScreen(props) {
    const {
        data, variations, selectedVariation, setselectedVariation, setselectedSize,
        selectedSize,
        selectedColor,
        setselectedColor, Footer, primaryColour } = props
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    return (
        <View style={isDesktopOrLaptop ? { paddingLeft: 30, } : { marginTop: 20, }}>
            {variations.length > 0 ?
                <>
                    <Text
                        style={{
                            fontWeight: 'bold',
                            fontSize: 17,
                            color: primaryColour,
                            textAlign: 'left'
                        }}>
                        Variations:
                    </Text>
                    <FlatList
                        horizontal
                        style={{ flex: 1, minHeight: 810 }}
                        data={variations}
                        contentContainerStyle={{
                            width: '100%', marginVertical: 10, marginLeft: 12
                        }}
                        showsHorizontalScrollIndicator={false}
                        renderItem={({ item, index }) => (
                            <TouchableOpacity
                                style={{
                                    borderWidth: selectedVariation == index ? 3 : 0,
                                    width: 70, height: 65, backgroundColor: primaryColour,
                                    marginHorizontal: 10, borderRadius: 5,
                                    alignItems: 'center', borderColor: secondarybg
                                }}
                                onPress={() => { setselectedVariation(index) }}
                            >
                                <Image
                                    loadingStyle={{ size: 'large', color: primaryColour }}
                                    source={{ uri: serverURL + item.images[0] }}
                                    style={{ width: selectedVariation == index ? 65 : 70, height: 40, resizeMode: 'cover', borderRadius: 5, }}
                                    imageStyle={{ borderRadius: 5 }}
                                />
                                <Text style={{ textAlign: 'center', color: primarybg }}>{item.name.length > 15 ? item.name.substring(0, 15) : item.name}</Text>
                            </TouchableOpacity>
                        )}
                    />
                </>
                : null
            }


            {typeof data.sizes != 'undefined' && data.sizes.length > 0 ?
                <>
                    <Text style={{ marginTop: 15, color: 'gray' }}>Sizes : </Text>
                    <FlatList
                        horizontal
                        // style={{ flex: 1, height: 80 }}
                        data={data.sizes}
                        contentContainerStyle={{
                            width: '100%', marginVertical: 10, marginLeft: 12
                        }}
                        showsHorizontalScrollIndicator={false}
                        renderItem={({ item, index }) => (
                            <TouchableOpacity
                                style={selectedSize == index ?
                                    {

                                        borderWidth: 0.2, marginHorizontal: 4,
                                        paddingHorizontal: 10, borderColor: primaryColour,
                                        backgroundColor: primaryColour, justifyContent: 'center', alignItems: 'center', paddingBottom: 2.5,
                                        borderRadius: 50,
                                    } :
                                    {
                                        borderWidth: 0.2, marginHorizontal: 4,
                                        paddingHorizontal: 10, paddingVertical: 5, borderColor: primaryColour
                                        , justifyContent: 'center', alignItems: 'center', paddingBottom: 5,
                                        borderRadius: 50,
                                    }
                                }
                                onPress={() => { setselectedSize(index) }}
                            >
                                <Text style={{ textAlign: 'center', color: selectedSize == index ? primarybg : primaryColour }}>{item.size}</Text>
                            </TouchableOpacity>
                        )}
                    />
                </>
                : null}


            {typeof data.colors != 'undefined' && data.colors.length > 0 ?
                <>
                    <Text style={{ marginTop: 5, color: 'gray' }}>Colors : </Text>
                    <FlatList
                        horizontal
                        // style={{ flex: 1 }}
                        data={data.colors}
                        contentContainerStyle={{
                            width: '100%', marginVertical: 5, marginLeft: 12
                        }}
                        showsHorizontalScrollIndicator={false}
                        renderItem={({ item, index }) => (
                            <TouchableOpacity
                                style={{
                                    borderWidth: selectedColor == index ? 3 : 1,
                                    width: 35, height: 35, backgroundColor: item.color,
                                    marginHorizontal: 10,
                                    alignItems: 'center', borderColor: selectedColor == index ? primaryColour : 'none',
                                }}
                                onPress={() => { setselectedColor(index) }}
                            >

                            </TouchableOpacity>
                        )}
                    />
                </>
                : null}




            <Text style={{ marginTop: 5, color: 'gray' }}>DETAILS : </Text>


            {variations.length > 0 && variations[selectedVariation].custom_feilds.map((item) => (
                <View style={{ flex: 1, alignSelf: 'stretch', flexDirection: 'row', borderColor: '#ede9e4', paddingVertical: 5, marginLeft: 12 }}>
                    <View style={{ flex: 1, alignSelf: 'stretch' }} >
                        <Text style={{ fontSize: 14, color: primaryColour, textTransform: 'capitalize' }}>{item.name}</Text>
                    </View>
                    <View style={{ flex: 1, alignSelf: 'stretch' }} >
                        <Text style={{ color: primaryLightColor, fontSize: 12, textTransform: 'capitalize' }}>{item.detail}</Text>
                    </View>
                </View>))
            }


            {
                typeof data.sub_category !== 'undefined' ?

                    [
                        { title: 'Price', value: variations.length > 0 ? "Rs. " + variations[selectedVariation].price : "Rs. " + data.price },
                        { title: 'Condition', value: data.condition === '1' ? 'New' : 'Used' },
                        { title: 'Shipping', value: 'Rs.' + data.shipping },
                        { title: 'Total available Quantity', value: data.quantity + ' item' },
                    ].map((item) => {
                        return (
                            <>
                                {item.title == 'Condition' && item.value == 'New' ?
                                    <>{console.log(item.title, data.condition)}</> :
                                    <View style={{ flex: 2, alignSelf: 'stretch', flexDirection: 'row', marginTop: 10, }}>
                                        <View style={{ flex: 1.4, alignSelf: 'stretch' }} >
                                            <Text style={{ fontSize: 15, color: primaryColour, textAlign: 'left' }}>{item.title}</Text>
                                        </View>
                                        <View style={{ flex: 0.6, alignSelf: 'stretch' }} >
                                            <Text style={{ color: primaryColour, fontSize: 15, textAlign: 'left' }}>{item.value}</Text>
                                        </View>
                                    </View>
                                }
                            </>
                        );
                    })
                    : null
            }

            <Text style={{ marginTop: 25, color: 'gray' }}>DESCRIPTION : </Text>


            <Text style={{
                fontSize: 16, lineHeight: 25, color: primaryColour, textAlign: 'justify',
                justifyContent: 'space-around', letterSpacing: 1, textTransform: 'none', textAlign: 'left'
            }}>
                {data.description} </Text>


        </View>
    );
}