import * as React from 'react';
import { useState, useEffect } from 'react';
import { ActivityIndicator, Button, Dimensions, FlatList, Image, ImageBackground, Linking, RefreshControl, ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { borderColor, inactiveColor, primarybg, primaryColour, primaryText, secondarybg, secondaryColor, serverURL } from '../../utils/string';
import { PerfectInput, RoundButton } from '../../utils/utils';

import { getStoreDetailsForCustomization, saveStoreDetailsForCustomization } from '../../utils/api';
import PerfectModal from '../../utils/perfectModel';
import { useNavigate } from "react-router-dom";
import Sidebar from '../sidebar';
import ApperanceSidebar from './sidebar';
import { shadow } from '../utils/main';
import { HexColorPicker } from "react-colorful";
import { useMediaQuery } from 'react-responsive';
// import ImagePicker from 'react-native-image-crop-picker';

const radio = 1000 / 500;
const static_width = Dimensions.get('window').width / 1.2;


export function ColorsSetting(props) {
    const move = useNavigate();
    const [primaryColor, setprimaryColor] = useState('#5893D4');
    const [secondaryColor, setsecondaryColor] = useState('#5893D4');
    const [colorModal, setcolorModal] = useState(false);
    const [secondaryColorModal, setsecondaryColorModal] = useState(false);
    const [saving, setsaving] = useState(false);
    const [data, setdata] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    useEffect(() => {
        getStoreDetailsForCustomization(move, setisLoading, setdata)
    }, [])

    useEffect(() => {
        if (data != '') {
            setprimaryColor(data.primary_color)
            setsecondaryColor(data.secondary_color)
        }
    }, [data])

    return (

        <ApperanceSidebar active='colors'>
            <View style={[{ marginHorizontal: isDesktopOrLaptop ? '5%' : '2%', paddingHorizontal: isDesktopOrLaptop ? '5%' : '2%', paddingVertical: isDesktopOrLaptop ? '2%' : '5%', borderRadius: 20 }, shadow]}>
                <Text style={{ color: primaryColour, fontWeight: 'bold', fontSize: 16 }}>
                    Colors
                </Text>
                <Text style={{ color: 'gray', fontWeight: 'bold', marginBottom: 10, fontSize: 13 }}>
                    Set colors of your website according to your brand
                </Text>
                <View style={{ justifyContent: 'space-between', alignItems: 'center', paddingRight: 20, }}>
                    <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                        <Text style={{ color: 'gray' }}>Primary Color:</Text>
                        <TouchableOpacity style={{ borderWidth: 1, marginLeft: 20, borderColor: 'lightgray', backgroundColor: primaryColor, height: 30, width: 30, borderRadius: 5, marginTop: 5 }}
                            onPress={() => { setcolorModal(true) }}
                        />
                    </View>
                    <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                        <Text style={{ color: 'gray' }}>Secondary Color</Text>
                        <TouchableOpacity style={{ marginLeft: 20, borderWidth: 1, borderColor: 'lightgray', backgroundColor: secondaryColor, height: 30, width: 30, borderRadius: 5, marginTop: 5 }}
                            onPress={() => { setsecondaryColorModal(true) }}
                        />
                    </View>

                </View>
                <PerfectModal modalVisible={colorModal}
                    padding={0} internalPadding={0}
                    style={{ width: isDesktopOrLaptop ? '30%' : '90%' }}
                >
                    <Text style={{ color: 'gray', marginBottom: 5, textAlign: 'center', marginBottom: 10 }}>Click on color that you want to use on your website </Text>

                    <View style={{ justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                        <HexColorPicker color={primaryColor} onChange={setprimaryColor} />
                    </View>

                    {saving && <ActivityIndicator color={secondarybg} size={'large'} />}
                    <RoundButton text='Apply' style={{ alignSelf: 'center', width: '40%', marginTop: 10 }}
                        onPress={() => {
                            saveStoreDetailsForCustomization(move, setsaving, '', '', '', '', primaryColor, '', '', '', '', '');
                            setcolorModal(!colorModal)
                        }}
                    />
                    <RoundButton text='Close' style={{ alignSelf: 'center', width: '40%', marginTop: 10 }}
                        onPress={() => { setcolorModal(!colorModal) }}
                    />
                </PerfectModal>
                <PerfectModal modalVisible={secondaryColorModal}
                    padding={0} internalPadding={0}
                    style={{ width: isDesktopOrLaptop ? '30%' : '90%' }}
                >
                    <Text style={{ color: 'gray', marginBottom: 5, textAlign: 'center', marginBottom: 10 }}>Click on color that you want to use on your website </Text>

                    <View style={{ justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                        <HexColorPicker color={secondaryColor} onChange={setsecondaryColor} />
                    </View>
                    {saving && <ActivityIndicator color={secondarybg} size={'large'} />}
                    <RoundButton text='Apply' style={{ alignSelf: 'center', width: '40%', marginTop: 10 }}
                        onPress={() => {
                            saveStoreDetailsForCustomization(move, setsaving, '', '', '', '', '', '', secondaryColor, '', '', '');
                            setsecondaryColorModal(!secondaryColorModal)
                        }}
                    />
                    <RoundButton text='Close' style={{ alignSelf: 'center', width: '40%', marginTop: 10 }}
                        onPress={() => { setsecondaryColorModal(!secondaryColorModal) }}
                    />
                </PerfectModal>
            </View>
        </ApperanceSidebar>
    );
}





