import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View, ScrollView, FlatList, Dimensions, ActivityIndicator, RefreshControl } from "react-native";
import { primarybg, serverURL, secondaryColor } from "../../../utils/string";
import { HomeContext } from "../../../utils/contexts";
import { getData, LoadingIndicator, storeData } from "../../../utils/utils";
import { useMediaQuery } from 'react-responsive';
import Product from '../templateUtils/product';
// import { FontAwesome } from '@expo/vector-icons';
import { useNavigate, useParams } from 'react-router-dom';
// import { useRouting } from 'expo-next-react-navigation';
// import { useRouter } from 'next/router'

const Icon = () => (<></>);
// import HomeTabs from './pages/homeTabs';


const { height, width } = Dimensions.get('window');

export default function Products(props) {
    // const router = useRouter()
    const navigate = useNavigate();
    const { dukanId } = useParams();
    // const { navigate, push, getParam, goBack } = useRouting()
    const [data, setdata] = useState([1, 2, 3, 5, 8, 8]);
    const { item, primaryColour, secondaryColor } = props
    const { products, productsLoading, } = useContext(HomeContext);
    const [dukanLink, setdukanLink] = React.useState('');
    const [secureToken, setsecureToken] = useState('');

    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1050px)'
    })
    const isTablet = useMediaQuery({
        query: '(min-width: 768px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const [numCol, setnumCol] = useState(2);
    const [loaded, setloaded] = useState(false);

    // city: "Adezai"
    // cover: "/media/product_thumbnail/thumbnail_69712b687e704f1e8de7a595ee10a63b.jpg"
    // id: 63
    // is_owner_of_product: 0
    // liked: 0
    // price: "25500"
    // state: "Punjab"
    // title: "Text you copy will automatically show hereText you copy will automatic"
    // user_id: 2

    useEffect(() => {
        getData('dukanLink', setdukanLink)
        if (dukanLink != window.location.pathname.split('/')[1]) {
            storeData(window.location.pathname.split('/')[1], 'dukanLink')
        }
        getData('secure_token', setsecureToken);
        // if (isDesktopOrLaptop && numCol != 4) {
        //     setnumCol(4)
        // }
        setloaded(true)
    }, [])



    return (
        <>
            {/* <Text style={[
            { textAlign: 'left', marginHorizontal: 30, marginVertical: 10, fontWeight: 'bold', fontSize: 19, color: primaryColour, },
            isDesktopOrLaptop ? { marginHorizontal: 30 } : { marginHorizontal: 10, }
        ]}>Products</Text> */}
            {!productsLoading ?
                <FlatList
                    contentContainerStyle={{ marginTop: 0, paddingBottom: 20, }}
                    data={products}
                    showsVerticalScrollIndicator={false}

                    ListFooterComponent={() => {
                        return (
                            <>
                                {
                                    products.length > 9 ?
                                        <div style={{ justifyContent: 'center', textAlign: 'center', marginTop: 30, }}>
                                            <TouchableOpacity style={{}}
                                                onPress={() => {
                                                    if (dukanId != window.location.pathname.split('/')[1]) {
                                                        storeData(window.location.pathname.split('/')[1], 'dukanLink')
                                                        navigate('/' + window.location.pathname.split('/')[1] + '/AllProducts/')
                                                    } else {
                                                        navigate('/' + dukanId + '/AllProducts/')
                                                    }

                                                }}
                                            >
                                                <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 50, marginBottom: 30, opacity: 0.9 }}>
                                                    <div style={{ width: 200, backgroundColor: primaryColour, padding: 10, borderRadius: 50, textAlign: 'center' }}>
                                                        <Text style={{ color: primarybg, position: 'relative', top: -3, }}>
                                                            See All
                                                        </Text>
                                                        {/* <FaArrowRight size={25} color={primarybg} style={{ marginHorizontal: 10 }} /> */}
                                                    </div>
                                                </View>
                                            </TouchableOpacity>
                                        </div>
                                        : null
                                }
                            </>
                        )
                    }}
                    ListEmptyComponent={() => {
                        return (
                            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                <Text style={isDesktopOrLaptop ? { marginTop: 50, backgroundColor: primaryColour, color: secondaryColor, fontSize: 19, padding: 10, } : { marginTop: 30, backgroundColor: primaryColour, color: secondaryColor, fontSize: 15, padding: 10, }}>No Any products</Text>
                                {/* <Icon name="arrow-right" size={25} color={primaryColour} style={{ marginHorizontal: 20 }} /> */}
                            </View>
                        )
                    }}
                    numColumns={isDesktopOrLaptop ? 4 : isTablet ? 3 : 2}
                    columnWrapperStyle={[{
                        flex: 1,
                        justifyContent: 'center',

                    },
                    isDesktopOrLaptop ? {} : { marginHorizontal: 3 }
                    ]}
                    renderItem={({ item }) => (
                        <Product item={item} primaryColour={primaryColour} secondaryColor={secondaryColor} />
                    )}
                    keyExtractor={item => item.id}
                />
                :
                <LoadingIndicator />
            }
        </>
    );
}

