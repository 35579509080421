import { useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View, ScrollView, Image, ActivityIndicator, ImageBackground, Dimensions } from "react-native";
import Home1 from "./1/home";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Shop1 from "./1/shop";
import CatalogPage1 from "./1/catalog";
import ProductDetails1 from "./1/productDetail";
import Bag1 from "./1/bag";
import Orders1 from "./1/orders";
import OrderDetails1 from "./1/orderDetails";
import Checkout1 from "./1/checkout";
import { getTemplateDetails } from "../utils/api";
import Home2 from "./2/home";
import CatalogPage2 from "./2/catalog";
import ProductDetails2 from "./2/productDetail";
import Bag2 from "./2/bag";
import Orders2 from "./2/orders";
import OrderDetails2 from "./2/orderDetails";
import Shop2 from "./2/shop";
import Checkout2 from "./2/checkout";
import Checkout3 from "./3/checkout";
import OrderDetails3 from "./3/orderDetails";
import Orders3 from "./3/orders";
import Bag3 from "./3/bag";
import ProductDetails3 from "./3/productDetail";
import CatalogPage3 from "./3/catalog";
import Shop3 from "./3/shop";
import Home3 from "./3/home";
import Loading from "./loading";

import Home4 from "./4/home";
import Shop4 from "./4/shop";
import Checkout4 from "./4/checkout";
import OrderDetails4 from "./4/orderDetails";
import Orders4 from "./4/orders";
import Bag4 from "./4/bag";
import ProductDetails4 from "./4/productDetail";
import CatalogPage4 from "./4/catalog";
import { borderColor, demo, primarybg, secondarybg } from "../utils/string";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useMediaQuery } from 'react-responsive';
const Colour = "#5893D4aa";

export function GetHomePage() {
    const navigate = useNavigate();
    const { dukanId } = useParams();
    const [templateId, settemplateId] = useState('');
    const [primaryColour, setprimaryColour] = useState(Colour);
    const [facebook, setfacebook] = useState('');
    const [instagram, setinstagram] = useState('');
    const [youtube, setyoutube] = useState('');
    const [secondaryColor, setsecondaryColor] = useState('');
    const [dukanName, setdukanName] = useState('');
    const [dukanDescription, setdukanDescription] = useState('');
    const [dukanContactNumber, setdukanContactNumber] = useState('');
    const [tId, settId] = useState('');
    let designs = [
        { id: 3, name: 'Shop Pro' },
        { id: 4, name: 'Porus' },
        { id: 1, name: 'Razzle' },
        { id: 2, name: 'Classic' },
    ]
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })



    useEffect(() => {
        getTemplateDetails(dukanId, navigate, settemplateId, setprimaryColour, setfacebook, setinstagram, setyoutube, setdukanName, setsecondaryColor, setdukanDescription, setdukanContactNumber)
    }, [])
    return (
        <>
            {demo &&
                <View style={{
                    flex: 1, flexDirection: 'row', flexWrap: 'wrap',
                    paddingHorizontal: 6, justifyContent: isDesktopOrLaptop ? 'center' : 'space-evenly', alignItems: 'flex-start',
                    borderBottomWidth: 1
                }}>
                    {designs.map((item, index) => {
                        return (
                            <TouchableOpacity
                                style={{
                                    width: '23%', backgroundColor: 'black',
                                    borderWidth: templateId == item.id ? 4 : 0, borderColor: secondarybg,
                                    elevation: 10, shadowColor: primaryColour,
                                    paddingVertical: 5, borderRadius: 30,
                                    alignSelf: 'center', justifyContent: 'center', marginVertical: 7,
                                    maxWidth: 100, marginLeft: isDesktopOrLaptop && 20,
                                }}
                                onPress={async () => {
                                    await AsyncStorage.setItem('templateId', item.id)
                                    window.location.href = window.location.href
                                }}
                            >
                                <Text style={{ textAlign: 'center', color: primarybg }}>{item.name}</Text>
                            </TouchableOpacity>
                        )
                    })}
                </View>}
            {templateId == 1 ?
                <Home1 primaryColour={primaryColour}
                    facebook={facebook}
                    instagram={instagram}
                    youtube={youtube}
                    secondaryColor={secondaryColor}
                    dukanName={dukanName}
                    dukanDescription={dukanDescription}
                    dukanContactNumber={dukanContactNumber}
                />
                : templateId == 2 ? <Home2 primaryColour={primaryColour}
                    facebook={facebook}
                    instagram={instagram}
                    youtube={youtube}
                    secondaryColor={secondaryColor}
                    dukanName={dukanName}
                    dukanDescription={dukanDescription}
                    dukanContactNumber={dukanContactNumber}
                />
                    : templateId == 3 ? <Home3 primaryColour={primaryColour}
                        facebook={facebook}
                        instagram={instagram}
                        youtube={youtube}
                        secondaryColor={secondaryColor}
                        dukanName={dukanName}
                        dukanDescription={dukanDescription}
                        dukanContactNumber={dukanContactNumber} />
                        : templateId == 4 ? <Home4 primaryColour={primaryColour}
                            facebook={facebook}
                            instagram={instagram}
                            youtube={youtube}
                            secondaryColor={secondaryColor}
                            dukanName={dukanName}
                            dukanDescription={dukanDescription}
                            dukanContactNumber={dukanContactNumber} />


                            : <Loading />
            }
        </>
    )
}

export function GetShopPage() {
    const navigate = useNavigate();
    const { dukanId } = useParams();
    const [templateId, settemplateId] = useState('');
    const [primaryColour, setprimaryColour] = useState(Colour);
    const [facebook, setfacebook] = useState('');
    const [instagram, setinstagram] = useState('');
    const [youtube, setyoutube] = useState('');
    const [secondaryColor, setsecondaryColor] = useState('');
    const [dukanName, setdukanName] = useState('');
    const [dukanDescription, setdukanDescription] = useState('');
    const [dukanContactNumber, setdukanContactNumber] = useState('');
    useEffect(() => {
        getTemplateDetails(dukanId, navigate, settemplateId, setprimaryColour, setfacebook, setinstagram, setyoutube, setdukanName, setsecondaryColor, setdukanDescription, setdukanContactNumber)
    }, [])
    return (
        <>
            {templateId == 1 ? <Shop1 primaryColour={primaryColour}
                facebook={facebook}
                instagram={instagram}
                youtube={youtube}
                secondaryColor={secondaryColor}
                dukanName={dukanName}
                dukanDescription={dukanDescription}
                dukanContactNumber={dukanContactNumber}
            />
                : templateId == 2 ? <Shop2 primaryColour={primaryColour}
                    facebook={facebook}
                    instagram={instagram}
                    youtube={youtube}
                    secondaryColor={secondaryColor}
                    dukanName={dukanName}
                    dukanDescription={dukanDescription}
                    dukanContactNumber={dukanContactNumber}
                /> :
                    templateId == 3 ? <Shop3 primaryColour={primaryColour}
                        facebook={facebook}
                        instagram={instagram}
                        youtube={youtube}
                        secondaryColor={secondaryColor}
                        dukanName={dukanName}
                        dukanDescription={dukanDescription}
                        dukanContactNumber={dukanContactNumber}
                    />
                        :
                        templateId == 4 ? <Shop4 primaryColour={primaryColour}
                            facebook={facebook}
                            instagram={instagram}
                            youtube={youtube}
                            secondaryColor={secondaryColor}
                            dukanName={dukanName}
                            dukanDescription={dukanDescription}
                            dukanContactNumber={dukanContactNumber}
                        />
                            : <Loading />
            }
        </>
    )
}

export function GetCatalogPage() {
    const navigate = useNavigate();
    const { dukanId } = useParams();
    const [templateId, settemplateId] = useState('');
    const [primaryColour, setprimaryColour] = useState(Colour);
    const [facebook, setfacebook] = useState('');
    const [instagram, setinstagram] = useState('');
    const [youtube, setyoutube] = useState('');
    const [secondaryColor, setsecondaryColor] = useState('');
    const [dukanName, setdukanName] = useState('');
    const [dukanDescription, setdukanDescription] = useState('');
    const [dukanContactNumber, setdukanContactNumber] = useState('');

    useEffect(() => {
        getTemplateDetails(dukanId, navigate, settemplateId, setprimaryColour, setfacebook, setinstagram, setyoutube, setdukanName, setsecondaryColor, setdukanDescription, setdukanContactNumber)
    }, [])
    return (
        <>
            {templateId == 1 ? <CatalogPage1 primaryColour={primaryColour}
                facebook={facebook}
                instagram={instagram}
                youtube={youtube}
                secondaryColor={secondaryColor}
                dukanName={dukanName}
                dukanDescription={dukanDescription}
                dukanContactNumber={dukanContactNumber}
            />
                : templateId == 2 ? <CatalogPage2 primaryColour={primaryColour}
                    facebook={facebook}
                    instagram={instagram}
                    youtube={youtube}
                    secondaryColor={secondaryColor}
                    dukanName={dukanName}
                    dukanDescription={dukanDescription}
                    dukanContactNumber={dukanContactNumber}
                />
                    : templateId == 3 ? <CatalogPage3 primaryColour={primaryColour}
                        facebook={facebook}
                        instagram={instagram}
                        youtube={youtube}
                        secondaryColor={secondaryColor}
                        dukanName={dukanName}
                        dukanDescription={dukanDescription}
                        dukanContactNumber={dukanContactNumber}

                    />
                        : templateId == 4 ? <CatalogPage4 primaryColour={primaryColour}
                            facebook={facebook}
                            instagram={instagram}
                            youtube={youtube}
                            secondaryColor={secondaryColor}
                            dukanName={dukanName}
                            dukanDescription={dukanDescription}
                            dukanContactNumber={dukanContactNumber}

                        />

                            : <Loading />
            }
        </>
    )
}

export function GetProductDetailsPage() {
    const navigate = useNavigate();
    const { dukanId } = useParams();
    const [templateId, settemplateId] = useState('');
    const [primaryColour, setprimaryColour] = useState(Colour);
    const [facebook, setfacebook] = useState('');
    const [instagram, setinstagram] = useState('');
    const [youtube, setyoutube] = useState('');
    const [secondaryColor, setsecondaryColor] = useState('');
    const [dukanName, setdukanName] = useState('');
    const [dukanDescription, setdukanDescription] = useState('');
    const [dukanContactNumber, setdukanContactNumber] = useState('');

    useEffect(() => {
        getTemplateDetails(dukanId, navigate, settemplateId, setprimaryColour, setfacebook, setinstagram, setyoutube, setdukanName, setsecondaryColor, setdukanDescription, setdukanContactNumber)
    }, [])



    return (
        <>
            {templateId == 1 ? <ProductDetails1 primaryColour={primaryColour}
                facebook={facebook}
                instagram={instagram}
                youtube={youtube}
                secondaryColor={secondaryColor}
                dukanName={dukanName}
                dukanDescription={dukanDescription}
                dukanContactNumber={dukanContactNumber}
            />
                : templateId == 2 ? <ProductDetails2 primaryColour={primaryColour}
                    facebook={facebook}
                    instagram={instagram}
                    youtube={youtube}
                    secondaryColor={secondaryColor}
                    dukanName={dukanName}
                    dukanDescription={dukanDescription}
                    dukanContactNumber={dukanContactNumber}
                />
                    : templateId == 3 ? <ProductDetails3 primaryColour={primaryColour}
                        facebook={facebook}
                        instagram={instagram}
                        youtube={youtube}
                        secondaryColor={secondaryColor}
                        dukanName={dukanName}
                        dukanDescription={dukanDescription}
                        dukanContactNumber={dukanContactNumber} />
                        : templateId == 4 ? <ProductDetails4 primaryColour={primaryColour}
                            facebook={facebook}
                            instagram={instagram}
                            youtube={youtube}
                            secondaryColor={secondaryColor}
                            dukanName={dukanName}
                            dukanDescription={dukanDescription}
                            dukanContactNumber={dukanContactNumber} />
                            : <Loading />
            }
        </>
    )
}

export function GetBagPage() {
    const navigate = useNavigate();
    const { dukanId } = useParams();
    const [templateId, settemplateId] = useState('');
    const [primaryColour, setprimaryColour] = useState(Colour);
    const [facebook, setfacebook] = useState('');
    const [instagram, setinstagram] = useState('');
    const [youtube, setyoutube] = useState('');
    const [secondaryColor, setsecondaryColor] = useState('');
    const [dukanName, setdukanName] = useState('');
    const [dukanDescription, setdukanDescription] = useState('');
    const [dukanContactNumber, setdukanContactNumber] = useState('');

    useEffect(() => {
        getTemplateDetails(dukanId, navigate, settemplateId, setprimaryColour, setfacebook, setinstagram, setyoutube, setdukanName, setsecondaryColor, setdukanDescription, setdukanContactNumber)
    }, [])
    return (
        <>
            {templateId == 1 ? <Bag1 primaryColour={primaryColour}
                facebook={facebook}
                instagram={instagram}
                youtube={youtube}
                secondaryColor={secondaryColor}
                dukanName={dukanName}
                dukanDescription={dukanDescription}
                dukanContactNumber={dukanContactNumber}
            />
                : templateId == 2 ? <Bag2 primaryColour={primaryColour}
                    facebook={facebook}
                    instagram={instagram}
                    youtube={youtube}
                    secondaryColor={secondaryColor}
                    dukanName={dukanName}
                    dukanDescription={dukanDescription}
                    dukanContactNumber={dukanContactNumber}
                />
                    : templateId == 3 ? <Bag3 primaryColour={primaryColour}
                        facebook={facebook}
                        instagram={instagram}
                        youtube={youtube}
                        secondaryColor={secondaryColor}
                        dukanName={dukanName}
                        dukanDescription={dukanDescription}
                        dukanContactNumber={dukanContactNumber} />
                        : templateId == 4 ? <Bag4 primaryColour={primaryColour}
                            facebook={facebook}
                            instagram={instagram}
                            youtube={youtube}
                            secondaryColor={secondaryColor}
                            dukanName={dukanName}
                            dukanDescription={dukanDescription}
                            dukanContactNumber={dukanContactNumber} />
                            : <Loading />
            }
        </>
    )
}

export function GetOrdersPage() {
    const navigate = useNavigate();
    const { dukanId } = useParams();
    const [templateId, settemplateId] = useState('');
    const [primaryColour, setprimaryColour] = useState(Colour);
    const [facebook, setfacebook] = useState('');
    const [instagram, setinstagram] = useState('');
    const [youtube, setyoutube] = useState('');
    const [secondaryColor, setsecondaryColor] = useState('');
    const [dukanName, setdukanName] = useState('');
    const [dukanDescription, setdukanDescription] = useState('');
    const [dukanContactNumber, setdukanContactNumber] = useState('');

    useEffect(() => {
        getTemplateDetails(dukanId, navigate, settemplateId, setprimaryColour, setfacebook, setinstagram, setyoutube, setdukanName, setsecondaryColor, setdukanDescription, setdukanContactNumber)
    }, [])
    return (
        <>
            {templateId == 1 ? <Orders1 primaryColour={primaryColour}
                facebook={facebook}
                instagram={instagram}
                youtube={youtube}
                secondaryColor={secondaryColor}
                dukanName={dukanName}
                dukanDescription={dukanDescription}
                dukanContactNumber={dukanContactNumber}
            />
                : templateId == 2 ? <Orders2 primaryColour={primaryColour}
                    facebook={facebook}
                    instagram={instagram}
                    youtube={youtube}
                    secondaryColor={secondaryColor}
                    dukanName={dukanName}
                    dukanDescription={dukanDescription}
                    dukanContactNumber={dukanContactNumber}
                />
                    : templateId == 3 ? <Orders3 primaryColour={primaryColour}
                        facebook={facebook}
                        instagram={instagram}
                        youtube={youtube}
                        secondaryColor={secondaryColor}
                        dukanName={dukanName}
                        dukanDescription={dukanDescription}
                        dukanContactNumber={dukanContactNumber} />
                        : templateId == 4 ? <Orders4 primaryColour={primaryColour}
                            facebook={facebook}
                            instagram={instagram}
                            youtube={youtube}
                            secondaryColor={secondaryColor}
                            dukanName={dukanName}
                            dukanDescription={dukanDescription}
                            dukanContactNumber={dukanContactNumber} />
                            : <Loading />
            }
        </>
    )
}

export function GetOrderDetailsPage() {
    const navigate = useNavigate();
    const { dukanId } = useParams();
    const [templateId, settemplateId] = useState('');
    const [primaryColour, setprimaryColour] = useState(Colour);
    const [facebook, setfacebook] = useState('');
    const [instagram, setinstagram] = useState('');
    const [youtube, setyoutube] = useState('');
    const [secondaryColor, setsecondaryColor] = useState('');
    const [dukanName, setdukanName] = useState('');
    const [dukanDescription, setdukanDescription] = useState('');
    const [dukanContactNumber, setdukanContactNumber] = useState('');

    useEffect(() => {
        getTemplateDetails(dukanId, navigate, settemplateId, setprimaryColour, setfacebook, setinstagram, setyoutube, setdukanName, setsecondaryColor, setdukanDescription, setdukanContactNumber)
    }, [])
    return (
        <>
            {templateId == 1 ? <OrderDetails1 primaryColour={primaryColour}
                facebook={facebook}
                instagram={instagram}
                youtube={youtube}
                secondaryColor={secondaryColor}
                dukanName={dukanName}
                dukanDescription={dukanDescription}
                dukanContactNumber={dukanContactNumber}
            />
                : templateId == 2 ? <OrderDetails2 primaryColour={primaryColour}
                    facebook={facebook}
                    instagram={instagram}
                    youtube={youtube}
                    secondaryColor={secondaryColor}
                    dukanName={dukanName}
                    dukanDescription={dukanDescription}
                    dukanContactNumber={dukanContactNumber}
                />
                    : templateId == 3 ? <OrderDetails3 primaryColour={primaryColour}
                        facebook={facebook}
                        instagram={instagram}
                        youtube={youtube}
                        secondaryColor={secondaryColor}
                        dukanName={dukanName}
                        dukanDescription={dukanDescription}
                        dukanContactNumber={dukanContactNumber} />
                        : templateId == 4 ? <OrderDetails4 primaryColour={primaryColour}
                            facebook={facebook}
                            instagram={instagram}
                            youtube={youtube}
                            secondaryColor={secondaryColor}
                            dukanName={dukanName}
                            dukanDescription={dukanDescription}
                            dukanContactNumber={dukanContactNumber} />
                            : <Loading />

            }
        </>
    )
}

export function GetCheckoutPage() {
    const navigate = useNavigate();
    const { dukanId } = useParams();
    const [templateId, settemplateId] = useState('');
    const [primaryColour, setprimaryColour] = useState(Colour);
    const [facebook, setfacebook] = useState('');
    const [instagram, setinstagram] = useState('');
    const [youtube, setyoutube] = useState('');
    const [secondaryColor, setsecondaryColor] = useState('');
    const [dukanName, setdukanName] = useState('');
    const [dukanDescription, setdukanDescription] = useState('');
    const [dukanContactNumber, setdukanContactNumber] = useState('');

    useEffect(() => {
        getTemplateDetails(dukanId, navigate, settemplateId, setprimaryColour, setfacebook, setinstagram, setyoutube, setdukanName, setsecondaryColor, setdukanDescription, setdukanContactNumber)
    }, [])
    return (
        <>
            {templateId == 1 ? <Checkout1 primaryColour={primaryColour}
                facebook={facebook}
                instagram={instagram}
                youtube={youtube}
                secondaryColor={secondaryColor}
                dukanName={dukanName}
                dukanDescription={dukanDescription}
                dukanContactNumber={dukanContactNumber}
            /> :
                templateId == 2 ? <Checkout2 primaryColour={primaryColour}
                    facebook={facebook}
                    instagram={instagram}
                    youtube={youtube}
                    secondaryColor={secondaryColor}
                    dukanName={dukanName}
                    dukanDescription={dukanDescription}
                    dukanContactNumber={dukanContactNumber}
                />
                    : templateId == 3 ? <Checkout3 primaryColour={primaryColour}
                        facebook={facebook}
                        instagram={instagram}
                        youtube={youtube}
                        secondaryColor={secondaryColor}
                        dukanName={dukanName}
                        dukanDescription={dukanDescription}
                        dukanContactNumber={dukanContactNumber} />
                        : templateId == 4 ? <Checkout4 primaryColour={primaryColour}
                            facebook={facebook}
                            instagram={instagram}
                            youtube={youtube}
                            secondaryColor={secondaryColor}
                            dukanName={dukanName}
                            dukanDescription={dukanDescription}
                            dukanContactNumber={dukanContactNumber} />
                            : <Loading />
            }
        </>
    )
}

