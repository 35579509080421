import React, { useState, useEffect, } from 'react';
import { Text, View, Dimensions, ScrollView, TouchableOpacity } from 'react-native';
import { defaultLink, demo, primarybg } from '../../../utils/string';
import Login from '../account/login';
import { getData, IconWithText, PerfectInput, RoundButton } from '../../../utils/utils';
import { TabContext } from '../../../utils/contexts';
import { useMediaQuery } from 'react-responsive';
// import { FontAwesome } from '@expo/vector-icons';

import { ToastContainer, toast } from 'react-toastify';
import { matchRoutes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { BiHomeSmile } from "react-icons/bi";
import { BsFillBagFill, BsBag, BsGridFill } from "react-icons/bs";
import { FaClipboardList } from "react-icons/fa";
import { GrDocumentUser } from "react-icons/gr";
import { RiFileList3Fill } from "react-icons/ri";
import { FaShoppingBag } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
import { IconContext } from "react-icons";
import PerfectModal from '../../../utils/perfectModel';
import { IoSearch } from 'react-icons/io5';
import SearchModal from '../templateUtils/searchModal';
const Icon = () => (<></>);

const dimensions = Dimensions.get('window');

export default function Tab(props) {

    const navigate = useNavigate();
    const { dukanId } = useParams();
    const move = navigate;
    const { children, notScrollView } = props
    const [bagVisible, setbagVisible] = useState(false);
    const [loginVisible, setloginVisible] = React.useState(false);
    const [dukanLink, setdukanLink] = useState(typeof window != 'undefined' ? window.location.pathname.split('/')[1] : '');
    const [secureToken, setsecureToken] = useState('');
    const [key, setKey] = useState(Math.random());

    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const [scrollEnabled, setscrollEnabled] = useState(true);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    const [bagCount, setbagCount] = useState(0);

    const [searchText, setsearchText] = useState('');
    const [searchModal, setsearchModal] = useState(false);

    const location = useLocation()
    const route = demo ? matchRoutes([{ path: "/:dukanId" }], location) : matchRoutes([{ path: "" }], location)

    React.useEffect(() => {
        getData('dukanLink', setdukanLink)
        getData('secure_token', setsecureToken);
    }, [])

    useEffect(() => {
        setKey(Math.random())
        if (typeof window === 'undefined') {
            global.window = {}
        }
    }, [secureToken])

    let primaryColour = typeof props.primaryColour != 'undefined' ? props.primaryColour : '#5893D4'
    let storeName = typeof props.storeName != 'undefined' ? props.storeName : ''
    let secondaryColor = typeof props.secondaryColor != 'undefined' ? props.secondaryColor : primarybg
    secondaryColor = secondaryColor == '#31456a' ? primarybg : secondaryColor

    // dukanLink merabcollection /merabcollection /merabcollection
    // tab.js:1 dukanLink merabcollection merabcollection/Bag merabcollection/Bag
    // tab.js:1 dukanLink merabcollection merabcollection/AboutUs merabcollection/AboutUs

    //     dukanLink merabcollection merabcollection/Bag merabcollection/Bag
    // tab.js:1 dukanLink merabcollection merabcollection/AboutUs merabcollection/AboutUs
    // tab.js:1 dukanLink merabcollection /merabcollection /merabcollection



    return (
        <>

            <TabContext.Provider
                value={{
                    setscrollEnabled
                }}
                key={key}
            >
                <ToastContainer autoClose={2000} />
                <Login visible={loginVisible} setvisible={setloginVisible} primaryColour={primaryColour} primaryColour={primaryColour} />
                {
                    isBigScreen || isDesktopOrLaptop ?
                        <View style={{ height: dimensions.height, }}>
                            {/* <Bag visible={bagVisible} setvisible={setbagVisible} /> */}
                            <View
                                style={{
                                    flex: 0.02,
                                    backgroundColor: secondaryColor,

                                    borderRightWidth: 0.01,
                                    borderColor: 'lightgray',

                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingHorizontal: '10%',
                                    minHeight: 65,
                                    zIndex: 3,

                                }}>
                                <Text style={{ flex: 0.3, fontSize: 29, letterSpacing: 1.5, fontWeight: '600', marginHorizontal: '7%', color: primaryColour }}>
                                    {storeName}
                                </Text>
                                <View style={{ flex: 0.3, flexDirection: 'row', marginTop: 3 }}>
                                    <TouchableOpacity
                                        onPress={() => {
                                            defaultLink == '' ?
                                                navigate('/' + dukanId + '/') :
                                                navigate('/')
                                        }}
                                    >
                                        <Text style={{ fontSize: 16, letterSpacing: 1.5, marginRight: 30, fontWeight: '600', color: primaryColour }}>Home</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={() => {
                                            defaultLink == '' ?
                                                navigate('/' + dukanLink + '/shop')
                                                : navigate('/shop')
                                        }}
                                    >
                                        <Text style={{ fontSize: 16, letterSpacing: 1.5, fontWeight: '600', color: primaryColour }}>Shop</Text>
                                    </TouchableOpacity>
                                </View>

                                <View style={{ flex: 0.3, flexDirection: 'row', marginTop: 3 }}>
                                    <TouchableOpacity
                                        onPress={() => {
                                            defaultLink == '' ?
                                                navigate('/' + dukanId + '/bag')
                                                : navigate('/bag')
                                        }}
                                    >
                                        <FaShoppingBag size={21} color={primaryColour} style={{ marginLeft: 10, marginRight: 10, color: primaryColour }} />
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={() => {
                                            console.log(window.location.pathname.substring(1).split('/')[0] + '/Orders')
                                            if (secureToken != '@error' && secureToken != '') {
                                                defaultLink == '' ?
                                                    navigate('/' + dukanId + '/orders')
                                                    : navigate('/orders')
                                            } else {
                                                setloginVisible(!loginVisible)
                                            }
                                        }}
                                    >
                                        <RiFileList3Fill size={20} color={primaryColour} style={{ marginLeft: 10, marginRight: 10, color: primaryColour }} />
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={() => {
                                            setsearchModal(!searchModal)
                                        }}
                                    >
                                        <IoSearch size={20} color={primaryColour} style={{ marginLeft: 10, marginRight: 10, color: primaryColour }} />
                                    </TouchableOpacity>
                                    {/* <FontAwesome name="search" size={24} color="black" style={{ marginHorizontal: 10, marginTop: 5 }} />
                                    <MaterialCommunityIcons name="account" size={30} color="black" style={{ marginHorizontal: 10, }} />
                                    <FontAwesome name="shopping-cart" size={24} color="black" style={{ marginHorizontal: 10, marginTop: 5 }} /> */}
                                </View>



                                {/* <TouchableOpacity
                                            onPress={() => {
                                                console.log(window.location.pathname.substring(1).split('/')[0] + '/AboutUs')
                                                navigate('/' + window.location.pathname.substring(1).split('/')[0] + '/AboutUs')
                                            }}

                                        >
                                            <Icon
                                                name="address-card" size={23} color={primaryColour} style={{ marginHorizontal: 20 }}
                                                solid
                                            // onPress={() => setbagVisible(!bagVisible)}
                                            />
                                            <Text style={{ fontSize: 12, textAlign: 'center' }}>About us</Text>
                                        </TouchableOpacity> */}
                            </View>
                            <div style={{ overflow: 'hidden', zIndex: 2, marginTop: '-2%', backgroundColor: 'trandparent', height: 200 }} ><svg viewBox="0 0 500 150" preserveAspectRatio="none">
                                <path d="M-17.49,5.44 C109.47,43.91 265.79,-14.30 514.10,31.08 L500.00,0.00 L0.00,0.00 Z" style={{ stroke: 'none', fill: primarybg, zIndex: 2000 }}>
                                </path>
                            </svg></div>
                            <View style={{ flex: 0.95, marginTop: -150 }}>
                                {children}
                            </View>
                        </View>
                        :
                        <View style={{
                            maxHeight: demo ? (route == null ? dimensions.height : dimensions.height - 55) : dimensions.height,
                            flexDirection: 'column',
                            height: demo ? (route == null ? dimensions.height : dimensions.height - 50) : dimensions.height,
                        }} >
                            {typeof props.hideBottomTab == 'undefined' ?
                                <>
                                    {typeof notScrollView != 'undefined' ?
                                        <View style={{ flex: 0.98 }}>
                                            {children}
                                        </View>
                                        :
                                        <ScrollView style={{ flex: 0.98 }} scrollEnabled={scrollEnabled}>
                                            {children}
                                        </ScrollView>
                                    }
                                    <View
                                        style={{
                                            flex: 0.02,
                                            backgroundColor: secondaryColor,
                                            shadowColor: demo ? primarybg : "#000",
                                            shadowOffset: {
                                                width: 0,
                                                height: 3,
                                            },
                                            shadowOpacity: 0.27,
                                            shadowRadius: 4.65,

                                            elevation: 6,
                                            borderTopWidth: 1.4,
                                            borderColor: '#f0ede9',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            paddingHorizontal: '15%',
                                            minHeight: 47,

                                        }}>
                                        <TouchableOpacity
                                            style={{ paddingTop: 2, alignItems: 'center', textAlign: 'center', }}
                                            onPress={() => {
                                                defaultLink == '' ?
                                                    navigate('/' + dukanId + '/') :
                                                    navigate('/')
                                            }}
                                        >
                                            <AiFillHome size={20} color={primaryColour} />
                                            {/* <Icon
                                                name="home" size={23} color={primaryColour} style={{ marginHorizontal: 20 }}
                                            // onPress={() => setbagVisible(!bagVisible)}
                                            /> */}
                                            <Text style={{ fontSize: 13, textAlign: 'center', color: primaryColour, fontWeight: '600' }}>Home</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={{ flex: 0.25, paddingTop: 2, alignItems: 'center', textAlign: 'center', }}
                                            onPress={() => {
                                                defaultLink == '' ?
                                                    navigate('/' + dukanLink + '/shop')
                                                    : navigate('/shop')
                                            }}
                                        >
                                            {/* <BsFillBagFill size={23} color={primaryColour} /> */}
                                            <IconContext.Provider value={{ bagCount: bagCount, setbagCount: setbagCount }}>
                                                <BsGridFill size={20} color={primaryColour} />
                                            </IconContext.Provider>
                                            <Text style={{ fontSize: 13, textAlign: 'center', color: primaryColour, fontWeight: '600' }}>Shop</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={{ flex: 0.25, paddingTop: 2, alignItems: 'center', textAlign: 'center', }}
                                            onPress={() => {
                                                defaultLink == '' ?
                                                    navigate('/' + dukanId + '/bag')
                                                    : navigate('/bag')
                                            }}
                                        >
                                            {/* <BsFillBagFill size={23} color={primaryColour} /> */}
                                            <IconContext.Provider value={{ bagCount: bagCount, setbagCount: setbagCount }}>
                                                <IconWithText
                                                    Icon={() => <FaShoppingBag size={21} color={primaryColour} />}
                                                    text={'' + bagCount}
                                                    color='red'

                                                />
                                            </IconContext.Provider>
                                            <Text style={{ fontSize: 13, textAlign: 'center', color: primaryColour, fontWeight: '600' }}>Bag</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={{
                                            paddingTop: 2, alignItems: 'center', textAlign: 'center',
                                            //  flexDirection: 'row',
                                        }}
                                            onPress={() => {
                                                console.log(window.location.pathname.substring(1).split('/')[0] + '/Orders')
                                                if (secureToken != '@error' && secureToken != '') {
                                                    defaultLink == '' ?
                                                        navigate('/' + dukanId + '/orders')
                                                        : navigate('/orders')
                                                } else {
                                                    setloginVisible(!loginVisible)
                                                }
                                            }}
                                        >
                                            <RiFileList3Fill size={20} color={primaryColour} />

                                            <Text style={{ fontSize: 13, textAlign: 'center', color: primaryColour, fontWeight: '600' }}>Orders</Text>
                                        </TouchableOpacity>
                                        {/* <TouchableOpacity
                                            onPress={() => {
                                                console.log(window.location.pathname.substring(1).split('/')[0] + '/AboutUs')
                                                navigate('/' + window.location.pathname.substring(1).split('/')[0] + '/AboutUs')
                                            }}

                                        >
                                            <Icon
                                                name="address-card" size={23} color={primaryColour} style={{ marginHorizontal: 20 }}
                                                solid
                                            // onPress={() => setbagVisible(!bagVisible)}
                                            />
                                            <Text style={{ fontSize: 12, textAlign: 'center' }}>About us</Text>
                                        </TouchableOpacity> */}
                                    </View>
                                </>
                                : <>{children}</>
                            }
                        </View>

                }
                <SearchModal primaryColour={primaryColour} searchModal={searchModal} setsearchModal={setsearchModal} />
            </TabContext.Provider>

        </>
    );
}