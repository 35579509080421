import * as React from 'react';
import { useState, useEffect } from 'react';
import { ActivityIndicator, Button, Dimensions, FlatList, ImageBackground, Linking, RefreshControl, ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { primarybg, primaryColour, secondarybg, } from '../../utils/string';
import { RoundButton, } from '../../utils/utils';
import { updateProductsInCatalog, profileProductsFetchSM } from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';

export default function ProductSelector(props) {
    const move = useNavigate();
    const [createCatalog, setcreateCatalog] = useState(false);
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState();
    const [prevStart, setprevStart] = useState(0);
    const [loadMore, setloadMore] = useState(true);
    const [pageRefreshKey, setpageRefreshKey] = useState(0);
    const [catalogData, setcatalogData] = useState({});
    const addProducts = React.useRef();
    const [key, setkey] = useState(0);
    const { idsOfCatalogProducts, productSelectorModal, setproductSelectorModal, setisUpdated, catalogId } = props

    const [saving, setsaving] = useState(false);


    useEffect(() => {
        profileProductsFetchSM(
            setdata,
            data,
            setisLoading,
            prevStart,
            setprevStart,
            setloadMore,
            move,
            null,
        );
    }, [])



    return (
        <>

            <Text style={{ textAlign: 'center', color: secondarybg, fontWeight: 'bold', marginBottom: 10 }}>
                Select items that you want to add and unselect items that you want to remove from this catalog/collection
            </Text>
            <FlatList
                data={data}
                onEndReached={() => {
                    if (loadMore) {
                        profileProductsFetchSM(
                            setdata,
                            data,
                            setisLoading,
                            prevStart,
                            setprevStart,
                            setloadMore,
                            move,
                            null,
                        );
                    }
                }}
                ListFooterComponent={() => (
                    <>
                        {isLoading && <ActivityIndicator color={primaryColour} size="small" />}

                    </>
                )}
                renderItem={({ item }) => (
                    <TouchableOpacity style={{
                        flexDirection: 'row', marginBottom: 10, alignItems: 'center',
                        backgroundColor: idsOfCatalogProducts.includes(item.id) ? 'rgba(88, 147, 212, 0.1)' : primarybg, paddingVertical: 5,
                        paddingHorizontal: 5,
                    }}
                        key={key}
                        onPress={() => {
                            if (!idsOfCatalogProducts.includes(item.id)) {
                                idsOfCatalogProducts.push(item.id)
                                setkey(Math.random())
                            } else {
                                idsOfCatalogProducts.splice(idsOfCatalogProducts.indexOf(item.id), 1);
                                setkey(Math.random())
                            }
                        }}
                    >
                        <FaCheckCircle
                            solid={idsOfCatalogProducts.includes(item.id) ? true : false}
                            color={idsOfCatalogProducts.includes(item.id) ? primaryColour : 'gray'}
                            size={18}
                        />
                        <Text style={{ fontSize: 16, color: primaryColour, fontWeight: 'bold', marginLeft: 10 }}>
                            {item.title.length > 20 ? String(item.title.substring(0, 20) + '...').replace(/\n/g, " ") : String(item.title.replace(/\n/g, " "))}
                        </Text>
                    </TouchableOpacity>
                )}
            />
            <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
                <RoundButton text='Close' style={{ width: '30%', marginRight: 20 }}
                    onPress={() => { setproductSelectorModal(false) }}
                />
                {saving && <ActivityIndicator color={primaryColour} size='small' />}
                <RoundButton
                    text='Save' style={{ width: '30%' }}
                    onPress={() => {
                        updateProductsInCatalog(setsaving, move, idsOfCatalogProducts, catalogId, setproductSelectorModal).then(() => {
                            window.location.href = window.location.href
                        })
                    }}
                />
            </View>

        </>
    )
}
