import React, { useEffect, useState, useContext } from 'react';
import {
    StyleSheet,
    View,
    Text,
    TouchableOpacity,
    ScrollView,
    Image,
    Dimensions,
    FlatList,
    RefreshControl,
    ActivityIndicator,
    Animated
} from 'react-native';

import {
    primaryLightColor,
    primaryLightColorButton,
    categoryId8,
    categoryId1,
    categoryId10,
    categoryId11,
    categoryId12,
    categoryId14,
    categoryId15,
    categoryId16,
    categoryId17,
    categoryId18,
    categoryId19,
    categoryId2,
    categoryId20,
    categoryId4,
    categoryId6,
    categoryId7,
    categoryId5,
    categoryIdOther,
    categoryId9,
    categoryId21,
    categoryId3

} from '../../../utils/string';
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from '../../sidebar';
import { shadow } from '../../utils/main';
import { FaArrowRight } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { primarybg, primaryColour } from '../../utils/string';

let Icon = () => (<></>)
const dimensions = Dimensions.get('window');

export default function SelectProductSubCategory(props) {
    const { category } = useParams();
    const navigate = useNavigate();
    const categoryId = parseInt(category);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })


    return (
        <>
            {console.log(category, 'ccccccc')}
            <Sidebar active='myItems' notScrollView={true} title={'Select subcategory:'}>
                <ScrollView style={{ backgroundColor: primarybg, height: '100%', paddingTop: 5, width: dimensions.width }}
                    nestedScrollEnabled={true}

                >
                    <View>
                        <FlatList
                            // horizontal={true}
                            // numColumns={4}
                            data={categoryId === 8 ? categoryId8 :
                                categoryId === 4 ? categoryId4 :
                                    categoryId === 2 ? categoryId2 :
                                        categoryId === 10 ? categoryId10 :
                                            categoryId === 1 ? categoryId1 :
                                                categoryId === 11 ? categoryId11 :
                                                    categoryId === 12 ? categoryId12 :
                                                        categoryId === 5 ? categoryId5 :
                                                            categoryId === 6 ? categoryId6 :
                                                                categoryId === 15 ? categoryId15 :
                                                                    categoryId === 16 ? categoryId16 :
                                                                        categoryId === 17 ? categoryId17 :
                                                                            categoryId === 18 ? categoryId18 :
                                                                                categoryId === 19 ? categoryId19 :
                                                                                    categoryId === 20 ? categoryId20 :
                                                                                        categoryId === 7 ? categoryId7 :
                                                                                            categoryId === 14 ? categoryId14 :
                                                                                                categoryId === 9 ? categoryId9 :
                                                                                                    categoryId === 3 ? categoryId3 :
                                                                                                        categoryIdOther
                            }

                            persistentScrollbar={true}
                            indicatorStyle={{ color: 'yellow' }}
                            keyExtractor={(item, id) => {
                                return (id)
                            }}
                            contentContainerStyle={{
                                marginHorizontal: 10,
                                marginTop: 5,
                                paddingRight: 20,
                                paddingVertical: 3,
                                backgroundColor: primarybg
                            }}
                            showsVerticalScrollIndicator={false}
                            showsHorizontalScrollIndicator={false}
                            // numColumns={2}
                            renderItem={({ item }) => (
                                <TouchableOpacity style={[{
                                    // borderBottomWidth: 1,
                                    paddingHorizontal: 5,
                                    paddingVertical: 8,
                                    flexDirection: 'row',
                                    maxWidth: isDesktopOrLaptop ? 300 : '100%',
                                    marginBottom: isDesktopOrLaptop ? 10 : 3, paddingLeft: 5
                                }, isDesktopOrLaptop && shadow]}
                                    onPress={() => {
                                        // props.navigation.navigate('AddProductForm', { category: '' +, subCategory: '' + , userCategory: false, userCategoryId: '' });
                                        navigate('/add-item/' + categoryId + '/' + item.id)
                                    }}
                                >
                                    <Text style={{ flexShrink: 1, fontSize: isDesktopOrLaptop ? 16 : 14 }}>{item.title}</Text>
                                    <FaArrowRight
                                        color={primaryColour}
                                        size={12}
                                        style={{
                                            marginTop: 2,
                                            left: '90%',
                                            position: 'absolute',
                                            alignSelf: 'center'
                                        }}
                                    />
                                </TouchableOpacity>

                            )}
                        />
                    </View>
                </ScrollView>
            </Sidebar>
        </>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: primarybg,
        flexDirection: 'column',
    },
    mediaImages: {
        width: dimensions.width / 3 - 77,
        height: dimensions.width / 3 - 77,
        borderRadius: 10,
        marginRight: 3,
        marginBottom: 3,
    },
    mediaImages2: {
        width: '100%',
        height: '70%',
        borderRadius: 20,
    },
});
