import React, { useEffect, useState } from 'react';
import {
    StyleSheet,
    View,
    Text,
    TouchableOpacity,
    ScrollView,
    Dimensions,
    FlatList,
    Picker
} from 'react-native';

// import * as SecureStore from 'expo-secure-store';

import { cityList, inputBackgound, primarybg, primaryColour, shadowColor } from './string';
import areas from './areas.json';
import DropDown from './dropDown';


export default function ShowLocations(props) {
    // const cities = cityList;


    const { city, setcity, province, setprovince, area, setarea, notProvince, notCity, notArea, cityLabel, notAll } = props

    const [provinces, setprovinces] = useState([]);
    const [cities, setcities] = useState([]);
    const [cityAreas, setcityAreas] = useState([]);

    const [defaultArea, setdefaultArea] = useState({ value: '', label: '' });
    const [key, setkey] = useState(Math.random());

    // const getSelectedArea = async () => {
    //     let cityy = await SecureStore.getItemAsync('city')
    //     let provincee = await SecureStore.getItemAsync('state2')
    //     if (cityy) {
    //         if (cityy !== '') {
    //             console.log(cityy)
    //             setcity(cityy)
    //         }

    //     }
    //     if (provincee) {
    //         if (provincee !== '') {
    //             setprovince(provincee)
    //         }
    //     }
    // }

    useEffect(() => {
        // if (notAll) {
        //     setprovinces([]);
        //     setcities([]);
        //     setcityAreas([]);
        // }
        // getSelectedArea()
        // {"area": ["Qazi Ahmad"], "city": "Qazi Ahmad", "province": "Sindh"}
        areas.forEach((item, index) => {
            if (!provinces.includes(item.province)) {
                provinces.push(item.province);
                setprovinces(provinces)
                setkey(Math.random())
            }
        })
        // setprovinces(provinces.map(t => ({ value: t, label: t })))
    }, [])

    useEffect(() => {
        if (province !== '') {
            setcities([])
            areas.forEach((item, index) => {
                if (item.province == province) {
                    if (!cities.includes(item.city)) {
                        cities.push(item.city);
                        setcities(cities)
                    }
                }
            })
            setkey(Math.random())
            // setcities(cities.map(t => ({ value: t, label: t })))
            // setdefaultArea({ value: cityAreas[0], label: cityAreas[0] })
        }
    }, [province])

    useEffect(() => {
        if (city !== '') {
            setcityAreas([])
            areas.forEach((item, index) => {
                if (item.city == city) {
                    setcityAreas(item.area)
                }
            })

        }
    }, [city])


    return (
        <View key={key}>
            {!notProvince ?
                <View style={{ zIndex: 102 }}>
                    <Text style={styles.title}>Province </Text>
                    {/* <DropDown label={(notAll ? 'Select province' : 'All')} data={provinces} selected={province} setSelected={setprovince}
                        onPress={(item, setshowDropDown) => {
                            setprovince(item);
                            setcity('')
                            setarea('')
                            setshowDropDown(false)
                        }}
                    /> */}
                    <Picker
                        selectedValue={province}
                        onValueChange={(item, itemIndex) => {
                            setprovince(item);
                            setcity('')
                            setarea('')
                            setkey(Math.random())
                        }}
                        style={{
                            backgroundColor: inputBackgound,
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            // margin: 12,
                            padding: 7,
                            paddingHorizontal: 10,
                            shadowColor: shadowColor,
                            shadowOffset: {
                                width: 0,
                                height: 2,
                            },
                            shadowOpacity: 0.25,
                            shadowRadius: 3.84,

                            elevation: 5,
                            alignContent: 'center',
                            borderRadius: 5,
                            borderColor: 'lightgray',
                            borderBottomWidth: 0,
                            color: primaryColour,
                            marginBottom: 10,
                            marginTop: 10,
                        }}
                    >
                        {provinces.map(t => {
                            if (t == provinces[0]) {
                                return (
                                    <>
                                        <Picker.Item enabled={false} label={'Please select province'} />
                                        <Picker.Item value={'' + t} label={'' + t} />
                                    </>
                                )
                            } else {
                                return (
                                    <Picker.Item value={'' + t} label={'' + t} />
                                )
                            }

                        })}
                    </Picker>

                </View>
                : null}
            {!notCity ?
                <View key={key} style={{ zIndex: 101 }}>
                    <Text style={styles.title}>City </Text>

                    <Picker
                        selectedValue={city}
                        onValueChange={(itemValue, itemIndex) => {
                            setcity(itemValue);
                            setarea('')
                        }}
                        enabled={province == '' ? false : true}
                        style={{
                            backgroundColor: inputBackgound,
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            // margin: 12,
                            padding: 7,
                            paddingHorizontal: 10,
                            shadowColor: shadowColor,
                            shadowOffset: {
                                width: 0,
                                height: 2,
                            },
                            shadowOpacity: 0.25,
                            shadowRadius: 3.84,

                            elevation: 5,
                            alignContent: 'center',
                            borderRadius: 5,
                            borderColor: 'lightgray',
                            borderBottomWidth: 0,
                            color: primaryColour,
                            marginBottom: 10,
                            marginTop: 10,
                        }}
                    >
                        {cities.length == 0 ?
                            <Picker.Item enabled={false} label={province == '' ? 'Please select province first' : 'Select city'} />
                            : null}
                        {cities.map(t => {
                            if (t == cities[0]) {
                                return (
                                    <>
                                        <Picker.Item enabled={false} label={province == '' ? 'Please select province first' : 'Select city'} />
                                        <Picker.Item value={'' + t} label={'' + t} />
                                    </>
                                )
                            } else {
                                return (
                                    <Picker.Item value={'' + t} label={'' + t} />
                                )
                            }
                        })}
                    </Picker>
                </View>
                : null}
            {!notArea ?
                <View style={{ zIndex: 100 }} key={defaultArea}>
                    <Text style={styles.title}>Area </Text>
                    {/* <DropDown
                        label={city === '' ? 'Please select city first' : (notAll ? 'Select area' : 'All')}
                        data={city === '' ? [] : cityAreas}
                        selected={area} setSelected={setarea}
                    /> */}
                    <Picker
                        onValueChange={(itemValue, itemIndex) => {
                            setarea(itemValue);
                        }}
                        enabled={city == '' ? false : true}
                        style={{
                            backgroundColor: inputBackgound,
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            // margin: 12,
                            padding: 7,
                            paddingHorizontal: 10,
                            shadowColor: shadowColor,
                            shadowOffset: {
                                width: 0,
                                height: 2,
                            },
                            shadowOpacity: 0.25,
                            shadowRadius: 3.84,

                            elevation: 5,
                            alignContent: 'center',
                            borderRadius: 5,
                            borderColor: 'lightgray',
                            borderBottomWidth: 0,
                            color: primaryColour,
                            marginBottom: 10,
                            marginTop: 10,
                        }}
                    >
                        {cityAreas.length == 0 ?
                            <Picker.Item enabled={false} label={province == '' ? 'Please select city first' : 'Select area'} />
                            : null}
                        {cityAreas.map(t => {
                            if (t == cityAreas[0]) {
                                return (
                                    <>
                                        <Picker.Item enabled={false} label={province == '' ? 'Please select city first' : 'Select area'} />
                                        <Picker.Item value={'' + t} label={'' + t} />
                                    </>
                                )
                            } else {
                                return (
                                    <Picker.Item value={'' + t} label={'' + t} />
                                )
                            }
                        })}
                    </Picker>
                </View>
                : null}

        </View>
    );
}

const dimensions = Dimensions.get('window');
const styles = StyleSheet.create({
    title: {
        textAlign: 'left',
        marginVertical: 10
    },
    container: {
        flex: 1,
        backgroundColor: primarybg,
        flexDirection: 'column',
        height: '100%',
        padding: 20,
        paddingBottom: 200,
    },
    mediaImages: {
        width: dimensions.width / 3 - 77,
        height: dimensions.width / 3 - 77,
        borderRadius: 10,
        marginRight: 3,
        marginBottom: 3,
    },
    mediaImages2: {
        width: '100%',
        height: '70%',
        borderRadius: 20,
    },
});
