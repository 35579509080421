
import { Text, TouchableOpacity, ToastAndroid, TextInput, ActivityIndicator, View, StyleSheet, ImageBackground } from 'react-native';
import { inputBackgound, primarybg, primaryColour, secondarybg, shadowColor } from './string';
import { ToastContainer, toast } from 'react-toastify';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import PerfectModal from './perfectModel';
import { useNavigate } from "react-router-dom";
import { createCatalog } from './api';
import { FaTimes } from 'react-icons/fa';

export function setTitle(title, pageDescription) {
    document.title = title
    // document.getElementsByTagName("META")[3].content = pageDescription;
}
export function isURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return pattern.test(str);
}




export function IconWithText(props) {
    const { text, color, left, top, Icon } = props;
    return (
        <View
            style={{
                alignItems: "center",
                alignSelf: "center",
                justifyContent: "center",
            }}
            key={text}
        >
            <Icon />
            {parseInt(text) > 0 ?
                <Text
                    style={{
                        position: "absolute",
                        // alignItems: "center",
                        // alignSelf: "center",
                        // justifyContent: "center",
                        color: color,
                        top: typeof top == 'undefined' ? -3 : top,
                        left: typeof left == 'undefined' ? 20 : left,
                        backgroundColor: 'red',
                        borderRadius: 25,
                        height: 13,
                        minWidth: 11,
                        fontSize: 12,
                        textAlign: 'center',
                        fontWeight: 'bold'
                    }}
                >
                    {text}
                </Text>
                : null}
        </View>
    )

}

export function filterById(jsonObject, id) { return jsonObject.filter(function (jsonObject) { return (jsonObject['id'] == id); })[0]; }

export const LoadingIndicator = () => {
    return (
        <View style={{ marginBottom: 20, justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <ActivityIndicator size="small" color={primaryColour} />
        </View>
    )
}

export const getData = async (key, setItem) => {
    try {
        const value = await AsyncStorage.getItem(key)
        if (value !== null) {
            setItem(value)
        }
    } catch (e) {
        setItem('@error')
    }
}

export const storeData = async (value, key) => {
    try {
        await AsyncStorage.setItem(key, value)
    } catch (e) {
        // saving error
    }
}

export async function AddToBag(id, variationId = '', maxQuantity = '', color = '', size = '',) {
    console.log(variationId, 'vvvvvvvvvv', color, size)
    const ba = await AsyncStorage.getItem('bag')
    if (ba !== null) {
        let bagArray = JSON.parse(ba);
        let cur = bagArray.find(x => x.id === id && x.variationId === variationId && x.color === color && x.size === size)
        if (typeof cur != 'undefined') {
            console.log('mmmmmmmmmm1', cur)
            let index = bagArray.findIndex(x => x.id == id)
            if (maxQuantity != '') {
                if (maxQuantity > (cur.quantity + 1)) {
                    bagArray[index] = { id: id, quantity: cur.quantity + 1, variationId: variationId, color: color, size: size }
                }
            } else {
                bagArray[index] = { id: id, quantity: cur.quantity + 1, variationId: variationId, color: color, size: size }
            }
            await AsyncStorage.setItem('bag', JSON.stringify(bagArray));
            notify('Product is added in bag')
        } else {
            bagArray.push({ id: id, quantity: 1, variationId: variationId, color: color, size: size })
            await AsyncStorage.setItem('bag', JSON.stringify(bagArray));
            notify('Product is added in bag')
        }
    } else {
        await AsyncStorage.setItem('bag', JSON.stringify([{ id: id, quantity: 1, variationId: variationId, color: color, size: size }]));
        notify('Product is added in bag')
    }
}


export const notify = (text) => {
    toast.dark(text, { position: toast.POSITION.BOTTOM_CENTER }
    )
};


export async function removeFromBag(id, notNotify = false) {
    const ba = await AsyncStorage.getItem('bag');
    if (ba !== null) {
        let bagArray = JSON.parse(ba);
        let cur = bagArray.find(x => x.id == id)
        if (typeof cur != 'undefined') {
            // console.log(bagArray, 'm1')
            let index = bagArray.findIndex(x => x.id == id)
            bagArray.splice(index, 1)
            // console.log(bagArray, 'm2')
            await AsyncStorage.setItem('bag', JSON.stringify(bagArray));

            if (!notNotify) notify('Product is removed from bag')
        } else {
            if (!notNotify) notify('Product is not in bag')
        }

    } else {
        if (!notNotify) notify('Product is not in bag')
    }

}


export function RoundButton(props) {
    const { onPress, children, text, textStyle } = props
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    return (
        <TouchableOpacity
            style={[isDesktopOrLaptop ? {
                height: 45,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 10,
                borderRadius: 30,
                marginTop: 5,
                borderColor: primarybg,
                borderWidth: 1,
                elevation: 1,
                backgroundColor: primaryColour
            } : {
                height: 30,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 1,
                borderRadius: 5,
                marginTop: 5,
                borderColor: primarybg,
                borderWidth: 1,
                elevation: 1,
                backgroundColor: primaryColour
            },
            typeof props.style !== 'undefined' ? props.style : {}
            ]}
            onPress={onPress}>
            {typeof text !== 'undefined' ?
                <Text style={[{ color: primarybg }, typeof props.textStyle !== 'undefined' ? props.textStyle : {}]}>{text}</Text>
                : null}
            {typeof children !== 'undefined' ? children : null}

        </TouchableOpacity>
    )
}

export function findDay(date) {
    try {
        let dateToCheck = new Date(date.split('-')[2], parseInt(date.split('-')[0]) - 1, date.split('-')[1]);

        const today = new Date();
        const yesterday = new Date(today);
        const oneDayBeforeYesterday = new Date(yesterday);
        const twoDayBeforeYesterday = new Date(yesterday);
        const threeDayBeforeYesterday = new Date(yesterday);
        const fourDayBeforeYesterday = new Date(yesterday);
        const fiveDayBeforeYesterday = new Date(yesterday);

        yesterday.setDate(yesterday.getDate() - 1);
        oneDayBeforeYesterday.setDate(yesterday.getDate() - 1);
        twoDayBeforeYesterday.setDate(yesterday.getDate() - 2);
        threeDayBeforeYesterday.setDate(yesterday.getDate() - 3);
        fourDayBeforeYesterday.setDate(yesterday.getDate() - 4);
        fiveDayBeforeYesterday.setDate(yesterday.getDate() - 5);

        let days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ];
        console.log(dateToCheck.toDateString(), 'umumu', today.toDateString(), 'umumu', date, 'umu', date.split('-')[2], date.split('-')[0], date.split('-')[1])

        if (dateToCheck.toDateString() === today.toDateString()) {
            return "Today";
        } else if (dateToCheck.toDateString() === yesterday.toDateString()) {
            return "Yesterday";
        } else if (dateToCheck.toDateString() === oneDayBeforeYesterday.toDateString()) {
            return days[dateToCheck.getDay()];
        } else if (dateToCheck.toDateString() === twoDayBeforeYesterday.toDateString()) {
            return days[dateToCheck.getDay()];
        } else if (dateToCheck.toDateString() === threeDayBeforeYesterday.toDateString()) {
            return days[dateToCheck.getDay()];
        } else if (dateToCheck.toDateString() === fourDayBeforeYesterday.toDateString()) {
            return days[dateToCheck.getDay()];
        } else if (dateToCheck.toDateString() === fiveDayBeforeYesterday.toDateString()) {
            return days[dateToCheck.getDay()];
        } else {
            return date;
        }
    } catch (e) {
        return date;
    }
}
export function PerfectInput(props) {
    const { value, setvalue, maxLength, placeholder, style, onChangeText, defaultValue, multiline, keyboardType,
        placeholderTextColor
    } = props;
    return (
        <>
            <TextInput
                style={[styles.inputs, typeof style !== 'undefined' ? style : {}]}
                // returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
                placeholder={placeholder}
                placeholderTextColor={typeof placeholderTextColor != 'undefined' ? placeholderTextColor : 'gray'}
                underlineColorAndroid="transparent"
                onChangeText={typeof onChangeText !== 'undefined' ? text => { onChangeText(text) } : text => {
                    setvalue(text);
                }}
                defaultValue={typeof defaultValue != 'undefined' ? defaultValue : ''}
                value={value}
                autoCapitalize='none'
                maxLength={typeof maxLength !== 'undefined' ? maxLength : 50} //setting limit of input
                multiline={typeof multiline != 'undefined' ? multiline : false}
                keyboardType={typeof keyboardType != 'undefined' ? keyboardType : 'default'}
            />
            {typeof maxLength !== 'undefined' && typeof value != 'undefined' ?
                <Text style={{ textAlign: 'right', color: primaryColour }}>{value.length}/{maxLength}</Text>
                : null}
        </>
    )
}

export function validateYTBURL(url) {
    var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    var matches = url.match(p);
    if (matches) {
        return true;
    }
    return false;
}

export function CreateCatalogModal(props) {
    const navigation = useNavigate()
    const { createCategoryModel, setcreateCategoryModel, refreshSomeKey, style } = props;

    const [newCategoryImage, setnewCategoryImage] = useState('');
    const [newCategoryName, setnewCategoryName] = useState('');
    const [createCategoryLoading, setcreateCategoryLoading] = useState(false);
    const [categoryNameError, setcategoryNameError] = useState('');

    return (
        <PerfectModal modalVisible={createCategoryModel}
            style={style}
        >
            <Text style={{ color: primaryColour, textAlign: 'center', marginBottom: 20, fontSize: 20, fontWeight: 'bold', color: secondarybg }}>Create Collection/Catalog</Text>
            {newCategoryImage != '' ?
                <>
                    <Text style={{ color: primaryColour }}>Selected Image </Text>
                    <View
                        // key={key}
                        style={[
                            styles.inputs,
                            { padding: 5, flexDirection: 'row', flexWrap: 'wrap' },
                        ]}>
                        <ImageBackground
                            style={styles.tinyLogo}
                            imageStyle={{ borderRadius: 9 }}
                            source={{ uri: URL.createObjectURL(newCategoryImage) }}>
                            <TouchableOpacity
                                onPress={() => {
                                    setnewCategoryImage('');
                                    // setkey(Math.random());
                                }}
                                style={{
                                    backgroundColor: '#00000020',
                                    marginBottom: '50%',
                                    // opacity: 0.7
                                }}>
                                <FaTimes color="white" />
                            </TouchableOpacity>
                        </ImageBackground>
                    </View>
                </>
                : null}

            <Text style={{ color: primaryColour, marginBottom: 10 }}>Catalog image </Text>
            <input type="file" name="upload_file"
                onChange={(event) => {
                    event.target.files.length > 0 && setnewCategoryImage(event.target.files[0]);
                }}
                style={{ outlineStyle: 'none' }}
            />

            {/* <TouchableOpacity
          // disabled={choosePhotosButton}
          onPress={() => {
            // NativeModules.ToastExample.show('hello')
            ImageCropPicker.openPicker({
              multiple: false,
              mediaType: 'photo',
              compressImageQuality: 0.5
            }).then(images => {
              setnewCategoryImage(images);
              // setkey(Math.random());
            });
          }}
          style={[
            styles.inputs,
            {
              alignItems: 'center',
            },
          ]}>
          <Text style={{ color: primaryColour }}>
            Select image  (optional)
          </Text>
        </TouchableOpacity> */}
            <Text style={{ color: primaryColour, marginTop: 20 }}>Catalog Name </Text>
            <PerfectInput value={newCategoryName} setvalue={setnewCategoryName} maxLength={20} placeholder='eg. Shoes' />
            <Text style={{ color: 'red' }}>{categoryNameError}</Text>
            <View style={{ flexDirection: 'row' }}>
                <RoundButton onPress={() => { setcreateCategoryModel(!createCategoryModel) }} text='Close' style={{ flex: 0.5, borderRadius: 20, backgroundColor: '#5893D4' }} />
                <RoundButton
                    onPress={() => {
                        if (newCategoryName == '') {
                            setcategoryNameError('Please add name first');
                            return;
                        }
                        setcategoryNameError('')
                        createCatalog(setcreateCategoryLoading, newCategoryName, navigation, setcreateCategoryModel, newCategoryImage).then(() => {
                            typeof props.refreshSomeKey != 'undefined' && props.refreshSomeKey(Math.random());
                            setnewCategoryImage('');
                            setnewCategoryName('');
                        })
                    }}
                    text='Create' style={{ flex: 0.5, borderRadius: 18, backgroundColor: '#5893D4' }} />
            </View>
            {createCategoryLoading ?
                <ActivityIndicator size="large" color={primaryColour} />
                : null
            }
        </PerfectModal>
    )
}




const styles = StyleSheet.create({
    inputs: {
        backgroundColor: inputBackgound,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        // margin: 12,
        padding: 7,
        paddingHorizontal: 10,
        shadowColor: shadowColor,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
        alignContent: 'center',
        borderRadius: 5,
        borderColor: 'lightgray',
        borderBottomWidth: 0,
        color: primaryColour,
        marginBottom: 10,
        marginTop: 10,
        outlineStyle: 'none',
    },
    tinyLogo: {
        width: 70,
        height: 60,
        resizeMode: 'cover',
        marginVertical: 10,
        marginLeft: 5,
        borderRadius: 9,
    },
});