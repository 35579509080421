import * as React from 'react';
import { StyleSheet, Text, TouchableOpacity, View, ScrollView, Image, FlatList, Dimensions, ActivityIndicator } from "react-native";
import { secondaryColor, serverURL, shadowColor } from "../../utils/string";
import { useMediaQuery } from 'react-responsive'
import { useEffect, useState } from "react";
import { orderDetails, updateOrderStatus } from "../../utils/api";
import ImageLoad from '../../utils/imageLoad';
import { useNavigate, useParams } from "react-router-dom";
import PerfectModal from '../../utils/perfectModel';
import { RoundButton } from '../../utils/utils';
import Sidebar from '../sidebar';
import { shadow } from '../utils/main';
import { primarybg, primaryColour, secondarybg } from "../utils/string";

export default function OrderDetails(props) {
    const move = useNavigate();
    const { orderId } = useParams();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(false);

    const [message, setmessage] = useState('');
    const [messageModal, setmessageModal] = useState(false);
    const [statusUpdating, setstatusUpdating] = useState(false);

    useEffect(() => {
        orderDetails(setdata, setisLoading, move, orderId)
    }, [])

    useEffect(() => {
        if (message != '') {
            orderDetails(setdata, setisLoading, move, orderId)
            setmessageModal(true)
        }
    }, [message])

    return (
        <>
            <Sidebar active='orders' title={'Order details'} notHeaderBottom>
                {!isLoading ?
                    <>

                        <View style={[{ marginHorizontal: '10%', paddingHorizontal: '5%', paddingVertical: 30, marginVertical: 30, marginBottom: isDesktopOrLaptop ? 30 : 10 }, shadow]}>
                            <Text
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: 15,
                                    color: secondarybg,
                                    marginTop: 0,
                                    textAlign: 'left',
                                }}>
                                Status:
                            </Text>
                            <View style={{ flexDirection: 'row', marginTop: 7, alignItems: 'center', justifyContent: 'center' }}>
                                <Text
                                    style={[{ fontWeight: 'bold', color: 'gray', flex: 0.22, marginTop: 1, textAlign: 'center' },
                                    data.status == 'shipped' || data.status == 'delivered' || data.status == 'accepted' ? { color: primaryColour } : {}
                                    ]}>Accepted</Text>
                                <View style={[{ flex: 0.15, height: 1.5, backgroundColor: 'lightgray', alignSelf: 'center', marginRight: 0, marginTop: 3 }]} />
                                <Text style={[{ fontWeight: 'bold', color: 'gray', flex: 0.2, textAlign: 'center' },
                                data.status == 'shipped' || data.status == 'delivered' ? { color: primaryColour } : {}
                                ]}>Shipped</Text>
                                <View style={[{ flex: 0.15, height: 1.5, backgroundColor: 'lightgray', alignSelf: 'center', marginRight: 7, marginTop: 3 }]} />
                                <Text style={[{ fontWeight: 'bold', color: 'gray', flex: 0.2, marginTop: 1, textAlign: 'center' },
                                data.status == 'delivered' ? { color: primaryColour } : {}
                                ]}>Delivered</Text>
                            </View>
                            <Text
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: 15,
                                    color: secondarybg,
                                    marginTop: 10,
                                    textAlign: 'left',
                                    marginBottom: isDesktopOrLaptop ? 0 : 10

                                }}>
                                Product:
                            </Text>
                            <TouchableOpacity style={[{

                                marginLeft: '5%',
                                marginRight: '5%',

                                // marginVertical: 10,
                                // shadowColor: "#000",
                                // shadowOffset: {
                                //     width: 0,
                                //     height: 1,
                                // },
                                // shadowOpacity: 0.22,
                                // shadowRadius: 2.22,
                                // elevation: 3,
                                // borderWidth: 0.2,
                                // borderColor: '#f0ede9',
                                // width: '100%',
                                // paddingHorizontal: 10,
                                // paddingVertical: 5,
                                // borderRadius: 6,
                                flexDirection: 'row',
                                // backgroundColor: primarybg,

                            }
                            ]}
                            // onPress={() =>{
                            //  defaultLink == '' ?
                            //      move('/' + dukanId + '/product/' + data.product_id + '/m' )
                            //      : move('/product/' + data.product_id + '/m')
                            //     }}
                            >
                                <ImageLoad
                                    style={{
                                        // marginTop: 20,
                                        height: isDesktopOrLaptop ? 70 : 50,
                                        borderRadius: 7,
                                        width: '30%',
                                        marginTop: 2,
                                        alignSelf: 'center',
                                        marginRight: 7
                                        // borderBottomLeftRadius: 0,
                                        // borderBottomRightRadius: 0,
                                        // backgroundColor: '#f0f0f0'
                                    }}
                                    imageStyle={{
                                        // flex: 1,
                                        borderRadius: 5,
                                        height: isDesktopOrLaptop ? 70 : 50,
                                        width: '100%'
                                    }}
                                    resizeMode="cover"
                                    source={{ uri: serverURL + data.thumbnail }}
                                />
                                <Text style={{ fontWeight: 'bold', color: primaryColour, width: '60%', alignSelf: 'center', fontSize: 12 }}>{data.title}</Text>

                                {data.variation != '' && <Text style={{ color: primaryColour, width: '60%', alignSelf: 'center' }}>Variation : {data.variation}</Text>}

                            </TouchableOpacity>
                            <Text
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                    color: secondarybg,
                                    marginTop: 10,
                                    textAlign: 'left',
                                }}>
                                Order Details:
                            </Text>
                            <View style={{ marginHorizontal: '5%', marginTop: 10 }}>
                                <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                    <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>Order # </Text>
                                    <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>{data.order_number}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                    <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>Ordered Quantity </Text>
                                    <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>{data.quantity} item</Text>
                                </View>
                                <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                    <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>Status</Text>
                                    <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>{data.status}</Text>
                                </View>
                                {data.color !== '' ?
                                    <View style={{ flexDirection: 'row', marginTop: 3, justifyContent: 'space-between' }}>
                                        <Text style={{ textAlign: 'left', flex: 0.95, fontWeight: 'bold', color: primaryColour }}>Color</Text>
                                        <View
                                            style={{
                                                width: 20, height: 20, backgroundColor: data.color,
                                                marginHorizontal: 10, borderRadius: 5,
                                                alignItems: 'center', borderColor: secondarybg
                                            }}
                                        />
                                    </View>
                                    : null}
                                {data.size !== '' ?
                                    <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                        <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>Size</Text>
                                        <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour, textTransform: 'capitalize' }}>{data.size}</Text>
                                    </View>
                                    : null}
                                <View style={{ flexDirection: 'row', marginTop: 3, justifyContent: 'space-between' }}>
                                    <Text style={{ textAlign: 'left', flex: 0.3, fontWeight: 'bold', color: primaryColour }}>Date</Text>
                                    <Text style={{ textAlign: 'right', flex: 0.7, color: primaryColour }}>{data.date}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                    <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>SubTotal</Text>
                                    <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>Rs. {data.subtotal}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                    <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>Shipping</Text>
                                    <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>Rs. {data.shipping}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                    <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>Total</Text>
                                    <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>Rs. {data.total}</Text>
                                </View>
                            </View>
                            <Text
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                    color: secondarybg,
                                    marginTop: 10,
                                    textAlign: 'left',

                                }}>
                                Customer Details:
                            </Text>
                            <View style={{ marginHorizontal: '5%', marginTop: 10 }}>
                                <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                    <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>Name</Text>
                                    <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>{data.full_name}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                    <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>Mobile</Text>
                                    <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>{data.mobile}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                    <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>Address</Text>
                                    <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>{data.address}, {data.area}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                    <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>City</Text>
                                    <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>{data.city}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                    <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: primaryColour }}>Province</Text>
                                    <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>{data.province}</Text>
                                </View>
                            </View>
                        </View>
                        {statusUpdating && <ActivityIndicator color={primaryColour} size={'large'} />}
                        {data.is_seller == 1 ?
                            <View style={{
                                flexDirection: 'row', height: 60, backgroundColor: primarybg, paddingHorizontal: '17%', justifyContent: 'space-between',
                                borderTopLeftRadius: 20, borderTopRightRadius: 20, paddingTop: 5, elevation: 8
                            }}>
                                {data.status == 'pending' ?
                                    <>
                                        <RoundButton text='Reject Order'
                                            onPress={() => {
                                                updateOrderStatus(setmessage, setstatusUpdating, move,
                                                    orderId, 'rejected'
                                                )
                                            }}
                                            style={{ flex: 0.45, height: 40, backgroundColor: primarybg, borderColor: '#f0ede9' }} textStyle={{ color: 'red' }} />
                                        <RoundButton text='Accept Order'
                                            onPress={() => {

                                                updateOrderStatus(setmessage, setstatusUpdating, move,
                                                    orderId, 'accepted'
                                                )
                                            }} style={{ flex: 0.45, height: 40, backgroundColor: primarybg, borderColor: '#f0ede9' }} textStyle={{ color: primaryColour, fontWeight: 'bold' }}
                                        />
                                    </>
                                    : null}
                                {data.status == 'accepted' ?
                                    <>
                                        <RoundButton text='Cancel'
                                            onPress={() => {
                                                updateOrderStatus(setmessage, setstatusUpdating, move,
                                                    orderId, 'canceled'
                                                )
                                            }}
                                            style={{ flex: 0.35, height: 40, backgroundColor: primarybg, borderColor: '#f0ede9' }} textStyle={{ color: 'red' }} />
                                        <RoundButton text='Mark as shiped'
                                            onPress={() => {

                                                updateOrderStatus(setmessage, setstatusUpdating, move,
                                                    orderId, 'shipped'
                                                )
                                            }} style={{ flex: 0.6, height: 40, backgroundColor: primarybg, borderColor: '#f0ede9' }} textStyle={{ color: 'green', fontWeight: 'bold' }}
                                        />
                                    </>
                                    : null
                                }
                                {data.status == 'shipped' ?
                                    <>
                                        <RoundButton text='Cancel'
                                            onPress={() => {
                                                updateOrderStatus(setmessage, setstatusUpdating, move,
                                                    orderId, 'canceled'
                                                )
                                            }}
                                            style={{ flex: 0.35, height: 40, backgroundColor: primarybg, borderColor: '#f0ede9' }} textStyle={{ color: 'red' }} />
                                        <RoundButton text='Mark as delivered'
                                            onPress={() => {
                                                updateOrderStatus(setmessage, setstatusUpdating, move,
                                                    orderId, 'delivered'
                                                )
                                            }} style={{ flex: 0.6, height: 40, backgroundColor: primarybg, borderColor: '#f0ede9' }} textStyle={{ color: 'green', fontWeight: 'bold' }}
                                        />
                                    </>
                                    : null
                                }
                                {data.status == 'delivered' ?
                                    <RoundButton text='Order delivered'
                                        onPress={() => {
                                        }}
                                        style={{ flex: 1, height: 40, backgroundColor: primarybg, borderColor: '#f0ede9' }} textStyle={{ color: 'green', fontWeight: 'bold' }}
                                    />
                                    : null
                                }
                                {data.status == 'rejected' ?
                                    <RoundButton text='Order rejected'
                                        onPress={() => {
                                        }}
                                        style={{ flex: 1, height: 40, backgroundColor: primarybg, borderColor: '#f0ede9' }} textStyle={{ color: 'green', fontWeight: 'bold' }}
                                    />
                                    : null
                                }
                                {data.status == 'canceled' ?
                                    <RoundButton text='Order Canceled'
                                        onPress={() => {
                                        }}
                                        style={{ flex: 1, height: 40, backgroundColor: primarybg, borderColor: '#f0ede9' }} textStyle={{ color: 'green', fontWeight: 'bold' }}
                                    />
                                    : null
                                }
                            </View>
                            : null}
                        <PerfectModal
                            modalVisible={messageModal}
                            style={[isDesktopOrLaptop ? { width: '30%', margin: 0, padding: 20, paddingBottom: 3, borderColor: primarybg, borderWidth: 2, borderRadius: 2 } : {}]}
                        >
                            <Text style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 16, borderBottomWidth: 1, borderColor: '#f0ede9', marginVertical: 10 }}>{message}</Text>
                            <RoundButton
                                text='Close'
                                onPress={() => {
                                    setmessageModal(false);
                                    setmessage('')
                                }}
                                style={{ width: '50%', alignSelf: 'center', marginTop: 15 }} />
                        </PerfectModal>
                    </> : <ActivityIndicator color={primaryColour} />}
            </Sidebar>
        </>

    );
}

