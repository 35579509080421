import React, { useEffect, useState } from 'react';
import {
    StyleSheet,
    View,
    Text,
    TouchableOpacity,
    ScrollView,
    Dimensions,
    FlatList,
    RefreshControl,
    ImageBackground
} from 'react-native';
import { defaultLink, cityList, primarybg, primaryColour, serverURL, shadowColor, secondaryColor } from '../../../utils/string';
import { notify, PerfectInput, RoundButton } from '../../../utils/utils';
import ShowLocations from '../../../utils/showLocations';
import { useMediaQuery } from 'react-responsive';
import Tab from '../tab';
import { getPrimaryColor, placeOrder, productsFromArray } from '../../../utils/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import PerfectModal from '../../../utils/perfectModel';
import { useNavigate, useParams } from 'react-router-dom';
import '../templateUtils/loader.css'
import Footer from '../home/footer'
import { HomeContext } from "../../../utils/contexts";
import { Tick } from '../templateUtils/tick';


export default function Checkout3(props) {
    const navigate = useNavigate();
    const { dukanId } = useParams();
    const { primaryColour, facebook, instagram, youtube, dukanName, dukanContactNumber, dukanDescription, secondaryColor } = props

    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    // const isFocused = useIsFocused();
    // const { dukanId } = props.route.params


    const [bagProductsId, setbagProductsId] = useState([]);
    const [bagProducts, setbagProducts] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [prevIds, setprevIds] = useState([]);
    const [loadMore, setloadMore] = useState(false);
    const [bag, setbag] = useState([]);
    const [quantityKey, setquantityKey] = useState(Math.random());

    const [data, setdata] = useState('');
    const [city, setcity] = useState('');
    const [province, setprovince] = useState('');
    const [area, setarea] = useState('');
    const [streetAddress, setstreetAddress] = useState('');
    const [fullName, setfullName] = useState('');
    const [contactNumber, setcontactNumber] = useState('');

    const [messages, setmessages] = useState([]);
    const [messagesModal, setmessagesModal] = useState(false);

    const [pageLoaded, setpageLoaded] = useState(false);

    useEffect(() => {
        if (primaryColour != '#5893D4aa') {
            setpageLoaded(true)
        }
    }, [primaryColour])

    const getProductIds = async () => {
        const ba = await AsyncStorage.getItem('productsForCheckout')
        if (ba !== null) {
            let bagArray = JSON.parse(ba)
            console.log(bagArray)
            setbag(bagArray);
            setbagProductsId(bagArray.map(a => a.id))
        }
    }

    // can_resell: 1
    // city: "Abbottabad"
    // cover: "/media/product_thumbnail/thumbnail_604bb79b190a45b2b587fa11843fca1f.jpg"
    // id: 57
    // is_owner_of_product: 0
    // liked: 0
    // price: "2500"
    // quantity: 3
    // shipping: 0
    // state: "Azad Kashmir"
    // title: "PakNeed key application ap Playstore say download kr sktay hain. Is"
    // user_id: 2

    useEffect(() => {
        getProductIds();
    }, []);

    useEffect(() => {
        productsFromArray(
            setbagProducts, setisLoading, navigate, bag
        )
    }, [bagProductsId])

    useEffect(() => {
        if (messages.length > 0) {
            setmessagesModal(true)
        }
    }, [messages])



    return (
        <>
            <HomeContext.Provider
                value={{
                    primaryColour
                }}
            >
                {!pageLoaded ?
                    <View style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: Dimensions.get('window').height }}>
                        <div className="loadingio-spinner-chunk-nx0mjvmz269"><div className="ldio-q1sjvrz6fl">
                            <div><div><div></div><div></div><div></div><div></div></div></div>
                        </div></div>
                    </View>
                    :
                    <Tab primaryColour={primaryColour} secondaryColor={secondaryColor} dukanName={dukanName} dukanContactNumber={dukanContactNumber}>
                        <View>
                            <View style={isDesktopOrLaptop ? { marginTop: 20, } : { marginTop: 45 }}>

                                <ImageBackground source='https://images.pexels.com/photos/1884581/pexels-photo-1884581.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'


                                    // {serverURL + item.image}

                                    imageStyle={

                                        isDesktopOrLaptop ? {
                                            width: '100%',
                                            height: 200,
                                            overflow: 'hidden',
                                            justifyContent: "left",
                                            width: '100%',

                                        } : {

                                            width: '100%',
                                            height: 200,
                                            overflow: 'hidden',
                                            justifyContent: "center",
                                            width: '100%',

                                        }
                                    }>

                                    <div className="jumbotron" style={{ borderRadius: 0, padding: 'none', margin: 'none', backgroundColor: 'rgb(0,0,0,0.9)', height: 200, }}>

                                        <p style={!isDesktopOrLaptop ? { marginTop: 30, color: secondaryColor } : { color: secondaryColor }}>Home / Bag / Checkout</p>

                                        <h1 style={{ fontFamily: 'sans-serif', justifyContent: 'center', textAlign: 'center', color: secondaryColor, }}>Checkout</h1>
                                    </div>

                                </ImageBackground>
                            </View>

                            <View style={{ flex: 1, marginHorizontal: '5%', marginTop: 50, }}>
                                <header className="text-center mb-5">
                                    <h2>Billing information</h2>
                                </header>
                                <ul className="nav nav-tabs nav-fill mb-5 flex-column flex-md-row border-none" style={!isDesktopOrLaptop ? { border: 'none' } : null}>
                                    <li className="nav-item"><a className="nav-link disabled p3" href="#" style={{ padding: 20, }}>1. Shopping cart</a></li>
                                    <li className="nav-item"><a className="nav-link active p3" aria-current="page" href="#" style={{ padding: 20, backgroundColor: '#F4F4F4', }}>2. Billing Information</a></li>
                                    <li className="nav-item"><a className="nav-link disabled p3" href="#" style={{ padding: 20, }}>3. Completed</a></li>
                                </ul>
                            </View>


                            <View style={isDesktopOrLaptop ? { flexDirection: 'row', marginHorizontal: '3%', } : { flexDirection: 'column', marginHorizontal: '3%', marginTop: -70, }}>
                                <View style={isDesktopOrLaptop ? { flex: 0.30 } : { flex: 1 }}>
                                    <View style={[{ paddingVertical: 46, borderRadius: 2, backgroundColor: '#F4F4F4', }, !isDesktopOrLaptop ? { marginTop: 30, } : null]}>
                                        <View style={{ elevation: 1, marginTop: 10, paddingHorizontal: 20, paddingVertical: 10, width: '80%', alignSelf: 'center' }}>

                                            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                                <Text style={{ flex: 0.5, fontWeight: 'bold', color: 'gray', fontSize: 22, textAlign: 'center' }}>View Total</Text>
                                            </View>
                                            <hr />
                                            <View style={{ flexDirection: 'row' }}>
                                                <Text style={{ flex: 0.5, fontWeight: 'bold', color: 'gray' }}>Products:</Text>
                                                <Text style={{ flex: 0.5, textAlign: 'right', color: 'gray' }}>
                                                    {bagProducts.length}
                                                </Text>
                                            </View>
                                            <View style={{ flexDirection: 'row' }}>
                                                <Text style={{ flex: 0.5, fontWeight: 'bold', color: 'gray' }}>Subtotal:</Text>
                                                <Text style={{ flex: 0.5, textAlign: 'right', color: 'gray' }}>Rs.
                                                    {
                                                        bagProducts.map(x => parseInt(x.price) * bag.find(y => y.id == x.id).quantity).reduce((a, b) => a + b, 0)
                                                    }
                                                </Text>
                                            </View>
                                            <View style={{ flexDirection: 'row' }}>
                                                <Text style={{ flex: 0.5, fontWeight: 'bold', color: 'gray' }}>Shipping:</Text>
                                                <Text style={{ flex: 0.5, textAlign: 'right', color: 'gray' }}>Rs.
                                                    {
                                                        bagProducts.map(x => parseInt(x.shipping) * bag.find(y => y.id == x.id).quantity).reduce((a, b) => a + b, 0)
                                                    }
                                                </Text>
                                            </View>
                                            <hr />
                                            <View style={{ flexDirection: 'row' }}>
                                                <Text style={{ flex: 0.5, fontWeight: 'bold', color: 'gray' }}>Payment Methd:</Text>
                                                <Text style={{ flex: 0.5, textAlign: 'right', color: 'orange' }}>
                                                    Cash on Delivery
                                                </Text>
                                            </View>
                                            <hr />
                                            <View style={{ flexDirection: 'row' }}>
                                                <Text style={{ flex: 0.5, fontWeight: 'bold', fontFamily: 'sans-serif', fontWeight: 'bold', color: 'gray' }}>Grand Total:</Text>
                                                <Text style={{ flex: 0.5, textAlign: 'right', fontWeight: 'bold', fontFamily: 'sans-serif', fontWeight: 'bold', color: 'gray' }}>Rs.
                                                    {/* {console.log(bagProducts.map(x => x.id), 'usamaaaaaaaaaaaa', bag.find(y => y.id == 114))} */}
                                                    {bagProducts.length > 0 &&
                                                        console.log(bagProducts.map(x => x.id), 'usamaaaaaaaaaaaa', bag.find(y => y.id == 114), 'mmmm', bagProducts.length)}
                                                    {bagProducts.length > 0 &&
                                                        bagProducts.map(x => parseInt(x.price) * bag.find(y => y.id == x.id).quantity).reduce((a, b) => a + b, 0) +
                                                        bagProducts.map(x => x.shipping * bag.find(y => y.id == x.id).quantity).reduce((a, b) => a + b, 0)
                                                    }
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                            <TouchableOpacity
                                                style={{ marginTop: 50, borderRadius: 100, backgroundColor: primaryColour, color: secondaryColor, height: 45, paddingVertical: 10, paddingHorizontal: 30, }}
                                                onPress={() => {
                                                    defaultLink == '' ?
                                                        navigate('/' + dukanId + '/bag')
                                                        : navigate('/bag')
                                                }}
                                            ><Text style={{ color: secondaryColor }}>Go back to Bag</Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                </View>
                                <View style={isDesktopOrLaptop ? { flex: 0.69, marginLeft: 20, } : null}>
                                    <View style={[{ paddingVertical: 46, borderRadius: 2, backgroundColor: '#F4F4F4', }, !isDesktopOrLaptop ? { marginTop: 30, } : null]}>
                                        <View style={{ elevation: 1, marginTop: 10, paddingHorizontal: 20, paddingVertical: 10, width: '80%', alignSelf: 'center' }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <Text style={styles.title}>Full Name </Text>
                                                <PerfectInput value={fullName} setvalue={setfullName} maxLength={250} placeholder='Enter your full name'
                                                    style={isDesktopOrLaptop ? {
                                                        width: '100%', borderRadius: 0, boxShadow: 'none', color: primaryColour, borderRadius: 0,
                                                        padding: 15, backgroundColor: 'white',

                                                    } : {
                                                        width: '100%', borderRadius: 0, boxShadow: 'none', color: primaryColour, borderRadius: 0,
                                                        padding: 15, backgroundColor: 'white',
                                                    }}
                                                />
                                            </View>
                                            <View style={{ flexDirection: 'column' }}>
                                                <Text style={styles.title}>Contact Number </Text>
                                                <PerfectInput value={contactNumber} setvalue={setcontactNumber} maxLength={250} placeholder='Enter your contact number'
                                                    style={isDesktopOrLaptop ? {
                                                        width: '100%', borderRadius: 0, boxShadow: 'none', color: primaryColour, borderRadius: 0,
                                                        padding: 15, backgroundColor: 'white',
                                                    } : {
                                                        width: '100%', borderRadius: 0, boxShadow: 'none', color: primaryColour, borderRadius: 0,
                                                        padding: 15, backgroundColor: 'white',
                                                    }}
                                                />
                                            </View>
                                            <View style={{ flexDirection: 'column' }}>
                                                <View style={isDesktopOrLaptop ? { width: '100%', borderRadius: 0, boxShadow: 'none', color: primaryColour, } : { width: '100%', borderRadius: 0, boxShadow: 'none', color: primaryColour, }}
                                                    key={province}
                                                >

                                                    <ShowLocations
                                                        province={province} setprovince={setprovince}
                                                        city={city} setcity={setcity}
                                                        area={area} setarea={setarea}
                                                        cityLabel='Please select province first'
                                                        notAll
                                                        // notArea
                                                        style={{ color: primaryColour, }}
                                                    />
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'column', marginTop: 20, }}>
                                                <Text style={styles.title}>Address</Text>
                                                <PerfectInput value={streetAddress} setvalue={setstreetAddress} maxLength={250} placeholder='Enter your street address'
                                                    style={isDesktopOrLaptop ? {
                                                        width: '100%', borderRadius: 0, boxShadow: 'none', color: primaryColour, borderRadius: 0,
                                                        padding: 15, backgroundColor: 'white',
                                                    } : {
                                                        width: '100%', borderRadius: 0, boxShadow: 'none', color: primaryColour, borderRadius: 0,
                                                        padding: 15, backgroundColor: 'white',
                                                    }}
                                                />
                                            </View>
                                            <View style={{ flexDirection: 'column' }}>
                                                <View style={isDesktopOrLaptop ? { width: '90%', marginTop: 10 } : { width: '100%', marginTop: 10 }}>
                                                    <TouchableOpacity style={isDesktopOrLaptop ? { borderRadius: 100, width: '30%', alignSelf: 'center', backgroundColor: primaryColour, color: secondaryColor, } : { borderRadius: 100, width: '50%', alignSelf: 'center', backgroundColor: primaryColour, color: secondaryColor, borderRadius: 100, paddingVertical: 15, }}
                                                        onPress={() => {

                                                            if (fullName == '') { notify('Please add title'); return; }
                                                            if (contactNumber == '') { notify('Please add contact number'); return; }
                                                            if (province == '') { notify('Please select province'); return; }
                                                            if (city == '') { notify('Please add '); return; }
                                                            if (area == '') { notify('Please add '); return; }
                                                            if (streetAddress == '') { notify('Please add '); return; }
                                                            if (bagProducts.length < 1) { notify('Please add at least one product in bag '); return; }
                                                            placeOrder(fullName, contactNumber, province, city, area, streetAddress, JSON.stringify(bag), 'COD', setdata, setisLoading, navigate, setmessages)
                                                        }}
                                                    >
                                                        <Text style={{ color: secondaryColor, padding: 20, justifyContent: 'center', textAlign: 'center', }}>Place Order</Text>
                                                    </TouchableOpacity>
                                                </View>
                                            </View>

                                            <PerfectModal
                                                modalVisible={messagesModal}
                                                style={[isDesktopOrLaptop ? { backgroundColor: primaryColour, width: '50%', margin: 0, padding: 20, paddingVertical: 50, borderRadius: 2 } : { backgroundColor: primaryColour, width: '80%', margin: 0, padding: 20, paddingVertical: 50, borderRadius: 2 }]}
                                            >
                                                {messages.map(i => {
                                                    return (
                                                        <View style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', }}>
                                                            <Tick size={200} />
                                                            <Text style={{ color: 'white', marginVertical: 10 }}>{i}</Text>
                                                            <hr />
                                                        </View>
                                                    )
                                                })}
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        setmessagesModal(false);
                                                        navigate('/' + dukanId + '/Orders')
                                                    }}
                                                    style={{ width: '30%', padding: 20, borderRadius: 100, alignSelf: 'center', marginTop: 15, backgroundColor: 'white', color: 'black', }}>
                                                    <Text style={{ color: 'black', justifyContent: 'center', textAlign: 'center' }}>close</Text>
                                                </TouchableOpacity>

                                            </PerfectModal>
                                        </View>
                                    </View>
                                </View>
                            </View>





                            <View style={{ marginTop: 170, }}>

                                <Footer dukanId={dukanId} dukanContactNumber={dukanContactNumber} secondaryColor={secondaryColor} dukanName={dukanName} dukanDescription={dukanDescription} data={data} facebook={facebook} instagram={instagram} youtube={youtube} />

                            </View>

                        </View>
                    </Tab>

                }
            </HomeContext.Provider>
        </>
    )
}



const dimensions = Dimensions.get('window');
const styles = StyleSheet.create({
    title: {
        textAlign: 'left',
        color: 'black'
    },
    container: {
        flex: 1,
        backgroundColor: primarybg,
        flexDirection: 'column',
        height: '100%',
        // padding: 10,
        paddingTop: 20,
        paddingHorizontal: 20
    },
    mediaImages: {
        width: dimensions.width / 3 - 77,
        height: dimensions.width / 3 - 77,
        borderRadius: 10,
        marginRight: 3,
        marginBottom: 3,
    },
    mediaImages2: {
        width: '100%',
        height: '70%',
        borderRadius: 20,
    },
});