import { StyleSheet, Text, TouchableOpacity, View, ScrollView, Image, FlatList, Dimensions, Linking } from "react-native";
import { useMediaQuery } from 'react-responsive'
import PerfectModal from "../../../utils/perfectModel";
import { AddToBag, getData, LoadingIndicator, notify, RoundButton, setTitle, storeData, useTitle } from "../../../utils/utils";
// import FontAwesomeIcon from 'react-native-vector-icons/dist/FontAwesome5';

import ImageLoad from "../../../utils/imageLoad";
import Tab from "../tab";
import { borderColor, defaultLink, primarybg, secondarybg, serverURL, shadowColor, secondaryColor } from "../../../utils/string";
import DetailsScreen from "./details";
import { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { getProductDetails } from "../../../utils/api";
import { TabContext } from "../../../utils/contexts";
import Login from "../account/login";
import Swiper from 'react-native-web-swiper';
import { useParams, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaShoppingBasket } from "react-icons/fa";
import '../templateUtils/loader.css'
import { BiArrowBack } from "react-icons/bi";
import Footer from "../home/footer";
import { IoArrowBack, IoRemove, IoAdd } from "react-icons/io5";
import { HomeContext } from "../../../utils/contexts";

const Icon = () => (<></>);


const handleDragStart = (e) => e.preventDefault();
const dimensions = Dimensions.get('window');

export default function ProductDetails3(props) {

    const navigate = useNavigate();
    const { dukanId, productId } = useParams();


    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTablet = useMediaQuery({
        query: '(min-width: 768px)'
    })

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const { primaryColour, facebook, instagram, youtube, dukanName, dukanDescription, dukanContactNumber, secondaryColor } = props
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const [images, setimages] = useState(false);
    const [loginVisible, setloginVisible] = useState(false);
    const [placeOrderQuantity, setplaceOrderQuantity] = useState(1);
    const [variations, setvariations] = useState([]);
    const [selectedVariation, setselectedVariation] = useState(0);
    const [dukanLink, setdukanLink] = useState('');
    // const isFocused = useIsFocused();
    const { hideBottomTab, sethideBottomTab } = useContext(TabContext);

    const [secureToken, setsecureToken] = useState('');
    const [pageRefresh, setPageRefresh] = useState(Math.random());
    const [selectedColor, setselectedColor] = useState(0);
    const [selectedSize, setselectedSize] = useState(0);

    const [pageLoaded, setpageLoaded] = useState(false);

    useEffect(() => {
        if (primaryColour != '#5893D4aa') {
            setpageLoaded(true)
        }
    }, [primaryColour])

    useEffect(() => {
        if (typeof productId !== 'undefined') {
            getProductDetails(productId, setdata, setisLoading, navigate, setvariations)

            storeData(dukanId, 'dukanLink')
            setisLoading(false)
        }
        getData('secure_token', setsecureToken);
        sethideBottomTab(true)
    }, [])

    useEffect(() => {
        console.log(hideBottomTab, 'uuuuuuuuuuu')
        setPageRefresh(Math.random())
    }, [hideBottomTab])

    useEffect(() => {
        if (typeof productId !== 'undefined') {

            getProductDetails(productId, setdata, setisLoading, navigate, setvariations)
        }
        getData('secure_token', setsecureToken);
        setPageRefresh(Math.random())
    }, [productId])





    useEffect(() => {
        if (typeof data.imgs !== 'undefined') {
            setimages(true)
        }
    }, [data])



    return (
        <>
            <HomeContext.Provider
                value={{
                    primaryColour,
                }}
            >
                {!pageLoaded ?
                    <View style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: Dimensions.get('window').height }}>
                        <div className="loadingio-spinner-chunk-nx0mjvmz269"><div className="ldio-q1sjvrz6fl">
                            <div><div><div></div><div></div><div></div><div></div></div></div>
                        </div></div>
                    </View>
                    :
                    <Tab primaryColour={primaryColour} dukanName={dukanName} data={data} dukanContactNumber={dukanContactNumber} secondaryColor={secondaryColor}>
                        <View>
                            <View style={isDesktopOrLaptop ? { marginHorizontal: "5%", marginTop: 180, maxHeight: dimensions.height, flexDirection: 'column', } : { marginHorizontal: "5%", marginTop: 90, marginLeft: 15, flexDirection: 'column', }} key={pageRefresh} >


                                {!isLoading ?
                                    <>
                                        <Login visible={loginVisible} setvisible={setloginVisible} primaryColour={primaryColour} secondaryColor={secondaryColor} />
                                        {typeof data.imgs != 'undefined' ?
                                            <View style={isDesktopOrLaptop ? { flexDirection: 'row', } : {}}>
                                                <View style={isDesktopOrLaptop ? { flex: 0.5, borderWidth: 1, borderColor: 'lightgray', height: Dimensions.get('window').height + 50 } : isTablet ? { borderWidth: 1, borderColor: 'gray', height: 600, } : { borderWidth: 1, borderColor: 'gray', height: 400, }}>
                                                    {/* <AliceCarousel mouseTracking items={images} autoWidth disableButtonsControls disableDotsControls /> */}
                                                    {
                                                        images ?
                                                            <Swiper
                                                                // from={1}
                                                                minDistanceForAction={0.1}
                                                                controlsProps={{
                                                                    dotsTouchable: true,
                                                                    prevPos: 'left',
                                                                    nextPos: 'right',
                                                                    nextTitle: 'Next',
                                                                    dotActiveStyle: { backgroundColor: primaryColour, },
                                                                    nextTitleStyle: { padding: 5, backgroundColor: primaryColour, color: secondaryColor, fontSize: 15, fontWeight: 800, marginTop: 0, },
                                                                    PrevComponent: ({ onPress }) => (
                                                                        <TouchableOpacity onPress={onPress}>
                                                                            <Text style={{ color: primaryColour, fontSize: 24, fontWeight: '500' }}>
                                                                                <IoArrowBack size={20} color={primaryColour} solid="false" />
                                                                            </Text>
                                                                        </TouchableOpacity>
                                                                    ),
                                                                }}
                                                            >
                                                                {
                                                                    data.imgs.map((i, k) => {
                                                                        return (
                                                                            // <View style={{ height: Dimensions.get('window').height, }}>
                                                                            <ImageLoad

                                                                                source={{ uri: serverURL + i.image }}


                                                                                // {{ uri: serverURL + i.image }}
                                                                                style={isDesktopOrLaptop ? { flex: 1, } : isTablet ? { height: Dimensions.get('window').width + 50, } : { height: Dimensions.get('window').width + 50 }}
                                                                            >
                                                                                <Text style={[{
                                                                                    textAlign: 'center',
                                                                                    margin: 50,
                                                                                    backgroundColor: secondarybg,
                                                                                    width: 70,
                                                                                    borderRadius: 20,
                                                                                    paddingHorizontal: 5,
                                                                                    color: primarybg,
                                                                                    alignSelf: 'center'
                                                                                }, !isDesktopOrLaptop && {
                                                                                    position: 'absolute',
                                                                                    bottom: -15,
                                                                                    marginLeft: 30,
                                                                                    alignSelf: 'flex-start'
                                                                                }]}>
                                                                                    {i.variation}
                                                                                </Text>
                                                                            </ImageLoad>
                                                                            // </View>

                                                                        )
                                                                    })
                                                                }

                                                            </Swiper>
                                                            : null
                                                    }
                                                    {/* <View style={{
                        flexDirection: 'row', position: 'absolute', top: Dimensions.get('window').height - 70,
                        justifyContent: 'center'
                        , alignItems: 'center', marginHorizontal: '40%', paddingTop: 0
                    }}>
                        {images.map((i, k) => (
                            <Icon name="circle" size={20} color={'#888'}
                                solid
                                style={{ marginHorizontal: 3 }}
                            // onPress={() => setbagVisible(!bagVisible)}
                            />
                        ))}
                    </View> */}

                                                </View>

                                                <View style={{ flex: 0.5, paddingHorizontal: 20, paddingTop: 0 }}>
                                                    <View style={isDesktopOrLaptop ? { paddingTop: 10, paddingLeft: 30 } : { paddingTop: 10, }}>
                                                        {/* <Text style={{ fontSize: 20, color: 'gray', textAlign: 'left', paddingLeft: 20 }}>{data.type}</Text> */}
                                                        {/* <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', alignSelf: 'center' }}>
                                            <Text style={
                                                !isDesktopOrLaptop ? { fontSize: 18, textAlign: 'center', fontWeight: 'bold', color: primaryColour } :
                                                    { fontSize: 29, textAlign: 'left', fontWeight: 'bold', paddingLeft: 20, color: primaryColour }}>
                                                Rs. {data.price}
                                            </Text>
                                        </View> */}
                                                        <Text style={
                                                            !isDesktopOrLaptop ? { paddingLeft: 0, marginLeft: 0, marginTop: 20, fontWeight: 700, textTransform: 'uppercase', fontSize: 25, textAlign: 'center', color: primaryColour } :
                                                                { marginTop: 20, fontWeight: 700, textTransform: 'uppercase', fontSize: 35, textAlign: 'left', color: primaryColour }}>
                                                            {data.title.replace(/(\r\n|\n|\r)/gm, "")}
                                                        </Text>

                                                        <Text style={
                                                            !isDesktopOrLaptop ? { marginTop: 20, marginBottom: 20, fontWeight: 500, textTransform: 'uppercase', fontSize: 22, textAlign: 'center', color: primaryColour } :
                                                                { marginTop: 20, marginBottom: 20, fontWeight: 500, textTransform: 'uppercase', fontSize: 22, textAlign: 'left', color: primaryColour, marginLeft: 5, }}>
                                                            Rs. {data.price}
                                                        </Text>

                                                        {/* <View style={{ flexDirection: 'row', paddingLeft: 20 }}>
                            <ReactStars
                                count={5}
                                onChange={() => { }}
                                size={24}
                                activeColor="#ffd700"
                                edit={false}
                                value={4}
                            />
                            <Text style={{ marginTop: 7, fontWeight: 'bold' }}>{'  '}200 reviews</Text>
                        </View> */}


                                                    </View>


                                                    <View style={isDesktopOrLaptop ? { paddingLeft: 30 } : null}>

                                                        <Text style={{ marginTop: 5, color: 'gray' }}>QUANTITY : </Text>
                                                        <View style={isDesktopOrLaptop ? { flex: 1, flexDirection: 'row', marginTop: 15, } : { flex: 1, flexDirection: 'row', justifyContent: 'center', marginTop: 15, }}>

                                                            <TouchableOpacity style={{
                                                                backgroundColor: 'white', borderWidth: 1, borderColor: 'lightgray', color: primaryColour, justifyContent: 'center', height: 60, paddingHorizontal: 15,
                                                            }}
                                                                onPress={() => { placeOrderQuantity > 1 ? setplaceOrderQuantity(placeOrderQuantity - 1) : setplaceOrderQuantity(placeOrderQuantity) }}
                                                            >

                                                                <IoRemove size={20} color={primaryColour} solid="false" />

                                                            </TouchableOpacity>
                                                            <Text style={{ backgroundColor: 'white', borderWidth: 1, borderColor: 'lightgray', color: primaryColour, justifyContent: 'center', fontSize: 17, height: 60, paddingHorizontal: 25, paddingVertical: 15, }}
                                                            // key={quantityKey}
                                                            >
                                                                {placeOrderQuantity}
                                                            </Text>
                                                            <TouchableOpacity style={{ backgroundColor: 'white', borderWidth: 1, borderColor: 'lightgray', color: primaryColour, justifyContent: 'center', height: 60, paddingHorizontal: 15, }}
                                                                onPress={() => {
                                                                    placeOrderQuantity < parseInt(data.quantity) ? setplaceOrderQuantity(placeOrderQuantity + 1)
                                                                        : notify('Max quantity of this product is ' + data.quantity)
                                                                }}
                                                            >
                                                                <IoAdd size={20} color={primaryColour} solid="false" />

                                                            </TouchableOpacity>
                                                        </View>

                                                        <View style={isDesktopOrLaptop ? {
                                                            flexDirection: 'row',
                                                            // backgroundColor: 'red',
                                                            // alignSelf: 'center',
                                                            width: '100%',
                                                            // borderTopWidth: 0.1,
                                                            marginTop: 20,
                                                            elevation: 5,
                                                        } : {
                                                            flexDirection: 'row',
                                                            justifyContent: 'center',
                                                            // backgroundColor: 'red',
                                                            // alignSelf: 'center',
                                                            width: '100%',
                                                            // borderTopWidth: 0.1,
                                                            marginTop: 20,
                                                            elevation: 5,
                                                        }}>



                                                            <TouchableOpacity

                                                                onPress={() => {
                                                                    if (secureToken != '@error' && secureToken != '' && secureToken != null && typeof secureToken != 'undefined') {
                                                                        storeData(JSON.stringify([{ id: productId, quantity: placeOrderQuantity }]), 'productsForCheckout')
                                                                        navigate('/' + window.location.pathname.substring(1).split('/')[0] + '/Checkout')
                                                                    } else {
                                                                        setloginVisible(!loginVisible)
                                                                    }

                                                                }}
                                                                style={{ borderRadius: 100, backgroundColor: primaryColour, height: 50, paddingVertical: 14, paddingHorizontal: 30, }} >

                                                                <Text style={{ color: 'white', fontSize: 15, fontWeight: 700, color: secondaryColor }}>Place Order</Text></TouchableOpacity>

                                                            <TouchableOpacity
                                                                onPress={() => {
                                                                    console.log(selectedVariation, 'ssssss')
                                                                    AddToBag(productId, selectedVariation,)
                                                                }}
                                                                style={{ borderRadius: 100, backgroundColor: '#f2f2f2', color: primaryColour, height: 57, marginLeft: 10, paddingVertical: 13, paddingHorizontal: 30, }} >


                                                                <Text style={{ marginTop: 1 }}>
                                                                    <Text style={{ color: 'black', fontSize: 15, fontWeight: 700, color: 'black' }}>Add to Cart</Text>
                                                                </Text>


                                                            </TouchableOpacity>

                                                        </View>
                                                    </View>




                                                    <DetailsScreen
                                                        data={data}
                                                        variations={variations}
                                                        selectedVariation={selectedVariation}
                                                        setselectedVariation={setselectedVariation}
                                                        setselectedSize={setselectedSize}
                                                        selectedSize={selectedSize}
                                                        selectedColor={selectedColor}
                                                        setselectedColor={setselectedColor}
                                                        primaryColour={primaryColour}
                                                        Footer={() => (
                                                            <>
                                                                {isDesktopOrLaptop ?
                                                                    <View style={{
                                                                        // flex: 0.05,
                                                                        // flexDirection: 'row',
                                                                        // backgroundColor: 'red',
                                                                        // alignSelf: 'center',
                                                                        width: '100%',
                                                                        // borderTopWidth: 0.1,
                                                                        paddingLeft: 15,
                                                                        marginTop: -20,


                                                                        elevation: 5,
                                                                    }}>



                                                                    </View>

                                                                    : null}
                                                            </>
                                                        )}
                                                    />
                                                    {/* <ProductDetailTabs /> */}

                                                </View>
                                            </View>
                                            : null}
                                    </>
                                    : <LoadingIndicator />}
                                {/* {isDesktopOrLaptop ? null :
                            <View style={{ alignItems: 'center', backgroundColor: primarybg, flex: 0.08, flexDirection: 'row', justifyContent: 'space-between', borderTopWidth: 1, borderColor: borderColor }}>
                                <View style={{ flexDirection: 'row', flex: 0.3, justifyContent: 'center' }}>
                                    <RoundButton style={{ marginRight: 10, height: 30, width: 20, justifyContent: 'center' }} text='-'
                                        onPress={() => { placeOrderQuantity > 1 ? setplaceOrderQuantity(placeOrderQuantity - 1) : setplaceOrderQuantity(placeOrderQuantity) }}
                                    />
                                    <Text style={{ marginTop: 10 }}
                                    // key={quantityKey}
                                    >
                                        {placeOrderQuantity}
                                    </Text>
                                    <RoundButton style={{ marginLeft: 10, height: 30, width: 20, justifyContent: 'center' }} text='+'
                                        onPress={() => {
                                            placeOrderQuantity < parseInt(data.quantity) ? setplaceOrderQuantity(placeOrderQuantity + 1)
                                                : notify('Max quantity of this product is ' + data.quantity)
                                        }}
                                    />
                                </View>

                                <View style={{ flexDirection: 'row', flex: 0.7, justifyContent: 'space-between', paddingHorizontal: '4%' }}>
                                    <RoundButton text='Add to bag'
                                        onPress={() => {
                                            typeof variations[selectedVariation] != 'undefined' ?
                                                AddToBag(productId, variations[selectedVariation].id)
                                                : AddToBag(productId)
                                        }}
                                        style={{ paddingHorizontal: 10, flex: 0.45, borderRadius: 20 }} /> */}
                                {/* <RoundButton text='Chat & Bargain' onPress={() => { }} style={{ paddingHorizontal: 20 }} /> */}

                                {/* <RoundButton text='Place order'
                                        onPress={() => {
                                            if (secureToken != '@error' && secureToken != '' && secureToken != null && typeof secureToken != 'undefined') {
                                                storeData(JSON.stringify([{ id: productId, quantity: placeOrderQuantity }]), 'productsForCheckout')
                                                move('/' + dukanId + '/Checkout')
                                            } else {
                                                setloginVisible(!loginVisible)
                                            }

                                        }}
                                        style={{ paddingHorizontal: 10, flex: 0.45, borderRadius: 20 }} >

                                    </RoundButton>
                                </View>
                            </View>
                        } */}


                            </View>


                            <View style={{ marginTop: 230, }}>
                                <Footer dukanId={dukanId} dukanContactNumber={dukanContactNumber} secondaryColor={secondaryColor} dukanName={dukanName} dukanDescription={dukanDescription} facebook={facebook} instagram={instagram} youtube={youtube} />
                            </View>
                        </View>
                    </Tab>
                }
            </HomeContext.Provider>
        </>
    );
}


const styles = StyleSheet.create({
    myStarStyle: {
        color: 'yellow',
        backgroundColor: 'transparent',
        textShadowColor: 'black',
        textShadowOffset: { width: 1, height: 1 },
        textShadowRadius: 2,
    },
    myEmptyStarStyle: {
        color: 'white',
    }
});
