import React, { Component, useEffect, useState } from 'react';
import {
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    Image,
    ScrollView,
    FlatList,
    TextInput,
    Switch,
    RefreshControl,
    ActivityIndicator,
} from 'react-native';
import axios from 'axios';
import { cityList, dukanTypes, inputBackgound, primarybg, primaryColour, primaryLightColorButton, secondarybg, serverURL, shadowColor } from '../../utils/string';
import { checkDukanLinkAvailability, profileFetch } from '../../utils/api';
import { PerfectInput, notify } from '../../utils/utils';
import DropDown from '../../utils/dropDown';
import { FaAngleDown, FaArrowLeft, FaCheck, FaPencilAlt } from 'react-icons/fa';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigate } from 'react-router-dom';
import Sidebar from '../sidebar';
import { shadow } from '../../utils/style';


export default function Settings(props) {
    const navigation = useNavigate();
    const [data, setdata] = useState([]);
    const [username, setusername] = useState('');
    const [title, settitle] = useState('');
    const [titleError, settitleError] = useState('');
    const [address, setaddress] = useState('');
    const [description, setdescription] = useState('');
    const [descriptionError, setdescriptionError] = useState('');

    const [newDp, setnewDp] = useState();
    const [dp, setdp] = useState();
    const [tag, settag] = useState();

    const [isBuyer, setisBuyer] = useState(false);
    const [isSeller, setisSeller] = useState(false);
    const [isReseller, setisReseller] = useState(false);

    const cities = cityList;

    const [showCities, setshowCities] = useState(false);
    const [city, setcity] = useState('');

    const [pageLoading, setpageLoading] = useState(true);
    const [switchValue, setswitchValue] = useState(false);

    const [pageRefreshKey, setPageRefreshKey] = useState(Math.random());

    const [title2, settitle2] = useState('');
    const [title3, settitle3] = useState('');
    const [dukanLink, setdukanLink] = useState('');
    const [linkMessage, setlinkMessage] = useState('');
    const [linkloading, setlinkloading] = useState(false);
    const [dukanCategory, setdukanCategory] = useState('');
    const [manualCategory, setmanualCategory] = useState(false);
    const [physicalBazaar, setphysicalBazaar] = useState('');

    const [saving, setsaving] = useState(false);

    useEffect(() => {
        // checkLogin().then(v => {
        profileFetch(setdata, setpageLoading, navigation, null)
        // });
    }, [])

    useEffect(() => {
        if (data !== []) {
            settitle(data.user_name);
            setaddress(data.address);
            setdescription(data.description);
            setcity(data.city);
            setswitchValue(data.show_number_on_chats == 'true')
            setisSeller(data.is_seller == "true")
            setisBuyer(data.is_buyer == "true")
            setisReseller(data.is_reseller == "true")
            settitle2(data.dukan_title1)
            settitle3(data.dukan_title3)
            setdukanLink(data.dukan_link)
            setdukanCategory(data.dukan_category)
            setphysicalBazaar(data.physical_bazaar)
        }
        setPageRefreshKey(Math.random())
    }, [data])

    //   const changeProfilePic = () => {
    //     ImagePicker.openPicker({
    //       width: 400,
    //       height: 370,
    //       cropping: true,
    //     }).then(image => {
    //       setnewDp(true)
    //       setdp(image);
    //       // {"cropRect": {"height": 2128, "width": 2128, "x": 1240, "y": 0}, "height": 400, "mime": "image/jpeg", "modificationDate": "1605732105000",
    //       //  "path": "file:///storage/emulated/0/Android/data/com.bazaar/files/Pictures/b7c27188-865f-4add-8a75-246a817beaca.jpg", "size": 94833, "width": 400}
    //     });
    //   }

    const onSave = async () => {
        setsaving(true)
        let formData = new FormData();
        formData.append('name', '' + title)
        formData.append('title1', '' + title2)
        formData.append('title3', '' + title3)
        formData.append('dukanLink', '' + dukanLink)
        formData.append('description', '' + description)
        formData.append('address', '' + address)
        formData.append('city', '' + '' + city)
        formData.append('new_dp', '' + newDp)
        formData.append('isBuyer', '' + isBuyer)
        formData.append('isSeller', '' + isSeller)
        formData.append('isReseller', '' + isReseller)
        formData.append('show_number_on_chats', '' + switchValue)
        formData.append('dukanCategory', '' + dukanCategory)
        formData.append('physicalBazaar', '' + physicalBazaar)



        let token = await AsyncStorage.getItem('secure_token');
        axios.post(serverURL + '/profile/settings', formData,
            {
                headers: {
                    Authorization: 'token ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                let r = res.data;
                setsaving(false)
                // {"address": "", "ads_count": 0, "city": "", "description": "",
                //  "dp": "/media/profile_images/profile_2_g20Rg7y.jpg", "followers_count": 0, "following_count": 0, "user_name": 4}
                if (r.code == 100) {
                    notify('Saved');
                } else {
                    notify(r.message)
                }

            })
            .catch(error => {
                console.log(error)
                notify('Something went wrong, please check your internet connection');
            });
    }

    return (
        <Sidebar active='settings' title={'Settings'}>

            {pageLoading ? (
                <>
                    <ActivityIndicator size="small" color={primaryColour} />
                </>
            ) : (
                <>

                    <View style={[styles.container, shadow, { marginHorizontal: '10%', shadowColor: primaryColour, paddingHorizontal: '5%' }]}>

                        <Text style={styles.title}>Business name </Text>
                        <View>
                            <PerfectInput
                                onChangeText={(text) => {
                                    settitle(text)
                                }}
                                value={title}
                                placeholder={'Name of your business'}
                                maxLength={20}
                            />

                            {titleError != '' ? (
                                <Text style={{ textAlign: 'right', color: 'red', fontSize: 12 }}>
                                    {titleError}
                                </Text>
                            ) : null}
                        </View>
                        <Text style={styles.title}>Title </Text>
                        <PerfectInput value={title3} setvalue={settitle3} maxLength={30} />


                        <Text style={styles.title}>Small Title </Text>
                        <PerfectInput value={title2} setvalue={settitle2} maxLength={30} />

                        <Text style={styles.title}>Your website link </Text>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={[styles.inputs, {}]}>bazars.pk/</Text>
                            <PerfectInput value={dukanLink} setvalue={setdukanLink}
                                onChangeText={(text) => {
                                    setdukanLink(text.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').replace(/\s/g, ''))
                                    if (dukanLink.length >= 5) {
                                        checkDukanLinkAvailability(setlinkMessage, setlinkloading, props.navigation, dukanLink)
                                    }
                                    console.log('')
                                }}
                                style={{ flex: linkloading ? 0.94 : 1 }}
                            />
                            {linkloading ? <ActivityIndicator size="small" color={primaryColour} style={{ flex: 0.1 }} /> : null}
                        </View>
                        {linkMessage != '' ? (
                            <Text style={{ textAlign: 'right', color: 'gray', fontSize: 12 }}>
                                {linkMessage}
                            </Text>
                        ) : null}


                        <Text style={styles.title}>Write something about your business </Text>
                        <View>
                            <TextInput
                                style={[
                                    styles.inputs,
                                    { height: 100, textAlignVertical: 'top', marginTop: 10 },
                                ]}
                                multiline={true}
                                placeholder="Details about your dukan"
                                numberOfLines={10}
                                multiline={true}
                                underlineColorAndroid="transparent"
                                returnKeyType="none"
                                onChangeText={text => setdescription(text)}
                                value={description}
                                maxLength={4000}
                            // maxLength={11}  //setting limit of input
                            // keyboardType={'phone-pad'}
                            />
                            <Text style={{ textAlign: 'right', color: secondarybg }}>
                                {description.length}/4000
                            </Text>
                            {descriptionError != '' ? (
                                <Text style={{ textAlign: 'right', color: 'red', fontSize: 12 }}>
                                    {descriptionError}
                                </Text>
                            ) : null}
                        </View>
                        <Text style={styles.title}>City </Text>
                        <DropDown
                            label={'Select city'}
                            data={cities}
                            listHeight={230}
                            selected={city} setSelected={setcity}
                            // onPress={async (item, setshowDropDown) => {
                            //     setcity(ite)
                            //     setshowDropDown(false);
                            // }}
                            style={{ marginTop: 15, marginBottom: 0 }}
                        />
                        <Text style={[styles.title, { marginTop: 15 }]}>Category of your Business:</Text>
                        <DropDown
                            label={'Select business category'}
                            data={dukanTypes}
                            listHeight={230}
                            selected={dukanCategory} setSelected={setdukanCategory}
                            onPress={async (item, setshowDropDown) => {
                                if (item == 'My dukan category is not in list') {
                                    setmanualCategory(true)
                                    setdukanCategory('')
                                } else {
                                    setdukanCategory(item);
                                    setmanualCategory(false)
                                }
                                setshowDropDown(false);
                            }}
                            style={{ marginTop: 15, marginBottom: 0 }}
                        />
                        {manualCategory &&
                            <PerfectInput
                                onChangeText={(text) => {
                                    setdukanCategory(text)
                                }}
                                placeholder={'Enter business category name'}
                                style={{ marginTop: 15, width: '90%', alignSelf: 'center' }}
                            />
                        }

                        <Text style={[styles.title, { marginTop: 15 }]}>Address </Text>
                        <View>
                            <TextInput
                                style={styles.inputs}
                                placeholder="Enter your address"
                                // keyboardType="email-address"
                                underlineColorAndroid="transparent"
                                onChangeText={text => {
                                    setaddress(text);
                                }}
                                value={address}
                                maxLength={200} //setting limit of input
                            />
                            <Text style={{ textAlign: 'right', color: primaryColour }}>
                                {address.length}/200
                            </Text>
                            {titleError != '' ? (
                                <Text style={{ textAlign: 'right', color: 'red', fontSize: 12 }}>
                                    {titleError}
                                </Text>
                            ) : null}
                        </View>
                        <Text style={styles.title}>Physical bazaar:</Text>
                        <PerfectInput
                            onChangeText={(text) => {
                                setphysicalBazaar(text)
                            }}
                            value={physicalBazaar}
                            placeholder={'Name of physical bazaar of your business'}
                            style={{ marginTop: 15, alignSelf: 'center' }}
                        />
                        <Text style={{ flex: 0.7, color: primaryColour, fontWeight: 'bold' }}>Type:</Text>
                        <View style={{ flex: 1, flexDirection: 'row', marginVertical: 10, justifyContent: 'center', alignItems: 'stretch', marginBottom: 15 }}>
                            <View style={{ flex: 0.3, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <input type="checkbox" checked={isBuyer} onChange={() => setisBuyer(!isBuyer)} />
                                <Text style={{ color: primaryColour, marginLeft: 10, fontWeight: 'bold' }}>Buyer</Text>
                            </View>
                            <View style={{ flex: 0.3, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <input type="checkbox" checked={isSeller} onChange={() => setisSeller(!isSeller)} />
                                <Text style={{ color: primaryColour, marginLeft: 10, fontWeight: 'bold' }}>Seller</Text>
                            </View>
                        </View>

                        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text style={{ flex: 0.7 }}>Show number on dukan,website and items</Text>
                            <Switch
                                style={{ flex: 0.3, maxWidth: 34 }}
                                trackColor={{ true: primaryLightColorButton, false: 'grey' }}
                                thumbColor={switchValue ? primaryColour : "#f4f3f4"}
                                value={switchValue}
                                onValueChange={switchValue => setswitchValue(switchValue)}
                            />
                        </View>

                        <TouchableOpacity
                            onPress={() => {
                                if (city == '') { notify('Please select bazaar'); return; }
                                onSave();
                            }}
                            style={[
                                styles.inputs,
                                {
                                    backgroundColor: primaryColour,
                                    paddingVertical: 10,
                                    marginBottom: 40,
                                    alignItems: 'center',
                                    flexDirection: 'row'
                                },
                            ]}>
                            <Text style={{ color: 'white', flex: 0.7, textAlign: 'center' }}>Save</Text>
                            {saving &&
                                <View sty>
                                    <ActivityIndicator size="small" color={primarybg} />
                                </View>
                            }
                        </TouchableOpacity>
                    </View>
                </>
            )
            }
        </Sidebar>
    );
}


const styles = StyleSheet.create({
    mediaImages2: {
        resizeMode: 'contain',
        width: 120,
        height: 120,
        borderRadius: 100,
        alignSelf: 'center',
    },
    loading: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        color: primaryColour,
        fontWeight: 'bold'
    },
    tinyLogo: {
        width: 70,
        height: 60,
        resizeMode: 'cover',
        marginVertical: 10,
        marginLeft: 5,
        borderRadius: 9,
    },
    container: {
        flex: 1,
        paddingTop: 20,
        backgroundColor: primarybg,
        paddingHorizontal: 20,
        flexDirection: 'column',
    },
    inputs: {
        // borderColor: primarybg, shadowColor:'black',elevation: 1, borderWidth: 5, borderRadius: 20,
        backgroundColor: '#fafafc',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 12,
        marginHorizontal: 0,
        padding: 7,
        paddingHorizontal: 10,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        borderWidth: 0.15,
        shadowColor: 'gray',
        elevation: 1,
        alignContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        borderColor: 'lightgray',
        borderBottomWidth: 0,
        color: primaryColour,
        shadowColor: primaryColour,
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.34,
        shadowRadius: 6.27,

        elevation: 5,
        borderWidth: 1
        // borderTopWidth: 1,
    },
    bodyContent: {
        alignItems: 'center',
        alignContent: 'center',
        padding: 1,
        paddingHorizontal: 25,
        flexDirection: 'row',
    },
    activeCondtion: {
        backgroundColor: primaryColour,
        width: 100,
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
        margin: 12,
        shadowColor: 'white',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        borderWidth: 0.01,
        shadowColor: 'black',
        elevation: 20,
        alignContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        borderColor: 'white',
    },
    inactiveCondtion: {
        width: 100,
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
        margin: 12,
        shadowColor: 'white',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        borderWidth: 0.01,
        shadowColor: 'black',
        elevation: 20,
        alignContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        borderColor: 'white',
        backgroundColor: primarybg,
    },
    menuBox: {
        width: 100,
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
        margin: 12,
        shadowColor: 'white',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        borderWidth: 0.01,
        shadowColor: 'black',
        elevation: 7,
        alignContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        borderColor: 'white',
        backgroundColor: primarybg,
    },
    conditionText: {
        color: primaryColour,
    },
    activeText: {
        color: 'white',
    },
});
