import { useContext, useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View, ScrollView, FlatList, Dimensions, ActivityIndicator, ImageBackground } from "react-native";
import { defaultLink, primarybg, serverURL } from "../../../utils/string";
import { getData, LoadingIndicator } from "../../../utils/utils";
import { HomeContext } from "../../../utils/contexts";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import { shadow } from "../../../utils/style";

const { height, width } = Dimensions.get('window');

export default function Categories() {
    // const router = useRouter()
    const navigate = useNavigate()
    const { dukanId } = useParams();
    // const move = useLinkTo();
    const { categories, selectedCategory, setselectedCategory, categoriesLoading, primaryColour, secondaryColor } = useContext(HomeContext);
    const onCategoryPress = (item) => {
        if (selectedCategory != item.id) { setselectedCategory(item.id) }
    }
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })


    return (
        <>
            <div style={{ marginTop: !isDesktopOrLaptop ? -10 : '-7%', zIndex: 100 }}></div>
            {categories.length > 0 &&
                <div class="two alt-two" style={{ marginBottom: 20, zIndex: 10 }}>
                    <h1 style={{ color: primaryColour, fontSize: !isDesktopOrLaptop && 27, "--bg-color": primaryColour }}>Categories
                        <span style={{ color: primaryColour + 80, fontSize: !isDesktopOrLaptop && 9 }}></span>
                    </h1>
                </div>
            }
            {!categoriesLoading ?

                <FlatList
                    contentContainerStyle={{ justifyContent: 'center', paddingBottom: 20, paddingTop: 0 }}
                    data={categories}
                    numColumns={isDesktopOrLaptop ? 4 : 3}
                    // horizontal
                    indicatorStyle={{ backgroundColor: 'red' }}
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}
                    columnWrapperStyle={{ justifyContent: 'center', }}
                    renderItem={({ item }) => (
                        <TouchableOpacity style={[shadow, {
                            marginHorizontal: 7,
                            marginVertical: 5, width: isDesktopOrLaptop ? Dimensions.get('window').width / 5.2 - 20 : Dimensions.get('window').width / 3 - 20,
                            backgroundColor: primarybg, paddingVertical: 10, marginTop: 5, marginBottom: isDesktopOrLaptop ? 20 : 5, shadowColor: primaryColour + '21'
                        }]}
                            onPress={() => {
                                let t = item.name.replace(/[^a-z0-9\s-]/ig, '')
                                    .trim()
                                    .replace(/\s+/g, '-')
                                    .toLowerCase()
                                defaultLink == '' ?
                                    navigate('/' + dukanId + '/catalog/' + item.id + '/' + t)
                                    : navigate('catalog/' + item.id + '/' + t)
                            }}
                        >
                            <ImageBackground
                                style={[{
                                    // marginTop: 20,
                                    width: '80%',
                                    height: isDesktopOrLaptop ? 180 : 80,
                                    borderRadius: 100,
                                    backgroundColor: item.image != '' ? primaryColour : 'lightgray',
                                    justifyContent: 'center',
                                    alignSelf: 'center',
                                    shadowColor: primaryColour, borderColor: primaryColour
                                }]}
                                imageStyle={[{
                                    opacity: isDesktopOrLaptop ? 1 : 1,
                                    borderRadius: isDesktopOrLaptop ? 2 : 27,
                                    width: '100%',
                                    shadowColor: primaryColour, borderColor: primaryColour
                                }]}
                                resizeMode="cover"
                                source={item.image != '' ? (serverURL + item.image) : ''}

                            >

                            </ImageBackground>

                            <Text style={{ marginTop: 10, textTransform: 'capitalize', fontWeight: '500', color: primaryColour, textAlign: 'center' }}>
                                {item.name}</Text>
                        </TouchableOpacity>
                    )}
                    keyExtractor={item => item.id}
                />
                :
                <LoadingIndicator />
            }
            {categories.length > 0 &&
                <div className="two alt-two" style={{ marginTop: !isDesktopOrLaptop ? -20 : 20, marginBottom: 20, zIndex: 1000000000 }}>
                    <h1 style={{ color: primaryColour, fontSize: !isDesktopOrLaptop && 27, "--bg-color": primaryColour }}>
                        <span style={{ color: primaryColour + 80, fontSize: !isDesktopOrLaptop && 9 }}></span>
                    </h1>
                </div>
            }
        </>
    );
}

