import { useContext, useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View, ScrollView, FlatList, Dimensions, ActivityIndicator, ImageBackground } from "react-native";
import { defaultLink, primarybg, serverURL, textColor } from "../../../utils/string";
import { getData, LoadingIndicator } from "../../../utils/utils";
import { HomeContext } from "../../../utils/contexts";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";

const { height, width } = Dimensions.get('window');

export default function Categories(props) {
    // const router = useRouter()
    const { primaryColour, secondaryColor } = props
    const navigate = useNavigate()
    const { dukanId } = useParams();
    // const move = useLinkTo();
    const { categories, selectedCategory, setselectedCategory, categoriesLoading, } = useContext(HomeContext);
    const onCategoryPress = (item) => {
        if (selectedCategory != item.id) { setselectedCategory(item.id) }
    }
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    const isTablet = useMediaQuery({
        query: '(min-width: 768px)'
    })


    return (
        <>
            <View style={{ flexDirection: 'row' }}>
                {/* <Text style={[
                    { textAlign: 'left', marginHorizontal: 30, marginVertical: 10, fontWeight: 'bold', fontSize: 19, flex: 0.95, color: primaryColour },
                    isDesktopOrLaptop ? { marginHorizontal: 30 } : { marginHorizontal: 10, marginVertical: 0 }

                ]}>Products</Text> */}
                {/* <Text style={{ textAlign: 'left', marginHorizontal: 30, marginVertical: 10, fontWeight: '500', fontSize: 17, textDecorationLine: 'underline' }}>See all</Text> */}
            </View>
            {!categoriesLoading ?
                <FlatList
                    contentContainerStyle={{ paddingBottom: 5, paddingTop: 10 }}
                    data={categories}
                    numColumns={isDesktopOrLaptop ? 4 : isTablet ? 4 : 2}
                    columnWrapperStyle={[{
                        flex: 1,
                        paddingVertical: 10,
                        justifyContent: 'center',


                    },
                    isDesktopOrLaptop ? {} : { marginHorizontal: 3 }
                    ]}
                    indicatorStyle={{ backgroundColor: 'red' }}
                    showsVerticalScrollIndicator={false}


                    renderItem={({ item }) => (

                        <div className="category" style={isDesktopOrLaptop ? { width: 270, height: 300, marginRight: 10, } : { height: 200, width: width / 2, justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }}>

                            <TouchableOpacity

                                onPress={() => {
                                    let t = item.name.replace(/[^a-z0-9\s-]/ig, '')
                                        .trim()
                                        .replace(/\s+/g, '-')
                                        .toLowerCase()
                                    defaultLink == '' ?
                                        navigate('/' + dukanId + '/catalog/' + item.id + '/' + t)
                                        : navigate('catalog/' + item.id + '/' + t)
                                }}

                                style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}

                            >
                                <ImageBackground source={item.image != '' ? (serverURL + item.image) : ''}


                                    // {serverURL + item.image}

                                    imageStyle={

                                        isDesktopOrLaptop ? {
                                            height: 270,
                                            width: 270,
                                            overflow: 'hidden',
                                            justifyContent: "center",
                                            borderRadius: '50%',
                                        } : {

                                            height: 150,
                                            width: 150,
                                            overflow: 'hidden',
                                            justifyContent: "center",
                                            borderRadius: '50%',
                                        }
                                    }
                                >

                                    <View style={[isDesktopOrLaptop ? {


                                        background: 'rgb(0,0,0,0.4)',
                                        justifyContent: 'center',
                                        height: 270,
                                        width: 270,
                                        borderRadius: '50%',
                                        shadowOpacity: 0.32,
                                        shadowRadius: 5.46,
                                        elevation: 9,
                                        // width: 70,
                                        // height: 70,
                                        flexDirection: 'row'
                                    } : {

                                        borderRadius: '50%',
                                        background: 'rgb(0,0,0,0.4)',
                                        justifyContent: 'center',
                                        height: 150,
                                        width: 150,
                                        shadowOpacity: 0.32,
                                        elevation: 9,
                                        // width: 70,
                                        // height: 70,
                                        flexDirection: 'row'
                                    },
                                    selectedCategory == item.id ? { background: 'rgb(0,0,0,0.6)', color: primaryColour } : {}
                                    ]}

                                    >



                                        {/* {item.image != '' ?
                                <ImageLoad
                                    style={{
                                        // marginTop: 20,
                                        width: 50,
                                        height: 30,
                                        borderRadius: 7,

                                        // borderBottomLeftRadius: 0,
                                        // borderBottomRightRadius: 0,
                                        // backgroundColor: '#f0f0f0'
                                    }}
                                    imageStyle={{
                                        // flex: 1,
                                        width: 50,
                                        height: 30,
                                        borderRadius: 7,
                                        position: 'absolute',
                                        width: '100%',

                                    }}
                                    resizeMode="cover"
                                    source={serverURL + item.image}
                                    onPress={() => onCategoryPress(item)}
                                />
                                : */}
                                        {/* <View
                                style={{

                                    height: 30,
                                    justifyContent: 'center'
                                }}
                            >

                            </View>
                            } */}

                                        <View
                                            style={{

                                                height: 20,
                                                justifyContent: 'center'
                                            }}
                                        >

                                        </View>



                                    </View>

                                </ImageBackground>

                                <View>
                                    <Text style={{
                                        textAlign: 'center', border: 'none',
                                        fontWeight: 'bold', textTransform: 'uppercase',
                                        fontsize: 30,
                                        fontFamily: 'sans-serif',
                                        color: primaryColour,
                                        borderRadius: 5,
                                        marginTop: 20,
                                    }}>
                                        <Text style={[{

                                        },
                                        isDesktopOrLaptop ? { fontSize: 20, width: '100%', paddingTop: 10, } : { fontSize: 15, width: '100%', paddingTop: 10, },
                                        selectedCategory == item.id ? { color: '#141414' } : {}
                                        ]}
                                        >

                                            {item.name}
                                        </Text>
                                    </Text>
                                </View>

                            </TouchableOpacity>

                        </div>
                    )
                    }
                    keyExtractor={item => item.id}
                />
                :
                <LoadingIndicator />
            }
        </>
    );
}

