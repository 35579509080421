import React, { useEffect, useState, useContext, useRef } from 'react';
import {
    StyleSheet,
    View,
    Text,
    TouchableOpacity,
    ScrollView,
    RefreshControl,
    ImageBackground,
    TextInput,
    ActivityIndicator,
    StatusBar,
    Linking,
    Dimensions,
    Image
} from 'react-native';
// import Icon from 'react-native-vector-icons/FontAwesome';
// import FontAwesomeIcon from 'react-native-vector-icons/dist/FontAwesome5';
import { borderColor, inputBackgound, primarybg, serverURL, shadowColor } from '../../../utils/string';
import { useMediaQuery } from 'react-responsive'
import Login from '../account/login';
import { AboutUsContext, HomeContext } from '../../../utils/contexts';
import { PerfectInput, RoundButton } from '../../../utils/utils';
import { IoCall, IoLogoWhatsapp, IoChatbox, IoChatboxSharp, IoSearchOutline } from "react-icons/io5";
import SearchModal from '../templateUtils/searchModal';
import Swiper from "react-native-web-swiper";
// import { FontAwesome } from '@expo/vector-icons';

const Icon = () => (<></>);
// let Icon = FontAwesomeIcon;


export default function ProfileHeader1(props) {

    const refRBSheet = useRef();
    const optionsRef = useRef();
    const [RITypeNumber, setRITypeNumber] = useState(0);
    const [RIType, setRIType] = useState('');
    const [RIComment, setRIComment] = useState('');
    const [reportSending, setreportSending] = useState(false);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const source =
        'https://images.unsplash.com/photo-1481595357459-84468f6eeaac?dpr=1&auto=format&fit=crop&w=376&h=251&q=60&cs=tinysrgb';

    const [bagVisible, setbagVisible] = useState(false);
    const [loginVisible, setloginVisible] = useState(false);
    const [searchModal, setsearchModal] = useState(false);


    const { data, searchText, setsearchText, primaryColour } = useContext(typeof props.AboutUs == 'undefined' ? HomeContext : AboutUsContext);


    // address: "Bbbb"
    // ads_count: 4
    // city: "Bhalwal"
    // description: "We sell this and that...inbox me for details\r\nAgr ap apnay products online baichna chahtay bilkul freevvbb"
    // dp: "/media/profile_images/profile_1_djFsrp2.jpg"
    // dukan_title1: ""
    // dukan_title3: ""
    // followers_count: 0
    // following_count: 2
    // is_buyer: "true"
    // is_reseller: "true"
    // is_seller: "true"
    // same_dukan: 0
    // show_number_on_chats: "true"
    // user_following: 0
    // user_name: "Dukaa"

    return (
        < >
            {/* <Bag visible={bagVisible} setvisible={setbagVisible} /> */}
            <Login visible={loginVisible} setvisible={setloginVisible} primaryColour={primaryColour} />
            {/* <View style={[
                { flexDirection: 'row', marginHorizontal: 150 },
                isDesktopOrLaptop ? { marginHorizontal: '10%' } : {}
            ]}>
                <View style={{ height: 50, flex: 0.6, backgroundColor: primarybg, alignItems: 'flex-start' }}>
                    <Text style={{ fontWeight: 'bold', color: primaryColour, fontSize: 20, elevation: 10, }}>Dukan6</Text>
                    <Text style={{ fontWeight: 'bold', color: 'gray', marginLeft: 10, fontSize: 10 }}>by PakNeed</Text>
                </View>
                <View style={{ flex: 0.17, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }} >
                    <Text style={{ fontWeight: 'bold', color: primaryColour, fontSize: 14, elevation: 10, }}>About us</Text>
                    <Text style={{ fontWeight: 'bold', color: primaryColour, fontSize: 14, elevation: 10, }}>Trust Scores</Text>
                    <Text style={{ fontWeight: 'bold', color: primaryColour, fontSize: 14, elevation: 10, }}>Needs</Text>
                </View>
                <View style={{ flex: 0.2, marginLeft: 70, alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                    <Icon name="shopping-bag" size={25} color={primaryColour} style={{ marginHorizontal: 20 }}
                        onPress={() => setbagVisible(!bagVisible)}
                    />
                    <Icon name="user-alt" size={25} color={primaryColour} style={{}}
                        onPress={() => setloginVisible(!loginVisible)}
                    />
                </View>

            </View> */}
            {/* {isDesktopOrLaptop ? null :
                <View style={{
                    alignItems: 'center', backgroundColor: primarybg, height: 40, flexDirection: 'row', justifyContent: 'space-between',
                    borderWidth: 1, borderColor: borderColor, paddingRight: 10, paddingLeft: 10
                }}>
                    <Text style={{ fontWeight: 'bold', color: primaryColour }}>Made with bazars.pk</Text>
                    <RoundButton text='Download App'
                        onPress={() => {
                            Linking.openURL('https://play.google.com/store/apps/details?id=com.pakneed')
                        }}
                        style={{ paddingHorizontal: 10, borderRadius: 20, backgroundColor: primaryColour }} />
                </View>
            } */}
            <SearchModal primaryColour={primaryColour} searchModal={searchModal} setsearchModal={setsearchModal} />
            {!isDesktopOrLaptop &&
                <View style={{ flexDirection: 'row', paddingBottom: 10, paddingTop: 7, paddingHorizontal: 10, justifyContent: 'space-between', alignItems: 'center' }}>
                    <TouchableOpacity
                        onPress={() => {
                            setsearchModal(!searchModal)
                        }}
                        style={{
                            flex: 0.2,
                            margin: 5,
                        }}>
                        <IoSearchOutline
                            color={primaryColour}
                            size={21}
                            style={{ marginTop: 2 }}
                        />
                    </TouchableOpacity>

                    <Text

                        style={{
                            color: primaryColour,
                            // fontWeight: '500',
                            fontSize: 20,
                            fontWeight: 'bold',
                            letterSpacing: 1.7,
                            flex: 0.5,
                            textTransform: 'capitalize',
                            textAlign: 'center'
                        }}>
                        <div class={'animate__animated animate__backInDown animate__delay-1s'}>
                            {data.user_name}
                            {'  '}
                        </div>

                    </Text>
                    <View style={{ flex: 0.3, flexDirection: 'row' }}>
                        {data.show_number_on_chats != 'false' && data.dukan_contact_number != '' ?
                            <>
                                <TouchableOpacity
                                    onPress={() => {
                                        Linking.openURL(`tel:${data.dukan_contact_number}`);
                                    }}
                                    style={{

                                        margin: 5,
                                    }}>
                                    <IoCall
                                        color={primaryColour}
                                        size={21}
                                        style={{ marginTop: 2 }}
                                    />
                                </TouchableOpacity>

                                <TouchableOpacity
                                    onPress={() => {
                                        // Linking.openURL(
                                        //     `sms:${data.mobile_number}${Platform.OS === 'ios' ? '&' : '?'
                                        //     }body=${'PakNeed: \n'}`,
                                        // );
                                        Linking.openURL(
                                            'https://api.whatsapp.com/send?phone=+92' + data.dukan_contact_number.substring(1) + '&text=' + data.user_name + ':\n'
                                        )
                                    }}
                                    style={{

                                        margin: 5,
                                    }}>

                                    <IoLogoWhatsapp
                                        color={primaryColour}
                                        size={23}
                                    // style={{ marginTop: 2 }}
                                    />
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => {
                                        Linking.openURL('https://play.google.com/store/apps/details?id=com.pakneed')
                                    }}

                                    style={{

                                        margin: 5,

                                    }}>
                                    <IoChatboxSharp
                                        color={primaryColour}
                                        size={24}
                                    />
                                </TouchableOpacity>
                            </> : null}
                    </View>
                </View>
            }
            {Object.keys(data).length !== 0 &&
                <>{

                    data.banners.banner1 != '' || data.banners.banner2 != '' || data.banners.banner3 != '' ?
                        <View style={{
                            height: isDesktopOrLaptop ? Dimensions.get('window').width * 0.5 : Dimensions.get('window').width * 0.6,
                            backgroundColor: '#f1f1f1', marginTop: isDesktopOrLaptop ? -30 : 0, zIndex: -1
                        }}>
                            <Swiper
                                // from={1}
                                minDistanceForAction={0.1}
                                loop={true}
                                timeout={5}
                                springConfig={{ speed: 7, bounciness: 0, }}
                                controlsProps={{
                                    dotsTouchable: true,
                                    prevPos: 'left',
                                    nextPos: 'right',
                                    nextTitle: '',
                                    dotActiveStyle: { color: 'black', backgroundColor: 'black' },
                                    nextTitleStyle: { color: primaryColour, fontSize: 24, fontWeight: '500' },
                                    PrevComponent: ({ onPress }) => (
                                        <TouchableOpacity onPress={onPress}>
                                            <Text style={{ color: primaryColour, fontSize: 24, fontWeight: '500' }}>
                                                {/* {'<'} */}
                                            </Text>
                                        </TouchableOpacity>
                                    ),
                                }}
                            >
                                {data.banners.banner1 !== '' &&
                                    <View style={{ height: '100%', backgroundColor: '#f1f1f1', justifyContent: 'center' }}>
                                        <Image
                                            style={{ height: '100%', width: '100%', backgroundColor: 'black' }}
                                            imageStyle={{ opacity: 0.9 }}
                                            // resizeMode="contain"
                                            source={serverURL + data.banners.banner1}
                                        />
                                    </View>
                                }
                                {data.banners.banner2 !== '' &&
                                    <View style={{ height: '100%', backgroundColor: '#f1f1f1', justifyContent: 'center' }}>
                                        <Image
                                            style={{ height: '100%', width: '100%' }}
                                            // resizeMode="contain"
                                            source={serverURL + data.banners.banner2}
                                        />
                                    </View>
                                }
                                {data.banners.banner3 !== '' &&
                                    <View style={{ height: '100%', backgroundColor: '#f1f1f1', justifyContent: 'center' }}>
                                        <Image
                                            style={{ height: '100%', width: '100%' }}
                                            // resizeMode="contain"
                                            source={serverURL + data.banners.banner3}
                                        />
                                    </View>
                                }


                            </Swiper>

                        </View>
                        :
                        <ImageBackground
                            style={{
                                // backgroundColor: primarybg,
                                backgroundColor: 'black',
                                height: isDesktopOrLaptop ? Dimensions.get('window').height / 1.7 : 180,
                                width: '100%',
                                // flex: 1,
                                flexDirection: 'column',
                                paddingTop: StatusBar.currentHeight,
                                paddingHorizontal: isDesktopOrLaptop ? 50 : 0

                            }}
                            imageStyle={{
                                // backgroundColor: 'black',
                                // opacity: 0.7,
                                height: isDesktopOrLaptop ? '100%' : 180

                            }}
                            resizeMode="cover"
                            source={data.dp !== 'null' ? serverURL + data.dp : '/static/dukanDefault.jpg'}
                        />
                }
                </>
            }

        </>
    );
}

