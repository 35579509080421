import React, { useState } from 'react';
import {
    View,
    Text,
    Image,
    Alert,
    Dimensions,
    ImageBackground,
    Modal,
    FlatList, TouchableOpacity, Picker
} from 'react-native';
import { inputBackgound, lightTextColor, primarybg, primaryColour, primaryLightColor, primaryLightColorButton, serverURL, shadowColor } from './string';


const Icon = () => (<></>);

const dimensions = Dimensions.get('window');


export default function DropDown(props) {
    const [showDropDown, setshowDropDown] = useState(false);
    const { selected, setSelected, label, data, style, labelColor } = props;

    return (

        <View style={[{
            backgroundColor: inputBackgound,
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            // margin: 12,
            padding: 7,
            paddingHorizontal: 10,
            shadowColor: shadowColor,
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,

            elevation: 5,
            alignContent: 'center',
            borderRadius: 5,
            borderColor: 'lightgray',
            borderBottomWidth: 0,
            color: primaryColour,
            marginBottom: 10,
            marginTop: 10,
        }, typeof style != 'undefined' && style]}>
            <Picker
                selectedValue={selected}
                onValueChange={(itemValue, itemIndex) => {
                    if (typeof props.onPress !== 'undefined') {
                        props.onPress(itemValue)
                    } else {
                        setSelected(itemValue);
                    }
                }}
                style={{
                    width: '100%', borderWidth: 0, height: '100%', backgroundColor: 'transparent', color: primaryColour, outlineStyle: 'none',
                    color: typeof labelColor != 'undefined' ? labelColor : primaryColour
                }}
            >

                {
                    data.map(t => {
                        if (t == data[0]) {
                            return (
                                <>
                                    <Picker.Item color="white" enabled={false} label={label} />
                                    <Picker.Item color={primaryColour} value={'' + t} label={'' + t} />
                                </>
                            )
                        } else {
                            return (
                                <Picker.Item color={primaryColour} value={'' + t} label={'' + t} />
                            )
                        }
                    })
                }
            </Picker>
        </View >

    )
}