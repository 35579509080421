import { StyleSheet, Text, TouchableOpacity, View, ScrollView, Image, FlatList, Dimensions } from "react-native";
import { primarybg, primaryColour, serverURL, shadowColor } from "../../../utils/string";
import { useMediaQuery } from 'react-responsive'
import { LoadingIndicator, RoundButton } from "../../../utils/utils";
// import FontAwesomeIcon from 'react-native-vector-icons/dist/FontAwesome5';
import ImageLoad from "../../../utils/imageLoad";
import Tab from "../tab";
import { useEffect, useState } from "react";
import { getPrimaryColor, placedOrders } from "../../../utils/api";
import { useNavigate, useParams } from "react-router-dom";
import '../templateUtils/loader.css'
// import HomeTabs from './pages/homeTabs';

// let Icon = FontAwesomeIcon;

function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export default function Orders1(props) {
    const navigate = useNavigate();
    const { dukanId } = useParams();
    const { primaryColour, secondaryColor } = props

    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const { visible, setvisible } = props

    // const { dukanId } = props.route.params

    const [messages, setmessages] = useState([]);
    const [orders, setorders] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const [pageLoaded, setpageLoaded] = useState(false);

    useEffect(() => {
        if (primaryColour != '#5893D4aa') {
            setpageLoaded(true)
        }
    }, [primaryColour])

    const month = [
        'Jan',
        'Fab',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    useEffect(() => {
        placedOrders(setorders, setisLoading, navigate, setmessages)
    }, [])

    return (
        <>
            {!pageLoaded ?
                <View style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: Dimensions.get('window').height }}>
                    <div className="loadingio-spinner-chunk-nx0mjvmz269"><div className="ldio-q1sjvrz6fl">
                        <div><div><div></div><div></div><div></div><div></div></div></div>
                    </div></div>
                </View>
                :
                <Tab primaryColour={primaryColour} secondaryColor={secondaryColor}>
                    <View style={[{
                        flexDirection: 'column', paddingHorizontal: '15%', marginVertical: 30,
                        marginHorizontal: '10%',
                        height: Dimensions.get('window').height - 70,
                        backgroundColor: primarybg,
                        borderColor: '#f0ede9',
                        borderWidth: 0.2,
                        paddingTop: 20,
                        shadowColor: "#000",
                        shadowOffset: {
                            width: 0,
                            height: 2,
                        },
                        shadowOpacity: 0.23,
                        shadowRadius: 2.62,

                        elevation: 4,
                    },
                    !isDesktopOrLaptop ?
                        { marginHorizontal: '1%', paddingHorizontal: '1%', marginVertical: 3, shadowColor: primarybg, borderWidth: 0 } : {}
                    ]}>
                        <Text style={{ fontSize: 23, fontWeight: '600', textAlign: 'left', color: primaryColour }}>Order history</Text>
                        {/* <Text
                    style={{
                        fontWeight: 'bold',
                        fontSize: 15,
                        color: primaryColour,
                        marginTop: 10,
                        textAlign: 'left',

                    }}>
                    Account No:
                </Text> */}
                        {/* <Text style={{ textAlign: 'left', color: primaryColour }}></Text> */}
                        <Text
                            style={{
                                fontWeight: 'bold',
                                fontSize: 15,
                                color: primaryColour,
                                marginTop: 10,
                                textAlign: 'left',

                            }}>
                            Orders:
                        </Text>
                        {!isLoading ?
                            <FlatList
                                contentContainerStyle={{ marginTop: 10, justifyContent: 'center', paddingBottom: 20 }}
                                data={orders}
                                renderItem={({ item }) => (
                                    <TouchableOpacity style={{
                                        marginHorizontal: 10,
                                        marginVertical: 10,
                                        shadowColor: "#000",
                                        shadowOffset: {
                                            width: 0,
                                            height: 1,
                                        },
                                        shadowOpacity: 0.22,
                                        shadowRadius: 2.22,
                                        elevation: 3,
                                        borderWidth: 0.2,
                                        borderColor: '#f0ede9',
                                        width: '90%',
                                        paddingHorizontal: 10,
                                        paddingVertical: 5,
                                        borderRadius: 6,
                                        flexDirection: 'row',
                                        flexWrap: 'wrap'

                                    }}
                                        onPress={() => {
                                            navigate('/' + window.location.pathname.substring(1).split('/')[0] + '/order-details/' + item.id)
                                        }}
                                    >
                                        <ImageLoad
                                            style={{
                                                // marginTop: 20,
                                                // height: 40,
                                                borderRadius: 7,
                                                flex: 0.15,
                                                marginTop: 2,
                                                // alignSelf: 'center',
                                                marginRight: 7
                                                // borderBottomLeftRadius: 0,
                                                // borderBottomRightRadius: 0,
                                                // backgroundColor: '#f0f0f0'
                                            }}
                                            imageStyle={{
                                                // flex: 1,
                                                borderRadius: 5,
                                                // height: 30,
                                            }}
                                            resizeMode="cover"
                                            source={serverURL + item.thumbnail}
                                        />
                                        <View style={{ flex: 0.85 }}>
                                            <View style={{ flexDirection: 'row' }}>
                                                <Text style={{ textAlign: 'left', fontWeight: 'bold', flex: 0.5, color: primaryColour, flexShrink: 1 }}>

                                                    {item.title.length > 42 ? String(item.title.substring(0, 42) + '...').replace(/\n/g, " ") : String(item.title.replace(/\n/g, " "))}

                                                </Text>
                                                <Text style={{ textAlign: 'right', fontWeight: 'bold', flex: 0.5, color: primaryColour }}>Rs. {item.price}</Text>
                                            </View>
                                            <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                                <Text style={{ textAlign: 'left', flex: 0.6, color: primaryColour }}>
                                                    {item.date}
                                                    {/* {formatAMPM(item.date)}
                                        {typeof item.date !== 'undefined' ? item.date.split('-')[2].split('T')[0] : null}{' '}
                                        {typeof item.date !== 'undefined' ? month[parseInt(item.date.split('-')[1], 10) - 1] : null}{' '}
                                        {typeof item.date !== 'undefined' ? item.date.split('-')[2].split('T')[1].split(':')[0] : null}:
                                        {typeof item.date !== 'undefined' ? item.date.split('-')[2].split('T')[1].split(':')[1] : null} */}
                                                </Text>
                                                <Text style={{ textAlign: 'right', flex: 0.4, color: primaryColour }}>Status : {item.status}</Text>
                                            </View>
                                            <View style={{ flexDirection: 'row' }}>
                                                {/* <Text style={{ textAlign: 'left', flex: 0.8, color: primaryColour }}>Order # {item.number}</Text> */}
                                            </View>
                                        </View>

                                    </TouchableOpacity>
                                )} />
                            : <LoadingIndicator />}
                    </View>


                </Tab>
            }
        </>
    );
}

