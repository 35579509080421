
import firebase from 'firebase';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCdBCYw1o3nlD850hnhJ0S4slqvHceV9T0",
    authDomain: "bazarspk-web.firebaseapp.com",
    projectId: "bazarspk-web",
    storageBucket: "bazarspk-web.appspot.com",
    messagingSenderId: "127909090511",
    appId: "1:127909090511:web:7274f2686f1de19fb3d3ba",
    measurementId: "${config.measurementId}"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
} else {
    firebase.app(); // if already initialized, use that one
}
var auth = firebase.auth();
export { auth, firebase };