import * as React from 'react';
import { useState, useEffect } from 'react';
import { ActivityIndicator, Button, Dimensions, FlatList, ImageBackground, Linking, View } from 'react-native';
import { primaryColour, secondarybg, } from '../../utils/string';
import { RoundButton } from '../../utils/utils';
import { getStoreDetailsForCustomization, setStoreDesign } from '../../utils/api';
import { useNavigate } from "react-router-dom";
import Sidebar from '../sidebar';
import { useMediaQuery } from 'react-responsive';

export default function Themes(props) {
    const move = useNavigate();
    const [isLoading, setisLoading] = useState(false);
    // const { premiumUser, storeDesignId } = props.route.params
    const [applyingDesign, setapplyingDesign] = useState(1);
    const [appliedDesign, setappliedDesign] = useState(1);
    const [data, setdata] = useState('');
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const [premiumUser, setpremiumUser] = useState(1);

    useEffect(() => {
        // setappliedDesign(storeDesignId)
        getStoreDetailsForCustomization(move, setisLoading, setdata)

    }, [])

    useEffect(() => {
        setappliedDesign(data.store_design_id)
        setpremiumUser(data.premium_user)
        console.log(data, 'dddddddddd')
    }, [data])


    return (
        <Sidebar active='themes' notScrollView={true} title={'Themes'}>
            <FlatList
                numColumns={isDesktopOrLaptop ? 3 : 1}
                data={[1, 2, 3]}
                contentContainerStyle={{ width: !isDesktopOrLaptop && Dimensions.get('window').width, paddingHorizontal: '3%', paddingBottom: 10 }}

                columnWrapperStyle={isDesktopOrLaptop && { justifyContent: 'space-between' }}
                renderItem={({ item, index }) => (
                    <View style={{ width: isDesktopOrLaptop ? '30%' : Dimensions.get('window').width - 20 }}>
                        <ImageBackground
                            source={item == 1 ? require("../../assets/designs/1.png")
                                : item == 2 ? require("../../assets/designs/2.png")
                                    : item == 3 && require("../../assets/designs/3.png")
                            }
                            style={{
                                width: '100%', height: isDesktopOrLaptop ? 300 : Dimensions.get('window').width / 1.5,
                                borderWidth: 0, marginTop: 20, paddingTop: 10, elevation: 5, backgroundColor: 'black'
                            }}
                            imageStyle={{ opacity: 0.9 }}
                            resizeMethod='scale'
                            resizeMode='center'
                        />

                        {/* <Text style={{
                            color: primaryColour, fontSize: 16, fontWeight: '600', letterSpacing: 1.5,
                            paddingVertical: 0, textAlign: 'center', backgroundColor: primarybg, width: '40%', alignSelf: 'center', borderRadius: 5
                        }}>{item == 1 ? 'Razzle' : item == 2 ? 'Classic' : 'Shopping Pro'}</Text> */}
                        {/* // <View style={{ flex: 1 }} /> */}

                        {isLoading && applyingDesign == item && <ActivityIndicator color={primaryColour} size={'large'} />}
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 10, paddingTop: 5, backgroundColor: primaryColour }}>
                            {appliedDesign == item &&
                                <RoundButton
                                    style={{ flex: 0.3, height: 30, borderRadius: 25, backgroundColor: secondarybg, borderColor: primaryColour }}
                                    // textStyle={{ color: primaryColour }}
                                    text='Customize'
                                    onPress={() => {
                                        move.navigate('CustomizeWebsite')
                                    }}
                                />}
                            <RoundButton
                                style={{ flex: appliedDesign == item ? 0.3 : 0.4, height: 30, backgroundColor: secondarybg, borderRadius: 25, borderColor: primaryColour }}
                                text='View Demo'
                                onPress={() => {
                                    Linking.openURL(item == 1 ? 'https://templates.bazars.pk/fashiondot'
                                        : item == 2 ? 'https://templates.bazars.pk/minfashion' :
                                            'https://templates.bazars.pk/fashion')
                                }}
                            />
                            {appliedDesign == item ?
                                <RoundButton style={{ flex: 0.3, borderRadius: 25, height: 30, backgroundColor: 'lightgray', borderColor: primaryColour }} text='Applied'
                                // onPress={() => setStoreDesign(props, setisLoading, item)}
                                /> :
                                premiumUser == 1 ?
                                    <RoundButton style={{ flex: appliedDesign == item ? 0.3 : 0.4, borderRadius: 25, height: 30 }} text='Apply'
                                        onPress={() => { setStoreDesign(move, setisLoading, item, setappliedDesign); setapplyingDesign(item) }}
                                    />
                                    :
                                    <RoundButton style={{ flex: appliedDesign == item ? 0.3 : 0.4, borderRadius: 5, height: 30, borderRadius: 25 }}
                                        text='Subscribe Premium' textStyle={{ textAlign: 'center', fontSize: 12 }}
                                        onPress={() => { Linking.openURL('https://api.whatsapp.com/send?phone=+923308080009&text=I want to subscribe premium plan') }}
                                    />
                            }
                        </View>
                    </View>
                )}
            />
        </Sidebar>
    )
}