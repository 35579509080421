import React, { useEffect, useState } from 'react';
import {
    StyleSheet,
    View,
    Text,
    TouchableOpacity,
    ScrollView,
    Dimensions,
    FlatList,
    RefreshControl,
} from 'react-native';
import { cityList, primarybg, primaryColour, serverURL, shadowColor } from '../../../utils/string';
import { notify, PerfectInput, RoundButton } from '../../../utils/utils';
import ShowLocations from '../../../utils/showLocations';
import { useMediaQuery } from 'react-responsive';
import Tab from '../tab';
import { getPrimaryColor, placeOrder, productsFromArray } from '../../../utils/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import PerfectModal from '../../../utils/perfectModel';
import { useNavigate, useParams } from 'react-router-dom';
import '../templateUtils/loader.css'

export default function Checkout4(props) {
    const navigate = useNavigate();
    const { dukanId } = useParams();
    const { primaryColour, secondaryColor } = props

    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    // const isFocused = useIsFocused();
    // const { dukanId } = props.route.params


    const [bagProductsId, setbagProductsId] = useState([]);
    const [bagProducts, setbagProducts] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [prevIds, setprevIds] = useState([]);
    const [loadMore, setloadMore] = useState(false);
    const [bag, setbag] = useState([]);
    const [quantityKey, setquantityKey] = useState(Math.random());

    const [data, setdata] = useState('');
    const [city, setcity] = useState('');
    const [province, setprovince] = useState('');
    const [area, setarea] = useState('');
    const [streetAddress, setstreetAddress] = useState('');
    const [fullName, setfullName] = useState('');
    const [contactNumber, setcontactNumber] = useState('');

    const [messages, setmessages] = useState([]);
    const [messagesModal, setmessagesModal] = useState(false);

    const [pageLoaded, setpageLoaded] = useState(false);

    useEffect(() => {
        if (primaryColour != '#5893D4aa') {
            setpageLoaded(true)
        }
    }, [primaryColour])

    const getProductIds = async () => {
        const ba = await AsyncStorage.getItem('productsForCheckout')
        if (ba !== null) {
            let bagArray = JSON.parse(ba)
            console.log(bagArray)
            setbag(bagArray);
            setbagProductsId(bagArray.map(a => a.id))
        }
    }

    // can_resell: 1
    // city: "Abbottabad"
    // cover: "/media/product_thumbnail/thumbnail_604bb79b190a45b2b587fa11843fca1f.jpg"
    // id: 57
    // is_owner_of_product: 0
    // liked: 0
    // price: "2500"
    // quantity: 3
    // shipping: 0
    // state: "Azad Kashmir"
    // title: "PakNeed key application ap Playstore say download kr sktay hain. Is"
    // user_id: 2

    useEffect(() => {
        getProductIds();
    }, []);

    useEffect(() => {
        productsFromArray(
            setbagProducts, setisLoading, navigate, bag
        )
    }, [bagProductsId])

    useEffect(() => {
        if (messages.length > 0) {
            setmessagesModal(true)
        }
    }, [messages])



    return (
        <>
            {!pageLoaded ?
                <View style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: Dimensions.get('window').height }}>
                    <div className="loadingio-spinner-chunk-nx0mjvmz269"><div className="ldio-q1sjvrz6fl">
                        <div><div><div></div><div></div><div></div><div></div></div></div>
                    </div></div>
                </View>
                :
                <Tab primaryColour={primaryColour} secondaryColor={secondaryColor}>
                    <View style={[{
                        flexDirection: 'column', paddingHorizontal: '15%', marginVertical: 30,
                        marginHorizontal: '10%',
                        height: Dimensions.get('window').height - 70,
                        backgroundColor: primarybg,
                        borderColor: '#f0ede9',
                        borderWidth: 0.2,
                        paddingTop: 20,
                        shadowColor: "#000",
                        shadowOffset: {
                            width: 0,
                            height: 2,
                        },
                        shadowOpacity: 0.23,
                        shadowRadius: 2.62,

                        elevation: 4,
                    },
                    !isDesktopOrLaptop ?
                        { marginHorizontal: '0%', paddingHorizontal: '3%', marginVertical: 3, shadowColor: primarybg, borderWidth: 0 } : {}
                    ]}>

                        <Text style={{ fontSize: 23, fontWeight: '600', textAlign: 'left', color: primaryColour }}>Checkout</Text>
                        <View style={{ flexWrap: "wrap", marginTop: 20 }}>
                            <Text style={styles.title}>Full Name </Text>
                            <PerfectInput value={fullName} setvalue={setfullName} maxLength={250} placeholder='Enter your full name'
                                style={isDesktopOrLaptop ? { width: '90%' } : {}}
                            />
                            <Text style={styles.title}>Contact Number </Text>
                            <PerfectInput value={contactNumber} setvalue={setcontactNumber} maxLength={250} placeholder='Enter your contact number'
                                style={isDesktopOrLaptop ? { width: '90%' } : {}}
                            />
                            {/* <View style={
                        isDesktopOrLaptop ? {
                            width: dimensions.width / 2 - 115,
                        } : { flex: 1 }
                    }>
                        
                    </View> */}
                            <View style={
                                isDesktopOrLaptop ? {
                                    width: dimensions.width / 2 - 115,
                                } : { flex: 1 }}
                                key={province}
                            >

                                <ShowLocations
                                    province={province} setprovince={setprovince}
                                    city={city} setcity={setcity}
                                    area={area} setarea={setarea}
                                    cityLabel='Please select province first'
                                    notAll
                                // notArea
                                />
                            </View>
                            <Text style={styles.title}>Address</Text>
                            <PerfectInput value={streetAddress} setvalue={setstreetAddress} maxLength={250} placeholder='Enter your street address'
                                style={isDesktopOrLaptop ? { width: '90%' } : {}}
                            />
                            <View style={{ flexDirection: 'row', width: '90%' }}>
                                <Text style={{ textAlign: 'left', flex: 0.5, fontWeight: 'bold', color: primaryColour }}>Products</Text>
                                <Text style={{ textAlign: 'right', flex: 0.5, color: primaryColour }}>
                                    {bagProducts.length}
                                </Text>
                            </View>
                            <View style={{ flexDirection: 'row', width: '90%' }}>
                                <Text style={{ textAlign: 'left', flex: 0.5, fontWeight: 'bold', color: primaryColour }}>Subtotal</Text>
                                <Text style={{ textAlign: 'right', flex: 0.5, color: primaryColour }}>Rs.
                                    {
                                        bagProducts.map(x => parseInt(x.price) * bag.find(y => y.id == x.id).quantity).reduce((a, b) => a + b, 0)
                                    }
                                </Text>
                            </View>
                            <View style={{ flexDirection: 'row', width: '90%' }}>
                                <Text style={{ textAlign: 'left', flex: 0.5, fontWeight: 'bold', color: primaryColour }}>Delivery </Text>
                                <Text style={{ textAlign: 'right', flex: 0.5, color: primaryColour }}>Rs.
                                    {
                                        bagProducts.map(x => parseInt(x.shipping) * bag.find(y => y.id == x.id).quantity).reduce((a, b) => a + b, 0)
                                    }
                                </Text>
                            </View>
                            <View style={{ flexDirection: 'row', width: '90%' }}>
                                <Text style={{ textAlign: 'left', flex: 0.5, fontWeight: 'bold', color: primaryColour }}>Total</Text>
                                <Text style={{ textAlign: 'right', flex: 0.5, color: primaryColour }}>Rs.
                                    {/* {console.log(bagProducts.map(x => x.id), 'usamaaaaaaaaaaaa', bag.find(y => y.id == 114))} */}
                                    {bagProducts.length > 0 &&
                                        console.log(bagProducts.map(x => x.id), 'usamaaaaaaaaaaaa', bag.find(y => y.id == 114), 'mmmm', bagProducts.length)}
                                    {bagProducts.length > 0 &&
                                        bagProducts.map(x => parseInt(x.price) * bag.find(y => y.id == x.id).quantity).reduce((a, b) => a + b, 0) +
                                        bagProducts.map(x => x.shipping * bag.find(y => y.id == x.id).quantity).reduce((a, b) => a + b, 0)
                                    }
                                </Text>
                            </View>
                            <View style={{ flexDirection: 'row', width: '90%' }}>
                                <Text style={{ textAlign: 'left', flex: 0.5, fontWeight: 'bold', color: primaryColour }}>Payment method</Text>
                                <Text style={{ textAlign: 'right', flex: 0.5, color: primaryColour }}>Cash on delivery</Text>
                            </View>
                            <View style={isDesktopOrLaptop ? { width: '90%', marginTop: 10 } : { width: '100%', marginTop: 10 }}>
                                <RoundButton text='Place order' style={isDesktopOrLaptop ? { backgroundColor: primaryColour, width: '20%', alignSelf: 'center' } : { backgroundColor: primaryColour, width: '40%', alignSelf: 'center' }}
                                    onPress={() => {

                                        if (fullName == '') { notify('Please add title'); return; }
                                        if (contactNumber == '') { notify('Please add contact number'); return; }
                                        if (province == '') { notify('Please select province'); return; }
                                        if (city == '') { notify('Please add '); return; }
                                        if (area == '') { notify('Please add '); return; }
                                        if (streetAddress == '') { notify('Please add '); return; }
                                        if (bagProducts.length < 1) { notify('Please add at least one product in bag '); return; }
                                        placeOrder(fullName, contactNumber, province, city, area, streetAddress, JSON.stringify(bag), 'COD', setdata, setisLoading, navigate, setmessages)
                                    }}
                                />
                            </View>
                            <PerfectModal
                                modalVisible={messagesModal}
                                style={[isDesktopOrLaptop ? { width: '30%', margin: 0, padding: 20, paddingBottom: 3, borderColor: primarybg, borderWidth: 2, borderRadius: 2 } : {}]}
                            >
                                {messages.map(i => {
                                    return (
                                        <Text style={{ fontWeight: 'bold', fontSize: 16, borderBottomWidth: 1, borderColor: '#f0ede9', marginVertical: 10 }}>{i}</Text>
                                    )
                                })}
                                <RoundButton
                                    text='Close'
                                    onPress={() => {
                                        setmessagesModal(false);
                                        navigate('/' + dukanId + '/Orders')
                                    }} style={{ width: '50%', alignSelf: 'center', marginTop: 15, backgroundColor: primaryColour }} />

                            </PerfectModal>
                            <View style={{ height: 40 }} />
                        </View>
                    </View>
                </Tab>
            }
        </>
    )
}



const dimensions = Dimensions.get('window');
const styles = StyleSheet.create({
    title: {
        textAlign: 'left',
        color: primaryColour
    },
    container: {
        flex: 1,
        backgroundColor: primarybg,
        flexDirection: 'column',
        height: '100%',
        // padding: 10,
        paddingTop: 20,
        paddingHorizontal: 20
    },
    mediaImages: {
        width: dimensions.width / 3 - 77,
        height: dimensions.width / 3 - 77,
        borderRadius: 10,
        marginRight: 3,
        marginBottom: 3,
    },
    mediaImages2: {
        width: '100%',
        height: '70%',
        borderRadius: 20,
    },
});