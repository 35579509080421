import { StyleSheet, Text, TouchableOpacity, View, ScrollView, Image, FlatList, ImageBackground, Dimensions, TextInput, Platform, ActivityIndicator, Keyboard, } from "react-native";
import { primarybg, shadowColor, primaryColour, textColor } from "../../../utils/string";
import { useMediaQuery } from 'react-responsive'
import PerfectModal from "../../../utils/perfectModel";
import { RoundButton } from "../../../utils/utils";
import { useEffect, useState } from "react";
import { verifyOTP, verifyOTPWithFirebase } from "../../../utils/api";
import { IoCall, } from 'react-icons/io5';

const Icon = () => (<></>);

export default function OTPVerification(props) {
    // const move = useLinkTo();
    const { visible, setvisible, contactNo, final } = props
    let verificationId = final.verificationId
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })


    // const [contactNo, setContactNo] = useState('');
    const [privacyCheckBox, setprivacyCheckBox] = useState(true);
    const [error, setError] = useState('');
    const [data, setData] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const [code, setCode] = useState('');
    const { primaryColour, secondaryColor } = props


    return (


        <PerfectModal
            modalVisible={visible}
            onRequestClose={() => {
                setvisible(false)
            }}


            // height={isDesktopOrLaptop ? '80%' : Dimensions.get('window').height - 30}
            style={[isDesktopOrLaptop ? { width: '60%', height: 570, margin: 0, padding: 0, } :
                { width: '95%', height: 500, margin: 0, padding: 0, }
                // { width: '100%', margin: 0, padding: 0, height: Dimensions.get('window').height - 30, borderColor: primarybg, borderWidth: 2, borderRadius: 20 }
            ]}
        >

            <View style={isDesktopOrLaptop ? { flexDirection: 'row', } : { flexDirection: 'column', }}>
                <View style={isDesktopOrLaptop ? { flex: 0.5, } : { flex: 0.5 }}>

                    <ImageBackground source='https://images.unsplash.com/photo-1630626988793-c06b32c346a3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=480&q=80'


                        // {serverURL + item.image}

                        imageStyle={

                            isDesktopOrLaptop ? {
                                width: '100%',
                                height: '100%',
                                overflow: 'hidden',
                                justifyContent: "left",
                                width: '100%',

                            } : {

                                width: '100%',
                                height: '100%',
                                overflow: 'hidden',
                                justifyContent: "center",
                                width: '100%',
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30,

                            }
                        }>

                        <View style={isDesktopOrLaptop ? { height: 570, background: 'rgb(0,0,0,0.8)' } : {
                            height: 150, background: 'rgb(0,0,0,0.8)', borderBottomLeftRadius: 30,
                            borderBottomRightRadius: 30,
                        }}>

                            {!isDesktopOrLaptop ?

                                <TouchableOpacity style={{ marginLeft: 273, }}
                                    onPress={() => setvisible(false)}
                                >
                                    <Text style={{ textAlign: 'center', color: textColor }}>
                                        {/* <TabIcon
                                        name="close" size={20} color={textColor}
                                    /> */}
                                        close
                                    </Text>
                                </TouchableOpacity>

                                : null}

                            <View style={isDesktopOrLaptop ? { marginTop: 250, height: 50, flexDirection: 'row', justifyContent: 'center', } : { marginTop: -13, flexDirection: 'row', justifyContent: 'center', }}>
                                <span class='section-title' style={isDesktopOrLaptop ? { justifyContent: 'center', fontFamily: 'sans-serif', fontSize: 35, color: secondaryColor, textTransform: 'capitalize' } : { justifyContent: 'center', textAlign: 'center', marginTop: 10, marginBottom: 5, fontSize: 20, color: secondaryColor }}>Verify your Account</span>

                            </View>
                            <Text
                                style={isDesktopOrLaptop ? {
                                    fontSize: 14,
                                    textAlign: 'center',
                                    marginBottom: 10,
                                    color: secondaryColor,
                                } : {
                                    fontSize: 10,
                                    textAlign: 'center',
                                    marginBottom: 10,
                                    color: secondaryColor,
                                }}>
                                To keep connected with us. <br /> Please login with your personal phone no.
                            </Text>
                        </View>



                    </ImageBackground>




                </View>
                <View style={{ flex: 0.5, }}>

                    <View style={{ width: '100%', }}>

                        {isDesktopOrLaptop ?

                            <TouchableOpacity style={{ textAlign: 'right', marginBottom: 20, position: 'absolute', right: 10, top: 5, }}
                                onPress={() => setvisible(false)}
                            >
                                <Text style={{ textAlign: 'center', }}>
                                    {/* <TabIcon
                                    name="close" size={30} color={primaryColour}
                                /> */}
                                    close
                                </Text>
                            </TouchableOpacity>

                            : null}

                        <View style={isDesktopOrLaptop ? {
                            display: "flex",
                            flexDirection: "vertical",
                            justifyContent: "space-around",
                            alignItems: "center",
                            padding: 10,
                            marginTop: 100,
                        } : {
                            alignItems: "center",
                            marginTop: -40,
                        }}>
                            <Image
                                style={isDesktopOrLaptop ? {
                                    resizeMode: "center",
                                    height: 100,
                                    width: 300
                                } : {
                                    resizeMode: "center",
                                    height: 70,
                                    width: 200
                                }}
                                source='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMCIlibT_fyh3UwzIUzL4zZb_cnsmk0LQoyPVtnv1HebNnAEXJ--wAU8mAL707cL8CtL4&usqp=CAU' />
                        </View>
                        <Text
                            style={{
                                fontSize: 20,
                                textAlign: 'center',
                                marginBottom: 10,
                                marginTop: 10,
                                color: primaryColour,
                                // fontWeight:'bold'
                            }}>
                            Mobile Verification
                        </Text>
                        <Text
                            style={{
                                fontSize: 14,
                                textAlign: 'center',
                                marginBottom: 10,
                                color: 'gray',
                            }}>
                            Please type the verification code sent to your number:
                        </Text>
                        {/* {error ? (
                            <Text
                                style={{
                                    fontSize: 14,
                                    textAlign: 'center',
                                    marginBottom: 10,
                                    color: 'red',
                                }}>
                                {error}
                            </Text>
                        ) : null} */}
                        {/* {this.state.toolTipVisible ? <Text style={{ textAlign: "center" }}>Please enter valid Contact number!! {'\n'} eg. 03123456789</Text> : <Text></Text>} */}

                        <View
                            style={{
                                borderColor: 'lightgray',
                                // backgroundColor: 'white',
                                backgroundColor: 'rgba(247, 223, 242, 0.1)',
                                borderRadius: 30,
                                borderWidth: 1,
                                width: 250,
                                height: 45,
                                marginBottom: 25,
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginTop: 10,
                                alignSelf: 'center'
                            }}>

                            <Text style={{
                                width: 30,
                                height: 30,
                                marginLeft: 15,
                                justifyContent: 'center',
                                color: primaryColour,
                                marginTop: 9,
                                fontWeight: 'bold',
                                fontSize: 16
                            }}>
                                {/* <TabIcon
                                    style={{
                                        width: 30,
                                        height: 30,
                                        marginLeft: 15,
                                        justifyContent: 'center',
                                        color: primaryColour,
                                        marginTop: 5,
                                    }}
                                    name="phone-check"
                                    type="font-awesome-5"
                                    color={primaryColour}
                                    size={22}
                                /> */}

                                <IoCall size={20} color={primaryColour} solid={false} />

                            </Text>

                            <TextInput
                                style={{
                                    height: 45,
                                    marginLeft: 16,
                                    borderBottomColor: primaryColour,
                                    color: primaryColour,
                                }}
                                returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
                                placeholder="Enter OTP"
                                placeholderTextColor={'lightgray'}
                                keyboardType="email-address"
                                underlineColorAndroid="transparent"
                                onChangeText={text => setCode(text.replace(/[^0-9]/g, ''))}
                                value={code}
                                maxLength={11} //setting limit of input
                                keyboardType={'phone-pad'}
                                onSubmitEditing={Keyboard.dismiss}
                            />
                        </View>

                        <TouchableOpacity
                            disabled={!privacyCheckBox}
                            style={[{
                                height: 45,
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignSelf: 'center',
                                marginBottom: 20,
                                width: 100,
                                borderRadius: 30,
                                marginTop: 10,
                                borderColor: secondaryColor,
                                borderWidth: 1,
                            },
                            privacyCheckBox === true ? { backgroundColor: primaryColour } : { backgroundColor: primaryColour }
                            ]}
                            onPress={() => {
                                console.log('kkkkkkkk')
                                verifyOTPWithFirebase(setisLoading, contactNo, code, setError, setvisible, verificationId)
                            }}>
                            {isLoading ? (
                                <View style={{ flex: 0.9 }}>
                                    <ActivityIndicator size="small" color={secondaryColor} />
                                </View>
                            ) : null}
                            <Text style={[
                                { fontWeight: 'bold' },
                                privacyCheckBox === true ? { color: secondaryColor, fontSize: 17, } : { color: secondaryColor, fontSize: 15, }

                            ]}>Verify</Text>
                            {/* <Icon
                    style={[{
                        marginLeft: 15,
                    },
                    privacyCheckBox === true ? { color: primarybg } : { color: primarybg, }
                    ]}
                    name="arrow-right"
                    type="font-awesome-5"
                    color={primarybg}
                    size={15}
                /> */}
                        </TouchableOpacity>
                        {/* <View style={{ flexDirection: 'row', justifyContent: 'center', paddingBottom: '8%', marginTop: 30 }}>
                <Text style={{ textAlign: 'center', color: primarybg, fontWeight: 'bold' }}>Made in Pakistan {' '}</Text>
                <Image
                    style={{
                        width: 24,
                        height: 15,
                        marginTop: 3
                    }}
                    source={'/static/Pakistan.png'}
                />
            </View> */}
                    </View>

                </View>
            </View>


        </PerfectModal>
    );
}

