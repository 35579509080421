import * as React from 'react';
import { useState, useEffect } from 'react';
import { ActivityIndicator, Alert, Button, Dimensions, FlatList, ImageBackground, Linking, RefreshControl, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { borderColor, secondarybg, serverURL } from '../../utils/string';
import { CreateCatalogModal, RoundButton, } from '../../utils/utils';
import { deleteCatalog, dukanCatalogs, } from '../../utils/api';
import { FaShareAlt } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import Sidebar from '../sidebar';
import { PerfectAlert, shadow } from '../utils/main';
import PerfectModal from '../../utils/perfectModel';
import { useMediaQuery } from 'react-responsive';
import { primarybg, primaryColour } from "../utils/string";

export default function MyCatalogs(props) {
    const move = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const [createCatalog, setcreateCatalog] = useState(false);
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState();
    const [deleteAlert, setdeleteAlert] = useState(false);
    const [deletingItem, setdeletingItem] = useState();

    useEffect(() => {
        dukanCatalogs(setisLoading, move, setdata, data)
    }, [])

    useEffect(() => {
        if (!createCatalog) {
            dukanCatalogs(setisLoading, move, setdata, data)
        }
    }, [createCatalog])

    return (
        <>
            <Sidebar active='myCatalogs' notScrollView={true} title={'My Catalogs/ Categories'}>
                <View key={data} style={{ backgroundColor: primarybg, width: !isDesktopOrLaptop && Dimensions.get('window').width }} key={isLoading} >
                    <RoundButton text='Create Catalog/Category'
                        style={{ width: isDesktopOrLaptop ? '20%' : '50%', alignSelf: 'center', marginBottom: 20, borderRadius: 20, backgroundColor: primaryColour }}
                        onPress={() => setcreateCatalog(true)} />

                    <FlatList
                        numColumns={isDesktopOrLaptop ? 2 : 1}
                        data={data}
                        // scrollEnabled={false}
                        refreshControl={
                            <RefreshControl
                                refreshing={isLoading}
                                onRefresh={() => { dukanCatalogs(setisLoading, move, setdata, []) }}
                            />
                        }
                        ListEmptyComponent={() => (!isLoading ? <Text style={{ color: primaryColour, textAlign: 'center' }}>No Categories</Text> : null)}
                        ListFooterComponent={() => (isLoading ? <ActivityIndicator color={primaryColour} /> : null)}
                        contentContainerStyle={[{ paddingHorizontal: '3%', paddingVertical: 20, marginTop: isDesktopOrLaptop ? 20 : 5, marginHorizontal: '5%', }, shadow]}
                        columnWrapperStyle={isDesktopOrLaptop && { justifyContent: 'space-between' }}
                        renderItem={({ item, index }) => (
                            <>
                                <TouchableOpacity style={{
                                    borderWidth: 0.5, backgroundColor: primarybg, marginTop: 0,
                                    paddingVertical: 10, paddingHorizontal: 10,
                                    elevation: 12, borderColor: borderColor, borderRadius: 5, marginBottom: 15,
                                    shadowColor: primaryColour, width: isDesktopOrLaptop ? '47%' : '100%', alignSelf: 'center'
                                }}
                                    onPress={() => move('/catalog-details/' + item.id)}
                                >
                                    <View style={{
                                        flexDirection: 'row',
                                    }}>
                                        <ImageBackground
                                            source={item.image != '' ? { uri: serverURL + item.image } : ''}
                                            style={{
                                                flex: 0.2, height: 70, paddingHorizontal: 20,
                                                borderRadius: 10, borderWidth: item.image == '' ? 1 : 0, borderColor: borderColor
                                            }}
                                            imageStyle={{ borderRadius: 5 }}
                                            resizeMode='cover'
                                        />
                                        <View style={{ flex: 0.8, paddingHorizontal: 10, marginTop: 5 }}>
                                            <Text style={{
                                                color: primaryColour, fontSize: 16, fontWeight: 'bold', letterSpacing: 1.2,
                                                paddingVertical: 0, fontSize: 18
                                            }}>{item.name}</Text>
                                            <Text style={{
                                                color: 'gray', fontSize: 16, fontWeight: '500', letterSpacing: 1,
                                                paddingVertical: 0, fontSize: 13
                                            }}>{item.total_items} items</Text>
                                        </View>


                                    </View>
                                    <View style={{ backgroundColor: borderColor, height: 1, marginVertical: 10 }} />
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <RoundButton text='- Delete' style={{
                                            marginVertical: 0, height: 30, width: '30%', marginBottom: 0, marginTop: 0,
                                            backgroundColor: primaryColour, borderRadius: 20
                                        }}
                                            onPress={() => {
                                                PerfectAlert(
                                                    "",
                                                    "Are you sure you want to remove this catalog?",
                                                    [
                                                        {
                                                            text: "Cancel",
                                                            onPress: () => console.log("Cancel Pressed"),
                                                            style: "cancel"
                                                        },
                                                        {
                                                            text: "Yes", onPress: () => {
                                                                deleteCatalog(setisLoading, move, item.id).then(() => {
                                                                    dukanCatalogs(setisLoading, props, setdata, []);
                                                                    window.location.href = window.location.href
                                                                })
                                                            }
                                                        }
                                                    ],
                                                    { cancelable: true }
                                                );
                                            }}


                                        />
                                        {/* <RoundButton style={{ flexDirection: 'row', marginVertical: 0, height: 30, width: '30%', marginBottom: 0, marginTop: 0 }} >
                                        <FaShareAlt
                                            color={primarybg}
                                            size={18}
                                        />
                                        <Text style={{ fontSize: 16, color: primarybg }}>{'  '}Share</Text>
                                    </RoundButton> */}

                                        <RoundButton text='View details' style={{
                                            marginVertical: 0, height: 30, width: '35%', marginBottom: 0, marginTop: 0,
                                            backgroundColor: primaryColour, borderRadius: 20
                                        }}
                                            onPress={() => move('/catalog-details/' + item.id)}
                                        />

                                    </View>
                                </TouchableOpacity>

                            </>
                        )}
                    />
                    <CreateCatalogModal createCategoryModel={createCatalog} setcreateCategoryModel={setcreateCatalog} style={{ width: isDesktopOrLaptop ? '30%' : '80%' }} />
                    {/* <PerfectModal modalVisible={deleteAlert}
                        style={{ width: isDesktopOrLaptop ? '30%' : '80%' }}
                    >
                        <Text style={{ color: primaryColour, textAlign: 'center', fontWeight: 'bold' }}>
                            Are you sure, You want to delete this catalog/category?
                        </Text>
                        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 20 }}>
                            <RoundButton text='Close' onPress={() => {
                                setdeleteAlert(false)
                            }}
                                style={{ width: 80, height: 30 }}
                            />
                            <RoundButton text='Yes' onPress={() => {
                                deleteCatalog(setisLoading, move, deletingItem.id).then(() => {
                                    dukanCatalogs(setisLoading, move, setdata, [])
                                })
                            }}
                                style={{ width: 80, height: 30, marginLeft: 20 }}
                            />
                        </View>

                    </PerfectModal> */}
                </View>
            </Sidebar>
        </>
    )
}
