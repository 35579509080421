import { StyleSheet, Text, TouchableOpacity, View, ScrollView, Image, FlatList, Dimensions, ImageBackground } from "react-native";
import { primarybg, primaryColour, serverURL, shadowColor, borderColor, textColor } from "../../../utils/string";
import { useMediaQuery } from 'react-responsive'
import { LoadingIndicator, RoundButton } from "../../../utils/utils";
// import FontAwesomeIcon from 'react-native-vector-icons/dist/FontAwesome5';
import ImageLoad from "../../../utils/imageLoad";
import Tab from "../tab";
import { useEffect, useState } from "react";
import { getPrimaryColor, orderDetails } from "../../../utils/api";
import { useNavigate, useParams } from "react-router-dom";
import '../templateUtils/loader.css'
// import HomeTabs from './pages/homeTabs';
import { HomeContext } from "../../../utils/contexts";
import Footer from '../home/footer'
// let Icon = FontAwesomeIcon;

export default function OrderDetails3(props) {

    const navigate = useNavigate();
    const { orderId, dukanId } = useParams();
    const { primaryColour, facebook, instagram, youtube, dukanName, dukanDescription, dukanContactNumber, secondaryColor } = props

    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })


    const [data, setdata] = useState({});
    const [isLoading, setisLoading] = useState(true);
    const [pageLoaded, setpageLoaded] = useState(false);

    useEffect(() => {
        if (primaryColour != '#5893D4aa') {
            setpageLoaded(true)
        }
    }, [primaryColour])

    useEffect(() => {
        orderDetails(setdata, setisLoading, navigate, orderId)
    }, [orderId])

    return (
        <>
            <HomeContext.Provider
                value={{
                    primaryColour
                }}
            >
                {!pageLoaded ?
                    <View style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: Dimensions.get('window').height }}>
                        <div class="loadingio-spinner-chunk-nx0mjvmz269"><div class="ldio-q1sjvrz6fl">
                            <div><div><div></div><div></div><div></div><div></div></div></div>
                        </div></div>
                    </View>
                    :
                    <Tab primaryColour={primaryColour} dukanName={dukanName} dukanContactNumber={dukanContactNumber} secondaryColor={secondaryColor}>
                        <View style={{ backgroundColor: '#f7f7f7' }}>
                            <View style={isDesktopOrLaptop ? { marginTop: 30, } : { marginTop: 50, }}>

                                <ImageBackground source='https://images.unsplash.com/photo-1638871788452-263a601c8616?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80'


                                    // {serverURL + item.image}

                                    imageStyle={

                                        isDesktopOrLaptop ? {
                                            width: '100%',
                                            height: 200,
                                            overflow: 'hidden',
                                            width: '100%',

                                        } : {

                                            width: '100%',
                                            height: 200,
                                            overflow: 'hidden',
                                            justifyContent: "center",
                                            width: '100%',

                                        }
                                    }>

                                    <div class="jumbotron" style={{ margin: 0, padding: 0, borderRadius: 0, backgroundColor: 'rgb(0,0,0,0.9)', height: 200, }}>

                                        <p style={isDesktopOrLaptop ? { marginTop: 50, marginLeft: 70, } : { marginTop: 50, marginLeft: 20, }}>Home / Order / Order Details</p>

                                        <h1 style={{ fontFamily: 'sans-serif', justifyContent: 'center', textAlign: 'center', color: 'white' }}>Order Details</h1>
                                    </div>

                                </ImageBackground>



                            </View>





                            <View style={[{
                                flexDirection: 'row',
                                marginBottom: 90,
                                marginHorizontal: '10%',
                                marginVertical: '5%',

                            },
                            !isDesktopOrLaptop ?
                                { flexDirection: 'column', marginHorizontal: '0.5%', paddingHorizontal: '1%', marginVertical: 3, borderWidth: 0 } : {}
                            ]}>
                                {/* <Text
                    style={{
                        fontWeight: 'bold',
                        fontSize: 15,
                        color: primaryColour,
                        marginTop: 10,
                        textAlign: 'left',

                    }}>
                    Account No:
                </Text> */}
                                {/* <Text style={{ textAlign: 'left', color: primaryColour }}></Text> */}

                                <View style={isDesktopOrLaptop ? { flex: 0.40, } : { flex: 1, }}>

                                    <View
                                        style={[{
                                            flexDirection: 'column',
                                            elevation: 4,
                                        },
                                        !isDesktopOrLaptop ?
                                            { marginVertical: 3, shadowColor: primarybg, borderWidth: 0 } : {}
                                        ]}>

                                        {!isLoading ?

                                            <TouchableOpacity style={[{
                                                elevation: 3,
                                                width: '100%',
                                                flexDirection: 'row',
                                                width: "100%",

                                            },
                                            !isDesktopOrLaptop ? {
                                                marginHorizontal: 0,
                                            } : {}
                                            ]}

                                            >

                                                <View class="sales-banner" style={isDesktopOrLaptop ? { height: 300, width: "100%" } : { height: 200, width: "100%" }}>

                                                    <ImageBackground

                                                        source={typeof data.thumbnail != 'undefined' ? serverURL + data.thumbnail : null}

                                                        imageStyle={

                                                            isDesktopOrLaptop ? {
                                                                width: '100%',
                                                                height: '100%',
                                                                overflow: 'hidden',
                                                                justifyContent: "left",
                                                                width: '100%',

                                                            } : {

                                                                width: '100%',
                                                                height: '100%',
                                                                overflow: 'hidden',
                                                                justifyContent: "center",
                                                                width: '100%',

                                                            }
                                                        }
                                                    >
                                                        <View style={[isDesktopOrLaptop ? {


                                                            background: 'rgb(0,0,0,0.7)',
                                                            flexDirection: 'row',
                                                            height: 525,

                                                            borderColor: 'lightgray',

                                                        }
                                                            : {
                                                                background: 'rgb(0,0,0,0.7)',
                                                                height: 300,
                                                                borderColor: borderColor,

                                                            }
                                                        ]}>


                                                            <View style={isDesktopOrLaptop ? { width: '100%', marginTop: 150, } : { width: '100%', marginTop: 55, }}>


                                                                <View style={isDesktopOrLaptop ? { margin: 0, flexDirection: 'row', justifyContent: 'center', } : { marginTop: 0, flexDirection: 'row', justifyContent: 'center', }}>
                                                                    <span style={isDesktopOrLaptop ? { margin: 0, fontSize: 17, color: textColor, } : { fontSize: 15, color: textColor, }}>#Order . {data.order_number}</span>

                                                                </View>

                                                                <View style={isDesktopOrLaptop ? { justifyContent: 'center', margin: 0 } : { justifyContent: 'center', marginHorizontal: 30, }}>
                                                                    <h1 style={isDesktopOrLaptop ? { margin: 0, fontFamily: 'sans-serif', fontWeight: 600, textAlign: 'center', fontSize: 30, color: textColor, } : { margin: 0, fontFamily: 'sans-serif', fontWeight: 600, textAlign: 'center', fontSize: 30, color: textColor, textTransform: 'uppercase' }}>{data.title}</h1>

                                                                </View>

                                                                <View style={isDesktopOrLaptop ? { margin: 0, flexDirection: 'row', justifyContent: 'center', } : { marginTop: 0, flexDirection: 'row', justifyContent: 'center', }}>
                                                                    <span style={isDesktopOrLaptop ? { margin: 0, fontSize: 17, color: textColor, } : { fontSize: 15, color: textColor, }}>#Placed on . {data.date}</span>

                                                                </View>

                                                                <View style={{ flexDirection: 'column', marginHorizontal: "5%", marginTop: -30, }}>
                                                                    <div>
                                                                        <div class="row">
                                                                            <div class="hh-grayBox" style={{ width: "100%" }}>
                                                                                <div class="row justify-content-between">
                                                                                    <div class={data.status == 'shipped' || data.status == 'delivered' || data.status == 'accepted' ? "order-tracking completed" : "order-tracking"}>
                                                                                        <span class="is-complete"></span>
                                                                                        <p>Accepted<br /></p>
                                                                                    </div>
                                                                                    <div class={data.status == 'shipped' || data.status == 'delivered' ? "order-tracking completed" : "order-tracking"}>
                                                                                        <span class="is-complete"></span>
                                                                                        <p>Shipped<br /></p>
                                                                                    </div>
                                                                                    <div class={data.status == 'delivered' ? "order-tracking completed" : "order-tracking"}>
                                                                                        <span class="is-complete"></span>
                                                                                        <p>Delivered<br /></p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </View>

                                                            </View>




                                                        </View>
                                                    </ImageBackground>

                                                </View>

                                            </TouchableOpacity>

                                            : <LoadingIndicator />}
                                    </View>

                                </View>
                                <View style={isDesktopOrLaptop ? { flex: 0.70, } : { marginTop: 100, }}>

                                    {!isLoading ?
                                        <View style={{
                                            paddingBottom: 40,
                                        }}>
                                            <View style={[{ elevation: 1, paddingHorizontal: 70, paddingVertical: 5, width: '100%', alignSelf: 'center', }, !isDesktopOrLaptop ? { paddingHorizontal: 30, } : {}]}>

                                                <View style={{ flexDirection: 'row', justifyContent: 'right', marginTop: 30, }}>
                                                    <Text style={[{ flex: 1, fontWeight: 'bold', color: 'gray', fontSize: 20, textAlign: 'right', textTransform: 'uppercase', letterSpacing: 3, }, !isDesktopOrLaptop ? { textAlign: 'center', } : null]}>Order summary</Text>
                                                </View>
                                                <hr />

                                                <View style={{ flexDirection: 'row', marginTop: 30 }}>
                                                    <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: 'gray', textTransform: 'uppercase', letterSpacing: 2, }}>Status</Text>
                                                    <Text style={{ textAlign: 'right', flex: 0.4, textTransform: 'uppercase', letterSpacing: 2, color: 'orange' }}>{data.status}</Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                                    <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: 'gray', textTransform: 'uppercase', letterSpacing: 2, }}>Date</Text>
                                                    <Text style={{ textAlign: 'right', flex: 0.4, }}>{data.date}</Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                                    <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: 'gray', textTransform: 'uppercase', letterSpacing: 2, }}>SubTotal</Text>
                                                    <Text style={{ textAlign: 'right', flex: 0.4, }}>Rs. {data.subtotal}</Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                                    <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: 'gray', textTransform: 'uppercase', letterSpacing: 2, }}>Shipping</Text>
                                                    <Text style={{ textAlign: 'right', flex: 0.4, }}>Rs. {data.shipping}</Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                                    <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: 'gray', textTransform: 'uppercase', letterSpacing: 2, }}>Total</Text>
                                                    <Text style={{ textAlign: 'right', flex: 0.4, }}>Rs. {data.total}</Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', justifyContent: 'right', marginTop: 50, }}>
                                                    <Text style={[{ flex: 1, fontWeight: 'bold', color: 'gray', fontSize: 20, textAlign: 'right', textTransform: 'uppercase', letterSpacing: 3, }, !isDesktopOrLaptop ? { textAlign: 'center', } : null]}>Customer Detail</Text>
                                                </View>
                                                <hr />
                                                <View style={{ flexDirection: 'row', marginTop: 30 }}>
                                                    <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: 'gray', textTransform: 'uppercase', letterSpacing: 2, }}>Name</Text>
                                                    <Text style={{ textAlign: 'right', flex: 0.4, }}>{data.full_name}</Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                                    <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: 'gray', textTransform: 'uppercase', letterSpacing: 2, }}>Mobile</Text>
                                                    <Text style={{ textAlign: 'right', flex: 0.4, }}>{data.mobile}</Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                                    <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: 'gray', textTransform: 'uppercase', letterSpacing: 2, }}>Address</Text>
                                                    <Text style={{ textAlign: 'right', flex: 0.4, }}>{data.address}, {data.area}</Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                                    <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: 'gray', textTransform: 'uppercase', letterSpacing: 2, }}>City</Text>
                                                    <Text style={{ textAlign: 'right', flex: 0.4, }}>{data.city}</Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', marginTop: 3 }}>
                                                    <Text style={{ textAlign: 'left', flex: 0.6, fontWeight: 'bold', color: 'gray', textTransform: 'uppercase', letterSpacing: 2, }}>Province</Text>
                                                    <Text style={{ textAlign: 'right', flex: 0.4, }}>{data.province}</Text>
                                                </View>

                                            </View>

                                        </View>

                                        : <LoadingIndicator />}
                                </View>

                            </View>




                            <View style={{ marginTop: 50, }}>
                                <Footer dukanId={dukanId} dukanContactNumber={dukanContactNumber} secondaryColor={secondaryColor} dukanName={dukanName} dukanDescription={dukanDescription} data={data} facebook={facebook} instagram={instagram} youtube={youtube} />
                            </View>
                        </View>
                    </Tab >
                }
            </HomeContext.Provider>


        </>
    );
}

