import { Text, View, ImageBackground, FlatList, Dimensions } from "react-native";
import { borderColor, defaultLink, primarybg, serverURL } from "../../../utils/string";
import { useMediaQuery } from 'react-responsive'
import Tab from "../tab";
import { useEffect, useState } from "react";
import { getCatalogDetails } from "../../../utils/api";
import { toast } from 'react-toastify';
import { LoadingIndicator, storeData } from "../../../utils/utils";
import Product from "../templateUtils/product";
import { useNavigate, useParams } from "react-router-dom";
import '../templateUtils/loader.css'

export default function CatalogPage4(props) {

    const { dukanId, catalogId } = useParams();
    const navigate = useNavigate();
    const { primaryColour, secondaryColor } = props

    const notify = (t) => toast.dark(t, {
        position: toast.POSITION.BOTTOM_CENTER
    });
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const [prevStart, setprevStart] = useState(0);
    const [loadMore, setloadMore] = useState(true);
    const [idsOfCatalogProducts, setidsOfCatalogProducts] = useState([]);
    const [catalogData, setcatalogData] = useState({});
    const [pageLoaded, setpageLoaded] = useState(false);

    useEffect(() => {
        if (primaryColour != '#5893D4aa') {
            setpageLoaded(true)
        }
    }, [primaryColour])

    useEffect(() => {
        if (defaultLink == '') {
            if (typeof dukanId !== 'undefined' && isLoading) {
                storeData(dukanId, 'dukanLink')
                getCatalogDetails(setisLoading, navigate, setdata, data, prevStart, setprevStart, setloadMore, setcatalogData, catalogId, setidsOfCatalogProducts)
                setisLoading(false)
            }
        } else {
            storeData(dukanId, 'dukanLink')
            getCatalogDetails(setisLoading, navigate, setdata, data, prevStart, setprevStart, setloadMore, setcatalogData, catalogId, setidsOfCatalogProducts)
            setisLoading(false)
        }

    }, [])




    return (
        <>
            {!pageLoaded ?
                <View style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: Dimensions.get('window').height }}>
                    <div className="loadingio-spinner-chunk-nx0mjvmz269"><div className="ldio-q1sjvrz6fl">
                        <div><div><div></div><div></div><div></div><div></div></div></div>
                    </div></div>
                </View>
                :
                <Tab primaryColour={primaryColour} secondaryColor={secondaryColor}>
                    {!isLoading ?
                        <>
                            <FlatList
                                ListHeaderComponent={
                                    <ImageBackground
                                        source={catalogData.image != '' ? { uri: serverURL + catalogData.image } : ''}
                                        style={{
                                            height: isDesktopOrLaptop ? 300 : 200, width: '100%',
                                            borderRadius: 0, borderWidth: catalogData.image == '' ? 1 : 0, borderColor: borderColor,
                                            backgroundColor: 'black', marginBottom: 20, marginTop: -20
                                        }}
                                        imageStyle={{ borderRadius: 5, opacity: 0.7 }}
                                        resizeMode='cover'
                                    >
                                        <View style={{ flex: 0.9, justifyContent: 'center', alignItems: 'center', }}>
                                            <Text style={{ fontSize: 25, fontWeight: '600', color: primarybg, textTransform: 'capitalize', textAlign: 'center' }}>{catalogData.name}</Text>
                                            <Text style={{ fontSize: 15, color: primarybg, color: 'lightgray', textTransform: 'capitalize' }}>
                                                {catalogData.total_items} items
                                                {/* || {catalogData.views} views */}
                                            </Text>
                                        </View>
                                    </ImageBackground>
                                }
                                numColumns={isDesktopOrLaptop ? 4 : 2}
                                columnWrapperStyle={[{
                                    flex: 1,
                                    backgroundColor: primarybg,
                                },
                                isDesktopOrLaptop ? { justifyContent: "center", marginHorizontal: '10%' } : { marginHorizontal: 3 }
                                ]}
                                contentContainerStyle={[
                                    { marginTop: 10, justifyContent: 'center', paddingBottom: 50 },
                                    isDesktopOrLaptop && { marginHorizontal: '0%' }]}
                                horizontal={false}
                                data={data}
                                onEndReached={() => {
                                    if (loadMore) {
                                        getCatalogDetails(setisLoading, navigate, setdata, data, prevStart, setprevStart, setloadMore, setcatalogData, catalogId, setidsOfCatalogProducts)
                                    }
                                }}
                                ListEmptyComponent={() => {
                                    return (
                                        <View style={{ marginTop: 20, paddingBottom: 20 }}>
                                            <Text style={{ textAlign: 'center', color: primaryColour }}>No Products in this catalog</Text>
                                        </View>
                                    )
                                }}

                                renderItem={({ item, index }) => (
                                    <Product item={item} primaryColour={primaryColour} />
                                )}

                            />
                        </>
                        :
                        <LoadingIndicator />
                    }
                </Tab>
            }
        </>
    );
}

