import * as React from 'react';
import { useState, useEffect } from 'react';
import { ActivityIndicator, Alert, Button, Dimensions, FlatList, ImageBackground, Linking, RefreshControl, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from "react-router-dom";
import { primarybg, primaryColour } from '../../utils/string';
import Sidebar from '../sidebar';
import { shadow } from '../utils/main';


export default function ApperanceSidebar(props) {
    const move = useNavigate();
    const { children, active } = props
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    return (
        <Sidebar active='customization' title={'Customize your website'}>
            <View style={{ flexDirection: 'row', marginHorizontal: isDesktopOrLaptop ? '10%' : '1%', marginTop: 20 }}>
                <View style={[{ flex: isDesktopOrLaptop ? 0.2 : 0.3, backgroundColor: primarybg, borderBottomLeftRadius: 20, borderBottomRightRadius: 20, paddingBottom: 30 }, shadow]}>
                    <TouchableOpacity style={{ height: 50, width: '100%', backgroundColor: active == 'colors' && primaryColour + '21', justifyContent: 'center', paddingHorizontal: '10%' }}
                        onPress={() => move('/colors-setting')}
                    >
                        <Text style={{ fontWeight: 'bold', color: primaryColour }}>Colors</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={{ height: 50, width: '100%', backgroundColor: active == 'topBanners' && primaryColour + '21', justifyContent: 'center', paddingHorizontal: '10%' }}
                        onPress={() => move('/top-banners-setting')}
                    >
                        <Text style={{ fontWeight: 'bold', color: primaryColour }}>Top Banners</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={{ height: 50, width: '100%', backgroundColor: active == 'storeDetails' && primaryColour + '21', justifyContent: 'center', paddingHorizontal: '10%' }}
                        onPress={() => move('/store-details-setting')}
                    >
                        <Text style={{ fontWeight: 'bold', color: primaryColour }}>Store Details</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={{ height: 50, width: '100%', backgroundColor: active == 'socialLinks' && primaryColour + '21', justifyContent: 'center', paddingHorizontal: '10%' }}
                        onPress={() => move('/social-links-setting')}
                    >
                        <Text style={{ fontWeight: 'bold', color: primaryColour }}>Social Links</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ flex: isDesktopOrLaptop ? 0.8 : 0.7, }}>
                    {children}
                </View>
            </View>
        </Sidebar>
    )
}
